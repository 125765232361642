// LoginPage.js
import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import googleLogo from '../google.svg'
// import microsoftLogo from '../ms.svg'
// import githubLogo from '../github.svg'
import {isAuthenticated} from "./Auth"

const LoginPage = () => {
  const url = process.env.REACT_APP_SERVER_URL;
  const navigate=useNavigate();
  useEffect(() => {
    if(isAuthenticated()){
      navigate('/tenants');
    }
    
  }, []);
  return (
    <div className="login-container">
      <div className="login-div">
      <h2>Login</h2>
        <div className="login-buttons">
          <button
            className="google-button"
            onClick={() => {
              // Handle Microsoft login logic here
            //   console.log(url)
              window.location.href = url + '/google'
            // window.location.href = 'http://localhost:8080/api/google'

            }}
          >
            <img src={googleLogo} alt="Google Logo" />
            Login with Google
          </button>
          {/* <button
            className="msft-button"
            onClick={() => {
              // Handle Microsoft login logic here
              window.location.href = url + '/microsoft'
            }}
          >
            <img src={microsoftLogo} alt="Microsoft Logo" />
            Login with Microsoft
          </button> */}

          {/* <button
            className="git-button"
            onClick={() => {
              // Handle Microsoft login logic here
              window.location.href = url + '/github'
            }}
          >
            <img src={githubLogo} height={24} alt="Github Logo" />
            Login with Github
          </button> */}
        </div>
      </div>
    </div>
  )
}

export default LoginPage;