
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import Header from "./Header.js";
import { Link, redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
const EditFinal = () => {
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    // const [card, setCard] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("Male");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [arrival, setArrival] = useState("");
    const [checkin,setCheckin]=useState("");
    const [checkout, setCheckout] = useState("");
    const [rate, setRate] = useState("");
    const [adults, setAdults] = useState("");
    const [children, setChildren] = useState("");
    const [deposit, setDeposit] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    // const [roomNumber,setRoomNumber]=useState("");
    // const [bedtype, setBedtype] = useState("");
    // const [size, setSize] = useState("");
    const [customerid,setCustomerid]=useState("");
    const [roomid,setRoomid]=useState("");
    const [customersDetails,setCustomersDetails]=useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const url=process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    getTenantById();
    getCustomersByTenantId();
    // alert(fname);
  }, []);
 const updatedates=async (e)=>{
  e.preventDefault();
  try{
    await axios.patch(`${url}/tenants/${id}`, {
      checkin,
      checkout
  })
  }
  catch(error){
    console.log(error);
  }
 }
  const updateTenant = async (e) => {
    e.preventDefault();
    try {
        
      await axios.patch(`${url}/tenants/${id}`, {
        // roomid,
        // customerid,
        arrival,
        checkout,
        rate,
        adults,
        children,
        deposit,
        type,
        status
      });

      await axios.patch(`${url}/customers/${customerid}`, {
        fname,
        lname,
        // card,
        email,
        gender,
        phone,
        address,
        state
      });

      // await axios.patch(`${url}/rooms/${roomid}`, {
      //   bedtype,
      //   size
      // });


      navigate(`/tenants`);
    } catch (error) {
      console.log(error);
    }
  };
 const getCustomersByTenantId = async() => {
  let response=await axios.get(`${url}/tenants/${id}/customers`)
  setCustomersDetails(response.data);
  // console.log(response.data);
 }
  const getTenantById = async () => {
    let response = await axios.get(`${url}/tenants/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    setFname(pp.fname);
    setLname(pp.lname);
    setEmail(pp.email);
    setAddress(pp.address);
    setState(pp.state);
    setPhone(pp.phone);
    setArrival(pp.arrival);
    setRoomid(pp.roomid);
    // alert(pp.checkout);
    setCheckout(pp.checkout);
    setRate(pp.rate);
    setAdults(pp.adults);
    setChildren(pp.children);
    setDeposit(pp.deposit);
    setType(pp.type);
    setStatus(pp.status);
    setCustomerid(pp.customerid); 
    // alert(pp.customerid)
    
  };
 
  return (
    <div>
     <Header/>
     <table className="table is-striped is-fullwidth" id="tble">
      <thead>
      <tr>
        <th>Name</th>
        <th>Name</th>
        <th width="5%">Email</th>
        <th>Phone</th>
        <th>Address</th>
        <th>State</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      {customersDetails.map((c, index) => (
        <tr>
          <td>{c.fname}</td>
        <td>{c.lname}</td>
          <td width="5%">{c.email}</td>
          <td>{c.phone}</td>
          <td>{c.address}</td>
          <td>{c.state}</td>
          <td><Link
                    to={`customers/${c.id}/edit`}
                    // className="button1 is-small is-info mr-2"
                  >
                    Edit
                  </Link></td>
        </tr>
      ))}
      </tbody>
    </table>
    
    <div className="container">
        <form onSubmit={updateTenant}>
          
     
<br></br>

<div class="row">
		<div class="col-half">
		  <h4>Arrival Date</h4>
		  <div class="input-group">
			<div>

			  <input type="date" value={arrival}
                onChange={(e) => {let dat=new Date(e.target.value);setArrival(e.target.value);let cdat=new Date(dat.getTime() + 7*24*60*60*1000);var input2 = document.getElementById('checkout');input2.value = cdat;checkout=toString(cdat);setCheckout(cdat)}} 
                placeholder="Arrival Date"/>
                
			</div>
		  </div>
		</div>
		<div class="col-half">
			<h4>Checkout Date</h4>
			<div class="input-group">
			  <div> 
				<input type="date" 
                value={checkout}
                id="checkout"
                onChange={(e) => setCheckout(e.target.value)} placeholder="Checkout Date"/>
			  </div>
			</div>
		  </div>
		
	  </div>
    <div class="row">
			
		<div class="col-third1">
		<div class="input-group input-group-icon">
			<input type="float" value={rate}
                onChange={(e) => {setRate(e.target.value);alert(checkout)}} placeholder="Rate(weekly)"/>
			<div class="input-icon"><i class="fa fa-dollar"><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon></i></div>
		  </div>
		  </div>
		  <div class="col-third1">
		  <div class="input-group input-group-icon">
			<input type="number" value={adults}
                onChange={(e) => setAdults(e.target.value)} placeholder="Adults"/>
			<div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		</div>
		</div>
			<div class="col-third1">
			<div class="input-group input-group-icon">
				<input type="number" value={children}
                onChange={(e) => setChildren(e.target.value)} placeholder="Children"/>
				<div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		  </div>
	  </div>
	  </div>

	  <div class="row">
		<div class="input-group input-group-icon">
			<input type="float" value={deposit}
                onChange={(e) => setDeposit(e.target.value)} placeholder="Deposit"/>
			<div class="input-icon"><i class="fa fa-dollar"><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon></i></div>
		  </div>
	  </div>

    <div class="row">
		<div class="col-half">
		<h4>Type</h4>
		<div>
			<select
			value={type}
			name="type"
			onChange={(e) => setType(e.target.value)}
                >
                  <option value="weekly">Weekly</option>
                  <option value="daily">Daily</option>
                </select>
			</div>
		</div>
		<div class="col-half">
			<h4>Status</h4>
			<div>
				<select
					  value={status}
            name="status"
            onChange={(e) => setStatus(e.target.value)}
					>
					  <option value="active">Active</option>
					  <option value="past">Past</option>
					</select>
				</div>
			</div>
		
	  </div>
    {/* <div class="row">
		<div class="col-third">
		<h4>Available Rooms</h4>
		<div>
			<select
                value={roomid}
                name="Available Rooms"
                onChange={(e) => setRoomid(e.target.value)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
			</div>
		</div>
    </div> */}

          <div className="field">
            <button type="submit" className="button is-success">
              Check In
            </button>
            
            <button type="submit" className="button is-success">
              Check Out 
            </button>
          </div>
        </form>
        <button type="submit" className="button is-success" onClick={updatedates}>
              Check Out and Renew
            </button>
      </div>
      </div>
  );
};
 
export default EditFinal;