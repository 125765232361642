import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Container } from "rsuite";
const DisplayImages= () =>{  
    const [images,setImages]=useState([]);
    const url=process.env.REACT_APP_SERVER_URL;
    const submit = async(event) => {
      event.preventDefault()
  
      await axios.get(`${url}/allImages`).then((response)=>{
        console.log(response);
        setImages(response.data);
      })
    }
  
    return (
      <>
        <>
          {images.map((row, index) => (
            <>
            <Container className="w-50 h-150">
            <img src={row.imageUrl} style={{marginBottom:"5%",marginLeft:"50%",width:"100%"}}></img><br></br>
            </Container>
            </>
          ))}
        </>

        <form onSubmit={submit}>
           <button type="submit">Submit</button>
        </form>
      </>
    )
  }
  export default DisplayImages;