import Header from '../Header.js'
import { Table, Pagination } from 'rsuite'
import React, { useState, useEffect } from 'react'
import { formatDate, formatDateText } from '../CommonFunctions'
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link, useNavigate } from 'react-router-dom'
import ConfigureModal from './ConfigureModal.js'
import PaymentViewModal from './PaymentViewModal.js'
const { Column, HeaderCell, Cell } = Table

const CurrentCash = () => {
  const [defaultData, setDefaultData] = useState([])
  const [showConfigure, setShowConfigure] = useState(false)
  const [updateCashbox, setUpdateCashbox] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [selectedRow, setSelectedRow] = useState(false)
  const [totalCashBox, setTotalCashBox] = useState(false)
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(1)
  let totalTableCash = 0,
    totalTableOther = 0,
    totalTableAmt = 0,
    totalCash = 0,
    totalOther = 0,
    totalAmt = 0
  const url = process.env.REACT_APP_SERVER_URL
  const navigate = useNavigate()

  const handleChangeLimit = (dataKey) => {
    setPage(1)
    setLimit(dataKey)
  }

  const data = defaultData.filter((v, i) => {
    const start = limit * (page - 1)
    const end = start + limit
    return i >= start && i < end
  })

  useEffect(() => {
    setDefaultData([])
    getCashBox()
    getCurrentTransactions()
  }, [updateCashbox])

  data.forEach((item) => {
    totalTableCash += item.cashAmount
    totalTableOther += item.otherAmount
    totalTableAmt += item.totalAmount
  })

  defaultData.forEach((item) => {
    totalCash += item.cashAmount
    totalOther += item.otherAmount
    totalAmt += item.totalAmount
  })

  const getCurrentTransactions = async () => {
    try {
      await axios.get(`${url}/currentTransactions`).then((response) => {
        setDefaultData((prevData) => [...prevData, ...response.data])
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getCashBox = async () => {
    try {
      await axios.get(`${url}/cashBox`).then((response) => {
        setUpdateCashbox(false)
        setDefaultData(response.data)
        setTotalCashBox(response.data[0].totalAmount)
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Header />
      <div className="container" id="container">
        <Row style={{ padding: '10px 80px' }}>
          <Col sm>
            <h4>Total Cash: ${totalCash}</h4>
          </Col>
          <Col sm>
            <h4>Total Other: ${totalOther}</h4>
          </Col>
          <Col sm>
            <h4>Total CashBox: ${totalCashBox}</h4>
          </Col>
          <Col sm>
            <h4>Total Sum: ${totalAmt}</h4>
          </Col>
          <Col sm>
            <Button
              style={{ float: 'right' }}
              onClick={
                (e) => {
                  setShowConfigure(true)
                }
                // getCurrentCash(e, searchStartDate, searchEndDate)
              }
            >
              Edit
            </Button>
          </Col>
        </Row>
        <br></br>
        <Table
          className="ms-5"
          height={500}
          data={data}
          size="lg"
          headerHeight={60}
        >
          <Column width={100} fixed>
            <HeaderCell>
              <b>Payment ID</b>
            </HeaderCell>
            <Cell dataKey="pid">
              {(rowData) => (
                <a
                  style={{
                    color: 'var(--rs-text-link-hover)',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    setSelectedRow(rowData)
                    setShowPayment(true)
                  }}
                >
                  {rowData.pid}
                </a>
              )}
            </Cell>
          </Column>
          <Column width={100} fixed>
            <HeaderCell>
              <b>Room ID</b>
            </HeaderCell>
            <Cell dataKey="roomid">
              {(rowData) => (
                <a
                  onClick={(e) => {
                    // setValueTenancyId(row.id);
                    navigate(`/tenants/add/${rowData.tid}`, {
                      state: { tenancyId: rowData.tid },
                    })
                  }}
                >
                  <Link>{rowData.roomid}</Link>
                </a>
              )}
            </Cell>
          </Column>
          <Column width={200} fixed>
            <HeaderCell>
              <b>Payment Date</b>
            </HeaderCell>
            <Cell dataKey="paymentDate">
              {(rowData) => <>{formatDateText(rowData.paymentDate)}</>}
            </Cell>
          </Column>
          <Column width={200} fixed>
            <HeaderCell>
              <b>Paid By</b>
            </HeaderCell>
            <Cell dataKey="paidBy" />
          </Column>
          <Column width={200} fixed>
            <HeaderCell>
              <b>Receipt</b>
            </HeaderCell>
            <Cell dataKey="receiptNumber"></Cell>
          </Column>

          <Column width={100} align="right">
            <HeaderCell>
              <HeaderSummary title="Cash" total={totalTableCash} />
            </HeaderCell>
            <NumberCell dataKey="cashAmount" />
          </Column>

          <Column width={100} align="right">
            <HeaderCell>
              <HeaderSummary title="Other" total={totalTableOther} />
            </HeaderCell>
            <NumberCell dataKey="otherAmount" />
          </Column>

          <Column width={100} align="right">
            <HeaderCell>
              <HeaderSummary title="Total" total={totalTableAmt} />
            </HeaderCell>
            <NumberCell dataKey="totalAmount" />
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={defaultData.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      {showConfigure && (
        <ConfigureModal
          data={{ showConfigure, setShowConfigure, setUpdateCashbox }}
        ></ConfigureModal>
      )}
      {showPayment && (
        <PaymentViewModal
          data={{ showPayment, setShowPayment, selectedRow }}
        ></PaymentViewModal>
      )}
    </>
  )
}

const thousands = (value) => `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, '$1,')
const NumberCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
)

const HeaderSummary = ({ title, total }) => (
  <div>
    <label>
      <b>{title}</b>
    </label>
    <div
      style={{
        fontSize: 18,
        color: '#2eabdf',
      }}
    >
      {'$' + thousands(total)}
    </div>
  </div>
)

export default CurrentCash
