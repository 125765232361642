

import React, { useState, useEffect, useRef,useContext } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { Link } from "react-router-dom";
import "../App.css";
import Header from "./Header.js";
import {Row , Col} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Box } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { dateDifference, formatDateText } from "./CommonFunctions.js";
import { PatternFormat } from 'react-number-format';
import {
  Autocomplete,
  TextField,
  Button

} from "@mui/material"
import TenancyDetailsByLTTI from "./TenancyDetailsByLTTI.js";
 const SearchCustomers = () => {

  const [fname,setFname]=useState(null);
  const [lname,setLname]=useState(null);
  const [phone,setPhone]=useState(null);
  const [showTenancyDetailsByLTTIModal,setShowTenancyDetailsByLTTIModal]= useState(false)
  const [customers,setCustomersData]=useState([]);
  const [allCustomersInfo,setAllCustomersInfo] = useState([])
  const [selectedRow,setSelectedRow]=useState({})
  const [longTermTenancyId,setLongTermTenancyId]=useState("")
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [tenancyDetailsByLTTI,setTenancyDetailsByLTTI] = useState([])
  const url=process.env.REACT_APP_SERVER_URL;
   
    useEffect(() => {

        getAllCustomers();
     
   }, [loading]);
   const columns=[
    { field: 'Name', headerName: 'Name', width: 150, align: 'left' ,
    renderCell:(params)=>{
      return params.row.fname + ' ' + params.row.lname;
      
    } },
    { field: 'phone', headerName: 'Phone Number', width: 160, align: 'left', 
    renderCell:(params)=>{
      return <PatternFormat  format="+1 (###) ###-####" 
       value={params.row.phone} readOnly displayType="text" 
      />
      
    }
    },
    { field: 'address', headerName: 'Address', width: 200, align: 'left' ,
    renderCell:(params)=>{
      return params.row.address + ' - ' + params.row.state;
      
    } },
    { field: 'firstCheckin', headerName: 'Tenancy Period', width: 300, align: 'left' ,
    renderCell:(params)=>{
      return <Link
        onClick={()=>{
          setSelectedRow(params.row);
          setShowTenancyDetailsByLTTIModal(true);
        }}
      >{formatDateText(params.row.firstCheckin) + ' - ' + 
        formatDateText(params.row.lastCheckout)}
      </Link>
      
    } },
    { field: 'Stay Duration', headerName: 'Stay Duration', width: 200, align: 'left' ,
    renderCell:(params)=>{
      const diff=dateDifference(params.row.firstCheckin,params.row.lastCheckout);
      const weeks=Math.floor(diff/7);
      const days=diff%7;
      return weeks + ' weeks ' +  ( days>0 ? days + 'days' : '');
      
    } },
    
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width:"140",
      renderCell:(params)=>{
        return <>Show Details
        </>
      }
    }
      
        
  ];
  
  const getAllCustomers = async()=>{
    try{
      const response= await axios.get(`${url}/customers`);
      if(response){
        setAllCustomersInfo(response.data);

      }
    

    }
    catch(error){
      console.log(error)
    }
  }
   const getCustomersByName= async()=>{
    try{

        const response1=await axios.post(`${url}/searchCustomers`,{
            fname,
            lname,
            phone
        });
        const response2=await axios.post(`${url}/getTenancyByCustomerName`,{
          lname,
          fname,
          phone
        })
        console.log(response1.data)
        var dict1={}; //Mapping customerId to customer details
        var dict2={}; //Mapping LTTI to Tenancy Details
        var dict3={}; //LTTI to customerId
        for(var i=0;i<response1.data.length;i++){
          const itr=response1.data[i];
          dict1[itr["id"]]=itr;
        }
        console.log(dict1)
        for(var i=0;i<response2.data.length;i++){
          const itr=response2.data[i];
          if(itr["status"]!="draft"){
            if(itr["longTermTenancyId"] in dict2){
              dict2[itr["longTermTenancyId"]].push(itr)
            }
            else{
              dict2[itr["longTermTenancyId"]]=[];
              dict2[itr["longTermTenancyId"]].push(itr)
            }
            dict3[itr["longTermTenancyId"]]=itr["customerid"];
          }
          
        }
        const tableData=[];
        for(var temp in dict2){
          var fcheckin=dict2[temp][0].checkin;
          var lcheckout=dict2[temp][dict2[temp].length-1].checkout;
          var customerId=dict3[temp];
          if(customerId in dict1){
            var tempJson={
              firstCheckin:fcheckin,
              lastCheckout:lcheckout,
              longTermTenancyId:dict2[temp][0].longTermTenancyId,
              customerId:dict3[temp],
              fname:dict1[customerId].fname,
              lname:dict1[customerId].lname,
              phone:dict1[customerId].phone,
              address:dict1[customerId].address,
              state:dict1[customerId].state
            }
            tableData.push(tempJson)
          }
          

        }
        console.log(tableData)
        setCustomersData(tableData);
        

    }
    catch(error){
        console.log(error);
    }
   }



   
    return (
        <>
        <Header />
        <br></br>
        <div  style={{width:"90%"}}>
          
    <Row className="ms-5 mt-2">
      
        <Col>
        <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={allCustomersInfo.map((option) => option.fname)}
        onInputChange={(event, newInputValue) => {
          console.log(newInputValue)
          if(newInputValue.length===0){
            setFname(null);
          }
          else{
            setFname(newInputValue)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="First Name"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        />
      </Col>
      <Col>
        <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={allCustomersInfo.map((option) => option.lname)}
        onInputChange={(event, newInputValue) => {
          console.log(newInputValue)
          if(newInputValue.length===0){
            setLname(null);
          }
          else{
            setLname(newInputValue)
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Last Name"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        />
      </Col>
      <Col>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          options={allCustomersInfo.map((option) => option.phone)}
          onInputChange={(event, newInputValue) => {
            console.log(newInputValue)
            if(newInputValue.length===0){
              setPhone(null);
            }
            else{
              setPhone(newInputValue)
            }
          }}
          
          renderInput={(params) => (
            <TextField
              {...params}
              label="Phone Number"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
          />
      </Col>
      <Col className='mt-2 ps-5'>
      <Button variant="contained" size="large" onClick={(e)=>{

        getCustomersByName()}
        }>
          Search
        </Button>
      </Col>
        </Row><br></br>
        <Box
        sx={{
          height: 500,
          width: '100%',
          marginLeft:'5%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={customers} columns={columns}
        getRowId={(row) => row.longTermTenancyId}/>
        
      </Box>
        </div>
        
 
        {showTenancyDetailsByLTTIModal && <TenancyDetailsByLTTI 
        longTermTenancyId={selectedRow.longTermTenancyId}
        showTenancyDetailsByLTTIModal={showTenancyDetailsByLTTIModal}
        setShowTenancyDetailsByLTTIModal={setShowTenancyDetailsByLTTIModal}
        />}
        </>
        
    );
   
   }
export default SearchCustomers;