

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import $ from 'jquery';
import Header from "./Header.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faSearch,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {dateDifference, formatDate, parseResponseJSON, getDataSorted, formatDateText} from "./CommonFunctions";
import {Form,Row,Col,Button,Modal} from "react-bootstrap"
import MakePayment from "./MakePayment";
import { Message,Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

const AddPayment = () => {
    const [searchStartDate,setSearchStartDate]=useState(formatDate(new Date(),-6));
    const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date(),1));
    const [roomNumber,setRoomNumber]=useState("")
    // const [selectedTenancyDetails,setSelectedTenancyDetails]=useState({});
    const [isMakePaymentComponentVisible,setIsMakePaymentComponentVisible]=useState(false)
    const [paymentHistory,setPaymentHistory]=useState([]);
    const [customerDetails,setCustomerDetails]=useState([]);
    const [tenancyDetails,setTenancyDetails]=useState([]);
    // const [rroomIds,setRRoomIds]=useState([])
    // const [roomid,setRoomid]=useState(null)
    // const [tenantIds,setTenantIds]=useState([]);    
    const [paymentDetails,setPaymentDetails]=useState({})
    const [tenancyId,setTenancyId]=useState(null);
    const [paymentId,setPaymentId]=useState(null);
    const [allRooms,setAllRooms]=useState([]);
    const [isRoomNumberInSearch, setIsRoomNumberInSearch]=useState(false);
    const [dateColumnFilter,setDateColumnFilter]=useState("systemPaymentDate");
    const [isMissingPayments,setIsMissingPayments]=useState(false);
    const [totalCashAmount,setTotalCashAmount]=useState(0);
    const [totalCreditAmount,setTotalCreditCardAmount]=useState(0);
    const [totalOtherAmount,setTotalOtherAmount]=useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");
    const [loading, setLoading] = useState(false);
    const url=process.env.REACT_APP_SERVER_URL;
    var balance=0;
    var previd=0;
    var totalRunningBalance=0;
    let paymentid=null;
    var totalRent=0;
    var totalAmountPaid=0;
    

    useEffect(() => {
        // displayRooms();
        // getPaymentHistoryByTenantId();
        // getCustomerDetailsByTenantId(); 
        getAllRooms();
        getRentedTenancyDetailsByRoomIdAndDateRange(null);
        
      }, []);
      const getAllRooms=async()=>{
        try{
          await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
            console.log(responseForAllRooms.data);
            setAllRooms(responseForAllRooms.data);
          })
    
        }
        catch(error){
          console.log(error);
        }
       }
      const handleCloseEditPayment=()=>{
        setPaymentId("");
        setIsMakePaymentComponentVisible(false);
        setPaymentDetails({})
      }
      const handleEditPayment=(paymentDetails)=>{
        setPaymentId(paymentDetails.paymentId)
        // setTenancyId(tenancyId)
        setPaymentDetails(paymentDetails)
        setIsMakePaymentComponentVisible(true)
      }
  

      function calculateBalance (rent, paid_amount,id,nextIdIndex){
        // console.log("sbsdgvfyhdv");
        if(nextIdIndex==null){
          if(previd!=id)
          {
            // alert(id)
            balance=rent;
            previd=id;
          }
          if(balance<0){
            balance = paid_amount + balance ;
          }
          else{
            balance = paid_amount - balance ;}
        if ( balance < 0 ) {
        return <label> -  ${balance*-1}</label>
        }
        
        if ( balance >= 0) {
        return <label> ${balance}</label>;
        }
        
        return balance;
      }
      else{
        totalRent=totalRent+ rent;
        totalAmountPaid = totalAmountPaid + paid_amount;
        if(tenancyDetails[nextIdIndex]){
          var nextId= tenancyDetails[nextIdIndex].id;
          if(nextId!=id)
          {
            totalRunningBalance+=balance
            return <label> {totalRunningBalance} </label>;
          }
        }
        else{
          totalRunningBalance+=balance
          return <label> {totalRunningBalance} </label>;
        }
      }
      }


      

      function exportReportToExcel() {
        alert("converting this payment history page to excel doc")
        }
      function calculateCardandCashAmount(paymentData){
        var totalCash=0;
    var totalCredit=0;
      var totalOther=0;
        for(var i=0;i<paymentData.length;i++){
          if(paymentData[i].cashAmount)
          totalCash+=paymentData[i].cashAmount;
          if(paymentData[i].creditCardAmount)
          totalCredit+=paymentData[i].creditCardAmount;
          if(paymentData[i].otherAmount)
          totalOther+=paymentData[i].otherAmount;
        }
        setTotalCashAmount(totalCash);
        setTotalCreditCardAmount(totalCredit);
        setTotalOtherAmount(totalOther)

      }
      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
        
    const getRentedTenancyDetailsByRoomIdAndDateRange=async(e)=>{
      if(e) e.preventDefault();
        try{
          if(isMissingPayments&&roomNumber){
            await axios.get(`${url}/missingPayments/${roomNumber}`).then((responseForTenancyDetails)=>{
              console.log(responseForTenancyDetails.data);
            setTenancyDetails(responseForTenancyDetails.data);
            setIsRoomNumberInSearch(true);
           
          })
            

          }
          else{
            if(roomNumber){
              await axios.post(`${url}/rentedTenancyDetailsByRoomId/${roomNumber}`,{
                  searchStartDate,
                  searchEndDate,
                  dateColumnFilter
              }).then((responseForTenancyDetails)=>{
                setTenancyDetails(responseForTenancyDetails.data);
                setIsRoomNumberInSearch(true);
                calculateCardandCashAmount(responseForTenancyDetails.data);
               
              })
            }
            else{
              
              await axios.post(`${url}/payments/allPaymentsinDateRange`,{
                searchStartDate,
                searchEndDate,
                dateColumnFilter
              }).then(async(responseForTenancyDetails)=>{
                console.log(responseForTenancyDetails.data)
                setTenancyDetails(responseForTenancyDetails.data);
                setIsRoomNumberInSearch(false);
                calculateCardandCashAmount(responseForTenancyDetails.data);
               
              })
            }
          }



        }catch(error){
            console.log(error)
        }
    }

    const getPaymentHistoryByRoomIdAndDateRange=async(e)=>{
      if(e) e.preventDefault();
      try{
        await axios.post(`${url}/paymentHistoryByRoomIdAndDateRange/${roomNumber}`,{
          searchStartDate,
          searchEndDate
        }).then((responseForPaymentHistory)=>{
          console.log(responseForPaymentHistory.data)
        })

      }catch(error){
        console.log(error)
      }
    }

    const getPaymentHistoryByTenantId=async(id)=>{
        try{
            const response=await axios.get(`${url}/paymentHistory/${id}`);
            setTenancyId(id);
            getCustomerDetailsByTenantId(id);

            setPaymentHistory(response.data)

        }catch(error){
            console.log(error)
        }
    }
    const getCustomerDetailsByTenantId=async(id)=>{
        try{
            
            const response=await axios.get(`${url}/tenants/${id}/customers`);
            setCustomerDetails(response.data);
            console.log(response.data)
            return response.data;
            // setPaidBy(response.data[0].fname+" "+response.data[0].lname)

        }catch(error){
            console.log(error)
        }
    }

    return(
        <div>
            <Header/>
            <div className="container">
            {/* <h4 className="ms-5 mt-3">Search By Start and End Date: </h4> */}
            
            <Row className="ps-5 mt-3 mb-0">
              <Col className="ps-5" style={{float:"right"}}>
              <Form.Label><b>Select a Room:</b></Form.Label>
                <Form.Select className="w-75 h-50"  size="sm"
                    value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value)}}>
                  <option value='' ></option>
                  {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.id}</option>
                ))}
                </Form.Select>
              </Col>
              <Col className="mt-4">
              { roomNumber ? 
              <>
              {/* <div style={{display:"flex"}}>
              <Form.Check size="lg" ></Form.Check><b>&nbsp;Show all past records</b>
              </div> */}
              <div style={{display:"flex"}}>
              <Form.Check size="lg" checked={isMissingPayments} 
            onChange={(e)=>setIsMissingPayments(!isMissingPayments)}></Form.Check><b>&nbsp;Show only missing payments</b>
              </div>
              </>
              : null}
              </Col>
              <Col>
              <Form.Label><b>Start Date:</b></Form.Label>
              <Form.Control
                className="w-75"
                type="date"
                value ={searchStartDate} 
                onChange={(e)=>{
                  setSearchStartDate(e.target.value);
                }}
                aria-describedby="basic-addon1"
              />
              </Col>
              <Col>
                <Form.Label><b>End Date:</b></Form.Label>
                <Form.Control
                  className="me-5 w-75"
                  type="date"
                  value ={searchEndDate} 
                  onChange={(e)=>{
                    setSearchEndDate(e.target.value);
                  }}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col>{dateDifference(searchStartDate,searchEndDate)} days</Col>
              <Col className="w-25 h-25 mt-4">
              <Button variant="secondary" onClick={(e)=>getRentedTenancyDetailsByRoomIdAndDateRange(e)}>
                <b>Search for payments</b></Button>
              </Col>
              <Col className="w-25 h-25 mt-4">
                <Button className="mb-4 me-5" style={{float:"right"}} 
                // onClick={(e)=>handleShowMakePaymentPopup()}
                onMouseUp={()=>{{setIsMakePaymentComponentVisible(false)}}}
                onTouchMove={()=>{{setIsMakePaymentComponentVisible(false)}}}
                onClick={()=>{setPaymentId("");setPaymentDetails({});setIsMakePaymentComponentVisible(true)}}
                >Make Payment</Button>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup" 
                onChange={()=>setDateColumnFilter("systemPaymentDate")}></Form.Check>
              <b> &nbsp;System Payment Date </b></Col>

              <Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup" 
                onChange={()=>setDateColumnFilter("actualPaymentDate")}></Form.Check>
              <b>  &nbsp;Actual Payment Date </b></Col>

              <Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup"
                onChange={()=>setDateColumnFilter("checkinDate")}/> 
              <b>  &nbsp;Checkin Date </b></Col>
              
              <Col></Col>
            </Row>
           {/* FIXME : This condition applies to search end date as well. Please fix */}
            {isRoomNumberInSearch && tenancyDetails.length>0  
              && searchStartDate > tenancyDetails[0].firstArrivalDate
              && <Message className="fs-5" showIcon type="warning" closable>
              Guest moved in on {tenancyDetails[0].firstArrivalDate }. Please use this date as the search start date to get all relevant records
            </Message> }
            
            {isMakePaymentComponentVisible  && <MakePayment value={{paymentDetails:paymentDetails,customerDetails:customerDetails}}
            getCustomerDetailsByTenantId={getCustomerDetailsByTenantId}
            getRentedTenancyDetailsByRoomIdAndDateRange={getRentedTenancyDetailsByRoomIdAndDateRange}
            />}
            {/* {isMakePaymentComponentVisible && Object.keys(paymentDetails).length > 0 && <MakePayment value={{paymentDetails:paymentDetails}}/>} */}
            <br></br>
            </div>
            <div className="container">
            

              {tenancyDetails.length ? 


<>
<table className="table is-striped is-fullwidth" id="payment-history">
          <thead>
            <tr>
               <th>Room</th>
               <th>Paid By</th>
               <th>Receipt Number</th>
              <th>Checkin</th>
              <th>Checkout</th>
              
              <th>Room Rate</th>
              <th>Payment</th>
              <th>Cash Amount</th>
              <th>Credit card Amount</th>
              <th>Other Amount</th>
              <th>System Payment Date</th>
              <th>Actual Payment Date</th>
              <th>Balance</th>
              
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tenancyDetails.map((row, index) => (
              <tr>
                <td><Link to={`/tenants/add/${row.id}`}>{row.roomid}</Link></td>
                <td>{row.paidBy}</td>
                <td>{row.receiptNumber}</td>
                 <td>{row.checkin}</td>
                <td>{row.checkout}</td>
                
                
                <td><b>$ </b>{row.rate}</td>
                {row.paidAmount ==0 ? <td> No Payment </td> : <td> ${row.paidAmount} </td>}
                <td>{row.cashAmount ? "$"+row.cashAmount : "-"}</td>
                <td>{row.creditCardAmount ? "$"+row.creditCardAmount : "-"}</td>
                <td>{row.otherAmount ? "$"+row.otherAmount : "-"}</td>
                <td>{row.paymentDate!=null ? new Date(row.paymentDate).toString().substring(0,16) : ""}</td>
                <td>{row.actualPaymentDate}</td>
                <td>{ calculateBalance(row.rate, row.paidAmount,row.id,null)}</td> 
                <td style={{display:"none"}}>{calculateBalance(row.rate,row.paidAmount,row.id,index+1)}</td>
                
                
       
          <td><Link onMouseUp={handleCloseEditPayment} 
          onTouchMove={handleCloseEditPayment}
          onClick={()=>handleEditPayment(row)}>Edit</Link><br></br></td>
              </tr>
            ))}


          <tr>
            <td></td><td></td><td></td><td></td><td></td>
            <td> <b> Expected rent: ${totalRent} </b> </td>
            <td> <b> Amount Received: ${totalAmountPaid} </b> </td>
            <td><b>Cash: ${totalCashAmount}</b></td>
            <td><b>Card: ${totalCreditAmount}</b></td>
            <td><b>Others: ${totalOtherAmount}</b></td>
            <td>
              </td>
            <td></td>
            <td></td>
          </tr>
          
          </tbody>
        </table>

    {/* <Table
      className="ms-1"
      autoHeight
      wordWrap="break-word"
      data={getDataSorted(sortColumn,sortType,tenancyDetails)}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}
      headerHeight={60}
      >

      <Column width={80} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Room</b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (

            <Link to={`/tenants/add/${rowData.id}`}>{rowData.roomid}</Link>
            
          )}
          </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell><b>Paid By</b></HeaderCell>
        <Cell dataKey="paidBy" />
      </Column>

      <Column width={150} sortable>
        <HeaderCell><b>Receipt Number </b></HeaderCell>
        <Cell dataKey="receiptNumber" />
      </Column>

      <Column width={150} sortable>
        <HeaderCell><b>Checkin </b></HeaderCell>
        <Cell dataKey="checkin">
        {rowData => (
            <>
              {formatDateText(rowData.checkin)}
            </>

            
          )}
        </Cell>
      </Column>

      <Column width={150} sortable>
        <HeaderCell><b>Checkout </b></HeaderCell>
        <Cell dataKey="checkout" >
        {rowData => (
            <>
              {formatDateText(rowData.checkout)}
            </>

            
          )}
        </Cell>
      </Column>
      
      <Column width={120} sortable>
        <HeaderCell style={{height:"5rem"}}><b>Room Rate </b></HeaderCell>
        <Cell dataKey="rate" />
      </Column>
      
      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Payment</b></HeaderCell>
        <Cell dataKey="paidAmount">
          {rowData => (
            <>
              {rowData.paidAmount ==0 ?  "No Payment" :  "$"+ rowData.paidAmount}
            </>
            
          )}
          </Cell>
      </Column>
      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Cash Amount</b></HeaderCell>
        <Cell dataKey="cashAmount">
          {rowData => (
            <>
              {rowData.cashAmount ? "$"+rowData.cashAmount : "-"}
            </>

            
          )}
          </Cell>
      </Column>
      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Credit card Amount</b></HeaderCell>
        <Cell dataKey="creditCardAmount">
          {rowData => (
            <>
              {rowData.creditCardAmount ? "$"+rowData.creditCardAmount : "-"}
            </>

            
          )}
          </Cell>
      </Column>
      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Other Amount</b></HeaderCell>
        <Cell dataKey="otherAmount">
          {rowData => (
            <>
            {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
            </>
            
          )}
          </Cell>
      </Column>


      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Other Amount</b></HeaderCell>
        <Cell dataKey="otherAmount">
          {rowData => (
            <>
            {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
            </>
            
          )}
          </Cell>
      </Column>


      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Other Amount</b></HeaderCell>
        <Cell dataKey="otherAmount">
          {rowData => (
            <>
            {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
            </>
            
          )}
          </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell style={{height:"5rem"}}><b style={{color:"black"}}>Other Amount</b></HeaderCell>
        <Cell dataKey="otherAmount">
          {rowData => (
            <>
            {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
            </>
            
          )}
          </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell style={{backgroundColor:"black"}}><b style={{color:"black"}}>Other Amount</b></HeaderCell>
        <Cell dataKey="otherAmount">
          {rowData => (
            <>
            {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
            </>
            
          )}
          </Cell>
      </Column>

        </Table> */}







        </>
         : <Message showIcon type="error" closable>
         No transactions in the selected Date Range
       </Message>} 


            
               <br></br>

           
            </div>
        </div>
    )
};
export default AddPayment;



