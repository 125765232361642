import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import EditIcon from '@rsuite/icons/Edit'
import AddReview from './AddReview'

const CustomerReviewModal = (data) => {
  const [tableData, setTableData] = useState([])
  const [isEditReview, setIsEditReview] = useState(null)
  const url = process.env.REACT_APP_SERVER_URL
  const { Column, HeaderCell, Cell } = Table
  useEffect(() => {
    // viewReviewByPhone(data.data.phone)
    if (data.data.customerId) getReviewsByCustomerId(data.data.customerId)
    else getReviewsByTenantId(data.data.tenantId)
  }, [isEditReview])

  const closeModal = () => {
    data.data.setShowReview(false)
  }

  const getReviewsByCustomerId = async (customerId) => {
    try {
      await axios
        .get(`${url}/getReviewsByCustomerId/${customerId}`)
        .then((responseForReviews) => {
          setTableData(responseForReviews.data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getReviewsByTenantId = async (tenantId) => {
    try {
      await axios
        .get(`${url}/getReviewsByTenantId/${tenantId}`)
        .then((responseForReviews) => {
          setTableData(responseForReviews.data)
        })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Modal
      open={data.data.showReview}
      onClose={closeModal}
      size="xl"
      style={{ zIndex: 2000 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Customer Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table height={300} data={tableData} autoHeight={true} id="table">
          <Column width={150} align="center" fixed>
            <HeaderCell>Overall Rating</HeaderCell>
            <Cell>
              {(rowData) =>
                Array.from({ length: rowData.overallRating }).map((_, i) => (
                  <span key={i}>⭐️</span>
                ))
              }
            </Cell>
          </Column>
          <Column flexGrow={2} align="center" fixed>
            <HeaderCell>Comment</HeaderCell>
            <Cell>{(rowData) => `${rowData.managerComments}`}</Cell>
          </Column>
          <Column width={150} align="center" fixed>
            <HeaderCell>Noisy</HeaderCell>
            <Cell>
              {(rowData) =>
                Array.from({ length: rowData.noisy }).map((_, i) => (
                  <span key={i}>⭐️</span>
                ))
              }
            </Cell>
          </Column>
          <Column width={150} align="center" fixed>
            <HeaderCell>Cleanliness</HeaderCell>
            <Cell>
              {(rowData) =>
                Array.from({ length: rowData.cleanliness }).map((_, i) => (
                  <span key={i}>⭐️</span>
                ))
              }
            </Cell>
          </Column>
          <Column width={150} align="center" fixed>
            <HeaderCell>Pay On Time</HeaderCell>
            <Cell>
              {(rowData) =>
                Array.from({ length: rowData.payOnTime }).map((_, i) => (
                  <span key={i}>⭐️</span>
                ))
              }
            </Cell>
          </Column>
          <Column width={150} align="center" fixed>
            <HeaderCell>Traffic</HeaderCell>
            <Cell>
              {(rowData) =>
                Array.from({ length: rowData.traffic }).map((_, i) => (
                  <span key={i}>⭐️</span>
                ))
              }
            </Cell>
          </Column>
          <Column width={150} align="center" fixed>
            <HeaderCell>Action</HeaderCell>
            <Cell>
              {(rowData) => (
                <IconButton
                  onClick={(e) => {
                    setIsEditReview(rowData.id)
                  }}
                  style={{
                    background: 'none',
                    padding: '0px',
                    margin: '0px',
                  }}
                  icon={<EditIcon />}
                />
              )}
            </Cell>
          </Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal()} appearance="primary">
          Back
        </Button>
      </Modal.Footer>
      {isEditReview !== null && (
        <AddReview data={{ isEditReview, setIsEditReview }}></AddReview>
      )}
    </Modal>
  )
}
export default CustomerReviewModal
