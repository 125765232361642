import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Header from "./Header1";
import "../css/imageUploadStyle.css";
import {Col,Progress,Carousel} from "rsuite";
import {Modal,Button,Tabs,Tab} from "react-bootstrap";
import {formatDate, parseResponseJSON, dateDifference} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';

const NewPost= (props) =>{  
    const [file, setFile] = useState();
    const [caption, setCaption] = useState("");
    const [progressBar,setProgressBar]=useState(0);
    const [showImageUpload,setShowImageUpload]=useState(false);
    const [repairId,setRepairId] =useState("");
    const [amenityId,setAmenityId]=useState("");
    const [tabKey,setTabKey]=useState("upload");
    const [images,setImages]=useState([]);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const url=process.env.REACT_APP_SERVER_URL;
    const {displayNotificationMessage}=useContext(CommonComponents);
    const status = progressBar === 100 ? 'success' : null;
    const color = progressBar === 100 ? '#52c41a' : '#3385ff';
    // const uploadFiles = (targetFile) =>{
    //   files.append(targetFile);
      
    // }
    useEffect(() => {
      setShowImageUpload(true);
     
      if(props.value && props.value.repairId)
      {
        setRepairId(props.value.repairId);
      }
      if(props.value && props.value.amenityId)
      {
        setAmenityId(props.value.amenityId);
      }
     
      },[props]);
    const submit = async(event) => {
      if(event)
      event.preventDefault();
    try{
  
      const formData = new FormData();
      let tempImageId=null;
      formData.append("image", file)
      formData.append("caption", caption)
      // console.log("good upto here!!!!!!!!");
      await axios.post(`${url}/api/posts`, formData, { 
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress : ProgressEvent =>{
          setProgressBar(parseInt(Math.round((ProgressEvent.loaded*100)/ProgressEvent.total)))
        }
      }).then((responseForSavingImage)=>{
        console.log(responseForSavingImage);
        const tempDbResponse=parseResponseJSON(responseForSavingImage.data);
        if(tempDbResponse)
        {
          tempImageId=tempDbResponse.data;
        }
      })
      
      if(tempImageId && repairId){
        let imageId=tempImageId;
        await axios.post(`${url}/addRepairImageRelation`,{
          imageId,
          repairId
        }).then((responseForAddingRepairImageRelation)=>{
          console.log(responseForAddingRepairImageRelation);
          displayNotificationMessage("success","Image uploaded");
          getImagesById();
          setTabKey("view");
        })
      }
      if(tempImageId && amenityId){
        let imageId=tempImageId;
        await axios.post(`${url}/addAmenityImageRelation`,{
          imageId,
          amenityId
        }).then((responseForAddingAmenityImageRelation)=>{
          console.log(responseForAddingAmenityImageRelation);
          displayNotificationMessage("success","Image uploaded");
          getImagesById();
          setTabKey("view");
        })
      }
    }
    catch(error){
      console.log(error)
    }
  }
  const getImagesById=async()=>{
    try{
      if(repairId){
        await axios.get(`${url}/images/getImagesByRepairId/${repairId}`).then((responseForImages)=>{
          console.log(responseForImages);
          setImages(responseForImages.data);

        })

      }
      else if(amenityId){
        await axios.get(`${url}/images/getImagesByAmenityId/${amenityId}`).then((responseForImages)=>{
          console.log(responseForImages);
          setImages(responseForImages.data);

        })

      }

    }
    catch(error){
      console.log(error);
    }
  }
  const deleteByKeyValue= async(indexValue) => {
    try{
      const imageId=images[indexValue].id;
      let flag=false;
      // alert(images[indexValue].id) --imageId that nedds to be deleted
      //Deletion stages : first remove the relation entry, then image details in image table and then in amazon s3 bucket
      //First Removing the relation --FIXME:Remaining Confirm
      if(repairId){
      await axios.delete(`${url}/images/deleteRepairImageRelation/${imageId}`).then((responseForDeletingImages)=>{
        getImagesById();
        displayNotificationMessage("success","Image deleted");
        flag=true;
      })
    }
    if(amenityId){
      await axios.delete(`${url}/images/deleteAmenityImageRelation/${imageId}`).then((responseForDeletingImages)=>{
        getImagesById();
        displayNotificationMessage("success","Image deleted");
        flag=true;
      })
    }
    if(flag){
      flag=false;
      await axios.delete(`${url}/images/deleteImageDetailsByImageId/${imageId}`).then((responseForDeleting)=>{
        console.log(responseForDeleting);
      })
    }
  }
  catch(error){
    console.log(error);
  }

  }
  const handleImageClick =(imageUrl)=>{
    if(imageUrl){
      window.open(imageUrl,'_blank');
    }

  }
   

    const handleCloseImageUpload=()=>{
      setShowImageUpload(false)
    }
    const handleTabSelect=(targetValue)=>{
      if(targetValue==="view")
      getImagesById();
    }
    
  
    return (
      <>
          {/* {progressBar} */}
          <Modal show={showImageUpload}
          onHide={handleCloseImageUpload} 
          style={{width:"100%"}}>
        <Modal.Header closeButton>
        <Tabs
          id="controlled-tab-example"
          activeKey={tabKey}
          onSelect={(k) => {setTabKey(k);handleTabSelect(k)}}
        >
          <Tab eventKey="upload" title="Upload Image" />
          <Tab eventKey="view" title="Images"/>
        </Tabs>
          {/* <Modal.Title>Upload Image: <i style={{color:"red",fontSize:"medium"}}>{}</i></Modal.Title>*/}
          </Modal.Header> 

        <Modal.Body>
          {tabKey==="upload" ? 
        <input onChange={e => {
          setFile(e.target.files[0]);
          // setProgressBar(parseInt(Math.round((ProgressEvent.loaded*100)/ProgressEvent.total))) 
        } }
          type="file" multiple="multiple" accept="image/*"></input>
          :null}
          {tabKey==="view" ? 
          <Carousel className="custom-slider" activeIndex={activeIndex} style={{width:"100%"}}
          onSelect={index => {
            setActiveIndex(index);
          }}>
          {images.map((row, index) => (
          <img src={row.imageUrl} onClick={(e)=>handleImageClick(row.imageUrl)}></img>

          ))}
          </Carousel>
          :null}

        </Modal.Body>
            <Modal.Footer>
              
        
        {tabKey==="upload" ? 
        <>
        <Button variant="secondary"  
         onClick={handleCloseImageUpload}
        >Cancel</Button> 
        <Button type="submit" 
        onClick={(e)=>submit(e)}
        >Upload</Button> 
        </>: null}

        {tabKey==="view" ? 
        <div>
          { activeIndex>0 ?
        <ArrowLeftLineIcon style={{fontSize:"3rem"}} 
        onClick={(e)=>activeIndex>0 ? setActiveIndex(activeIndex-1) : null}/>
          : null}
        {/* <FontAwesomeIcon icon="fa-sharp fa-solid fa-trash" style={{color: "#f22f0d"}} /> */}
        <Button type="submit"
        onClick={(e)=>deleteByKeyValue(activeIndex)}
        >Delete</Button> 
        { activeIndex<images.length-1 ?
        <ArrowRightLineIcon style={{fontSize:"3rem"}}
        onClick={(e)=>activeIndex<images.length-1 ? setActiveIndex(activeIndex+1) : null}/>
          : null}
        
        </div>: null}
    

            </Modal.Footer>
            
          </Modal>
       {/* <form onSubmit={submit} style={{marginTop:"5%",marginLeft:"5%"}}>
         <input onChange={e => {
          setFile(e.target.files[0]);
          setProgressBar(parseInt(Math.round((ProgressEvent.loaded*100)/ProgressEvent.total))) 
        } }
          type="file" multiple="multiple" accept="image/*"></input>
        
         <button type="submit">Submit</button>
       </form>
     
       <Col md={6}>
          <div style={{ width: 120, marginTop: 10 }}>
            <Progress.Circle percent={progressBar} strokeColor={color} status={status} />
          </div>
        </Col> */}
         {/* <input value={caption} onChange={e => setCaption(e.target.value)} type="text" placeholder='Remarks'></input> */}
       </>
    )
  }
  export default NewPost;