import React, { useState, useEffect } from 'react'
import { Link, redirect } from 'react-router-dom'
import { Table, Tabs, Pagination, Input } from 'rsuite'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  getDataSorted,
  formatDateText,
} from '../CommonFunctions.js'
import { Row, Col, Button } from 'react-bootstrap'
import axios from 'axios'
const { Column, HeaderCell, Cell } = Table

const CurreInvReport = (props) => {
  const [defaultData, setDefaultData] = useState([])
  const [limit, setLimit] = React.useState(10)
  const [sortColumn, setSortColumn] = useState('cid')
  const [sortType, setSortType] = useState('asc')
  const [page, setPage] = React.useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const url = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    getCurrentInventory()
  }, [
    props.data.modalInventory,
    props.data.createlInventory,
    props.data.requestInventory,
    props.data.editInventory,
    props.data.voidInventory,
  ])

  const getCurrentInventory = async () => {
    try {
      await axios.get(`${url}/getCurrentInventory`).then((response) => {
        setDefaultData(response.data)
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeLimit = (dataKey) => {
    setPage(1)
    setLimit(dataKey)
  }

  const data = defaultData
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice((page - 1) * limit, page * limit)

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn)
    setSortType(sortType)
  }

  const handleSearch = (value) => {
    setSearchTerm(value)
    setPage(1)
  }
  return (
    <>
      {' '}
      <Row style={{ padding: '10px 80px' }}>
        <Col sm>
          <Input
            placeholder="Search by Product Name"
            value={searchTerm}
            onChange={(value) => handleSearch(value)}
          />
        </Col>
        <Col sm></Col>
        <Col sm></Col>
        <Col sm>
          <Button
            style={{ float: 'right' }}
            onClick={(e) => props.data.setCreatelInventory(true)}
          >
            Create Product
          </Button>
        </Col>
      </Row>
      <Table
        className="ms-5"
        autoHeight
        width={'100%'}
        data={getDataSorted(sortColumn, sortType, data)}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        size="lg"
      >
        <Column width={100} align="center" fixed sortable>
          <HeaderCell>Product ID</HeaderCell>
          <Cell dataKey="productId">
            {(rowData) => (
              <a
                style={{
                  color: 'var(--rs-text-link-hover)',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  props.data.setSelectedRow(rowData)
                  props.data.setEditInventory(true)
                }}
              >
                {rowData.productId}
              </a>
            )}
          </Cell>
        </Column>

        <Column width={250} sortable>
          <HeaderCell>Product</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column width={160} sortable>
          <HeaderCell>Location</HeaderCell>
          <Cell dataKey="storageLocation" />
        </Column>

        <Column width={100} sortable>
          <HeaderCell>Quantity</HeaderCell>
          <Cell dataKey="count" />
        </Column>

        <Column width={100} sortable>
          <HeaderCell>Request</HeaderCell>
          <Cell dataKey="request" style={{ color: 'red' }} />
        </Column>
        <Column width={100} sortable>
          <HeaderCell>Request Description</HeaderCell>
          <Cell dataKey="requestDescription" />
        </Column>

        <Column width={160} sortable>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>
        <Column width={300}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => (
              <>
                <Link
                  onClick={(e) => {
                    props.data.setModalInventory(true)
                    props.data.setSelectedRow(rowData)
                    props.data.setIsUse(true)
                  }}
                >
                  Consume
                </Link>
                {rowData.status != 'Discontinued' ? (
                  <>
                    {' '}
                    |{' '}
                    <Link
                      onClick={(e) => {
                        props.data.setModalInventory(true)
                        props.data.setSelectedRow(rowData)
                        props.data.setIsUse(false)
                      }}
                    >
                      Restock
                    </Link>{' '}
                    |{' '}
                    <Link
                      onClick={(e) => {
                        props.data.setRequestInventory(true)
                        props.data.setSelectedRow(rowData)
                      }}
                    >
                      Request New Stock
                    </Link>
                  </>
                ) : null}
              </>
            )}
          </Cell>
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={defaultData.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}
export default CurreInvReport
