import React, { useState, useEffect ,useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "./Header.js";
import { useNavigate, useParams } from "react-router-dom";
import {formatDate, parseResponseJSON,dateFormatOptions} from "./CommonFunctions";
// import Table from 'react-bootstrap/Table';
import { ManageTenancyInformationComponent } from "./Tenancy.js";
import SearchDateContext from "./GuestContext";
import { Table , Rate , RadioTile} from 'rsuite';
// import "../css/reports.css";
const { Column, HeaderCell, Cell } = Table;
const Reports = () => {

const [records,setRecords]=useState([]);
const [nrecords,setNrecords]=useState([]);
const [vacantRooms,setVacantRooms]=useState([]);
const [todayTotal,setTodayTotal]=useState("");
const [tmroTotal,setTmroTotal]=useState("");
const [isTenancyDetails,setIsTenancyDetails]=useState(false);
const url=process.env.REACT_APP_SERVER_URL;
// const [tenancyId,setTenancyId]=useState("");
// const {searchDate,vacantRoomNumber,rentedRoomNumber,
//   tenancyId,
//   setValueTenancyId,
//   setValueRentedRoomNumber,
//   setValueVacantRoomNumber
//   }=useContext(SearchDateContext); 
// let today=formatDate(new Date());
let today=formatDate(new Date())
const navigate=useNavigate();


// FIXME : Room ID should be used instead of index
var tt=0;
var tt1=0;
let weekly_rate=400;
let daily_rate=75;
  // const { id1,id2 } = useParams();
 
  useEffect(() => {
    // getTenants();
    getTodayCheckouts();
    getTmroCheckouts();
    getVacantRooms();
  }, []);
 
//   const getTenants = async () => {
//     const response = await axios.get(`${url}/tenants`);
//     setTenant(response.data);
//   };

const getTodayCheckouts = async()=>{
    const response=await axios.get(`${url}/reports`);
    // console.log(response.data);
    setRecords(response.data);
    console.log(response.data)
    records.map((row, index) => (
        tt=tt+((row.days/7)*(row.rate)-row.deposit)
    ));
    setTodayTotal(tt);
    

    // console.log(records);
    
}
const getTmroCheckouts = async()=>{
    const response=await axios.get(`${url}/nreports`);
    // console.log(response.data);
    setNrecords(response.data);
    

    nrecords.map((row, index) => (
        tt1=tt1+((row.days/7)*(row.rate)-row.deposit)
    ));
    setTmroTotal(tt1);
    

    
    // console.log(records);
    
}
const  getVacantRooms= async()=>{
  const response=await axios.get(`${url}/vrooms`);
  // console.log(response.data);
  setVacantRooms(response.data);
  
  
  

  // console.log(records);
  
}
// setInterval(function () {
// 	// setTodayTotal(todayTotal);
//     // setTmroTotal(tmroTotal);
//     tt=0;
//     tt1=0;
//     records.map((row, index) => (
//         tt=tt+(row.exceeded)*(daily_rate)*-1+row.rate-row.deposit - row.paidAmount
//     ));
//     setTodayTotal(tt);
//     nrecords.map((row, index) => (
//         tt1=tt1+row.rate
//     ));
//     setTmroTotal(tt1);
// }, 5000);
function calculateTotalBalance (){
  var total=0;
  for(var i=0;i<records.length;i++){
    total=total + ((records[i].exceeded)*(daily_rate)*-1+records[i].rate-records[i].deposit - records[i].paidAmount);

  }
  return total;
}
function calculateTmroTotalBalance () {
var total=0;
  for(var i=0;i<nrecords.length;i++){
    total=total + ((nrecords[i].exceeded)*(daily_rate)*-1+nrecords[i].rate-nrecords[i].deposit - nrecords[i].paidAmount);

  }
  return total;
}



  
  // Get the element with id="defaultOpen" and click on it

 
  return (
    <div>
      <Header/>
        {/* <button class="tablink" onclick={opentoday}>Today</button> */}
{/* <button class="tablink" onclick={opentmro} id="defaultOpen">Tmro</button> */}

{/* <div id="today" class="tabcontent">
  <h3>Home</h3>
  <p>Home is where the heart is..</p>
</div>

<div id="tmro" class="tabcontent">
  <h3>News</h3>
  <p>Some news this fine day!</p>
</div> */}


<h4 className="mt-3">Expected Check-out Today or earlier</h4><br></br>
<h5 className="me-0" style={{marginLeft:"60%"}}>Count=<b style={{color:"red"}}>{records.length}</b> &nbsp;&nbsp;&nbsp; Total=<b style={{color:"red"}}> ${calculateTotalBalance()}</b></h5>
<Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    //   data={activeTenancyDetails}
    // data={getData()}
    data={records}
      size="lg"
      // sortColumn={sortColumn}
      // sortType={sortType}
      // onSortColumn={handleSortColumn}
      // loading={loading}

    >

     
    
      <Column width={100} fixed sortable>
        <HeaderCell><b>Room </b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
            <a onClick={(e)=>{
              // setValueTenancyId(row.id);
              navigate(`/tenants/add/${rowData.id}`, { state:{ tenancyId: rowData.id} })}}>
            <Link>
              {rowData.roomid}
            </Link>
            </a>
          )}
          </Cell>
      </Column>
  


      <Column width={180} sortable>
        <HeaderCell>Names</HeaderCell>
        <Cell dataKey="names" />
      </Column>

      <Column width={180} sortable>
        <HeaderCell>phoneNumbers</HeaderCell>
        <Cell dataKey="phoneNumbers" />
      </Column>
            
      <Column width={180} sortable>
        <HeaderCell>Arrival Date</HeaderCell>
        <Cell dataKey="arrival" />
      </Column>
      <Column width={180} sortable>
        <HeaderCell>last Checkin</HeaderCell>
        <Cell dataKey="checkin" />
      </Column>
      <Column width={180} sortable>
        <HeaderCell>Expected Checkout</HeaderCell>
        <Cell dataKey="checkout" />
      </Column>
      <Column width={150} sortable>
        <HeaderCell>Days Overstayed</HeaderCell>
        <Cell dataKey="exceeded" />
      </Column>
      <Column width={80} sortable>
        <HeaderCell>Rate</HeaderCell>
        <Cell dataKey="rate" />
      </Column>
      <Column width={80} sortable>
        <HeaderCell>Adults</HeaderCell>
        <Cell dataKey="adults" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Children</HeaderCell>
        <Cell dataKey="children" />
      </Column>
      {/* <Column width={90} sortable>
        <HeaderCell>Deposit</HeaderCell>
        <Cell dataKey="deposit" />
      </Column>
      <Column width={120} sortable>
        <HeaderCell>Rental Type</HeaderCell>
        <Cell dataKey="rentalType" />
      </Column> */}
      {/* <Column width={160} sortable>
        <HeaderCell>Total Amount Paid</HeaderCell>
        <Cell dataKey="paidAmount" />
      </Column> */}
      <Column width={180} sortable>
        <HeaderCell>Past rent</HeaderCell>
        <Cell style={{ padding: '6px' }} dataKey="stayDuration">
          {rowData => (
              <>
              {(rowData.exceeded)*(daily_rate)*-1+rowData.rate-rowData.deposit - rowData.paidAmount}
              </>    
          )}
        </Cell>
      </Column>
    </Table>

        {/* {isTenancyDetails && <ManageTenancyInformationComponent value={tenancyId}/>} */}

       




{/* <Table>
          <thead>
            <tr>
        
        <th>Vacant Rooms:</th>
        </tr>
        </thead>
        <tbody width="5%">
        {vacantRooms.map((row, index) => (
          
          <tr>
           
              <td>{row.id}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}

    <h4 className="mt-3">Expected Check-out Tomorrow</h4><br></br>
    <h5 className="me-0" style={{marginLeft:"60%"}}>Count =<b style={{color:"red"}}>{nrecords.length}</b> &nbsp;&nbsp;&nbsp; Total=<b style={{color:"red"}}> ${calculateTmroTotalBalance()}</b></h5>
    
    <br></br>
    <Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    //   data={activeTenancyDetails}
    // data={getData()}
    data={nrecords}
      size="lg"
      // sortColumn={sortColumn}
      // sortType={sortType}
      // onSortColumn={handleSortColumn}
      // loading={loading}

    >

     
    
      <Column width={70} fixed sortable>
        <HeaderCell><b>Room </b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
            <a onClick={(e)=>{
              // setValueTenancyId(row.id);
              navigate(`/tenants/add/${rowData.id}`, { state:{ tenancyId: rowData.id} })}}>
            <Link>
              {rowData.roomid}
            </Link>
            </a>
          )}
          </Cell>
      </Column>
  


      <Column width={180} sortable>
        <HeaderCell>Names</HeaderCell>
        <Cell dataKey="names" />
      </Column>

      <Column width={180} sortable>
        <HeaderCell>phoneNumbers</HeaderCell>
        <Cell dataKey="phoneNumbers" />
      </Column>
            
      <Column width={180} sortable>
        <HeaderCell>Arrival Date</HeaderCell>
        <Cell dataKey="arrival" />
      </Column>
      <Column width={180} sortable>
        <HeaderCell>last Checkin</HeaderCell>
        <Cell dataKey="checkin" />
      </Column>
      <Column width={180} sortable>
        <HeaderCell>Expected Checkout</HeaderCell>
        <Cell dataKey="checkout" />
      </Column>
  
      <Column width={80} sortable>
        <HeaderCell>Rate</HeaderCell>
        <Cell dataKey="rate" />
      </Column>
      <Column width={80} sortable>
        <HeaderCell>Adults</HeaderCell>
        <Cell dataKey="adults" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Children</HeaderCell>
        <Cell dataKey="children" />
      </Column>
      {/* <Column width={80} sortable>
        <HeaderCell>Deposit</HeaderCell>
        <Cell dataKey="deposit" />
      </Column>
      <Column width={70} sortable>
        <HeaderCell>Rental Type</HeaderCell>
        <Cell dataKey="rentalType" />
      </Column> */}
      {/* <Column width={60} sortable>
        <HeaderCell>Total Amount Paid</HeaderCell>
        <Cell dataKey="paidAmount" />
      </Column> */}
      <Column width={180} sortable>
        <HeaderCell>Past rent</HeaderCell>
        <Cell style={{ padding: '6px' }} dataKey="stayDuration">
          {rowData => (
              <>
              {(rowData.exceeded)*(daily_rate)*-1+rowData.rate-rowData.deposit - rowData.paidAmount}
              </>    
          )}
        </Cell>
      </Column>
    </Table>
    {/* <Table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Room No</th>
              
              <th>Guest Names</th>
              <th>Phone Numbers</th>
              <th>Arrival Date</th>
              <th>Checkout</th>
              <th>Rate</th>
              <th>Adults</th>
              <th>Children</th>
              <th>Deposit</th>
              <th>Rental type</th>
              <th>Past rent</th>
              
            </tr>
          </thead>
          <tbody>
            {nrecords.map((row, index) => (

              <tr key={row.id}>
                <td onClick={(e)=>{
                  navigate(`/tenants/add`, { state:{ tenancyId: row.id} })}}><Link>{row.roomid}</Link></td>
                <td>{row.names.split(',')+"\n"}</td>
                <td>{row.phoneNumbers}</td>
                <td>{row.arrival}</td>
                
                
                <td>{row.checkout}</td>
                <td><b> $</b>{row.rate}</td>
                <td>{row.adults}</td>
                <td>{row.children}</td>
                <td><b> $</b>{row.deposit}</td>
                <td>{row.type}</td>
                
                <td><b> $</b>{row.rate}</td>
                
              </tr>
            ))}
          </tbody>
        </Table>  */}
        


    </div>
  );
};
 
export default Reports;