import ConsumptionReport from './ConsumptionReport.js'
import RestockReport from './RestockReport.js'
import ProductReport from './ProductReport.js'
import Header from '../Header.js'
import React, { useState, useEffect } from 'react'
import { Tabs } from 'rsuite'
import CurreInvReport from './CurreInvReport.js'
import DeleteVoidModal from './Modals/DeleteVoidModal.js'
import InventoryModal from './Modals/InventoryModal.js'
import RequestInventoryModal from './Modals/RequestInventoryModal.js'
import EditInventoryModal from './Modals/EditInventoryModal.js'
import CreateInventoryModal from './Modals/CreateInventoryModal.js'

const InventoryReport = () => {
  const [modalInventory, setModalInventory] = useState(false)
  const [createlInventory, setCreatelInventory] = useState(false)
  const [requestInventory, setRequestInventory] = useState(false)
  const [editInventory, setEditInventory] = useState(false)
  const [isUse, setIsUse] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [voidInventory, setVoidInventory] = useState(false)
  const [selectedRow, setSelectedRow] = useState([])
  return (
    <>
      <Header />
      <div className="container" id="container">
        <Tabs defaultActiveKey="1">
          <Tabs.Tab eventKey="1" title="Current Inventory">
            <CurreInvReport
              data={{
                modalInventory,
                createlInventory,
                requestInventory,
                setCreatelInventory,
                setModalInventory,
                setIsUse,
                setRequestInventory,
                selectedRow,
                setSelectedRow,
                editInventory,
                setEditInventory,
                voidInventory,
                setVoidInventory,
              }}
            ></CurreInvReport>
          </Tabs.Tab>
          <Tabs.Tab eventKey="2" title="Consumption Report">
            <ConsumptionReport
              data={{
                modalInventory,
                createlInventory,
                requestInventory,
                editInventory,
                setEditInventory,
                voidInventory,
                setVoidInventory,
                selectedRow,
                setSelectedRow,
              }}
            ></ConsumptionReport>
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title="Restock Report">
            <RestockReport
              data={{
                modalInventory,
                createlInventory,
                requestInventory,
                editInventory,
                setEditInventory,
                voidInventory,
                setVoidInventory,
                selectedRow,
                setSelectedRow,
              }}
            ></RestockReport>
          </Tabs.Tab>
          <Tabs.Tab eventKey="4" title="Product List">
            <ProductReport
              data={{
                modalInventory,
                createlInventory,
                setCreatelInventory,
                requestInventory,
                editInventory,
                isDelete,
                setIsDelete,
                setEditInventory,
                voidInventory,
                setVoidInventory,
                selectedRow,
                setSelectedRow,
              }}
            ></ProductReport>
          </Tabs.Tab>
        </Tabs>
        {voidInventory && (
          <DeleteVoidModal
            data={{
              voidInventory: voidInventory,
              setVoidInventory: setVoidInventory,
              selectedRow,
              isDelete,
            }}
          ></DeleteVoidModal>
        )}
        {modalInventory && (
          <InventoryModal
            data={{
              modalInventory: modalInventory,
              setModalInventory: setModalInventory,
              selectedRow: selectedRow,
              isUse,
            }}
          ></InventoryModal>
        )}
        {requestInventory && (
          <RequestInventoryModal
            data={{
              requestInventory: requestInventory,
              setRequestInventory: setRequestInventory,
              selectedRow: selectedRow,
            }}
          ></RequestInventoryModal>
        )}
        {editInventory && (
          <EditInventoryModal
            data={{
              editInventory: editInventory,
              setEditInventory: setEditInventory,
              selectedRow: selectedRow,
            }}
          ></EditInventoryModal>
        )}
        {createlInventory && (
          <CreateInventoryModal
            data={{
              createlInventory: createlInventory,
              setCreatelInventory: setCreatelInventory,
            }}
          ></CreateInventoryModal>
        )}
      </div>
    </>
  )
}
export default InventoryReport
