
import axios from "axios";
const url=process.env.REACT_APP_SERVER_URL;
export const getAllActiveTenantsInfo= async(e,searchStartDate,searchEndDate)=>{
    try{
        const dateColumnFilter="actualPaymentDate";
        const response = await axios.post(`${url}/payments/allPaymentsinDateRange`,{
            searchStartDate,
            searchEndDate,
            dateColumnFilter
        })
      return response;

    }
    catch(error){
      console.log(error)

    }
}
  
export const getFreeRoomsByDate= async(startDate,endDate) => {
        try{
            console.log(startDate + endDate)
            
            const responseForRooms= await axios.post(`${url}/vacancyCheckByDateRange`,{
            startDate,
            endDate
            })
            if(responseForRooms && responseForRooms.data){
                const response1=await axios.get(`${url}/rooms`);
                if(response1){
                    console.log("in getFreeRoomsByDate")
                const temp=response1.data;
                const rooms=temp.filter(roomId => !roomId.rentable);
                return {data:responseForRooms.data,rooms:rooms};
                }

            }
            return null;
        
        // .then( async(responseForRooms) =>{
        //     console.log(responseForRooms.data);
            
        //     const response1=await axios.get(`${url}/rooms`);
        //     if(response1){
        //         console.log("in getFreeRoomsByDate")
        //       const temp=response1.data;
        //       const rooms=temp.filter(roomId => !roomId.rentable);
              
        //     }
            
        //   })
        }catch(error)
        {
            console.log(error);
        }
      }
      export const getCurrentInventory = async () => {
        try {
          const response=await axios.get(`${url}/getCurrentInventory`);
            if(response)
          return response.data;
        return null;
        } catch (error) {
          console.log(error)
        }
      }