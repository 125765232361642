import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem

} from "@mui/material"

import Header from "../Header";
import { NumericFormat,PatternFormat } from "react-number-format";
import CardGroup from "./CardGroup";
import ChartGroup from "./ChartGroup";
import { formatDate, formatDateText } from "../CommonFunctions";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import {
  getAllActiveTenantsInfo,
  getFreeRoomsByDate,
  getCurrentInventory
} from "./DataConnections"
let last12Months=[];
const options = { month: 'short', day: 'numeric', year:'numeric' };
let totalMonthlyAmount=0;
let currentVacancy=0;
let avgRooms=0;
let repairs=0;
let inventoryCount=0;
const now=new Date();
now.setDate(1);
const Dashboard=()=>{
  const currentDate=formatDate(new Date());

  const [searchStartDate,setSearchStartDate]=  useState(formatDate(now));
  const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date()));
  const [reportSummary,setReportSummary] = useState([])
  const [occupancyReport,setOccupancyReport] = useState([]);
 
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      getReportSummary(null,searchStartDate,searchEndDate);
      getVacancyReportByDateRange(searchStartDate,searchEndDate);
    }, [searchStartDate,searchEndDate]);
    useEffect(() => {
      getLast12Months();
      
      getCurrentMonthTotalPayment()
      getCurrentDayvacancy();
      getActiveRepairs();
      getCurrentRequestedInventory();
    }, [currentDate]);
    const getCurrentRequestedInventory = async() =>{
      try{
        const response=await getCurrentInventory();
        if(response){
          console.log("in getCurrentRequestedInventory");
          console.log(response)
          const data=response;
          let sum=0;
          for(let itr in data){
            const currentRow=data[itr];
            console.log("currentRow.request " + currentRow.request)
            if(currentRow.request && currentRow.status!=="Discontinued"){

              sum+=parseInt(currentRow.request);
            }
          }
          
          inventoryCount=sum;
        }

      }catch(error){
        console.log(error)
  
      }
    }
    const getCurrentMonthTotalPayment =async()=>{
      try{
        const currentDate = new Date();
        const searchEndDate=formatDate(currentDate)
        currentDate.setDate(1);
        const searchStartDate=formatDate(currentDate);
        
          const response=await getAllActiveTenantsInfo(null,searchStartDate,searchEndDate);
          if(response){
            const resultArray=preProcess(response.data);
            let temp=0;
            for(let itr in resultArray){
              const currentRow=resultArray[itr];
              temp+=currentRow.amount;
            }
            totalMonthlyAmount=temp;


          }
  
      }
      catch(error){
        console.log(error)
  
      }
    }
    const getReportSummary =async(e,searchStartDate,searchEndDate)=>{
      try{
          const response=await getAllActiveTenantsInfo(null,searchStartDate,searchEndDate);
          if(response){
            setReportSummary(response.data)
          }
  
      }
      catch(error){
        console.log(error)
  
      }
    }
    
    function formatDateToMonthAndDate(inputDate) {
      const date = parseISO(inputDate);
      const options = { month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
  }
    const preProcess = (data) =>{
      var dictionary={}
      for(let itr in data){
        const currentRow=data[itr];
        if(!(currentRow.actualPaymentDate in dictionary))
        {
          dictionary[currentRow.actualPaymentDate]=0;
        }
        let temp=0;
        if(currentRow.cashAmount!==null){
          temp+=currentRow.cashAmount;
        } 
        if(currentRow.creditCardAmount!==null){
          temp+=currentRow.creditCardAmount;
        } 
        if(currentRow.otherAmount!==null){
          temp+=currentRow.otherAmount;

        }
        dictionary[currentRow.actualPaymentDate] +=temp;
      }
      const interval = {
        start: parseISO(searchStartDate),
        end: parseISO(searchEndDate)
      };
      const dates = eachDayOfInterval(interval).map(date => format(date, 'yyyy-MM-dd'));
      const resultArray=[];
      dates.forEach(date => {
        if(date in dictionary){
            resultArray.push({
              date:formatDateToMonthAndDate(date),
              amount:dictionary[date]
            })
        }
        else{
          resultArray.push({
            date:formatDateToMonthAndDate(date),
            amount:0
          })

        }
        
      });
      
      return resultArray;
    }
    function getLast12Months() {
      const months = [];
      const currentDate = new Date();
      currentDate.setDate(1); // Set to the first day of the current month
  
      for (let i = 0; i < 12; i++) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          if(i!==0){
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // Set to the last day of the previous month
          }
          else{
            endDate=new Date();
          }
          
  
          months.push({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
          });
  
          // Move to the previous month
          currentDate.setMonth(currentDate.getMonth() - 1);
      }
  
      last12Months= months;
  }
  const getCurrentDayvacancy =async()=>{
    try{
      const startDate=formatDate(new Date());
      const endDate=formatDate(new Date())
        const response=await getFreeRoomsByDate(startDate,endDate);
        if(response){
          const resultArray=preProcessVacancyData(response.data,startDate,endDate,response.rooms)
          if(resultArray){
            console.log("resultArray")
            console.log(resultArray)
            currentVacancy=resultArray[0].numberOfVacantRooms;
          }
        }

    }
    catch(error){
      console.log(error)

    }
  }
  const getVacancyReportByDateRange =async(startDate,endDate)=>{
    try{
      
        const response=await getFreeRoomsByDate(startDate,endDate);
        console.log(response)
        if(response){
          console.log(response)
          const data=response.data;
          const rooms=response.rooms;
          const res=preProcessVacancyData(data,startDate,endDate,rooms);
          console.group(res)
          setOccupancyReport(res)
        }

    }
    catch(error){
      console.log(error)

    }
  }
  const preProcessVacancyData = (data,startDate,endDate,rooms)=>{
    let myDict={};
    console.log("start Date : " + startDate + " end date : " + endDate);
    const interval = {
      // start: new Date(startDate).toUTCString(),
      // end: new Date(endDate).toUTCString()
      start: parseISO(startDate),
      end: parseISO(endDate)
    };
    const dates = eachDayOfInterval(interval).map(date => format(date, 'yyyy-MM-dd'));
    const result = {};
    dates.forEach(date => {
      result[date] = [];
    });      
Object.entries(data).forEach(([roomId, tenancies]) => {
  tenancies.forEach(tenancy => {
    const checkInDate = format(parseISO(tenancy.checkin), 'yyyy-MM-dd');
    let checkoutDate=null;
    if(tenancy.actualCheckoutDate===null){

    }
    else{
      checkoutDate = format(parseISO(tenancy.actualCheckoutDate), 'yyyy-MM-dd');
    }

    dates.forEach(date => {
      if(checkoutDate===null){
        if (date >= checkInDate) {
          result[date].push(parseInt(roomId));
        }
      }
      else{
        if (date >= checkInDate && date < checkoutDate) {
          result[date].push(parseInt(roomId));
        }
      }
      
    });
  });
});
const resultArray=[];
avgRooms=0;
for(let key in result){
  const occupiedRooms=result[key];
  const date=parseISO(key);
  

  const vacantRooms= rooms.filter(row => !occupiedRooms.includes(row.id));
  let lostRent=0;
  vacantRooms.map((row)=>{
    lostRent +=row.dailyRate;
  })
  avgRooms=avgRooms + vacantRooms.length
  resultArray.push({
    date:date.toLocaleDateString('en-US',{ month: 'short', day: 'numeric' }),
    occupiedRooms:occupiedRooms,
    vacantRooms:vacantRooms,
    lostRent:lostRent,
    numberOfVacantRooms:vacantRooms.length
  })

}
avgRooms = avgRooms/resultArray.length;

console.log("Average rooms vacant: " + avgRooms)


return resultArray;

  }
   const getActiveRepairs = async() =>{
    const targetValue="Incomplete";
    const response= await axios.post(`${url}/repairs/searchRepairByStatus`,{
      targetValue
    });
    if(response){
      console.log(response)
      repairs=response.data.length;
    }
   }
    
    
    

    return (
      <>
      <Header/>
      <Box sx={{  padding:"0% 10%",backgroundColor:"rgb(250, 250, 251)" }}>
      {/* <Grid container alignItems="center" sx={{padding:"0.5% 0%"}}>
          <Grid item>
        <Typography variant="h6" color="text.primary">
          Dashboard
        </Typography>
          </Grid>
          </Grid> */}
      <CardGroup totalMonthlyAmount={totalMonthlyAmount} repairs={repairs}
      currentVacancy={currentVacancy} inventoryCount={inventoryCount}/>
        <br></br>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
        <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          onChange={(e)=>{
            setSearchStartDate(formatDate(e.target.value.startDate))
            setSearchEndDate(formatDate(e.target.value.endDate));
          }}
        >
          {last12Months.map((month)=>(
             <MenuItem value={month}>{month.startDate.toLocaleDateString('en-US', options) + " - " + 
              month.endDate.toLocaleDateString('en-US', options)}</MenuItem>
          ))}
          </Select>
          </FormControl>
        </Grid>
      </Grid>
        <ChartGroup 
        paymentReportSummary={preProcess(reportSummary)}
        avgRooms={avgRooms}
        occupancyReport={occupancyReport}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        />
      </Box>
      
      
      </>
    );


}
export default Dashboard;