import { createContext, useState } from "react";
import { ManageTenancyInformationComponent } from "./Tenancy";
import { SearchRoomsByDateComponent } from "./SearchRoomsByDate";
import {formatDate, parseResponseJSON,logMe} from "./CommonFunctions";
import Reports from "./Reports";
import CustomerWizard from "./CustomerWizard";
import IndividualTenancy from "./IndividualTenant";
import { useToaster } from "rsuite";
const SearchDateContext=createContext();
function Provider({children}){
    const toaster = useToaster();
    const [searchDate,setSearchDate]=useState(formatDate(new Date()));
    const [vacantRoomNumber,setVacantRoomNumber]=useState("");
    const [rentedRoomNumber,setRentedRoomNumber]=useState("");
    const [tenancyId,setTenancyId]=useState("");
    const [searchStartDate,setSearchStartDate]=useState(formatDate(new Date()));
    const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date()));
    const [vacantRooms,setVacantRooms]=useState([])
 
    const valuesToShare={
        searchDate,
        vacantRoomNumber,
        rentedRoomNumber,
        tenancyId,
        searchStartDate,
        searchEndDate,
        vacantRooms,
        setValueSearchDate: (searchDate) => {
            setSearchDate(searchDate);
            logMe("setValueSearchDate",searchDate)

        },
        setValueVacantRoomNumber: (vacantRoomNumber)=>{
            setVacantRoomNumber(vacantRoomNumber);
            setRentedRoomNumber("");
        },
        setValueRentedRoomNumber: (rentedRoomNumber)=>{
            setRentedRoomNumber(rentedRoomNumber);
            console.log(rentedRoomNumber)
            setVacantRoomNumber("");
            logMe("setValueRentedRoomNumber",rentedRoomNumber)
        },
        setValueTenancyId: (tenancyId)=>{
            logMe("setValueTenancyId",tenancyId);
            setTenancyId(tenancyId);
           
        },
        setValueSearchStartDate: (searchStartDate)=>{
            setSearchStartDate(searchStartDate)
        },
        setValueSearchEndDate:(searchEndDate)=>{
            setSearchEndDate(searchEndDate)
        },
        setValueVacantRooms:(vacantRooms)=>{
            setVacantRooms(vacantRooms)
        }

    };


    return (
        <SearchDateContext.Provider value={valuesToShare}>
            {/* <SearchRoomsByDateComponent/>    */}
            <ManageTenancyInformationComponent/>
            {false && <IndividualTenancy/>}
            {children}
            
        </SearchDateContext.Provider>
    );
}
export {Provider};
export default SearchDateContext;