import React from 'react';
import {createContext} from "react"
import ReactDOM from 'react-dom/client';
// import './index.css';
// import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';


import {GetTenancyDetailsById} from './components/Tenancy.js'
import { ManageCustomerInformationComponent } from './components/Customer';
// const AppContext=createContext();

import {Provider} from '../src/components/CommonComponents';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Auth0Provider
  //   domain="dev-qd7bxsf1ag5aoig1.us.auth0.com"
  //   clientId="HWHkFACvRiNrxddnHcfJoT0Z06yHUar0"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin
  //   }}
  // >
  <React.StrictMode>
    {/* <AppContext valuesToShare={logMe}> */}

    <Provider>
    <App>
    </App>
    </Provider>
    {/* </AppContext> */}
  </React.StrictMode>
  // </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
