import React, { useState, useEffect, useRef, useContext } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import $, { fn } from 'jquery'
import { Link, redirect } from 'react-router-dom'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCreditCard,
  faEnvelope,
  faPhone,
  faBuilding,
  faFlag,
  faDollar,
} from '@fortawesome/free-solid-svg-icons'
import SearchDateContext from './GuestContext'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Table from 'react-bootstrap/Table'
import { Row, Col } from 'react-bootstrap'
import { AutoComplete } from 'rsuite'

import CommonComponents from './CommonComponents.js'

import {
  formatDate,
  parseResponseJSON,
  allStates,
  logMe,
} from './CommonFunctions'
import CustomerReviewModal from './CustomerReviewModal'

export const ManageCustomerInformationComponent = (props) => {
  const [fname, setFname] = useState(null)
  const [lname, setLname] = useState(null)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState(null)
  const [address, setAddress] = useState('')
  const [state, setState] = useState('Florida')
  const [customerId, setCustomerId] = useState('')
  const [tempCustomerId, setTempCustomerId] = useState('')
  const [customerValidate, setCustomerValidate] = useState(false)
  const [showReview, setShowReview] = useState(false)
  const [isValidationSatisfied, setIsValidationSatisfied] = useState(false)
  const [existingCustomers, setExistingCustomers] = useState([])
  const [allPhoneNumbers, setAllPhoneNumbers] = useState([])
  const {
    searchDate,
    vacantRoomNumber,
    rentedRoomNumber,
    tenancyId,
    customersDetails,
    setValueSearchDate,
    setValueVacantRoomNumber,
    setValueRentedRoomNumber,
    setValueTenancyId,
    setValueCustomersDetails,
  } = useContext(SearchDateContext)
  const [showCustomerDetails, setShowCustomerDetails] = useState(false)
  // const [showDeleteCustomer, setShowDeleteCustomer] = useState(false);
  const [responseForCustomer, setResponseForCustomer] = useState('')
  const States = allStates()
  const { displayNotificationMessage } = useContext(CommonComponents)
  const url = process.env.REACT_APP_SERVER_URL

  const handleCloseCustomerDetails = (e) => {
    if (e) e.preventDefault()

    console.log(
      ' After before settings the flag to show customer details is ' +
        showCustomerDetails
    )
    // setShowCustomerDetails(true);
    setShowCustomerDetails(false)
    setResponseForCustomer('')

    console.log(
      ' After Closing the flag to show customer details is ' +
        showCustomerDetails
    )
  }

  const handleShowCustomerDetails = () => {
    setShowCustomerDetails(true)
  }
  // const handleShowDeleteCustomer=(customerId)=>{
  //   setCustomerId(customerId);
  //   setShowDeleteCustomer(true)
  // }
  // const handleCloseDeleteCustomer = () => {
  //   setShowDeleteCustomer(false);
  // }
  const getAllPhoneNumbers = async () => {
    try {
      await axios
        .get(`${url}/customers/allPhoneNumbers`)
        .then((responseForPhoneNumbers) => {
          console.log(responseForPhoneNumbers.data.length)
          for (var i = 0; i < responseForPhoneNumbers.data.length; i++) {
            allPhoneNumbers.push(responseForPhoneNumbers.data[i].phone)
          }
          console.log(allPhoneNumbers)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllPhoneNumbers()

    if (props.value) {
      // console.log (" UseEffect: Before the flag to show customer details is " + showCustomerDetails);
      console.log(props.value.customerAction)
      setShowCustomerDetails(props.value.isComponentVisible)
      setCustomerId(props.value.customerId)
      getCustomerById(null, props.value.customerId)
    }
  }, [])

  // Save Customer details
  const saveCustomerDetails = async (e) => {
    if (e) e.preventDefault()
    try {
      setCustomerValidate(true)
      /*
       * Saranya
       * Create the customer
       *
       */
      // alert ("Before calling customer save");

      await axios
        .post(`${url}/customers`, {
          lname,
          fname,
          email,
          phone,
          address,
          state,
        })
        .then(async (responseForSaveCustomer) => {
          /*
           * Saranya
           * Customer information is saved in the database
           *
           * */
          const tempDbResponse = parseResponseJSON(responseForSaveCustomer.data)
          console.log(tempDbResponse)
          if (tempDbResponse) {
            // alert(tempDbResponse.data);
            // Set customer ID
            setCustomerId(tempDbResponse.data)
            // Save the customer - tenancy relation
            if (tenancyId)
              //none
              props.saveTenancyCustomerRelation(
                e,
                tempDbResponse.data,
                tenancyId
              ) // Called twice?
          }
          if (!tenancyId) {
            logMe('saveTenancyDetails', 'No tenancy Id')
            await props.saveTenancyDetails(e, 'draft').then((tempTenancyId) => {
              logMe(
                'in await saveTenancyDetails',
                'the response is ' + tempTenancyId
              )
              props.saveTenancyCustomerRelation(
                e,
                tempDbResponse.data,
                tempTenancyId
              )
            })
          }

          // Close the modal UI window
          handleCloseCustomerDetails()

          // Display the status to the customer in UI
          displayNotificationMessage(
            'success',
            'Added ' + fname + ' ' + lname + ' to the list'
          )
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //Edit Customer Details
  const editCustomerDetails = async (e, customerId) => {
    if (e) e.preventDefault()
    try {
      setCustomerValidate(true)
      getCustomerById(e, customerId)
      // alert("in editing customer")
      await axios
        .patch(`${url}/customers/${customerId}`, {
          lname,
          fname,
          email,
          phone,
          address,
          state,
        })
        .then((responseForSaveCustomer) => {
          console.log(
            'Customer updated (' +
              customerId +
              ' ). Status - ' +
              responseForSaveCustomer.status
          )

          handleCloseCustomerDetails()
          displayNotificationMessage(
            'success',
            ' Updated Guest Infromation : ' + fname + ' ' + lname
          )
        })
      // getCustomerById(e,customerId)
      props.getCustomersByTenancyId()
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  // Delete a customer associated with this tenancy
  const deleteCustomerDetails = async (e) => {
    if (e) e.preventDefault()
    try {
      if (customerId) {
        props.deleteTenancyCustomerRelation(e, customerId)
        // await axios.delete(`${url}/customers/${customerId}`).then((responseForSaveCustomer) => {
        //     console.log( "Customer Deleted for ID (" + customerId +" ). Status - " + responseForSaveCustomer.status);

        //   });
      }

      handleCloseCustomerDetails(e)
      displayNotificationMessage(
        'success',
        'Deleted ' + fname + ' ' + lname + ' from the list'
      )
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const getCustomerById = async (e, customerId) => {
    if (e) e.preventDefault()

    try {
      // alert("in getting customer By Id")
      if (customerId) {
        const responseForGettingCustomerById = await axios.get(
          `${url}/customers/${customerId}`
        )
        const tempForGettingCustomerById =
          responseForGettingCustomerById.data[0]
        setCustomerId(tempForGettingCustomerById.id)
        setFname(tempForGettingCustomerById.fname)
        setLname(tempForGettingCustomerById.lname)
        setEmail(tempForGettingCustomerById.email)
        setPhone(tempForGettingCustomerById.phone)
        setAddress(tempForGettingCustomerById.address)
        setState(tempForGettingCustomerById.state)
        handleShowCustomerDetails()
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const searchCustomers = async (e) => {
    if (e) e.preventDefault()
    try {
      const responseForSearchingCustomers = await axios.post(
        `${url}/searchCustomers`,
        {
          fname,
          lname,
          phone,
        }
      )
      if (responseForSearchingCustomers) {
        console.log(responseForSearchingCustomers.data)

        if (responseForSearchingCustomers.data.length > 0) {
          // props.saveTenancyCustomerRelation(e,responseForSearchingCustomers.data[0].id);
          // setCustomerId(responseForSearchingCustomers.data[0].id)
          setExistingCustomers(responseForSearchingCustomers.data)
          setResponseForCustomer('Customer Exists')
          return
        } else {
          setResponseForCustomer('No Existing Customers')
          return
        }
      }
      setResponseForCustomer('Something Went Wrong!!!')
      return
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const handleCustomerDetailsValidation = (e) => {
    if (e) e.preventDefault()
    setCustomerValidate(true)
    const form = e.currentTarget
    const validation = form.checkValidity()
    if (validation) {
      if (customerId) {
        editCustomerDetails(e, customerId)
        if (props.value.customerAction == 'update') {
        } else updateCustomerTenancyRelation(e)
        setShowCustomerDetails(false)
      } else {
        // const searchResult=searchCustomers(e);
        // console.log(searchResult)
        // searchResult.then((result)=>{
        //   console.log(result)
        //   if(result===false)
        //   {
        //     console.log("before saveCustomerDetails")
        //     saveCustomerDetails(e);

        //   }
        //   else{
        //     setResponseForCustomer("Customer Details already exists")
        //   }
        //  })

        saveCustomerDetails(e)
      }
      // if(props.value.customerAction=="update"){}
      // else
      // updateCustomerTenancyRelation(e)
      // setShowCustomerDetails(false)

      handleCloseCustomerDetails(e)
    } else {
      setResponseForCustomer('enter all required Fields')
    }
  }
  const updateCustomerTenancyRelation = async (e) => {
    if (e) e.preventDefault()
    try {
      if (!tenancyId) {
        logMe('saveTenancyDetails', 'No tenancy Id')
        await props.saveTenancyDetails(e, 'draft').then((tempTenancyId) => {
          logMe(
            'in await saveTenancyDetails',
            'the response is ' + tempTenancyId
          )
          props.saveTenancyCustomerRelation(e, customerId, tempTenancyId)
        })
      } else {
        logMe(
          'saveTenancyCustomerRelation',
          'Only saving relation to existing tenancyId ' + tenancyId
        )
        props.saveTenancyCustomerRelation(e, customerId)
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }

    // setTimeout(() => {
    //   props.saveTenancyCustomerRelation(e,tempCustomerId)
    // }, 15000);
  }
  const customerInputValues = () => {
    return (
      <>
        <Form
          noValidate
          validated={customerValidate}
          onSubmit={(e) => handleCustomerDetailsValidation(e)}
        >
          <Modal.Body className="ms-5 w-75">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Phone Number"
                aria-label="PhoneNumber"
                value={phone}
                onChange={(e) => {
                  var len = e.target.value.length
                  if (len == 0) setPhone(null)
                  else setPhone(e.target.value)
                }}
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
              </InputGroup.Text>
              <Form.Control
                placeholder="First Name"
                aria-label="FirstName"
                value={fname}
                onChange={(e) => {
                  var len = e.target.value.length
                  if (len == 0) setFname(null)
                  else setFname(e.target.value)
                }}
                aria-describedby="basic-addon1"
                required
              ></Form.Control>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
              </InputGroup.Text>
              <Form.Control
                placeholder="Last Name"
                aria-label="LastName"
                value={lname}
                onChange={(e) => {
                  var len = e.target.value.length
                  if (len == 0) setLname(null)
                  else setLname(e.target.value)
                }}
                aria-describedby="basic-addon1"
                required
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
              </InputGroup.Text>
              <Form.Control
                placeholder="Email Address"
                aria-label="EmailAddress"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon>
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Street Address"
                aria-label="StreetAddress"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <Form.Select
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="w-75 ms-5 pe-5"
            >
              <option value="" disabled selected>
                State
              </option>
              {States.map((state) => (
                <option value={state}>{state}</option>
              ))}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => handleCloseCustomerDetails(e)}
            >
              Close
            </Button>
            {customerId ? (
              <Button
                variant="primary"
                type="submit"
                // onClick={(e)=>editCustomerDetails(e,customerId)}
              >
                Update Changes and Add to List
              </Button>
            ) : (
              // variant="success"
              <Button
                variant="success"
                type="submit"
                //  onClick={(e)=>saveCustomerDetails(e)}
              >
                Add Guest to Room{' '}
                {vacantRoomNumber
                  ? vacantRoomNumber
                  : rentedRoomNumber
                  ? rentedRoomNumber
                  : null}
              </Button>
            )}

            <Button
              style={{ backgroundColor: '#80669d' }}
              onClick={(e) => searchCustomers(e)}
            >
              Search
            </Button>
          </Modal.Footer>
        </Form>
      </>
    )
  }
  const existingCustomersDetails = () => {
    // var tempCustomerId;
    // const setTempCustomerId=(Id)=>{
    //   tempCustomerId=Id
    // }

    const showingSelectedSearchCustomer = (
      e,
      id,
      fname,
      lname,
      phone,
      email,
      address,
      state
    ) => {
      setCustomerId(id)
      setExistingCustomers([])
      setFname(fname)
      setLname(lname)
      setPhone(phone)
      setEmail(email)
      setAddress(address)
      setState(state)
    }
    return (
      <>
        <Modal.Body>
          <Table>
            {existingCustomers.map((row, index) => (
              <tr>
                <th className="m-0">
                  <Form.Check
                    className="ms-4 p-0"
                    type="radio"
                    name="radioGroup"
                    onClick={(e) =>
                      showingSelectedSearchCustomer(
                        e,
                        row.id,
                        row.fname,
                        row.lname,
                        row.phone,
                        row.email,
                        row.address,
                        row.state
                      )
                    }
                  ></Form.Check>
                </th>

                {/* <td>{row.id}</td> */}
                <td>{row.phone}</td>
                <td>{row.fname}</td>
                <td>{row.lname}</td>
                <td>
                  {row.reviewCount == '0' ? (
                    <span
                      style={{
                        background: 'none',
                      }}
                    >
                      No Reviews
                    </span>
                  ) : (
                    <a
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        color: 'blue',
                      }}
                      onClick={(e) => {
                        setCustomerId(row.id)
                        setShowReview(true)
                      }}
                    >
                      {'[' + row.reviewCount + '] '} Reviews
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setExistingCustomers([])}>Back</Button>
          {/* <Button onClick={(e)=>showingSelectedSearchCustomer(e)}>Add to List</Button> */}
        </Modal.Footer>
      </>
    )
  }

  return (
    <div>
      {props.value &&
      (props.value.customerAction == 'create' ||
        props.value.customerAction == 'update') ? (
        <Modal
          show={showCustomerDetails}
          onHide={handleCloseCustomerDetails}
          dialogClassName="modal-width"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Customer Details{' '}
              {responseForCustomer ? (
                <h5 style={{ color: 'blue' }}>{responseForCustomer}</h5>
              ) : (
                <h5></h5>
              )}
            </Modal.Title>
          </Modal.Header>
          {existingCustomers.length == 0 && customerInputValues()}
          {existingCustomers.length > 0 && existingCustomersDetails()}
          {showReview && (
            <CustomerReviewModal
              data={{ showReview, setShowReview, phone, customerId }}
            ></CustomerReviewModal>
          )}
        </Modal>
      ) : null}

      {props.value && props.value.customerAction == 'delete' ? (
        <Modal show={showCustomerDetails} onHide={handleCloseCustomerDetails}>
          <Modal.Body>
            <h4>Are You Sure</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => handleCloseCustomerDetails(e)}
            >
              No
            </Button>
            <Button variant="primary" onClick={(e) => deleteCustomerDetails(e)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  )
}
