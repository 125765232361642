import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import Header from "./Header.js";
import $ from 'jquery';
import Header from "./Header";
import { Button, Col, Row,Form } from "react-bootstrap";
import {formatDate, parseResponseJSON,logMe,dateDifference,getDataSorted, formatDateText} from "./CommonFunctions";
import { Message } from "rsuite";
import { Table , Rate , RadioTile} from 'rsuite';

const { Column, HeaderCell, Cell } = Table;


const RoomAvailabilityReport=()=> 
{
    const [checkin, setCheckin]                     = useState (formatDate(new Date())) ;
    const [checkout, setCheckout]                   = useState (formatDate(new Date(),7)) ;
    const [vacantRooms,setVacantRooms]              = useState([]);
    const [sortColumn, setSortColumn] = useState("id");
  const [sortType, setSortType] = useState("asc");
  const [loading, setLoading] = useState(false);
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      getVacantRoomsByDate(null,checkin,checkout);
    },[]);
    const getVacantRoomsByDate  = async (e,searchStartDate,searchEndDate) => {
        //  if(e) 
        //  e.preventDefault();
         try {
           if(searchStartDate==null || searchEndDate==null)
           {
             alert("Select Both searchStartDate and searchEndDate Dates");
             return;
           }
           
           await axios.post(`${url}/vRoomsByDate`,{
             searchStartDate,
             searchEndDate
           }
           ).then((responseForVacantRooms) => {
             console.log(responseForVacantRooms.data);
             setVacantRooms(responseForVacantRooms.data);
           })
         } 
         catch ( error)
         {
           console.log (error);
         }
      }
      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
      

    return (
        <>
            <Header/>
            <div class="container" id="container">
              <div style={{width:"75%",marginLeft:"10%"}}>
                {/* <Table style={{width:"75%",marginLeft:"10%"}}> */}
                    <Row style={{marginTop:"1%",marginLeft:"10%",fontWeight:"bolder"}}>
                        <Col>Checkin Date: </Col>
                        <Col>Checkout Date: </Col>
                        <Col style={{marginBottom:"0.5%"}}>Selected For</Col>
                        <Col></Col>
                       
                    </Row>
                    <Row style={{marginBottom:"1%",marginLeft:"8%"}}>
                    <Col sm>
                        <Form.Control
                        className="w-75"
                            type="date"
                            value={checkin} 
                            // style={{backgroundColor:"#edfae1"}}
                            onChange={(e)=>
                            {
                                setCheckin(e.target.value);
                            }}
                            aria-describedby="basic-addon1"
                        />
                    </Col>
                    <Col sm>
                        <Form.Control
                        className="w-75"
                            type="date"
                            value={checkout}  
                            // style={{backgroundColor:"#edfae1"}}
                            onChange={(e)=>setCheckout(e.target.value)}
                            aria-describedby="basic-addon1"
                        />
                    </Col>
                    <Col sm><b>{dateDifference(checkin,checkout)} Days</b></Col>
                    <Col aria-rowspan={2}><Button onClick={(e)=>getVacantRoomsByDate(e,checkin,checkout)}>Search to get availability</Button></Col>
                    
                    </Row>
                    </div>
                {/* </Table> */}
                <br></br>
                {vacantRooms==0 ? <Message showIcon type="error" closable style={{fontSize:"1.3rem"}}>
                    There is no room available in the selected date range.
                  </Message> : 
      //           <Table className="table is-striped is-fullwidth" >
      //   <thead>
      //     <tr>
      //       <th>Room Number</th>
      //       <th>Readiness</th>
      //       <th>Last Checkedout</th>
      //       <th>Room Size</th>
      //       <th>Weekly Rate / Daily Rate</th>
      //       <th>Daily Rate</th>
      //       <th>Amenities</th>
      //     </tr>
      //   </thead>
      //   <tbody>
      //     {vacantRooms.map((c, index) => (
      //       <tr>
      //         <td><Link to={`/search?checkin=${checkin}&checkout=${checkout}&roomNumber=${c.id}`}>{c.id}</Link></td>
      //         <td>{c.currentStatus ? c.currentStatus : "checked out"}</td>
      //         <td>{formatDateText(c.actualCheckoutDate)} &nbsp;(&nbsp;{c.actualCheckoutDate ? <b>{dateDifference(new Date(),c.actualCheckoutDate)}</b> : null} days )</td>
      //         <td>{c.size}</td>
      //         <td>{"$"+ c.weeklyRate}</td>
      //         <td>{"$" + c.dailyRate}</td>
      //         <td></td>
      //       </tr>
      //     ))}
      //   </tbody>
      // </Table>
      
      <Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    data={getDataSorted(sortColumn,sortType,vacantRooms)}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}

    >

     
    
      {/* <Column width={100} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Room </b></HeaderCell>
        <Cell dataKey="id">
          {rowData => (
            <Link to={`/search?checkin=${checkin}&checkout=${checkout}&roomNumber=${rowData.id}`}>
              {rowData.id}
            </Link>
            
          )}
          </Cell>
      </Column> */}
      <Column width={100} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Name</b></HeaderCell>
        <Cell dataKey="alias">
          {rowData => (
            <Link to={`/search?checkin=${checkin}&checkout=${checkout}&roomNumber=${rowData.id}`}>
              {rowData.alias}
            </Link>
            
          )}
          </Cell>
      </Column>
      <Column width={280}>
        <HeaderCell><b>Readiness</b> </HeaderCell>
        <Cell >
        {rowData => (
            <>{rowData.currentStatus ? rowData.currentStatus : "checked out"}</>
            
          )}
        
        </Cell>
      </Column>

      <Column width={280} sortable>
        <HeaderCell><b>Last Checkedout </b></HeaderCell>
        <Cell dataKey="actualCheckoutDate">
        {rowData => (
            <>
            {formatDateText(rowData.actualCheckoutDate)} &nbsp;(&nbsp;
            {rowData.actualCheckoutDate ? 
            <b>{dateDifference(new Date(),rowData.actualCheckoutDate)}</b> : null} 
            days )
            </>
            
          )}
        
        </Cell>
      </Column>

      <Column width={120}>
        <HeaderCell><b>Room Size </b></HeaderCell>
        <Cell dataKey="size" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Weekly Rate </b></HeaderCell>
        <Cell dataKey="weeklyRate">
        {rowData => (
            <>{"$"+ rowData.weeklyRate}</>
            
          )}

        </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell><b>Daily Rate </b></HeaderCell>
        <Cell dataKey="dailyRate">
        {rowData => (
            <>{"$" + rowData.dailyRate}</>
            
          )}
        </Cell>
      </Column>

      {/* <Column width={150}>
        <HeaderCell><b>Amenities </b></HeaderCell>
        <Cell dataKey="" />
      </Column> */}
      

    </Table>
}
            </div>
        </>
    
 
    
    );

    


};
 
export default RoomAvailabilityReport;








