// axiosSetup.js
import axios from 'axios';

// Add a request interceptor to the global axios instance
axios.interceptors.request.use(
  (config) => {
    const credentials = 'your-credentials'; // Replace with your actual credentials or retrieve from state/storage
    config.headers.Authorization = `Bearer ${credentials}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
