import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'


import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
// import Table from 'react-bootstrap/Table';
import { useFormInput } from '../useFormInput.js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import { Rate, Table, Steps, Panel, Placeholder, ButtonGroup } from 'rsuite'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  getDataSorted,
  formatDateText,
} from '../CommonFunctions'
import Header from '../Header.js';
import UndepositedPayments from './UndepositedPayments.js'
import DepositDetails from './DepositDetails.js';
import PaymentsToDepositSummary from './PaymentsToDepositSummary.js';
import AddCashDeposit from '../AddCashDeposit.js'
const stepsComponents = [UndepositedPayments, PaymentsToDepositSummary, AddCashDeposit]
const PaymentsToDeposit = () => {
  const [paymentsToDeposit,setPaymentsToDeposit]=useState([]);
  const [searchStartDate, setSearchStartDate] = useState(formatDate(new Date(),-7))
  const [searchEndDate, setSearchEndDate] = useState(formatDate(new Date()))
  const url = process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
  }, []);
  const [step, setStep] = useState(0)

  const onPrevious = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1)
    }
  }

  const onNext = () => {
    if (step < stepsComponents.length - 1) {
      setStep((prevStep) => prevStep + 1)
    }
  }

  const CurrentStepComponent = stepsComponents[step]
  return (
    <>
    <Header/>
    <div class="container" style={{width:"80%"}}>
        {/* Commented Table */}
        
    <Steps current={step}>
            <Steps.Item title="Undeposited Payments" />
            <Steps.Item title="Payments to Deposit" />
            <Steps.Item title="Deposit Details" />
          </Steps>
          <hr />
          <Panel>
            <CurrentStepComponent 
            paymentsToDeposit={paymentsToDeposit}
            setPaymentsToDeposit={setPaymentsToDeposit}
            searchStartDate={searchStartDate}
            searchEndDate={searchEndDate}
            setSearchStartDate={setSearchStartDate}
            setSearchEndDate={setSearchEndDate}
            />
          </Panel>
          <hr />
          <ButtonGroup style={{display:"flex",justifyContent:"space-between"}}>
            <Button onClick={onPrevious} disabled={step === 0}>
              Previous
            </Button>
            <Button
              onClick={onNext}
              disabled={step === stepsComponents.length - 1}
            >
              Next
            </Button>
          </ButtonGroup>{' '}
          </div>
    </>

  );
}
export default PaymentsToDeposit;
