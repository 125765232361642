
// material-ui
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MainCard from './MainCard';
// import {TrendingDownIcon , TrendingUpIcon } from '@mui/icons-material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { NumericFormat } from 'react-number-format';
import { Button } from '@mui/material';

// project import



export default function TitleComponent({  
  title="checkins", count=null,isMoney=false,displayType,setDisplayType }) 
  {

    

  return (
    <>
    <MainCard contentSX={{ p: 0.25 }}>
      <Stack spacing={0.5}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h6" color="text.secondary">
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item>
               <Typography variant="h4" color="inherit">
                <NumericFormat value={count} thousandSeparator prefix={isMoney ? '$' : ''} displayType="text"/>
              </Typography> 
            </Grid>
          </Grid>
          </Grid>
          {displayType ? <Grid item xs={12} sm={12} md={5}>
          <Button variant={displayType==="weekly" ? "outlined" : "text"}
            onClick={(e)=>setDisplayType("weekly")}
            >Weekly
          </Button>
          <Button 
            variant={displayType==="daily" ? "outlined" : "text"}
              onClick={(e)=>setDisplayType("daily")}
            >Daily
          </Button>

          </Grid> :null}
        
        
      </Grid>
      
      </Stack>
    </MainCard>
    
    </>
  );
}