import React, { useState, useEffect, useRef,useContext } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn, nodeName } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import Header from "./Header1.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faThLarge, faDollar } from '@fortawesome/free-solid-svg-icons'
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Button,Row,Col} from "react-bootstrap"
import SelectUSState from 'react-select-us-states';
import { createSlice } from '@reduxjs/toolkit'
import Card from 'react-bootstrap/Card';
import {Modal} from 'react-bootstrap';

import {formatDate, parseResponseJSON} from "./CommonFunctions";
import { ManageTenancyInformationComponent } from "./Tenancy";

export const ManageRoomInfo = (props) => {
    const [showRoomDetailsPopup,setShowRoomDetalsPopup]=useState(false);
    const [roomId,setRoomId]=useState("");
    const [bedType,setBedType]=useState("");
    const [size,setSize]=useState("");
    const [weeklyRate, setWeeklyRate]=useState("");
    const [dailyRate, setDailyRate]=useState("");
    const [rentableCheckBox,setRentableCheckBox]=useState(false);
    const [alias,setAlias]=useState("");
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      
        setShowRoomDetalsPopup(true);
        if(props.value)
    {
        
        setRoomId(props.value);
        getRoomAndAmenityDetailsById(props.value);
    }
    else{
        setRoomId(null)
    }
   
     
   }, []);
   useEffect(()=>{
    
//     if(!roomId)
// setRoomId(null)
   },[])
   const handleCloseShowRoomDetailsPopup=()=>{
    setShowRoomDetalsPopup(false);
    
    setRoomId(null);
    if(props && props.setShowRoomDetailsPopup)
    {
      props.setShowRoomDetailsPopup(false);
    }
    
   }
   const getRoomAndAmenityDetailsById= async (roomId)=>{
    try{
        await axios.get(`${url}/rooms/allAmenities/${roomId}`).then((responseForSingleRoomDetails)=>{
            console.log(responseForSingleRoomDetails.data);
            setStateValues(responseForSingleRoomDetails.data[0])
        })


    }catch(error){
        console.log(error)
    }
   }
   const createNewRoom = async (e) => {
    if(e) e.preventDefault();
    try{
        const available_status=0;
        const rentable=rentableCheckBox ? 1 : 0;
      await axios.post(`${url}/rooms`,{
        bedType,
        size,
        available_status,
        weeklyRate,
        dailyRate,
        alias,
        rentable


      }).then((responseForCreatingRoom)=>{
        console.log(responseForCreatingRoom.data);
        const tempDbResponse=parseResponseJSON(responseForCreatingRoom.data);
        // if(tempDbResponse)
        // {
        //   setRoomId()
        // }
        
        props.getRoomsDetails();
        handleCloseShowRoomDetailsPopup();
      })

    }catch(error){
      console.log(error)
    }
  }
  const editRoomDetails = async (e,roomId) => {
    if(e) e.preventDefault();
    try{
      const rentable=rentableCheckBox ? 1 : 0;
      await axios.patch(`${url}/rooms/${roomId}`,{
        bedType,
        size,
        weeklyRate,
        dailyRate,
        alias,
        rentable

      }).then((responseForEditingRoom)=>{
        console.log(responseForEditingRoom.data);
        props.getRoomsDetails();
        // handleCloseShowRoomDetailsPopup();
        setShowRoomDetalsPopup(false)
        handleCloseShowRoomDetailsPopup();
      })

    }catch(error){
      console.log(error)
    }
  }
  const setStateValues = (roomDetails) =>{
    try{
        if(roomDetails){
            setRoomId(roomDetails.id);
            setBedType(roomDetails.bedType);
            setSize(roomDetails.size);
            setWeeklyRate(roomDetails.weeklyRate);
            setDailyRate(roomDetails.dailyRate);
            setAlias(roomDetails.alias);
            if(roomDetails.rentable==1)
            {
            setRentableCheckBox(true);
            }
            else
            {
            setRentableCheckBox(false);
            }

        }
        else{
            setRoomId();
            setBedType();
            setSize();
            setWeeklyRate();
            setDailyRate();
            setAlias();
            setRentableCheckBox(false);
        }
       

    }
    catch(error){
        console.log(error)
    }
  }

    return (<>
    <Modal show={showRoomDetailsPopup} onHide={handleCloseShowRoomDetailsPopup}>
        <Modal.Header closeButton>Room Details : {roomId} </Modal.Header>

            
        <Modal.Body className="me-3">
          <Form.Group >
          <Row>
              <Col className="mt-3 ms-5"><Form.Label style={{float:"left"}}><b>Name :</b></Form.Label></Col>
              <Col className="w-25 me-5 pe-5 mt-3"><Form.Control  size="sm" style={{float:"left"}}
                  value= { alias }  onChange={(e)=>{setAlias(e.target.value)}}>
            
              </Form.Control></Col>


            </Row>
              
            <Row>
              <Col className="mt-4 ms-5"><Form.Label style={{float:"left"}}><b>Bed Type :</b></Form.Label></Col>
              <Col className="w-25 me-5 pe-5 mt-3"><Form.Select className="w-75 me-5"  size="sm" style={{float:"left",marginLeft:"-10%"}}
                  value= { bedType }  onChange={(e)=>{setBedType(e.target.value)}}>
                <option value='' disabled selected></option>
                <option value="Single">Single</option>
                <option value="Double">Double</option>
            
              </Form.Select></Col>


            </Row>
              
          </Form.Group>                                     
   
            <Row>
            <Col><Form.Label className="ms-5 mt-4" style={{float:"left"}}><b>Room Size :</b></Form.Label></Col>
            <Col className="w-25 me-5 pe-5 mt-3">
            <Form.Select className="w-75"  size="sm"
                    value= { size }  onChange={(e)=>{setSize(e.target.value)}}>
                    <option value='' disabled selected></option>
                    <option value="Small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                </Form.Select>
            </Col>
            </Row>
        <br></br>
            <Row>
            <Col><Form.Label className="ms-5 mt-1 h-4" style={{float:"left"}}><b>Weekly Rate :</b></Form.Label></Col>
            <Col className="w-25 me-5 pe-5">
            <Form.Control
                type="number"
                min={0}
                value={weeklyRate} 
                onChange={(e)=>{setWeeklyRate(e.target.value)}}
                aria-describedby="basic-addon1"
                style={{float:"left"}}
            />
            </Col>
            </Row>
        <br></br>
            <Row>
            <Col><Form.Label className="ms-5 mt-1 h-4" style={{float:"left"}}><b>Daily Rate :</b></Form.Label></Col>
            <Col className="w-25 me-5 pe-5">
            <Form.Control
                type="number"
                min={0}
                value={dailyRate} 
                onChange={(e)=>{setDailyRate(e.target.value)}}
                aria-describedby="basic-addon1"
                style={{float:"left"}}
            />
            </Col>
            
            </Row>
            <br></br>
            <Row className="ms-2"><b>
              <Form.Check label="Do not Rent" 
              className="ms-5 mt-1 h-4" checked={rentableCheckBox}
               onChange={(e)=>setRentableCheckBox(!rentableCheckBox)}>
              </Form.Check></b>
              </Row>

        </Modal.Body>

        <Modal.Footer>
            <Button onClick={()=>handleCloseShowRoomDetailsPopup()}>Close</Button>

            {roomId!=null ? <Button onClick={(e)=>editRoomDetails(e,roomId)}>Save Changes</Button> : 

            <Button onClick={(e)=>createNewRoom(e)}>Add Room</Button>}
            

        </Modal.Footer>
    </Modal>
    </>);
}