import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import 'reactjs-popup/dist/index.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom'


import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
// import Table from 'react-bootstrap/Table';
import { useFormInput } from '../useFormInput.js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  getDataSorted,
  formatDateText,
} from '../CommonFunctions'
import Header from '../Header.js';
import AddCashDeposit from "../AddCashDeposit.js";

import CommonComponents from '../CommonComponents.js'
import { Rate, Table, Steps, Panel, Placeholder, ButtonGroup } from 'rsuite'
const { Column, HeaderCell, Cell } = Table
// const {id}=useParams();



const CashManagement = () => {
  const [cashDetails, setCashDetails] = useState([])
  const [searchStartDate, setSearchStartDate] = useState(formatDate(new Date(),-7))
  const [searchEndDate, setSearchEndDate] = useState(formatDate(new Date()))
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [sortColumn, setSortColumn] = useState('id')
  const [sortType, setSortType] = useState('asc')
  const [loading, setLoading] = useState(false)
  const [cashDepositEntry,setCashDepositEntry]=useState(false)
  let totalCashAmountForSelectedPeriod = 0,
    totalCreditAmountForSelectedPeriod = 0
  const { id } = useParams()
  const navigate = useNavigate()
  const url = process.env.REACT_APP_SERVER_URL;

  
  useEffect(() => {
    // getLast3MonthsCashPayments()
    getCashManagementDetailsByDate()
  }, [])
  cashDetails.forEach((item) => {
    totalCashAmountForSelectedPeriod += item.totalCashAmount
    totalCreditAmountForSelectedPeriod += item.totalCreditAmount
  })

  const setDetailsAndCalculateOverall = (cashDetails) => {
    setCashDetails(cashDetails)

    // for(var i=0;i<cashDetails.length;i++)
    // {

    // }
    // console.log(totalCashAmountForSelectedPeriod);
    // console.log(totalCreditAmountForSelectedPeriod);
  }
  const getCashManagementDetailsByDate = async () => {
    try {
      if (searchStartDate && searchEndDate) {
        await axios
          .post(`${url}/cashManagementByDateRange`, {
            searchStartDate,
            searchEndDate,
          })
          .then((responseForDateRange) => {
            console.log(responseForDateRange.data)
            setDetailsAndCalculateOverall(responseForDateRange.data)
            // setCashDetails(responseForDateRange.data);
          })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getLast3MonthsCashPayments = async () => {
    try {
      await axios.get(`${url}/cashManagement`).then((responseForCash) => {
        console.log(responseForCash.data)
        setDetailsAndCalculateOverall(responseForCash.data)
        // setCashDetails(responseForCash.data);
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handleCashDepositEntry=(boolValue)=>{
    setCashDepositEntry(boolValue)
  }
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortColumn)
      setSortType(sortType)
    }, 500)
  }

  return (
    <>
      <Header />
      {/* <Modal show={isAddReview} onHide={handleCloseAddReview} dialogClassName="modal-width">

      <Modal.Header closeButton>

      <Modal.Title></Modal.Title>
      
      </Modal.Header>

      <Modal.Body> */}
      <div class="container" id="container">
        {/* Commented Table */}
        <table style={{ width: '75%', marginLeft: '10%' }}>
          <Row
            style={{ marginTop: '1%', marginLeft: '10%', fontWeight: 'bolder' }}
          >
            <Col> </Col>
            <Col></Col>
            <Col style={{ marginBottom: '0.5%' }}></Col>
            <Col></Col>
          </Row>
          <Row style={{ marginBottom: '1%', marginLeft: '8%' }}>
            <Col sm>
              <Form.Control
                className="w-75"
                type="date"
                value={searchStartDate}
                // style={{backgroundColor:"#edfae1"}}
                onChange={(e) => {
                  setSearchStartDate(e.target.value)
                }}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                className="w-75"
                type="date"
                value={searchEndDate}
                // style={{backgroundColor:"#edfae1"}}
                onChange={(e) => setSearchEndDate(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            {/* <Col sm><b>{dateDifference(checkin,checkout)} Days</b></Col> */}
            <Col aria-rowspan={2}>
              <Button
                onClick={(e) =>
                  getCashManagementDetailsByDate(
                    e,
                    searchStartDate,
                    searchEndDate
                  )
                }
              >
                Search
              </Button>
            </Col>
            <Col aria-rowspan={2}>
              <Button 
                // onTouchMove={(e)=>handleCashDepositEntry(false)} 
                // onMouseMove={(e)=>handleCashDepositEntry(false)}
              >
                <Link to={`/paymentsToDeposit`}style={{color:"white"}}>
                Make Deposit
                </Link>
              </Button>
            </Col>
          </Row>
        </table>
        <br></br>
        <div>
          {/* <Table bordered>
          <thead>
            <th>Date</th>
            <th>Cash received</th>
            <th>Credit card Amount received</th>

            </thead>
        <tbody>
        {cashDetails.map((row, index) => (
            <tr>
            <td>{row.paymentDate}</td>
            <td>{row.totalCashAmount}</td>
            <td>{row.totalCreditAmount}</td>
            
            </tr>
          ))}
          </tbody>

          //Commented Table 
          </Table> */}
          <Table
            className="ms-5"
            autoHeight
            // wordWrap="break-word"
            data={getDataSorted(sortColumn, sortType, cashDetails)}
            size="lg"
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}
            headerHeight={60}
          >
            <Column width={160} sortable>
              <HeaderCell>
                <b>Payment Date</b>
              </HeaderCell>
              <Cell dataKey="paymentDate">
                {(rowData) => <>{formatDateText(rowData.paymentDate)}</>}
              </Cell>
            </Column>

            <Column width={200} align="center" sortable>
              <HeaderCell>
                <HeaderSummary
                  title="Cash Amount Received"
                  total={totalCashAmountForSelectedPeriod}
                />
              </HeaderCell>
              <NumberCell dataKey="totalCashAmount" />
            </Column>

            <Column width={200} align="center" sortable>
              <HeaderCell>
                <HeaderSummary
                  title="Credit card Amount Received"
                  total={totalCreditAmountForSelectedPeriod}
                />
              </HeaderCell>
              <NumberCell dataKey="totalCreditAmount" />
            </Column>
            <Column width={200} align="center" sortable>
              <HeaderCell>
                <HeaderSummary
                  title="Deposit Number(Date,By)"
                  total={0}
                />
              </HeaderCell>
              <NumberCell dataKey="depositId" />
            </Column>
            <Column width={200} align="center" sortable>
              <HeaderCell>
                <HeaderSummary
                  title="Deposit Amount"
                  total={0}
                />
              </HeaderCell>
              <NumberCell dataKey="depositAmount" />
            </Column>
          </Table>
          
        </div>
        {/* </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      
        
        
        </Modal> */}
        {/* {cashDepositEntry && <AddCashDeposit handleCashDepositEntry={handleCashDepositEntry}/>} */}
      </div>
    </>
  )
}

const thousands = (value) => `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, '$1,')
const NumberCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
)

const HeaderSummary = ({ title, total }) => (
  <div>
    <label>
      <b>{title}</b>
    </label>
    <div
      style={{
        fontSize: 18,
        color: '#2eabdf',
      }}
    >
      {'$' + thousands(total)}
    </div>
  </div>
)

export default CashManagement
