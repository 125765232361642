
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {Provider} from "./GuestContext";
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
// import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {formatDate, parseResponseJSON,logMe , dateDifference, formatDateText} from "./CommonFunctions";
import { ManageCustomerInformationComponent } from "./Customer";
import {SearchRoomsByDateComponent} from "./SearchRoomsByDate"
import TestComponent from "./TestComponent";
import {CheckinForm} from "./CheckinForm";
import {CheckoutForm} from "./CheckoutForm"
import MakePayment from "./MakePayment";
import {Row , Col} from 'react-bootstrap'
import Header from "./Header.js"
import { Table , Rate , RadioTile} from 'rsuite';
import SendSMS from "./SendSMS";

const { Column, HeaderCell, Cell } = Table;

const VacancyTrackingReport=()=>{
    const [vacancyTrackingHistory,setVacancyTrackingHistory] =useState([]);
    const [vacancyTrackingId,setVacancyTrackingId] =useState("");
    const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [allRooms,setAllRooms]=useState([]);
  const [roomNumber,setRoomNumber]=useState("")
  const [totalVacantDays,setTotalVacantDays]=useState(0);
  const [startDate,setStartDate]=useState(formatDate(new Date(),-7));
  const [endDate,setEndDate]=useState(formatDate(new Date()));
  const url=process.env.REACT_APP_SERVER_URL;
  
  const navigate=useNavigate();
    useEffect(() => {
        getVacancyHistoryReport();
        getAllRooms();
        
      }, []);
      const getAllRooms=async()=>{
        try{
          await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
            console.log(responseForAllRooms.data);
            setAllRooms(responseForAllRooms.data);
          })
    
        }
        catch(error){
          console.log(error);
        }
       }

    function calculateTotalVacantDays(trackingData){
      var tempTotalVacantDays=0;
      for(var i=0;i<trackingData.length;i++){
        if(trackingData[i].checkin)
        tempTotalVacantDays+=dateDifference(trackingData[i].actualCheckoutDate,trackingData[i].checkin);
      else
      tempTotalVacantDays+=dateDifference(trackingData[i].actualCheckoutDate,new Date());
      }
      setTotalVacantDays(tempTotalVacantDays);
    }
    const getVacancyHistoryReport = async(roomNumber) => {
        try{
          if(roomNumber){
            await axios.get(`${url}/vacancyTrackingReport/${roomNumber}`).then((responseForResponseTrackingReport)=>{
              console.log(responseForResponseTrackingReport.data);
              setVacancyTrackingHistory(responseForResponseTrackingReport.data);
              calculateTotalVacantDays(responseForResponseTrackingReport.data);
          })
          }
          else{
            await axios.get(`${url}/vacancyTracking`).then((responseForResponseTrackingReport)=>{
                console.log(responseForResponseTrackingReport.data);
                setVacancyTrackingHistory(responseForResponseTrackingReport.data);
                calculateTotalVacantDays(responseForResponseTrackingReport.data);
            })
          }

        }
        catch(error){
            console.log(error);
        }
    }
    const getData = () => {
        if (sortColumn && sortType) {
          return vacancyTrackingHistory.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
          
            console.log(typeof x)
            if (typeof x === 'string') {
              x = x.charCodeAt();
              console.log(typeof x + ' x: ' +x)
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
              console.log(typeof y + ' y: ' +y)
            }
            // if(typeof x==="date")
            // {
            //     console.log("Date type : "+x)
            // }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
        }
        return vacancyTrackingHistory;
      }


    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
    const formatDate_local = (inputDate) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = new Date(inputDate + 'T00:00:00').toLocaleDateString('en-US', options);
  return formattedDate;
      };
      

    // FIXME: export the table to excel
    return (
      <>
    <Header/>
    
    {/* <h4 className="mt-3 ms-5" > Today's Guests</h4>
    <h5 className="mt-0 me-5 mb-0" style={{marginLeft:"50%"}}>Active Guests: {activeTenancyDetails.length } 
    &nbsp; &nbsp; Overstayed Guests : {countOverstayedGuests(activeTenancyDetails)}  
    &nbsp; &nbsp; Today Checkouts : {countTodayCheckoutGuests(activeTenancyDetails)}  
    &nbsp; &nbsp; Tomorrow Checkouts : {countTmroCheckoutGuests(activeTenancyDetails)}  </h5> */}
    <br></br>
    {/* FIXME: Filter by Room and Date (default a month) */}
    <div  style={{width:"90%"}}>
    <Row className="ms-5 mt-2">
      <Col>
                <Form.Label className="ms-5"><b>Select a Room:</b></Form.Label>
                <Form.Select className=" ms-5 w-50 h-120"  size="sm"
                    value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value);getVacancyHistoryReport(e.target.value)}}>
                  <option value='' ></option>
                  {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.id}</option>
                ))}
                </Form.Select>
                </Col>
               
   
        <Col>
              <Form.Label><b>Start Date:</b></Form.Label>
              <Form.Control
                className="w-75"
                type="date"
                value ={startDate} 
                onChange={(e)=>{
                  setStartDate(e.target.value);
                }}
                aria-describedby="basic-addon1"
              />
              </Col>
              <Col>
                <Form.Label><b>End Date:</b></Form.Label>
                <Form.Control
                  className="me-5 w-75"
                  type="date"
                  max={new Date()}
                  value ={endDate} 
                  onChange={(e)=>{
                    setEndDate(e.target.value);
                  }}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col className='mt-4'>
              <Button 
              // onClick={(e)=>getFreeRoomsByDate()}
              >
                Search</Button>
              </Col>
        </Row>
        </div>
    <Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    //   data={activeTenancyDetails}
    data={getData()}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}

    >
        {/* <Column width={100} sortable>
        <HeaderCell>Tracking Id</HeaderCell>
        <Cell dataKey="vacancyTrackingId" />
      </Column> */}

      <Column width={80} sortable>
        <HeaderCell>Room</HeaderCell>
        <Cell dataKey="roomId" />
      </Column>

      <Column width={180}>
        <HeaderCell>Actual Checkout date</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.actualCheckoutDate ? formatDateText(rowData.actualCheckoutDate) : null}
          </>
          )}
        </Cell>
      </Column>
      
      <Column width={140}>
        <HeaderCell>Cleaned</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.cleanedDate ? formatDateText(rowData.cleanedDate) : null}
          </>
          )}
        </Cell>
      </Column>
      <Column width={120}>
        <HeaderCell>Repair</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.repairDate ? formatDateText(rowData.repairDate) : null}
          </>
          )}
        </Cell>
      </Column>

      <Column width={120}>
        <HeaderCell>Supplies</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.suppliesDate ? formatDateText(rowData.suppliesDate) : null}
          </>
          )}
        </Cell>
      </Column>
      <Column width={140}>
        <HeaderCell>Inspection</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.inspectedDate ? formatDateText(rowData.inspectedDate) : null}
          </>
          )}
        </Cell>
      </Column>
      
      <Column width={140}>
        <HeaderCell>Ready to rent</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.readyToRentDate ? formatDateText(rowData.readyToRentDate) : null}
          </>
          )}
        </Cell>
      </Column>

      <Column width={140}>
        <HeaderCell>Next Checkin</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.checkin ? formatDateText(rowData.checkin) : null}
          </>
          )}
        </Cell>
      </Column>


      
      <Column width={140}>
        <HeaderCell>Total days <b>{totalVacantDays}</b></HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.checkin ? dateDifference(rowData.actualCheckoutDate,rowData.checkin) :
             dateDifference(rowData.actualCheckoutDate,new Date()) + " and running"}
          </>
           )} 
        </Cell>
      </Column>
      

      



     
    
      {/* <Column width={100} fixed sortable>
        <HeaderCell><b>Room </b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
            <a onClick={(e)=>{
              // setValueTenancyId(row.id);
              navigate(`/tenants/add/${rowData.id}`, { state:{ tenancyId: rowData.id} })}}>
            <Link>
              {rowData.roomid}
            </Link>
            </a>
            
          )}
          </Cell>
      </Column> */}
      

      {/* <Column width={180} sortable>
        <HeaderCell>Names</HeaderCell>
        <Cell dataKey="names" />
      </Column> */}

      

      <Column width={180}>
        <HeaderCell>...</HeaderCell>
        <Cell>
          {rowData => (
            <>
            { rowData.previousTenancyId ?<> <Link to={`/tenants/add/${rowData.previousTenancyId}`}>
                Previous Booking Info</Link> <br></br> </> :null}
           { rowData.nextTenancyId ? <><Link to={`/tenants/add/${rowData.nextTenancyId}`}>Next Booking Info</Link><br></br></>  :null}
           <Link to={`/VacancyActivityReport/${rowData.vacancyTrackingId}`}>Details</Link>
          
          </>
          )}
        </Cell>
      </Column>
      

    </Table>
 
    
    </>
    );

}

export default VacancyTrackingReport;