import React, { useState, useEffect, useRef,useContext } from "react";
import 'reactjs-popup/dist/index.css';
import "../App.css";
import Header from "./Header.js";
import {Provider} from './GuestContext.js';
import { ManageTenancyInformationComponent,GetTenancyDetailsById } from "./Tenancy.js";
import { ManageCustomerInformationComponent } from "./Customer.js";
import { SearchRoomsByDateComponent } from "./SearchRoomsByDate.js";
import IndividualTenancy from "./IndividualTenant";




const GuestManagement = ()=>{
    return (
    <div>
        <Header />

        <div class="container" id="container">
            <Provider>
                
               
            </Provider>
        </div>
        
   </div> 
    );
}

export default GuestManagement;