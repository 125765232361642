import React, { useState, useEffect, createContext, useContext  } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import Header from "./Header1.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import { format, formatDistance, formatRelative, subDays } from 'date-fns' 
import {formatDate} from "./CommonFunctions";
import { useToaster,Notification, Message } from "rsuite";


const CommonComponents=createContext();
function Provider({children}){
    const toaster = useToaster();
    const displayNotification =(type,customMessage,url)=>{
        let displayDuration=5000;
        //FIXME: Why not taking dynamic value
        if(type==="error")
        {
            displayDuration=0;
            console.log(type + " displayNotification in CommonComponents")
            toaster.push(<Message showIcon type={type} closable>{customMessage}   {url ? <a href={url}>Click Here for details</a> : null}</Message>,{placement : 'bottomStart' , duration: 0});
        }
        else{
        console.log(displayDuration)
        toaster.push( <Message showIcon type={type} closable>{customMessage}  
        {url ? <a href={url}>Click Here for details</a> : <></>}
        </Message>,{placement : 'bottomStart' , duration: 5000});
        }
      }
 
    const valuesToShare={
        displayNotificationMessage:(type,message,url)=>{
            displayNotification(type,message,url);
        }
       

    };


    return (
        <CommonComponents.Provider value={valuesToShare}>
            
            {children}
            
        </CommonComponents.Provider>
    );
}
export {Provider};
export default CommonComponents;