import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import $ from 'jquery'
import { Modal, Button } from 'react-bootstrap'
import { Radio, RadioGroup, Form, Checkbox, Input } from 'rsuite'
import CommonComponents from './CommonComponents.js'

const AddVacancyActivity = () => {
  const [currentStatus, setCurrentStatus] = useState('')
  const [vacancyTrackingId, setVacancyTrackingId] = useState('')
  const [showAddVacancyActivityModal, setShowAddVacancyActivityModal] =
    useState(true)
  const [changeDate, setChangeDate] = useState(new Date())
  const [latestRoomStatus, setLatestRoomStatus] = useState('')
  const [responsiblePerson, setResponsiblePerson] = useState('')
  const [comments, setComments] = useState('')
  const navigate = useNavigate()
  const { roomId } = useParams()
  const { displayNotificationMessage } = useContext(CommonComponents)
  const handleCloseAddVacancyActivityModal = () => {
    setShowAddVacancyActivityModal(false)
    window.history.back()
  }
  const url = process.env.REACT_APP_SERVER_URL
  useEffect(() => {
    if (roomId) {
      getVacancyTrackingIdByRoomNumber(roomId)
    }
  }, [])
  const getLatestStatusByVacancyTrackingId = async (vacancyTrackingId) => {
    try {
      await axios
        .get(
          `${url}/vacancyActivity/getLatestStatusByVacancyTrackingId/${vacancyTrackingId}`
        )
        .then((responseForCurrentRoomStatus) => {
          console.log(responseForCurrentRoomStatus.data)
          // alert(responseForCurrentRoomStatus.data[0].currentStatus)
          if (responseForCurrentRoomStatus.data.length > 0)
            setLatestRoomStatus(
              responseForCurrentRoomStatus.data[0].currentStatus
            )
          setCurrentStatus(responseForCurrentRoomStatus.data[0].currentStatus)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const getVacancyTrackingIdByRoomNumber = async (roomId) => {
    try {
      await axios
        .get(`${url}/vacancyTracking/getVacancyTrackingIdByroomId/${roomId}`)
        .then((responseForVacancyTrackingId) => {
          console.log(responseForVacancyTrackingId.data)
          if (responseForVacancyTrackingId.data.length > 0) {
            setVacancyTrackingId(
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId
            )
            getLatestStatusByVacancyTrackingId(
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId
            )
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const createVacancyActivity = async () => {
    try {
      let activityLog =
        'Changes status from ' + latestRoomStatus + ' to ' + currentStatus
      await axios
        .post(`${url}/vacancyActivity`, {
          vacancyTrackingId,
          roomId,
          currentStatus,
          activityLog,
          responsiblePerson,
          comments,
        })
        .then((responseForVacancyActivity) => {
          console.log(responseForVacancyActivity.data)
        })
      let cleanedDate = null,
        repairDate = null,
        suppliesDate = null,
        inspectedDate = null,
        readyToRentDate = null
      if (currentStatus == 'cleaned and needs inspection') {
        cleanedDate = changeDate
        await axios
          .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
            cleanedDate,
          })
          .then((response) => {
            displayNotificationMessage('success', 'Cleaned date saved')
          })
      } else if (currentStatus == 'missing supply') {
        suppliesDate = changeDate
        await axios
          .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
            suppliesDate,
          })
          .then((response) => {
            displayNotificationMessage('success', 'Supplies date saved')
          })
      } else if (currentStatus == 'needs repair') {
        repairDate = changeDate
        await axios
          .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
            repairDate,
          })
          .then((response) => {
            displayNotificationMessage('success', 'Repair date saved')
          })
      } else if (currentStatus == 'inspection completed') {
        inspectedDate = changeDate
        await axios
          .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
            inspectedDate,
          })
          .then((response) => {
            displayNotificationMessage('success', 'Inspected date saved')
          })
      } else if (currentStatus == 'ready to rent') {
        readyToRentDate = changeDate
        await axios
          .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
            readyToRentDate,
          })
          .then((response) => {
            displayNotificationMessage('success', 'Ready to Rent date saved')
          })
      } else {
        displayNotificationMessage(
          'success',
          'Room Readiness status for room Number ' +
            roomId +
            ' updated as ' +
            currentStatus
        )
      }
      window.history.back()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        show={showAddVacancyActivityModal}
        onHide={handleCloseAddVacancyActivityModal}
      >
        <Modal.Header closeButton style={{ fontSize: '16px' }}>
          {' '}
          <b>Room Number : {roomId}</b>
          {/* <br></br>Last updated status: {latestRoomStatus} */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <RadioGroup
              name="radioList"
              onChange={(e) => setCurrentStatus(e)}
              value={currentStatus}
            >
              <b> Readiness Status</b>
              <Radio value="needs cleaning">Needs Cleaning</Radio>
              <Radio value="cleaned and needs inspection">
                Cleaned and needs inspection
              </Radio>
              <Radio value="inspection completed">Inspection completed</Radio>
              <Radio value="ready to rent">Ready to rent</Radio>
              <Radio value="missing supply">Missing supply</Radio>
              <Radio value="needs repair">Needs repair</Radio>
              {/* <Radio value="repair resolved">repair resolved</Radio> */}
              <Radio value="pest issues">Pest issues</Radio>
              {/* <Radio value="pest issues resolved">Pest issues resolved</Radio> */}
            </RadioGroup>
            <br></br>
            {/* {currentStatus && (currentStatus==="inspection completed" || 
    currentStatus==="repair resolved" || 
    currentStatus==="pest issues resolved" ) ? <Checkbox>Ready to rent</Checkbox> : null} */}

            {currentStatus &&
            currentStatus === 'cleaned and needs inspection' ? (
              <>
                <Form.ControlLabel>
                  <b>Cleaned Date</b>
                </Form.ControlLabel>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeDate(e)}
                ></Form.Control>{' '}
                <br></br>{' '}
              </>
            ) : null}

            {currentStatus && currentStatus === 'missing supply' ? (
              <>
                <Form.ControlLabel>
                  <b>Supply Date</b>
                </Form.ControlLabel>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeDate(e)}
                ></Form.Control>{' '}
                <br></br>{' '}
              </>
            ) : null}

            {currentStatus && currentStatus === 'needs repair' ? (
              <>
                <Form.ControlLabel>
                  <b>Repair Date</b>
                </Form.ControlLabel>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeDate(e)}
                ></Form.Control>{' '}
                <br></br>{' '}
              </>
            ) : null}

            {currentStatus && currentStatus === 'inspection completed' ? (
              <>
                <Form.ControlLabel>
                  <b>Inspected Date</b>
                </Form.ControlLabel>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeDate(e)}
                ></Form.Control>{' '}
                <br></br>{' '}
              </>
            ) : null}

            {currentStatus && currentStatus === 'ready to rent' ? (
              <>
                <Form.ControlLabel>
                  <b>Ready to rent Date</b>
                </Form.ControlLabel>
                <Form.Control
                  type="date"
                  onChange={(e) => setChangeDate(e)}
                ></Form.Control>{' '}
                <br></br>{' '}
              </>
            ) : null}

            <Form.ControlLabel>
              <b>Responsible Person</b>
            </Form.ControlLabel>
            <Form.Control
              type="text"
              onChange={(e) => setResponsiblePerson(e)}
            ></Form.Control>
            <br></br>
            <Form.ControlLabel>
              <b>Remarks</b>
            </Form.ControlLabel>
            <Form.Control
              rows={5}
              name="textarea"
              accepter={Textarea}
              onChange={(e) => setComments(e)}
            ></Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseAddVacancyActivityModal}>Cancel</Button>
          <Button onClick={(e) => createVacancyActivity()}> Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
))

export default AddVacancyActivity
