
// import { forwardRef } from 'react';

// material-ui
import React, {useState,useEffect} from 'react'
  import axios from 'axios'
  import { eachDayOfInterval, format, parseISO  } from 'date-fns';
  import {
    formatDate,
    formatDateText,
  } from '../CommonFunctions';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Grid,Stack,Box} from '@mui/material'
import MainCard from './MainCard';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardAnalysis from "./CardAnalysis"
import BarChartComponent from './BarChartComponent';
import AreaChartComponent from './AreaGraphComponent';

// header style
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
function ChartGroup({paymentReportSummary,occupancyReport,avgRooms,searchStartDate}) 
{
    //Time being here ..will be moved to context
    const [startDate,setStartDate]=useState(formatDate(new Date(),-7));
    const [endDate,setEndDate]=useState(formatDate(new Date()));
    const url = process.env.REACT_APP_SERVER_URL;
    
    useEffect(() => {
      console.log("ChartGroup");
      console.log(occupancyReport)
    }, []);
    

  return (
    
    <Box sx={{  marginTop: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7.2}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <BarChartComponent data={paymentReportSummary} searchStartDate={searchStartDate}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4.8}>
                    <Paper sx={{ padding: 2, textAlign: 'center' }}>
                        <AreaChartComponent data={occupancyReport} summaryValue={avgRooms}/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
  );
}

export default ChartGroup;

