import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { Link, redirect } from "react-router-dom";
import Header from "./Header1.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import { SearchRoomsByDateComponent } from "./SearchRoomsByDate";
import { ManageTenancyInformationComponent } from "./Tenancy";
import SearchDateContext from "./GuestContext";
import {Provider} from './GuestContext.js';
 
const IndividualTenancy = () => {
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    // const [card, setCard] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("Male");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [arrival, setArrival] = useState("");
    const [checkout, setCheckout] = useState("");
    const [rate, setRate] = useState("");
    const [adults, setAdults] = useState("");
    const [children, setChildren] = useState("");
    const [deposit, setDeposit] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    // const [bedtype, setBedtype] = useState("");
    // const [size, setSize] = useState("");
    const [customerid,setCustomerid]=useState("");
    const [customersDetails,setCustomersDetails]=useState([]);
    const url=process.env.REACT_APP_SERVER_URL;
    // const [roomid,setRoomid]=useState("");
  const navigate = useNavigate();
  const { id } = useParams();
 
  useEffect(() => {
    // getTenantById();
    // getCustomersByTenantId();
    // alert(fname);
  }, []);
  const getCustomersByTenantId = async() => {
    let response=await axios.get(`${url}/tenants/${id}/customers`)
    setCustomersDetails(response.data);
    // console.log(response.data);
   }
  const updateTenant = async (e) => {
    e.preventDefault();
    try {
        
      await axios.patch(`${url}/tenants/${id}`, {
        // roomid,
        // customerid,
        arrival,
        checkout,
        rate,
        adults,
        children,
        deposit,
        type,
        status
      });

      await axios.patch(`${url}/customers/${customerid}`, {
        fname,
        lname,
        // card,
        email,
        gender,
        phone,
        address,
        state
      });

      // await axios.patch(`${url}/rooms/${roomid}`, {
      //   bedtype,
      //   size
      // });


      navigate(`/tenants`);
    } catch (error) {
      console.log(error);
    }
  };
 
  const getTenantById = async () => {
    let response = await axios.get(`${url}/tenants/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    setFname(pp.fname);
    setLname(pp.lname);
    setEmail(pp.email);
    setAddress(pp.address);
    setState(pp.state);
    setPhone(pp.phone);
    setArrival(pp.arrival);
    // alert(pp.checkout);
    setCheckout(pp.checkout);
    setRate(pp.rate);
    setAdults(pp.adults);
    setChildren(pp.children);
    setDeposit(pp.deposit);
    setType(pp.type);
    setStatus(pp.status);
    setCustomerid(pp.customerid); 
    // alert(pp.customerid)
    
  };
 
  return (
    <div>
      <Header/>
    {/* <SearchRoomsByDateComponent/>
    <ManageTenancyInformationComponent /> */}
    <Provider>
    <ManageTenancyInformationComponent />
    </Provider>
    </div>
  );
};
 
export default IndividualTenancy;