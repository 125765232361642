import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import Header from "./Header1.js";
import "../App.css"
import { Link, redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {formatDate, parseResponseJSON,dateDifference} from "./CommonFunctions";
import InputGroup from 'react-bootstrap/InputGroup';
import {Row,Col,Table} from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import CommonComponents from "./CommonComponents.js";
import { Rate , Divider} from "rsuite";
import NewPost from "./AddImage.js"

export const AddRepair=(props)=>{
  const [file, setFile] = useState();
    const [description,setDescription]=useState("");
    const [assignedto,setAssignedto]=useState("");
    let [repairStatus,setRepairStatus]=useState("Yet to be assigned");
  const [scheduledDate,setScheduledDate]=useState("");
    let [active,setActive]=useState("");
    const [completedDate,setCompletedDate]=useState(null)
    const [roomNumber,setRoomNumber]=useState("");
    const [repairs, setRepairs] = useState([]);
    const [isAddRepair,setIsAddRepair]=useState(false);
    const [repairId,setRepairId]=useState("");
    const [responseForRepair,setResponseForRepair]=useState("");

    const [managerRatingOnRepair, setManagerRatingOnRepair] = useState("");
    const [managerComments,setManagerComments]=useState("");//update in UI and database
    const [allRooms,setAllRooms]=useState([]);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      setIsAddRepair(true)
      getAllRooms();
      if(props.value && props.value.repairId){
        setRepairId(props.value.repairId);
        getRepairById(props.value.repairId);
      }
      else if(props.value && props.value.roomNumber)
      {
        console.log("props.value.roomNumber " + props.value.roomNumber)
        setRoomNumber(props.value.roomNumber)
      }
    
    }, []);

   const getAllRooms=async()=>{
    try{
      await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
        console.log(responseForAllRooms.data);
        setAllRooms(responseForAllRooms.data);
      })

    }
    catch(error){
      console.log(error);
    }
   }

   const getRepairById=async(repairId)=>{
    try{
      if(repairId){
        let responseForRepairById = await axios.get(`${url}/repairs/${repairId}`);
        const tempResponseForRepairId=parseResponseJSON(responseForRepairById.data);
        console.log(tempResponseForRepairId[0].managerRatingOnRepair) ;
        setRoomNumber(tempResponseForRepairId[0].roomid);
        setDescription(tempResponseForRepairId[0].description);
        setActive(tempResponseForRepairId[0].active);
        setAssignedto(tempResponseForRepairId[0].assignedto);
        setScheduledDate(tempResponseForRepairId[0].scheduledDate);
        setCompletedDate(tempResponseForRepairId[0].completedDate);
        setRepairStatus(tempResponseForRepairId[0].status);
        setManagerRatingOnRepair(tempResponseForRepairId[0].managerRatingOnRepair)
      }  
      else{
        setRoomNumber("");
        setDescription("");
        setActive("");
        setAssignedto("");
        setScheduledDate(null);
        setCompletedDate(null);
        setRepairStatus("");
        setManagerRatingOnRepair(0)

      }
    }
  
  
  catch(error)
  {
    console.log(error);
    displayNotificationMessage("error",error.response.data);
  }
}

    // const getRepairs = async () => {
    //   try{
    //   const responseForGettingRepairs = await axios.get(`${url}/repairs`);
    //   setRepairs(responseForGettingRepairs.data);
    //   console.log(responseForGettingRepairs.data)
    //   }
    //   catch(error){
    //     console.log(error);
    //     displayNotificationMessage("error",error.response.data);
    //   }
    // };

  
    const handleCloseAddRepair=()=>{
      setIsAddRepair(false);
      setRepairId("")
      setResponseForRepair("");
      setManagerRatingOnRepair(0);
      if(props && props.handleCloseAddRepair)
      props.handleCloseAddRepair();
    }


    const editRepairDetails=async(e,repairId)=>{
      if(e) e.preventDefault();
      try{
        var status=repairStatus;
        var roomid=roomNumber;
        // var managerRatingonRepair= managerRatingonRepair==0 ? null : managerRatingonRepair;
        setRepairId(repairId)
        await axios.patch(`${url}/repairs/${repairId}`,{
          roomid,
        description,
        status,
        scheduledDate,
        completedDate,
        active,
        assignedto,
        managerRatingOnRepair,
        managerComments
        }).then((responseForEditRepair)=>{
          console.log( "Repair updated (" + repairId +" ). Status - " + responseForEditRepair.status);
          if(props.value){
            if(props.value.repairId)
            {
              props.getRepairs();
            }
          }
          
          setResponseForRepair("Updated "+ repairId);
          displayNotificationMessage("success","Updated - Ticket Number: " + repairId);
          handleCloseAddRepair();
        })


      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 

      }
    }
    const saveRepairDetails=async(e)=>{
      if(e) e.preventDefault();
      try{
        var status=repairStatus;
        var roomid=roomNumber;
        var completedDate=completedDate ? completedDate : null
        var scheduledDate=scheduledDate ? scheduledDate : null
        var managerRatingOnRepair= managerRatingOnRepair==0 ? null : managerRatingOnRepair
        await axios.post(`${url}/repairs` ,{
        roomid,
        description,
        status,
        scheduledDate,
        completedDate,
        active,
        assignedto,
        managerRatingOnRepair,
        managerComments
      }).then((responseForSavingNewRepair)=>{
        const tempDbResponse=parseResponseJSON(responseForSavingNewRepair.data);
        if(tempDbResponse)
        {
          setRepairId(tempDbResponse.ticketId)
          // getRepairById(tempDbResponse.ticketId)
          setResponseForRepair("Ticket " + tempDbResponse.ticketId + " created ");
          if(props.value && !props.value.roomNumber){
              props.getRepairs();
          }
          displayNotificationMessage("success","Created a new Repair Ticket - Ticket Number: " + tempDbResponse.ticketId);
          handleCloseAddRepair();
        }

      })
      
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }




    return (
      <div>
        <Modal show={isAddRepair} onHide={handleCloseAddRepair}>

        <Modal.Header closeButton>
        
        <Modal.Title>{repairId ? <a>Update Ticket: <b className="Tidy-ids">{repairId}</b></a>  : "Create Ticket" }{responseForRepair ? <h5 style={{color:"blue"}}>{responseForRepair} </h5> :  <h5></h5>}</Modal.Title>
         
        </Modal.Header>
        
        <Modal.Body className="me-3">
          <Form.Group >
            <Row>
              <Col className="mt-2 ms-4"><Form.Label style={{float:"right"}}><b>Select a Room Number :</b></Form.Label></Col>
              <Col><Form.Select className="w-75"  size="sm"
                  value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value)}}>
                <option value='' disabled selected></option>
                {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.alias}</option>
                ))}
              </Form.Select></Col>


            </Row>
              
          </Form.Group>                                     
   
          <Form.Label className="ms-5 mt-3"><b>Repair Description :</b></Form.Label>
        <Form.Control className="me-3 ms-5 mt-1 w-75"  as="textarea" rows={3} 
            value={description} onChange={(e) => setDescription(e.target.value)}/>

  
        <br></br>
        <Row>
          <Col><Form.Label className="ms-5 mt-1" style={{float:"left"}}><b>Assigned To :</b></Form.Label></Col>
          <Col className="w-25 me-5 pe-5">
          <Form.Control
              type="text"
              value={assignedto}
              onChange={(e) => setAssignedto(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col><Form.Label className="ms-5 mt-1 h-4" style={{float:"left"}}><b>Scheduled At :</b></Form.Label></Col>
          <Col className="w-25 me-5 pe-5">
          <Form.Control
            type="date"
            value={scheduledDate} 
            onChange={(e)=>{setScheduledDate(e.target.value)}}
            aria-describedby="basic-addon1"
            style={{float:"left"}}
          />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col><Form.Label className="ms-5 mt-2" style={{float:"left"}}><b>Repair Status :</b></Form.Label></Col>
          <Col className="w-25 me-5 pe-5">
          <Form.Select size="sm"
              value= { repairStatus }  onChange={(e)=>{setRepairStatus(e.target.value)}}>
            <option value='' disabled selected></option>
            <option value="Yet to be assigned">Yet to be Assigned</option>
            <option value="Assigned">Assigned</option>
            <option value="Need management action">Need Management Action</option>
            <option value="In progress">In Progress</option>
            <option value="Completed">Completed</option>
          </Form.Select>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col><Form.Label className="ms-5 mt-1" style={{float:"left"}}><b>Completed At :</b></Form.Label></Col>
          <Col className="w-25 me-5 pe-5">
            <Form.Control
              type="date"
              value={completedDate} 
              onChange={(e)=>{setCompletedDate(e.target.value)}}
              aria-describedby="basic-addon1" />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col><Form.Label className="ms-5 mt-2" style={{float:"left"}}><b>Manager Review :</b></Form.Label></Col>
          <Col className="w-25 me-5 pe-4 mt-1">
        <Rate className="mt-1" value={managerRatingOnRepair} 
      defaultValue={0} size="xs" onChange={(value)=>setManagerRatingOnRepair(value)}/>
            
          </Col>
        </Row>
        <Form.Label className="ms-5 mt-3"><b>Manager Comments :</b></Form.Label>
        <Form.Control className="me-3 ms-5 mt-1 w-75"  as="textarea" rows={3} 
            value={managerComments} onChange={(e) => setManagerComments(e.target.value)}/>

        {/* <input onChange={e => setFile(e.target.files[0])} type="file" multiple="multiple" accept="image/*"></input> */}
     

        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={(e)=>handleCloseAddRepair(e)}>
            Close
          </Button>
          { repairId ? <Button variant="primary" onClick={(e)=> editRepairDetails(e,repairId)}>
          Update Changes
        </Button> : 
       <Button variant="primary" onClick={(e)=>saveRepairDetails(e)}>
        Create
        </Button>
        } 
          
        </Modal.Footer>
      </Modal> 




 




      </div>
    );


}

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9"
  
};