import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import axios from 'axios'
import {
  Table,
  Popover,
  Whisper,
  Checkbox,
  Dropdown,
  IconButton,
  Progress,
} from 'rsuite';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  getDataSorted,
  formatDateText,
} from '../CommonFunctions'
const { Column, HeaderCell, Cell } = Table

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      {rowData.depositStatus=="deposited" ? null :
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item) => item === rowData[dataKey])}
      />
}
    </div>
  </Cell>
)

const UndepositedPayments = (props) => {
  const [undepositedPaymentDetails,setUndepositedPaymentDetails]=useState([]);
  const [includeDepositedPayments,setIncludeDepositedPayments]=useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [sortColumn, setSortColumn] = useState('id')
  const [sortType, setSortType] = useState('asc')
  const [loading, setLoading] = useState(false)
  const url = process.env.REACT_APP_SERVER_URL;
  let checked = false
  let indeterminate = false
  useEffect(() => {
    if(props && props.searchStartDate && props.searchEndDate){
    getUndepositedPayments(null,props.searchStartDate,props.searchEndDate);
    }
    if(props && props.paymentsToDeposit)
    console.log(props.paymentsToDeposit)
    if(props && props.paymentsToDeposit){
      const array=props.paymentsToDeposit;
      const keys=[]
      for(let i=0;i<array.length;i++){
      keys.push(array[i].id);
        
      }
      setCheckedKeys(keys);
      // checked=true;
    }
    
    

  }, []);
  let totalAmountToDeposit=0;
  if(props && props.paymentsToDeposit){
    const array=props.paymentsToDeposit;
    for(let i=0;i<array.length;i++){
      if(array[i].cashAmount)
      totalAmountToDeposit+=array[i].cashAmount;
      if(array[i].otherAmount)
      totalAmountToDeposit+=array[i].otherAmount;
    //  checkedKeys.push(array.id)
      
    }
  }
  const getUndepositedPayments= async(e,searchStartDate,searchEndDate)=>{
    try{
      await axios.post(`${url}/undepositedPayments`,{
        searchStartDate,
        searchEndDate

      }).then(
        (responseForUndepositedPayments)=>{
          console.log(responseForUndepositedPayments.data);
          setUndepositedPaymentDetails(responseForUndepositedPayments.data)
        })

    }
    catch(error){
      console.log(error.message);
    }
  }

  const getAllPaymentsInDateRange=async(e,searchStartDate,searchEndDate)=>{
    try{
      const dateColumnFilter="systemPaymentDate"
      await axios.post(`${url}/payments/allPaymentsinDateRange`,{
        searchStartDate,
        searchEndDate,
        dateColumnFilter

      }).then(
        (responseForUndepositedPayments)=>{
          console.log(responseForUndepositedPayments.data);
          setUndepositedPaymentDetails(responseForUndepositedPayments.data)
        })

    }
    catch(error){
      console.log(error.message);
    }
  }
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortColumn)
      setSortType(sortType)
    }, 500)
  }





  //UI operations to keep track of checked boxes

  

  if (checkedKeys.length === undepositedPaymentDetails.length) {
    checked = true
  } else if (checkedKeys.length === 0) {
    checked = false
  } else if (checkedKeys.length > 0 && checkedKeys.length < undepositedPaymentDetails.length) {
    indeterminate = true
  }

  const handleCheckAll = (value, checked) => {
    const keys = checked ? undepositedPaymentDetails.map((item) => item.id) : []
    const details = checked ? undepositedPaymentDetails.map((item) => item) : []
    setCheckedKeys(keys);
    if(props && props.setPaymentsToDeposit)
    {
      props.setPaymentsToDeposit(details);
      totalAmountToDeposit=0;
    for(let i=0;i<details.length;i++){
      if(details[i].cashAmount)
      totalAmountToDeposit+=details[i].cashAmount;
      if(details[i].otherAmount)
      totalAmountToDeposit+=details[i].otherAmount;
    }
    }
  }
  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value)
      const details = keys.map(id =>
        undepositedPaymentDetails.find(item => item.id === id)
      ).filter(Boolean);
      
    setCheckedKeys(keys);
    if(props && props.setPaymentsToDeposit)
    {
      props.setPaymentsToDeposit(details);
      totalAmountToDeposit=0;
    for(let i=0;i<details.length;i++){
      if(details[i].cashAmount)
      totalAmountToDeposit+=details[i].cashAmount;
      if(details[i].otherAmount)
      totalAmountToDeposit+=details[i].otherAmount;
    }
    }

  }
  return (
    <>
    <table style={{ width: '95%', marginLeft: '5%' }}>
         
          <Row style={{ marginBottom: '1%' }}>
            <Col sm>
              <Form.Control
                className="w-75"
                type="date"
                value={props && props.searchStartDate ? props.searchStartDate : null}
                // style={{backgroundColor:"#edfae1"}}
                onChange={(e) => {
                  if(props && props.setSearchStartDate)
                  props.setSearchStartDate(e.target.value)
                }}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                className="w-75"
                type="date"
                value={props && props.searchEndDate ? props.searchEndDate : null }
                // style={{backgroundColor:"#edfae1"}}
                onChange={(e) => {
                  if(props && props.searchEndDate)
                  props.setSearchEndDate(e.target.value)
                }}
                aria-describedby="basic-addon1"
              />
            </Col>
            {/* <Col sm><b>{dateDifference(checkin,checkout)} Days</b></Col> */}
            <Col aria-rowspan={2}>
              <Button
                onClick={(e) =>{
                  // alert(includeDepositedPayments)
                  if(props && props.searchStartDate && props.searchEndDate){
                  includeDepositedPayments ? getAllPaymentsInDateRange(e,
                    props.searchStartDate,
                    props.searchEndDate) : getUndepositedPayments(
                      e,
                      props.searchStartDate,
                      props.searchEndDate
                    )
                  }
                }}
              >
                Search
              </Button>
            </Col>
            <Col><Checkbox value={includeDepositedPayments}
          onChange={(e)=>setIncludeDepositedPayments(!includeDepositedPayments)}> Show Deposited Payments
          </Checkbox></Col>
            <Col>Total Amount:<b> ${totalAmountToDeposit}</b></Col>

            <Col>Payments selected:<b>{checkedKeys.length}</b></Col>
          </Row>
          
        </table><br></br>
    <Table height={320} id="table" data={getDataSorted(sortColumn, sortType, undepositedPaymentDetails)}
            size="lg"
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={loading}>
      <Column width={50} align="left">
        <HeaderCell style={{ padding: 0 }}>
          <div style={{ lineHeight: '40px' }}>
            <Checkbox
              inline
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleCheckAll}
            />
          </div>
        </HeaderCell>
        <CheckCell
          dataKey="id"
          checkedKeys={checkedKeys}
          onChange={handleCheck}
        />
      </Column>
      <Column width={120} align="center" fixed sortable>
        <HeaderCell>Room Number</HeaderCell>
        <Cell dataKey="roomid">
        {(rowData) => <>{rowData.roomid}</>}
        </Cell>
      </Column>
      <Column width={200} align="left" fixed sortable>
        <HeaderCell>Payment Date</HeaderCell>
        <Cell dataKey="paymentDate">
        {(rowData) => <>{formatDateText(rowData.actualPaymentDate)}</>}
        </Cell>
      </Column>

      <Column width={180} align="left" fixed sortable>
        <HeaderCell>Receipt Number</HeaderCell>
        <Cell dataKey="receiptNumber" />
      </Column>

      <Column width={180} align="left" fixed sortable>
        <HeaderCell>Paid By</HeaderCell>
        <Cell dataKey="paidBy" />
      </Column>

      <Column width={120} align="right" fixed>
        <HeaderCell>Cash Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.cashAmount ? '$'+rowData.cashAmount : "-"}</>}
        </Cell>
      </Column>
      <Column width={120} align="right" fixed>
        <HeaderCell>Credit Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.creditCardAmount ? '$'+rowData.creditCardAmount : "-"}</>}
          
          </Cell>
      </Column>

      <Column width={120} align="right" fixed>
        <HeaderCell>Other Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.otherAmount ? '$'+rowData.otherAmount : "-"}</>}
          
        </Cell>
      </Column>
    </Table>
    </>
  )
}

export default UndepositedPayments;
