import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { formatDate } from '../../CommonFunctions'
import CommonComponents from '../../CommonComponents.js'

const EditInventoryModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [inventoryData, setInventoryData] = useState({
    name: '',
    type: 1,
    count: 0,
    description: '',
    supplierLinks: '',
    storageLocation: '',
    status: '',
    cost: 0,
  })
  const url = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    console.log(props.data.selectedRow)
    getInventoryDetails()
  }, [])

  const getInventoryDetails = async () => {
    try {
      await axios
        .get(`${url}/inventoryByID/${props.data.selectedRow.productId}`)
        .then((response) => {
          setInventoryData({
            name: response.data[0].name,
            type: response.data[0].type,
            count: response.data[0].count,
            status: response.data[0].status,
            description: response.data[0].description,
            supplierLinks: response.data[0].supplierLinks,
            storageLocation: response.data[0].storageLocation,
            cost: response.data[0].cost,
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  const editInventory = async () => {
    try {
      await axios
        .patch(
          `${url}/updateInventory/${props.data.selectedRow.productId}`,
          inventoryData
        )
        .then((response) => {
          closeModal()
        })
    } catch (error) {
      displayNotificationMessage('error', error.response.data)
      console.log(error)
    }
  }

  const closeModal = () => {
    props.data.setEditInventory(false)
  }
  return (
    <Modal open={props.data.editInventory} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Edit Product</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'none' }}>
        <Row>
          <Col>
            <Form.Label>Product Name</Form.Label>
          </Col>
          <Col>
            <Form.Label>Product Count</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="string"
              value={inventoryData.name}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  name: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              type="number"
              value={inventoryData.count}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  count: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Unit Type</Form.Label>
          </Col>
          <Col>
            <Form.Label>Storage Location</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Select
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  type: Number(e.target.value),
                })
              }
              className="h-100"
            >
              <option value="0">Lbs</option>
              <option value="1">Number</option>
              <option value="2">Gallon</option>
            </Form.Select>
          </Col>
          <Col xs={6}>
            <Form.Control
              type="string"
              value={inventoryData.storageLocation}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  storageLocation: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Description</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Control
              as="textarea"
              rows={2}
              value={inventoryData.description}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  description: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Cost</Form.Label>
          </Col>
          <Col>
            <Form.Label>Status</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="number"
              value={inventoryData.cost}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  cost: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <Form.Check
              type="radio"
              value="Discontinued"
              label="Discontinued"
              checked={inventoryData.status === 'Discontinued'}
              onChange={(e) => {
                setInventoryData({
                  ...inventoryData,
                  status: e.target.value,
                })
              }}
            />
            <Form.Check
              type="radio"
              value={null}
              label="Continue"
              checked={inventoryData.status === null}
              onChange={(e) => {
                setInventoryData({
                  ...inventoryData,
                  status: null,
                })
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Supplier Links</Form.Label>
          </Col>
          <Col xs={12}>
            <Form.Control
              as="textarea"
              rows={2}
              value={inventoryData.supplierLinks}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  supplierLinks: e.target.value,
                })
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => editInventory()} appearance="primary">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default EditInventoryModal
