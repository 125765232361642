import React, { useState } from "react";
import axios from "axios";
import { Form, Card, Button,Row,Col,Table } from "react-bootstrap";
import validator from "validator";
import { DateRangePicker , AutoComplete, Input } from 'rsuite';

import {formatDate, parseResponseJSON} from "./CommonFunctions";
// creating functional component ans getting props from app.js and destucturing them
const StepThree = () =>{
    const [rent, setRent]                           = useState ();
    const [rentalType , setRentalType]              = useState ("weekly");
 
    const [deposit , setDeposit]                    = useState (0);

  const url=process.env.REACT_APP_SERVER_URL;
  return (
    <Table striped>
    <Row className="mt-4 mb-4">
      <Col sm>Rent:</Col>
      <Col sm>
        <Form.Control
          type="number"
          min="0"
          value={rent}
          onChange={(e)=>setRent(e.target.value)} 
          aria-describedby="basic-addon1"
          
        />
      </Col>
      <Col sm>Deposit</Col>
      <Col sm>
        <Form.Control
          type="number"
          min="0" 
          value={deposit}
          onChange={(e) => {setDeposit(e.target.value);
          } }
          aria-describedby="basic-addon1"
        />
      </Col>

    </Row>
    </Table>
  );
}

export default StepThree;
