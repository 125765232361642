import React, { useState, useEffect , useContext } from "react";
import axios from "axios";

import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Row,Col,Table, FormCheck} from "react-bootstrap";
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";

const AddFollowUp=(props)=>{
    const [description,setDescription]=useState("");
    const [isFollowUpYes,setIsFollowUpYes]=useState(false);
    const [isFollowUpNo,setIsFollowUpNo]=useState(true);
    const url = process.env.REACT_APP_SERVER_URL;
    // const [showFollowUpModal, setShowFollowUpModal] = useState(true);
    useEffect(() => {
        
      }, [])
      const updateFollowUpInTenancy = async() =>{
        try{
        if(props && props.data){
            const roomid=props.data.roomid;
            const tenancyDescription=props.data.tenancyDescription + description;
            const renewing = isFollowUpYes ? 1 : 0;
        await axios.patch(`${url}/tenants/${props.data.id}`,{
            roomid,
            tenancyDescription,
            renewing

        }).then((responseForFollowUp) =>{
            console.log(responseForFollowUp.data);
            props.setShowFollowUpModal(false);
            if(props && props.activeTenancyDetails){
                for(let i=0;i<props.activeTenancyDetails.length;i++)
                {
                    if(props.activeTenancyDetails[i].id===props.data.id)
                    {
                        props.activeTenancyDetails[i].renewing=isFollowUpYes ? 1 : 0;
                        
                    }
                }
            }

        })
      }
    }catch(error){
        console.log(error);
    }
    }
    return (
        <Modal show={props.showFollowUpModal} onHide={()=>props.setShowFollowUpModal(false)}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <b>
                    <Form.Label>Is the customer staying for another term?</Form.Label>
                <Form.Check label="Yes" checked={isFollowUpYes} type="switch"
                    onChange={(e)=>{setIsFollowUpYes(!isFollowUpYes);setIsFollowUpNo(!isFollowUpNo)}}>
                </Form.Check><Form.Check label="No" checked={isFollowUpNo} type="switch"
                  onChange={(e)=>{setIsFollowUpYes(!isFollowUpYes);setIsFollowUpNo(!isFollowUpNo)}} >
                </Form.Check></b><br></br>
                <Form.Label><b>Comments: </b></Form.Label>
                <Form.Control as="textarea" value={description} 
                    onChange={(e)=>setDescription(e.target.value)}>
                </Form.Control>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={(e)=>props.setShowFollowUpModal(false)}>
            Close
          </Button>
            <Button variant="primary" onClick={(e)=>updateFollowUpInTenancy()}>
                Save
            </Button>
            </Modal.Footer>
        </Modal>

    );
}
export default AddFollowUp;