import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert

} from "@mui/material"

import Header from "../Header";
import {
    GridPagination,
    DataGrid,
  } from '@mui/x-data-grid';
import { PatternFormat } from "react-number-format";

const FeedbackReport=()=>{
    const [reportSummary, setReportSummary] = useState([])
    
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        getAllCustomerInqueries();
      
    }, []);
    const columns=[
        { field: 'id', headerName: 'FeedbackID', width: 200, align: 'left' },
        { field: 'name', headerName: 'Customer Name', width: 300, align: 'left' },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 200, align: 'left',
            renderCell:(params)=>{
            const currentRow=params.row;
            return <>
            <PatternFormat value={currentRow.phoneNumber} format="(###) ###-####"
        mask="_"  displayType="text" />
            </>
            
           } },
        { field: 'description', headerName: 'Description', width: 400, align: 'left' },


    ];
    const getAllCustomerInqueries = async()=>{
    try{
        const response=await axios.get(`${url}/customerInqueries`);
        if(response && response.data){
            console.log(response.data)
            setReportSummary(response.data);
        }

    }
    catch(error){
        console.log(error)
    }
    }

    
    

    return (
      <>
      <Header/>
      <Box
        sx={{
          height: 450,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={reportSummary} columns={columns}
        getRowId={(row) => row.id}
        />
        
      </Box>
      
      </>
    );


}
export default FeedbackReport;