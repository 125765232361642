import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { formatDate } from '../../CommonFunctions'
import CommonComponents from '../../CommonComponents.js'

const RequestInventoryModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [requestData, setRequestData] = useState({
    request: props.data.selectedRow.request,
    requestDescription: props.data.selectedRow.requestDescription,
  })
  const url = process.env.REACT_APP_SERVER_URL

  const requestInventory = async () => {
    try {
      await axios
        .patch(
          `${url}/updateInventory/${props.data.selectedRow.currentId}`,
          requestData
        )
        .then((response) => {
          closeModal()
        })
    } catch (error) {
      displayNotificationMessage('error', error.response.data)
      console.log(error)
    }
  }
  const closeModal = () => {
    props.data.setRequestInventory(false)
  }
  return (
    <Modal open={props.data.requestInventory} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Request Inventory : {props.data.selectedRow.name}</h4>
        </Modal.Title>
      </Modal.Header>
      <>
        <Modal.Body style={{ overflow: 'none' }}>
          <Row>
            <Col>
              <Form.Label>Current Stock</Form.Label>
            </Col>
            <Col>
              <Form.Label>Location</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{props.data.selectedRow.count}</Form.Label>
            </Col>
            <Col>
              <Form.Label>{props.data.selectedRow.storageLocation}</Form.Label>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>Request Count</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Control
                type="number"
                value={requestData.request}
                onChange={(e) =>
                  setRequestData({
                    ...requestData,
                    request: Number(e.target.value),
                  })
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>Description</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Control
                as="textarea"
                rows={3}
                value={requestData.requestDescription}
                onChange={(e) =>
                  setRequestData({
                    ...requestData,
                    requestDescription: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => requestInventory()} appearance="primary">
            Request
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  )
}
export default RequestInventoryModal
