import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import $ from 'jquery';
import Header from "./Header1.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faSearch,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import {Form,Row,Col,Button,Modal} from "react-bootstrap"
import CommonComponents from "./CommonComponents.js";

const MakePayment = (props) => {
    const [showMakePaymentPopup,setShowMakePaymentPopup]=useState(false)
    const [creditCardAmount,setCreditCardAmount]=useState(null);
    const [cashAmount,setCashAmount]=useState(null);
    const [otherAmount,setOtherAmount]=useState(null);
    const [paidBy,setPaidBy]=useState(null);
    const [tenancyId,setTenancyId]=useState("");
    const [tenancyDetails,setTenancyDetails]=useState({})
    const [paymentId,setPaymentId]=useState("")
    const [responseForPayment,setResponseForPayment]=useState("")
    const [customersDetails,setCustomersDetails]=useState([]);
    const [tenancyPeriods,setTenancyPeriods]=useState([])
    const [tempTenancyId,setTempTenancyId]=useState('');
    // const [paymentDetails,setPaymentDetails]=useState({})
    const [roomNumber,setRoomNumber]=useState("");
    const [allRooms,setAllRooms]=useState([]);
    const [receiptNumber,setReceiptNumber]=useState("");
    const [actualPaymentDate,setActualPaymentDate]=useState(formatDate(new Date()))
    const {displayNotificationMessage}=useContext(CommonComponents);
    const url=process.env.REACT_APP_SERVER_URL;
    const handleShowMakePaymentPopup=()=>{
        setShowMakePaymentPopup(true);
      }
      const handleCloseMakePaymentPopup=()=>{
        setShowMakePaymentPopup(false)
        setTenancyId("")
        setPaymentId("")
        setTenancyDetails({});
      }
      useEffect(() => {
        // displayRooms();
        // getPaymentHistoryByTenantId();
        // getCustomerDetailsByTenantId(); 
        getAllRooms();
        console.log(paymentId + ' - ' + tenancyId)
        
        setShowMakePaymentPopup(true);
        if(props.value){
            
            if(props.value.tenancyId){
                console.log(props.value.tenancyId);
                setTenancyId(props.value.tenancyId);
                if(props.value.tenancyDetails){
                setRoomNumber(props.value.tenancyDetails.roomid)
                setTenancyDetails(props.value.tenancyDetails)
                }
                getTenancyDetailsByTenancyId(props.value.tenancyId)
                getCustomerDetailsByTenantId(props.value.tenancyId)
            }
            if(props.value.paymentDetails){
                console.log("in Make Payment UseEffect from add Payment Page" + props.value.paymentDetails.id);
                setPaymentId(props.value.paymentDetails.paymentId)
                setTenancyId(props.value.paymentDetails.id)
                console.log(props.value.customersDetails)
                getCustomerDetailsByTenantId(props.value.paymentDetails.id)
                setCustomersDetails(props.value.customersDetails)
                // console.log(props.value)
                getPaymentDetailsByPaymentId(props.value.paymentDetails.paymentId);
                getCustomerDetailsByTenantId(props.value.paymentDetails.id);
            }
            else{

            }
            if(props.value.paymentId){
                console.log(props.value.paymentId)
            }
            
        }
        
        
        
      }, []);

      const getTenancyDetailsByTenancyId=(tenancyId)=>{
        try{
          
          axios.get(`${url}/tenants/${tenancyId}`).then((responseTenancyDetails)=>{
            console.log(responseTenancyDetails.data[0])
            setTenancyDetails(responseTenancyDetails.data[0])
            setRoomNumber(responseTenancyDetails.data[0].roomid)
            
          });
        }
        catch(error)
        {
          console.log(error)
          displayNotificationMessage("error",error.response.data);
          // displayNotificationMessage("error",error.response.data);
        }
      }
      const getAllRooms=async()=>{
        try{
          await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
            console.log(responseForAllRooms.data);
            setAllRooms(responseForAllRooms.data);
          })
    
        }
        catch(error){
          console.log(error);
          displayNotificationMessage("error",error.response.data);
        }
       }
      const getLastEightWeeksTenancyPeriods=async(e,roomNumber)=>{
        if(e) e.preventDefault();
        try{
          // alert("getLastEightWeeksTenancyPeriods")
          await axios.get(`${url}/tenants/last8WeeksTenancyPeriods/${roomNumber}`).then((responseForTenancyPeriods)=>{
            console.log(responseForTenancyPeriods.data);
            setTenancyPeriods(responseForTenancyPeriods.data);
          })

        }catch(error){
          console.log(error);
          displayNotificationMessage("error",error.response.data);
        }

      }
      const createPaymentTenancyRelation=async(paymentId,tenancyId)=>{
        try{
            
            await axios.post(`${url}/addTenancyPaymentRelation`,{
                    tenancyId,
                    paymentId
            }).then((responseForCreatingPaymentTenancyRelation)=>{
                console.log(responseForCreatingPaymentTenancyRelation)
                if(props.value.tenancyDetails)
                props.getBalanceDueByTenancyId();
            })

        }catch(error){
            console.log(error);
            displayNotificationMessage("error",error.response.data);
        }
      }

    const getPaymentDetailsByPaymentId=async(paymentId)=>{
        // if(e) e.preventDefault();
        try{
          if(paymentId){
            await axios.get(`${url}/payments/${paymentId}`).then((responseForGettingPaymentDetailsByPaymentId)=>{
                console.log(responseForGettingPaymentDetailsByPaymentId.data)
                setStateValues(responseForGettingPaymentDetailsByPaymentId.data[0])
            })
          }

        }catch(error){
            console.log(error);
            displayNotificationMessage("error",error.response.data);
        }
    }
    const setStateValues=(paymentDetails)=>{
      if(paymentDetails){
        console.log(paymentDetails.creditCardAmount)
        setCashAmount(paymentDetails.cashAmount);
        setCreditCardAmount(paymentDetails.creditCardAmount);
        setOtherAmount(paymentDetails.otherAmount);
        setPaidBy(paymentDetails.paidBy);
        setReceiptNumber(paymentDetails.receiptNumber)
        setActualPaymentDate(paymentDetails.actualPaymentDate)
      }
    }
    const editPaymentDetails=async(e)=>{
        if(e) e.preventDefault();
        try{
            await axios.patch(`${url}/payments/${paymentId}`,{
                creditCardAmount,
                cashAmount,
                otherAmount,
                paidBy,
                receiptNumber,
                actualPaymentDate
            }).then((responseForEditingPaymentDetails)=>{
                setResponseForPayment("Payment Details Updated");
                handleCloseMakePaymentPopup();
                const sum=creditCardAmount ?  creditCardAmount : 0 + cashAmount ? cashAmount : 0 + otherAmount ? otherAmount : 0;
              displayNotificationMessage("success", "Updated Payment of " + sum + '$ is made by ' + paidBy + "");
                if(props.value.tenancyId)
                props.getBalanceDueByTenancyId();
                console.log(" before props.getRentedTenancyDetailsByRoomIdAndDateRange();")
                if(props.value.paymentDetails)
                props.getRentedTenancyDetailsByRoomIdAndDateRange();
                console.log("after props.getRentedTenancyDetailsByRoomIdAndDateRange();")
                
            })

        }catch(error){
            console.log(error);
            displayNotificationMessage("error",error.response.data);
        }
    }
    const getCustomerDetailsByTenantId=async(id)=>{
      try{
        if(id){
          
          await axios.get(`${url}/tenants/${id}/customers`).then((responseForGettingCustomers)=>{
            console.log(responseForGettingCustomers.data)
            setCustomersDetails(responseForGettingCustomers.data);
          return responseForGettingCustomers.data;
          });
        }
          

      }catch(error){
          console.log(error);
          displayNotificationMessage("error",error.response.data);
      }
  }
const createPaymentDetailsTenancyId = async(e)=>{
  if(tenancyId){
    createPaymentDetails(e,tenancyId)
  }
  else if(tempTenancyId){
    createPaymentDetails(e,tempTenancyId)
  }
}
    const createPaymentDetails=async(e,tenancyId)=>{
        e.preventDefault();
        try{
          // FIXME : What happens if payment table is updated and relationship to tenancy is not updated.
          // Need to have a database transaction enabled to trigger roll back
            // alert(tenancyId)
            const amount=parseInt(creditCardAmount ? creditCardAmount : 0) + 
            parseInt(cashAmount ? cashAmount : 0) + parseInt(otherAmount ? otherAmount :0)
            const payment_date=new Date();
            let depositStatus="deposited";
            if((cashAmount && cashAmount>0) || (otherAmount && otherAmount>0))
            depositStatus="undeposited";
            if(tenancyId){
            await axios.post(`${url}/payments`,{
                tenancyId,
                creditCardAmount,
                cashAmount,
                otherAmount,
                payment_date,
                paidBy,
                receiptNumber,
                actualPaymentDate,
                depositStatus
            }).then((responseForCreatingPayment) => {
                const tempDbResponse=parseResponseJSON(responseForCreatingPayment.data);
                console.log(tempDbResponse);
                if(tempDbResponse){
                setPaymentId(tempDbResponse.data);
                handleCloseMakePaymentPopup();
                if(props.value.tenancyId)
                props.getBalanceDueByTenancyId(tenancyId);
              //  if(props.value.paymentDetails)
              displayNotificationMessage("success","Payment of " + amount + '$ is made by ' + paidBy);
              console.log("in create before props.getRentedTenancyDetailsByRoomIdAndDateRange();")
                  props.getRentedTenancyDetailsByRoomIdAndDateRange();
                  console.log("in create after props.getRentedTenancyDetailsByRoomIdAndDateRange();")  
                     
                }
                })
              }
              
                
           
        //   getRentedTenancyDetailsByRoomIdAndDateRange(e);
        //   getPaymentHistoryByTenantId(tenantId);


        }
        catch(error){
            console.log(error);
            displayNotificationMessage("error",error.response.data);
        }
    }
    return (
        <>
                <Modal show={showMakePaymentPopup} onHide={handleCloseMakePaymentPopup}>
          <Modal.Header closeButton>
            <Modal.Title>Payment Details: {tenancyId} 
                {/* <b>{tenancyId ? "TenancyId: "+tenancyId : null}</b>
                <b>{paymentId ? "PaymentId: "+paymentId : null}</b> */}
                {responseForPayment ? responseForPayment : ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col><b>Room Number: </b></Col>
              <Col><b>Tenancy Period: </b></Col>
              {/* <Col>Balance: </Col> */}
            </Row>
            <Row className="mb-4">
              <Col>
              {tenancyId ? props.value.paymentDetails ? props.value.paymentDetails.roomid : roomNumber : 
                <Form.Select className="mt-2 w-50 h-100"  size="sm"
                    value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value);getLastEightWeeksTenancyPeriods(e,e.target.value)}}>
                    <option value='' disabled selected></option>
                    {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.id}</option>
                ))}
                </Form.Select>
             } 
              </Col>
              <Col>
              {tenancyId ? props.value.paymentDetails ? props.value.paymentDetails.checkin+ ' - ' + props.value.paymentDetails.checkout :
              tenancyDetails.checkin + " to " + tenancyDetails.checkout : 
              
              <Form.Select className="mt-2 w-100 h-100"  size="sm"

                      onChange={async(e)=>{
                        // const tempCustomerDetails=await 
                        console.log("in make payment tenancy dropdown : tenancyId " + e.target.value)
                        getCustomerDetailsByTenantId(e.target.value);
                        // setCustomersDetails(tempCustomerDetails);
                        setPaidBy("");
                        setTempTenancyId(e.target.value);
                        
                        
                      }}>

                <option value='' disabled selected></option>

                { tenancyPeriods.map((row, index) => (

                  <option value={row.id}>{row.checkin + ' to ' +  row.checkout}</option>

                ))}
                </Form.Select>
                }
                </Col>
              {/* <Col><b>{}</b></Col> */}
            </Row>
            <hr></hr>
            <Row>
            <Col><b>Reciept Number</b></Col>
              <Col><b>Actual Payment Date</b></Col>
             
            </Row>
            {/* FIXME: SET VALIDATION VARIABLE--if all requirements are matched or not */}
            <Form validated={true}>
            <Row>
              <Col><Form.Control 
                    type="text" 
                    value={receiptNumber}
                    onChange={(e) => setReceiptNumber(e.target.value)} 
                    aria-describedby="basic-addon1"/></Col>
              <Col><Form.Control 
                    type="date" 
                    value={actualPaymentDate}
                    onChange={(e) => setActualPaymentDate(e.target.value)} 
                    aria-describedby="basic-addon1"/>
              </Col>
            </Row>
            
              <Row className="mt-4">
                <Col><b>Cash Amount:</b></Col>
                <Col><b>Card Amount: </b></Col>
                <Col><b>Other Amount: </b></Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Form.Control 
                    type="number" 
                    min="0"
                    value={cashAmount}
                    onChange={(e) => setCashAmount(e.target.value)} 
                    aria-describedby="basic-addon1"/>
                </Col>
                <Col>
                  <Form.Control 
                    type="number" 
                    min="0"
                    value={creditCardAmount}
                    onChange={(e) => setCreditCardAmount(e.target.value)} 
                    aria-describedby="basic-addon1"/>
                </Col>
                <Col>
                  <Form.Control 
                    type="number" 
                    min="0"
                    value={otherAmount}
                    onChange={(e) => setOtherAmount(e.target.value)} 
                    aria-describedby="basic-addon1"/>
                </Col>
              </Row><br></br>
              <b>Paid By: </b>
              <Form.Select className="h-75 mt-2"
                  onChange={(e)=>setPaidBy(e.target.value)}>
                    <option></option>
                  {customersDetails && customersDetails.map((row, index) => (
                    <option value={row.fname+' '+row.lname}>{row.fname+' '+row.lname}</option>
                  ))};
              </Form.Select><br></br>
              <b>If paid by others- Name:</b>
              <Form.Control value={paidBy} onChange={(e)=>setPaidBy(e.target.value)} required/>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseMakePaymentPopup}>Cancel</Button>
            {paymentId ? <Button onClick={(e)=>editPaymentDetails(e)}>Update Payment</Button> 
            : <Button onClick={(e)=>createPaymentDetailsTenancyId(e)}>Make Payment</Button>}
          </Modal.Footer>
          
        </Modal>
        </>
    );

}
export default MakePayment;