// import React, { useState } from "react";
// import axios from "axios";
// // import { useNavigate } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";
// import $ from 'jquery';
// import Rating from "react-rating";
// // var Rating = require('react-rating');
 
// const AddManagerReview = () => {
//   const [rating, setRating] = useState("");
//   const [comment, setComment] = useState("");
//   const [evicted, setEvicted] = useState("");
//   const [payOnTime, setPayOnTime] = useState("");
//   const [cleanliness, setCleanliness] = useState("");
//   const [traffic, setTraffic] = useState("");
// //   const [address, setAddress] = useState("");
// //   const [state, setState] = useState("");
//   const navigate = useNavigate();
//   const { id1 } = useParams();
//  const tenantId=id1;
// let customerId=46;
//  const getCustomerID = async () => {
//     const response = await axios.get(`${url}/tenants/{id1}/customerData`);
//     customerId=response.data;
//   }; 

//   const saveManagerReview= async(e) => {
//     e.preventDefault();
//     try {
//     //   await axios.post(`${url}/tenants/{id1}/managerReview`, {
//     //     customerId,
//     //     tenantId,
//     //     rating,
//     //     comment,
//     //     evicted,
//     //     payOnTime,
//     //     cleanliness,
//     //     traffic
//     //   });
      
//     //   navigate("/customers");
//     alert(cleanliness);
//     } catch (error) {
//       console.log(error);
//     }
//   };
    
//     return (

//       <form
//         onSubmit={saveManagerReview}
//       >
//        <div class="row">
// 		<div class="input-group input-group-icon">
// 			<input type="float" value={rating}
//                 onChange={(e) => setRating(e.target.value)} placeholder="Rating"/></div>
// 	  </div>

//       <div class="row">
// 		<div class="input-group input-group-icon">
// 			<input type="float" value={cleanliness}
//                 onChange={(e) => setCleanliness(e.target.value)} placeholder="Cleanliness"/>
// 			</div>
// 	  </div>

//       <div class="row">
// 		<div class="input-group input-group-icon">
// 			<input type="float" value={traffic}
//                 onChange={(e) => setTraffic(e.target.value)} placeholder="Traffic"/></div>
// 	  </div>

//       <div class="row">
//       <h4>Evicted</h4>
// 		<div>
// 			<select
//                 value={evicted}
//                 name="evicted"
//                 onChange={(e) => setEvicted(e.target.value)}
//                 >
//                   <option value="0">No</option>
//                   <option value="1">Yes</option>
//                 </select>
// 			</div>
// 	  </div>

//       <div class="row">
// 		<div class="input-group input-group-icon">
// 			<input type="float" value={payOnTime}
//                 onChange={(e) => setPayOnTime(e.target.value)} placeholder="payOnTime"/></div>
// 	  </div>

//       <div class="row">
//       <input name="comment"
//                 type="text"
//                 className="input"
//                 id="Comment"
//                 value={comment}
//                 onChange={(e) => setComment(e.target.value)} 
//                 placeholder="Comment"/>
//       </div>

  
        
  
//         <button type="submit">Submit review</button>
//       </form>
//     );
//   }
//   export default AddManagerReview;


import { useState,useEffect } from "react";
import '../App.css';
import { FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../css/display.css";
import Header from "./Header.js";

const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
    
};


const AddManagerReview = () =>{
    const [rating, setRating] = useState("");
      const [comment, setComment] = useState("");
      // const [evicted, setEvicted] = useState("");
      const [payOnTime, setPayOnTime] = useState("");
      const [cleanliness, setCleanliness] = useState("");
      const [traffic, setTraffic] = useState("");
      const [noisy,setNoisy]=useState("");
      const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phone, setPhone] = useState("");
    const [reviews, setReviews] = useState([]);
//   const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue1, setHoverValue1] = useState(undefined);
  const [hoverValue2, setHoverValue2] = useState(undefined);
  const [hoverValue3, setHoverValue3] = useState(undefined);
  const [hoverValue4, setHoverValue4] = useState(undefined);
  const [hoverValue5, setHoverValue5] = useState(undefined);
  const stars1 = Array(5).fill(0)
  const stars2 = Array(5).fill(0)
  const stars3 = Array(5).fill(0)
  const stars4 = Array(5).fill(0)
  const stars5 = Array(5).fill(0)
    const navigate = useNavigate();
  const { id } = useParams();
  const url=process.env.REACT_APP_SERVER_URL;
 let tenantId=id;
  
//    const getCustomerID = async () => {
//     const response = await axios.get(`${url}/tenants/{id1}/customerData`);
//     customerId=response.data;
//     console.log(customerId);
//   }; 
useEffect(() => {
  getTenantById();
  getManagerReviewsByTenantId();
  // alert(fname);
}, []);
  const saveManagerReview= async(e) => {
        e.preventDefault();
        try {
    //         await axios.get(`${url}/tenants/{id1}/customerData`);
    // customerId=response.data;
    // alert(customerId);
    // alert(id);
    // console.log(id)
            // customerId=getCustomerID();
          await axios.post(`${url}/tenants/${id}/managerReviews`, {
            // customerId,
            tenantId,
            rating,
            comment,
            // evicted,
            payOnTime,
            cleanliness,
            traffic,
            noisy
            
          });
          
          
        alert(cleanliness);
        navigate("/tenants");
        } catch (error) {
          console.log(error);
        }
      };
      const getTenantById = async () => {
        let response = await axios.get(`${url}/tenants/${id}`);
        let semisplit= JSON.stringify(response.data)//.split(':');
        // alert(JSON.stringify(response.data));
        let pp=JSON.parse(semisplit)[0];
        // alert(pp.id);
        setFname(pp.fname);
        setLname(pp.lname);
        setPhone(pp.phone); 
        // alert(pp.customerid)
        
      };
     
  const getManagerReviewsByTenantId = async () => {
    let response = await axios.get(`${url}/tenants/${id}/managerReviews`);
    // alert(response.data);
    setReviews(response.data);
    // let semisplit= JSON.stringify(response.data)//.split(':');
    // // alert(JSON.stringify(response.data));
    // let pp=JSON.parse(semisplit)[0];
    // alert(pp);

  }

  const handleClick1 = value => {
    setRating(value)
    // alert(value)
  }
  const handleClick2 = value => {
    setPayOnTime(value)
    // alert(value)
    // alert(payOnTime)
  }
  const handleClick3 = value => {
    setCleanliness(value)
    // alert(value)
    // alert(cleanliness)
  }
  const handleClick4 = value => {
    setTraffic(value)
    // alert(value)
    // alert(traffic)
  }
  const handleClick5 = value => {
    setNoisy(value)
    // alert(value)
    // alert(traffic)
  }

  const handleMouseOver1 = newHoverValue => {
    setHoverValue1(newHoverValue)
  };

  const handleMouseOver2 = newHoverValue => {
    setHoverValue2(newHoverValue)
  };

  const handleMouseOver3 = newHoverValue => {
    setHoverValue3(newHoverValue)
  };

  const handleMouseOver4 = newHoverValue => {
    setHoverValue4(newHoverValue)
  };
  const handleMouseOver5 = newHoverValue => {
    setHoverValue5(newHoverValue)
  };
  const handleMouseLeave1 = () => {
    setHoverValue1(undefined)
  }
  const handleMouseLeave2= () => {
    setHoverValue2(undefined)
  }
  const handleMouseLeave3 = () => {
    setHoverValue3(undefined)
  }
  const handleMouseLeave4 = () => {
    setHoverValue4(undefined)
  }
  const handleMouseLeave5 = () => {
    setHoverValue5(undefined)
  }


  return (
    <div>
      <Header/>
      <div class="topnav" id="topnav"> 
  <div class="topnav-right">
  <a>{fname+" "+lname}</a>
      <a>{phone}</a>
      <a>Room :#{id}</a>
  </div>
</div>
      
    <div style={styles.container}>
      <h2> Ratings </h2>
      <form onSubmit={saveManagerReview} >
      <h3>Overall rating</h3>
      <div style={styles.stars}>
        
        {stars1.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick1(index + 1)}
              onMouseOver={() => handleMouseOver1(index + 1)}
              onMouseLeave={handleMouseLeave1}
              color={(hoverValue1 || rating) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <h3>Pay on time</h3>
      <div style={styles.stars}>
      
        {stars2.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick2(index + 1)}
              onMouseOver={() => handleMouseOver2(index + 1)}
              onMouseLeave={handleMouseLeave2}
              color={(hoverValue2 || payOnTime) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>

      <h3>Cleanliness</h3>
      <div style={styles.stars}>
      
        {stars3.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick3(index + 1)}
              onMouseOver={() => handleMouseOver3(index + 1)}
              onMouseLeave={handleMouseLeave3}
              color={(hoverValue3 || cleanliness) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>

      <h3>Traffic</h3>
      <div style={styles.stars}>
      
        {stars4.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick4(index + 1)}
              onMouseOver={() => handleMouseOver4(index + 1)}
              onMouseLeave={handleMouseLeave4}
              color={(hoverValue4 || traffic) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <h3>Noisy</h3>
      <div style={styles.stars}>
      
        {stars5.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={24}
              onClick={() => handleClick5(index + 1)}
              onMouseOver={() => handleMouseOver5(index + 1)}
              onMouseLeave={handleMouseLeave5}
              color={(hoverValue4 || traffic) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <textarea
        placeholder="What's your experience?"
        style={styles.textarea}
        onChange={(e)=>setComment(e.target.value)}
      />
<br></br>
      <button type="submit"
        style={styles.button}
      >
        Submit
      </button>

      </form>
      
    </div>

    <div>
      <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>PayOnTime</th>
              <th>Cleanliness</th>
              <th>Traffic</th>
              <th>Noisy</th>
              <th>Overall Rating</th>
              <th>Comments</th>
              </tr>
          </thead>
          <tbody>
            {reviews.map((review, index) => (
              <tr key={review.id}>
                <td>{index + 1}</td>
                <td>{review.payOnTime}</td>
                <td>{review.cleanliness}</td>
                <td>{review.traffic}</td>
                <td>{review.noisy}</td>
                <td>{review.rating}</td>
                <td>{review.comment}</td>
                </tr>
            ))}
          </tbody>

      </table>
    </div>
    </div>
  );
};




const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  }

};




export default AddManagerReview;