

import React, { useState, useEffect, useRef,useContext } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn, nodeName } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import Header from "./Header.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faThLarge, faDollar } from '@fortawesome/free-solid-svg-icons'
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Button,Row,Col} from "react-bootstrap"
import SelectUSState from 'react-select-us-states';
import { createSlice } from '@reduxjs/toolkit'
import Card from 'react-bootstrap/Card';
import { ManageRoomInfo } from "./ManageRoomInfo";
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import { ManageTenancyInformationComponent } from "./Tenancy";
import { Badge,Whisper, IconButton, Dropdown} from "rsuite";
import PlusIcon from '@rsuite/icons/Plus';
import { AddRepair } from "./AddRepair";
import { AddAmenity } from "./AddAmenity";
const renderIconButton = (props, ref) => {
  return (
    <IconButton {...props} ref={ref} icon={<PlusIcon />} circle 
    // color="yellow" appearance="primary" 
    style={{ 
      backgroundImage: "linear-gradient(10deg,white, #00a8fc)"}}/>
  );
};
export const Rooms = () => {
    const [roomsDetails,setRoomsDetails]=useState([]);
    const [roomId,setRoomId]=useState("");
    const [showRoomDetailsPopup,setShowRoomDetailsPopup]=useState(false);
    const [isAddRepair,setIsAddRepair] = useState(false);
    const [isAddAmenity,setIsAddAmenity]=useState(false);
  //  const [roomNumber,setRoomNumber]=
  const url=process.env.REACT_APP_SERVER_URL;
   
    useEffect(() => {
        getRoomsDetails();
        console.log(url);
     
   }, []);

 const getRoomsDetails=async()=>{
    try{
      console.log("in getRoomsDetails : URL " + url);
        await axios.get(`${url}/rooms/allDetails`).then((responseForGettingRoomsDetails)=>{
            // console.log(responseForGettingRoomsDetails.data[0])
            setRoomsDetails(responseForGettingRoomsDetails.data)
        })

    }catch(error){
        console.log(error);
    }
 }

   
    return (
        <>
        <Header />
        <div className="mt-5">
        <Button 
        onMouseMove={(e)=>{setShowRoomDetailsPopup(false);setRoomId(null);}}
        onTouchMove={(e)=>{setShowRoomDetailsPopup(false);setRoomId(null);}} 
        onClick={(e)=>setShowRoomDetailsPopup(true)}>Add A New Room</Button>
        </div>
        <div className="mt-5">
        {roomsDetails.map((row, index) => (
        <Card style={{ width: '18rem', float:"left" , fontSize:'1.2em'}} className="mt-5 ms-5 me-5 mb-3">
        <Card.Body>
          <Card.Title>Room # {row.id}  - {row.alias}
          <Dropdown renderToggle={renderIconButton} style={{float:"right"}}>
          <Dropdown.Item icon={<PlusIcon />} 
            onClick={(e)=>{setIsAddAmenity(true);setRoomId(row.id)}} 
            onTouchMove={(e)=>{setIsAddAmenity(false);setRoomId("")}}
            onMouseMove={(e)=>{setIsAddAmenity(false);setRoomId("")}} >
              Amenity
          </Dropdown.Item>
          <Dropdown.Item icon={<PlusIcon />} 
            onClick={(e)=>{setIsAddRepair(true);setRoomId(row.id)}}
            onTouchMove={(e)=>{setIsAddRepair(false);setRoomId("")}}
             onMouseMove={(e)=>{setIsAddRepair(false);setRoomId("")}}>
              Repair
          </Dropdown.Item>
          {/* <Dropdown.Item icon={<PlusIcon />}>Download As...</Dropdown.Item>
          <Dropdown.Item icon={<PlusIcon />}>Export PDF</Dropdown.Item> */}
        </Dropdown>

          </Card.Title>
          <Card.Text>
            <Row> <Col><FontAwesomeIcon icon={faBed}></FontAwesomeIcon> Bed Type:  <b>{row.bedType}</b></Col>            </Row>
            <Row> <Col><FontAwesomeIcon icon={faThLarge}></FontAwesomeIcon> Room Size:  <b>{row.size}</b></Col>  </Row>
            <Row> <Col><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon> Weekly Rate :  <b>${row.weeklyRate}</b></Col> </Row>
            <Row> <Col><FontAwesomeIcon icon={faDollar}></FontAwesomeIcon> Daily Rate:  <b>${row.dailyRate}</b></Col>
            </Row> Amenities : {row.allAmenities==null ? null : row.allAmenities} <br></br>
            <b style={{color:"red"}}>{row.rentable==1 ? "Do not rent" : null}</b>
             </Card.Text>
          <Button variant="info" 
          // onTouchMove={(e)=>{setShowRoomDetailsPopup(false);setRoomId(null);}}
          // onMouseMove={(e)=>{setShowRoomDetailsPopup(false);setRoomId(null);}} 
          onClick={(e)=>{setRoomId(row.id);setShowRoomDetailsPopup(true)}}>Edit</Button>&nbsp;
          <Badge color="red" content={row.repairsCount}>
            <Button style={{backgroundImage: "linear-gradient(50deg,#6d006d, #009fee)"}}><Link style={{color:"white"}} to={`/repairs/${row.id}`}>Repairs</Link></Button>
          </Badge>
          &nbsp;
          <Badge color="grey" content={row.allAmenities==null ? 0 : row.allAmenities.split(',').length}>
            <Button 
            style={{backgroundImage: "linear-gradient(60deg,#006d00, orange)"}}>
              <Link style={{color:"white"}} to={`/amenities/${row.id}`}>Amenities</Link></Button>
          </Badge>
          &nbsp;      
        </Card.Body>
      </Card>
       ))}
      {showRoomDetailsPopup  && <ManageRoomInfo value={roomId} getRoomsDetails={getRoomsDetails} 
      setShowRoomDetailsPopup={setShowRoomDetailsPopup}/>}
      {isAddRepair && <AddRepair value={{roomNumber:roomId}}/>}

      {isAddAmenity && <AddAmenity value={{roomNumber:roomId}}/>}
      </div>
        </>
     

    );
   
   }
