import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert

} from "@mui/material"

import Header from "../Header";
import { NumericFormat,PatternFormat } from "react-number-format";
const NumberFormatPhone = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <PatternFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="(###) ###-####"
        mask="_"
      />
    );
  };
const CustomerInquiry=(props)=>{
    const [name,setName]=useState("");
    const [phoneNumber,setPhoneNumber]=useState(null);
    const [description,setDescription] = useState("");
    const [successfullySaved,setSuccessfullySaved]= useState(false);
    const [customerInquiryId,setCustomerInquiryId] = useState(null)
    
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      
    }, []);
    const saveCustomerInquiry = async() =>{
        try{
            const response= await axios.post(`${url}/customerInquiry`,{
                name,
                phoneNumber,
                description
            })
            if(response){
              console.log(response.data.customerInquiryId);
              setCustomerInquiryId(response.data.customerInquiryId)
              setSuccessfullySaved(true)
            }

        }
        catch(error){
            console.log(error);
        }
    }
    
    const editCustomerInquiry = async(customerInquiryId) =>{
      try{
          const response= await axios.patch(`${url}/customerInquiry/${customerInquiryId}`,{
              name,
              phoneNumber,
              description
          })
          if(response){
            setSuccessfullySaved(true)
          }

      }
      catch(error){
          console.log(error);
      }
  }

    return (
      <>
      {/* <Header/> */}
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{backgroundColor:"black"}}>
        <Toolbar>
          
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Tidy Inn
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
    <br></br>
    
      <Paper elevation={3} style={{marginLeft:"10%",marginRight:"10%",marginTop:"2%",
        padding:"2%"
      }}>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
           Feedback Form
          </Typography><br></br>
          {successfullySaved ? <Alert severity="success">
            We appreciate and value your feedback. We will get back to you shortly at the number you have provided.
            <br></br>
            Regards<br></br>
            Management</Alert> : null}
          <br></br>
      <TextField id="outlined-basic" label="Full Name" variant="outlined" fullWidth
        value={name}
        onChange={(e)=>{
          setName(e.target.value)
  
        }}/>

      <br></br><br></br>
      <TextField
      id="outlined-basic"
      label="Phone Number"
      variant="outlined"
      fullWidth
      value={phoneNumber}
      onChange={(e)=>{
        setPhoneNumber(e.target.value)

      }}
      InputProps={{
        inputComponent: NumberFormatPhone,
      }}
    />
      <br></br><br></br>
      <TextField id="outlined-basic" label="Description" variant="outlined" fullWidth  multiline
      rows={4} value={description}
      onChange={(e)=>{
        setDescription(e.target.value)

      }}/>
      <br></br><br></br>
      <Button variant="contained" color="success" onClick={(e)=>{
        if(customerInquiryId){
          editCustomerInquiry(customerInquiryId)
        }
        else{
          saveCustomerInquiry()
        }
        
        }}>  Save</Button>
      
      
      </Paper>
      </>
    );


}
export default CustomerInquiry;