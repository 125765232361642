
import { useState } from 'react';
import TitleComponent from './TitleComponent';
import { XAxis,YAxis, ResponsiveContainer,Tooltip, AreaChart, Area } from 'recharts';


// project import
const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300
    },
    {
        "name": "Page A",
        "uv": 4000,
        "pv": 2400
      },
      {
        "name": "Page B",
        "uv": 3000,
        "pv": 1398
      },
      {
        "name": "Page C",
        "uv": 2000,
        "pv": 9800
      },
      {
        "name": "Page D",
        "uv": 2780,
        "pv": 3908
      },
      {
        "name": "Page E",
        "uv": 1890,
        "pv": 4800
      },
      {
        "name": "Page F",
        "uv": 2390,
        "pv": 3800
      },
      {
        "name": "Page G",
        "uv": 3490,
        "pv": 4300
      },
    
      
  ]


export default function AreaChartComponent({data,summaryValue }) {
  // const [displayType,setDisplayType] = useState("daily"); 
  return (
    <>
    <TitleComponent title="Average Vacancy" count={Math.round(summaryValue)} />
    <div style={{ width: '100%', height: 280 }}>
    <ResponsiveContainer>
    <AreaChart  data={data} margin={{ top: 10, right: 30, left: 20, bottom: 5 }} >
    <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
        </linearGradient>
    </defs>
        <XAxis dataKey="date" />
        <YAxis label={{ value: "Number of Vacant Rooms", angle: -90,  position: 'insideBottomLeft'}}/>
        <Tooltip />
        <Area type="monotone" dataKey="numberOfVacantRooms" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
       
    </AreaChart>
    </ResponsiveContainer>
    </div>
    
    </>
  );
}