import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {Provider} from "./GuestContext";
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// Register fonts with pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CheckoutForm=(props)=>{
    const [show,setShow]=useState("");
    const today=formatDate(new Date());
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        setShow(props.value.isCheckoutFormVisible)
        console.log(props.value.tenancyDetails)
       },[props]);
    const handleClose=()=>{
        setShow(false);
        props.setIsCheckoutFormVisible(false)
    }

    const printCheckoutForm=()=>{
        var nameString="";

        props.value.customersDetails.map((c, index) => {
            if(index==0)
            nameString=c.fname + " " + c.lname;
            else
            nameString=nameString+ " , " + c.fname + " " + c.lname;
            
            
        })
        const documentDefinition = {
            content: [
              {
                text: 'Tidy Inn LLC\n1601 George Jenkins Blvd\nLakeland Fl. 3381\n863-825-5007 \n',
                style: 'header'
              },
              {
                text: 'Room Number #: ' + props.value.tenancyDetails.roomid + '\n\n'

              },
              {
                margin: [20,0,0,10],
                fontSize: 11,
                text:[
                    { text: 'Name(s) : ' }, // Text before the bold word
                    { text: nameString + '\n\n', bold: true }, // Bold word
                ] 
              },
              {
                bold: false,
                text:'I understand and agree that this will not be my primary residence. I have a permanent address somewhere else and agree and understand that this will be a transient occupancy.\n\n',
                style: 'statement'
              },
              {
                bold: false, 
                text:'I agree to check out on ' + props.value.tenancyDetails.checkout +' before 11:00 am EST. I will remove all my stuff, leave the room in a clean condition, and return the keys to the manager at the front desk as well.',
                style:'statement'

              },
              {
                text:'\n\nGuest Signature.............................................\t\t\tManager Signature.......................................\n\n',
                style: 'statement'
              },
              {
                text:'Date: '+ today +"\n\n",
                style: 'statement'
              }
            ],
            styles: {
                header: {
                  fontSize: 12,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 0, 0, 20]
                },
                subheader: {
                  fontSize: 11,
                  bold: true,
                  margin: [0, 0, 0, 10]
                },
                statement: {
                    fontSize: 10,
                    margin: [0,0,10,7],
                    alignment: 'left',
                    lineHeight: 1.5,
                    bold: true
                  }
            }
        }
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download('checkout-'+ props.value.tenancyDetails.checkout + '.pdf');

    }




return (

    <div id="checkout-form">
        <Modal show={show} dialogClassName="checkout-form-modal" onHide={handleClose}>
            <Modal.Header closeButton><Modal.Title>
                Print Checkout Form</Modal.Title>
                </Modal.Header>
        
        <Modal.Body>
        <div style={{textAlign:"center"}}>
        <a><strong>Tidy Inn LLC</strong></a><br></br>
        <a>1601 George Jenkins Blvd </a><br></br>
        <a>Lakeland Fl. 33815 </a><br></br>
        <a>863-825-5007 </a><br></br>
        </div>
        <a>Room#: <strong>{props.value.tenancyDetails.roomid}</strong></a>
        <div className="info">
            {props.value.customersDetails.map((c, index) => (
                <div>
                    <div> <a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a></div>
                </div>
            ))}
        </div>

        
       
<p>I understand and agree that this will not be my primary residence.  I have a permanent address somewhere else and agree and understand that this will be a transient occupancy.</p>

<p>I agree to check out on <strong>{props.value.tenancyDetails.checkout}</strong> before 11:00 am EST.  I will remove all my stuff, leave the room in a clean condition, and return the keys to the manager at the front desk as well.</p>


        <a><strong>Guest Signature: </strong></a><hr/>
        <a><strong>Date: {formatDate(new Date())}</strong></a><hr/>
        </Modal.Body>
    
        <Modal.Footer>
    
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
    
          <Button variant="primary" onClick={printCheckoutForm}>
            Print
          </Button> 
    
        </Modal.Footer>
      </Modal>

  
</div>




);
}