import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { NumericFormat } from 'react-number-format';
import { dateDifference, formatDateText, formatDate} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Menu,
    Paper
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
  } from '@mui/x-data-grid';
import TenancyDetailsByLTTI from "./TenancyDetailsByLTTI";
import Header from "./Header";
import { Table , Rate , RadioTile} from 'rsuite';
import {  Col, Row,Form } from "react-bootstrap";
import PaymentsByTenancyId from "./PaymentsByTenancyId";

const { Column, HeaderCell, Cell } = Table;

const ActiveTenantsReport = ({}) => {
  const [checkin, setCheckin]                     = useState (formatDate(new Date(),-7)) ;
    const [checkout, setCheckout]                   = useState (formatDate(new Date())) ;
    const [reportSummary,setReportSummary]=useState([]);
    const [showTenancyDetailsByLTTIModal,
      setShowTenancyDetailsByLTTIModal]= useState(false);
    const [showPaymentDetailsByTenancyIdModal,
      setShowPaymentDetailsByTenancyIdModal]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow,setSelectedRow] = useState({})
    const navigate=useNavigate();
    const url=process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    
    
    
    
  }, []);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns=[
    { field: 'roomid', headerName: 'Room Number', width: 100, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;
      
        return <Link onClick={(e)=>{
          // setTenancyJson(params.row);
          // setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
          // setShowTaxCalculationModal(true)
        }}>
          {currentRow.roomid}
        </Link>
      
      
    } },
    { field: 'customerNames', headerName: 'Names', width: 300, align: 'left' },
    { field: 'phoneNumbers', headerName: 'phoneNumbers', width: 300, align: 'left' },
    // { field: 'overStay', headerName: 'OverStayed', width: 100, align: 'left' ,
    // renderCell:(params)=>{
    //   const overStay=params.row.overStay;

    //   if(overStay===0)  
    //     return <>Checkout Today</> 
        
    //   else if(overStay<0)  
    //     return <>{"Overstayed" + (-1*overStay) + " days" }</>
    //   else
    //     return <>{overStay + " day(s)"}</>
    //  } },
    { field: 'checkin', headerName: 'Tenancy Period', width: 300, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;
      const tenancyDetails=currentRow.tenancyDetails;
      const lastTenancyIndex=tenancyDetails.length-1
      const first=tenancyDetails[0].checkin;
      const last=params.row.tenancyDetails[lastTenancyIndex].actualCheckoutDate ?
      params.row.tenancyDetails[lastTenancyIndex].actualCheckoutDate : 
      params.row.tenancyDetails[lastTenancyIndex].checkout;
      
        return <Link onClick={(e)=>{
          // setTenancyJson(params.row);
          // setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
          // setShowTaxCalculationModal(true)
          setSelectedRow(params.row)
          setShowTenancyDetailsByLTTIModal(true);
        }}>
          {formatDateText(first) + ' to ' + formatDateText(last)}
        </Link>
       
      
    } },
    { field: 'stayDuration', headerName: 'Stay Duration', width: 150, align: 'left' ,
    renderCell:(params)=>{
      const stayDuration=params.row.stayDuration;
      return stayDuration + " days";

      
     } },
    
    { field: 'totalRent', headerName: 'Total', width: 100, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;

      return <NumericFormat value={currentRow.totalRent} thousandSeparator prefix={'$'} displayType="text"/>
     } },
     { field: 'amountPaid', headerName: 'Amount Paid', width: 100, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;
      

      return <Link onClick={(e)=>{
        setSelectedRow(params.row);
        setShowPaymentDetailsByTenancyIdModal(true);
      }}>
      <NumericFormat value={currentRow.amountPaid} 
        thousandSeparator prefix={'$'} displayType="text"/>
      </Link>
      
     } },
     { field: 'balance', headerName: 'Balance', width: 100, align: 'right' ,
      renderCell:(params)=>{
        const currentRow=params.row;
        // let temp=currentRow.totalRent-currentRow.amountPaid
        
  
        return <>
        <NumericFormat value={currentRow.balance} thousandSeparator prefix={'$'} displayType="text"/>
        </>
        
       } },

  ];
  const preprocessData = (data) => {
    
    return data.map(row => {
      const lastTenancyIndex = row.tenancyDetails.length - 1;
      const lastTenancy = row.tenancyDetails[lastTenancyIndex];
      const first=row.tenancyDetails[0].checkin;
      const last=row.tenancyDetails[lastTenancyIndex].actualCheckoutDate ?
                  row.tenancyDetails[lastTenancyIndex].actualCheckoutDate : 
                  row.tenancyDetails[lastTenancyIndex].checkout;
      const customersInfo=row.customerDetails;
      var names="";
      var phoneNumbers="";
      customersInfo.map((row=>{
        const fullName=row.fname + " " + row.lname;
        if(names.length==0){
          names=fullName;
          phoneNumbers=row.phone;
        }
        else{
          names=names + ", " + fullName;
          phoneNumbers=phoneNumbers + ", " + row.phone;
        }
        
      }));
      var amountPaid=0;
      const payments=row.payments;
      console.log(payments)
      payments.map((row)=>{
        row.map((sub)=>{
          var temp=0;
          if(sub.cashAmount!==null){
            temp+=sub.cashAmount;
          } 
          if(sub.creditCardAmount!==null){
            temp+=sub.creditCardAmount;
          } 
          if(sub.otherAmount!==null){
            temp+=sub.otherAmount;

          }
          amountPaid=amountPaid + temp;
        })
        

      })
      console.log(amountPaid)
      
      return {
      ...row,
      roomid: row.tenancyDetails.length > 0 ? row.tenancyDetails[lastTenancyIndex].roomid : null,
      lastCheckin: lastTenancy.checkin,
      overStay: lastTenancy.actualCheckoutDate ? 
        null : dateDifference(lastTenancy.checkout,new Date()),
      stayDuration:dateDifference(first,last),
      amountPaid:amountPaid,
      customerNames:names,
      phoneNumbers:phoneNumbers,
      balance:row.totalRent - amountPaid

      }
      

    });
  };
  const getAllActiveTenantsInfo= async(e,searchStartDate,searchEndDate)=>{
    try{
      const response=await axios.post(`${url}/getActiveTenants`,{
        searchStartDate,
        searchEndDate
      });
      if(response){
        console.log(response.data)
        setReportSummary(response.data);
      }

    }
    catch(error){
      console.log(error)

    }
  }
 
  


  
   
    
    return (
        <>
        <Header/>
        
        <br></br>
        <Paper style={{margin:"0% 10%", padding:"1.2% 0%"}} >
          <Box style={{margin:"0% 10%", display:"flex" , justifyContent:"space-around"}}>
            <TextField id="outlined-basic" label="Start Date" variant="outlined" 
            type="date" value={checkin} onChange={(e)=>{
                  setCheckin(e.target.value);
              }}
            />
            <TextField id="outlined-basic" label="End Date" variant="outlined" 
            type="date" value={checkout} onChange={(e)=>{
              setCheckout(e.target.value);
              }}
            />
            <b className="mt-3"
            > Selected for {dateDifference(checkin,checkout) + " days"}</b>
            <Button variant="contained" onClick={(e)=>getAllActiveTenantsInfo(e,checkin,checkout)}>Search</Button>
          </Box>
        </Paper>
        
        <br></br>
        
          <Box
        sx={{
          height: 450,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={preprocessData(reportSummary)} columns={columns}
        getRowId={(row) => row.longTermTenancyId}
        />
        
      </Box>
      {showTenancyDetailsByLTTIModal && <TenancyDetailsByLTTI
      tenancyDetailsProps={selectedRow}
        showTenancyDetailsByLTTIModal={showTenancyDetailsByLTTIModal}
        setShowTenancyDetailsByLTTIModal={setShowTenancyDetailsByLTTIModal}
        />}
      { showPaymentDetailsByTenancyIdModal && <PaymentsByTenancyId
        paymentDetailsProps={selectedRow}
        showPaymentDetailsByTenancyIdModal={showPaymentDetailsByTenancyIdModal}
        setShowPaymentDetailsByTenancyIdModal={setShowPaymentDetailsByTenancyIdModal}

      />}
      
        </>
    );

}
export default ActiveTenantsReport;