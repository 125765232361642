import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import Header from "./Header.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
const EditRepair = () => {
    const [description,setDescription]=useState("");
    const [assignedto,setAssignedto]=useState("");
    const [status,setStatus]=useState("");
  const [scheduleddate,setScheduleddate]=useState("");
    const [active,setActive]=useState("");
    const [completeddate,setCompletedDate]=useState("");
    const [roomid,setRoomid]=useState("");
    // const [roomid,setRoomid]=useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const url=process.env.REACT_APP_SERVER_URL;
//   status="Yet to be assigned";
//   active="Active";
  useEffect(() => {
    getRepairById();
    // alert(fname);
  }, []);
 
  const updateRepair = async (e) => {
    e.preventDefault();
    try {
        
      await axios.patch(`${url}/repairs/${id}`, {
        // roomid,
        // customerid,
        roomid,
        description,
        status,
        scheduleddate,
        active
      });

      navigate(`/repairs`);
    } catch (error) {
      console.log(error);
    }
  };
 
  const getRepairById = async () => {
    let response = await axios.get(`${url}/repairs/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    // setFname(pp.fname);
    console.log(pp);
    setRoomid(pp.roomid);
    setDescription(pp.description);
    setActive(pp.active);
    setAssignedto(pp.assignedto);
    setScheduleddate(pp.scheduleddate);
    setCompletedDate(pp.completeddate)
    // alert(pp.customerid)
    
  };
 
  return (
    <div>
      <Header/>
    <div className="container">
        <form onSubmit={updateRepair}>
            <div class="row">
		<div class="input-group input-group-icon">
    <textarea
        placeholder="Repair Description"
        // style={styles.textarea}
        value={description}
        name="description" 
        onChange={(e)=>setDescription(e.target.value)}
      />
		  {/* <div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div> */}
		</div>
          </div>
         

          <div class="row">
		<div class="col">
      <select value={roomid}
                name="Room"
                onChange={(e) => setRoomid(e.target.value)}>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
      </div>
      </div>
          <div class="row">
		<div class="col-third">
		<h4>Status</h4>
		<div>
			<select
                value={status}
                name="status"
                onChange={(e) => {setStatus(e.target.value);}}
                >
                  <option value="Yet to be assigned">Yet to be Assigned</option>
                  <option value="In progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
			</div>
		</div>
    </div>

    <div class="row">
		<div class="input-group input-group-icon">
    <input type="text" name="assignedto" 
        className="input"
        id="first-name"
        value={assignedto}
        onChange={(e) => setAssignedto(e.target.value)}
        placeholder="Assigned To"/>
		  <div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		</div>
    </div>

    <div class="row">
		<div class="col-half">
		  <h4>Scheduled At</h4>
		  <div class="input-group">
			<div>

			{/* <!-- class="col-half" --> */}
			  <input type="date" value={scheduleddate}
                onChange={(e) => setScheduleddate(e.target.value)} 
                placeholder="Scheduled Date"/>
			</div>
		  </div>
		</div> 

		
	  </div>


          <div className="field">
            <button type="submit" className="button is-success">
              Save
            </button>
          </div>
        
        </form>
      </div>
    </div>
  );
};
 
export default EditRepair;