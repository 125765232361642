// import React, { useState } from 'react';
// import { Link, redirect } from "react-router-dom";
// import FormWizard from "react-form-wizard-component";
// import "react-form-wizard-component/dist/style.css";
// import StepOne from './StepOne';
// import StepTwo from './StepTwo';
// import StepThree from './StepThree';
// import StepFour from "./StepFour";
// import StepFive from "./StepFive";
// import {Form,Col} from "react-bootstrap";

 
// const SampleWizard = () => {

//   const handleComplete = () => {
//     console.log("Form completed!");
//     // Handle form completion logic here
//   };
//   return (
//     <>
//       <FormWizard
//         stepSize="sm"
//         onComplete={handleComplete}
//       >

//         <FormWizard.TabContent title="Select Dates" icon="ti-user">
//           {/* <h3>First Tab</h3>
//           <p>Some content for the first tab</p> */}
//           <StepOne/>

//         </FormWizard.TabContent>
//         <FormWizard.TabContent title="Add Guests" icon="ti-settings">
//           <StepTwo/>
//         </FormWizard.TabContent>
//         <FormWizard.TabContent title="Rate" icon="ti-check">
//           <StepThree/>
//         </FormWizard.TabContent>
//         <FormWizard.TabContent title="pets" icon="ti-user">
//           <StepFour></StepFour>
//         </FormWizard.TabContent>
//         <FormWizard.TabContent title="Review" icon="ti-user">
//          <StepFive/>
//         </FormWizard.TabContent>
        
//       </FormWizard>
//       {/* add style */}
//       <style>{`
//         @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
//       `}</style>
//     </>
//   );
// }

// export default SampleWizard;

import React, { useState } from 'react';
import { Link, redirect } from "react-router-dom";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import {Form,Col} from "react-bootstrap";

 
const SampleWizard = () => {

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  return (
    <>
      <FormWizard
        stepSize="sm"
        onComplete={handleComplete}
      >

        <FormWizard.TabContent title="Select Dates" icon="ti-user">
          {/* <h3>First Tab</h3>
          <p>Some content for the first tab</p> */}
          <StepOne/>

        </FormWizard.TabContent>
        <FormWizard.TabContent title="Add Guests" icon="ti-settings">
          <StepTwo/>
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Rate" icon="ti-check">
          <StepThree/>
        </FormWizard.TabContent>
        <FormWizard.TabContent title="pets" icon="ti-user">
          <StepFour></StepFour>
        </FormWizard.TabContent>
        <FormWizard.TabContent title="Review" icon="ti-user">
         <StepFive/>
        </FormWizard.TabContent>
        
      </FormWizard>
      {/* add style */}
      <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>
    </>
  );
}

export default SampleWizard;