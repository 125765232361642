import React, { useState, useEffect } from 'react';
import {
    Box ,FilledInput ,InputLabel ,FormControl,Stepper,Step,StepLabel,
    Typography,Button, Container,Paper,Select,MenuItem  
} from '@mui/material';
import axios from 'axios';
import { useParams,useNavigate } from 'react-router-dom';
import { formatDate } from './CommonFunctions';
// const steps = ['Select campaign settings', 'Create an ad group'];
const components=[] 

const CustomerWizard = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate=useNavigate();
    const {propertyId}=useParams();
    const {tenancyId} = useParams();
    const url = process.env.REACT_APP_SERVER_URL;
    const [rows,setRows]=useState([]);
    let [scheduledPayments,setScheduledPayments] = useState([])
    const [unitJson,setUnitJson] = useState({
      unitId:null,
      name:"",
      streetAddress:"",
      city:"",
      state:"",
      zipCode:"",
      currentTenancyId:null
    });
    const [tenancyJson,setTenancyJson]= useState({
        firstArrivalDate:formatDate(new Date()),
        checkin:formatDate(new Date()),
        checkout:formatDate(new Date(),6),
        actualCheckoutDate:null,
        rentalType:"monthly",
        rate:0,
        paymentDueDate:1,
        deposit:0,
        unitId:null,
        tenancyId:null,
        description:"",
        electricity:0,
        water:0,
        garbage:0,
        sewage:0
    });
    

    
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 const CurrentComponent=components[activeStep];
  return (
    <div>
      <Container sx={{mt:5}}>
        <Paper elevation={3} sx={{ps:5,pe:5}} >
          <Box sx={{ width: '100%',pt:5}}>
            <Stepper activeStep={activeStep} color="red" >
              <Step>
                <StepLabel><b>{unitJson.name ? "Unit: " + unitJson.name : "Select unit to be leased"}</b></StepLabel>
        
              </Step>
              <Step>
                <StepLabel><b>Lease Term</b></StepLabel>
              </Step>
              <Step>
                <StepLabel><b>Tax Information</b></StepLabel>
              </Step>
              <Step>
                <StepLabel><b>Payment Schedule</b></StepLabel>
              </Step>
              <Step>
                <StepLabel><b>Additional Information</b></StepLabel>
              </Step>
              
              <Step>
                <StepLabel><b>Tenant Information</b></StepLabel>
              </Step>
              
              <Step>
                <StepLabel><b>Review</b></StepLabel>
              </Step>
              <Step>
                <StepLabel><b>Print Lease</b></StepLabel>
              </Step>
            </Stepper>
     
            <React.Fragment>
        
              <Typography sx={{ mt: 2, mb: 1 }}>
                <CurrentComponent 
                  
                />
              </Typography>
            </React.Fragment>
      
          </Box>
          <br></br>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
            Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep < components.length-1 ? 
            <Button disabled={activeStep === components.length-1}
                onClick={handleNext}> Next </Button>
            : <Button
            onClick={handleNext}> Finish </Button>}
          </Box>
        </Paper>
      </Container> 
    </div>
  );
};

export default CustomerWizard;