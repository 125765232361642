

import React, { useState, useEffect, useRef,useContext } from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn, nodeName } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import Header from "./Header.js"
import { Table, Rate } from "rsuite";
import {Form,Row,Col,Button,Modal} from "react-bootstrap"
import CommonComponents from "./CommonComponents.js";
import MakePayment from "./MakePayment";
import {formatDateText} from "./CommonFunctions.js"
const { Column, HeaderCell, Cell } = Table;

export const TenantHistory = () => {
    const [tenancyDetails, setTenancyDetails] = useState([]);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const [allRooms,setAllRooms]=useState([]);
    const [roomNumber,setRoomNumber]=useState("");
    const [isPayment,setIsPayment] = useState(false);
    const [tenancyId,setTenancyId] = useState("");
    const [showVoidTenancy,setShowVoidTenancy]=useState(false);
    const [selectedRowData,setSelectedRowData]=useState({});
    const [tenancyDescription,setTenancyDescription]=useState("");
    const navigate=useNavigate();
  const url=process.env.REACT_APP_SERVER_URL;
   
    useEffect(() => {
      getAllRooms();
        getAllTenantsHistory(null);
     
   }, []);
   const propsForMakePayment={
    tenancyId
   }


   const getData = () => {
    if (sortColumn && sortType) {
      return tenancyDetails.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
      
        console.log(typeof x)
        if (typeof x === 'string') {
          x = x.charCodeAt();
          console.log(typeof x + ' x: ' +x)
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
          console.log(typeof y + ' y: ' +y)
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return tenancyDetails;
  }
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };


  const getAllRooms=async()=>{
    try{
      await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
        console.log(responseForAllRooms.data);
        setAllRooms(responseForAllRooms.data);
      })

    }
    catch(error){
      console.log(error);
    }
   }



  const getAllTenantsHistory= async(roomNumber) =>{
    try{
      if(roomNumber){
        await axios.get(`${url}/tenantHistory/${roomNumber}`).then((responseForTenantHistory)=>{
            console.log(responseForTenantHistory.data);
            setTenancyDetails(responseForTenantHistory.data);

        })
      }
      else{
        await axios.get(`${url}/tenantHistory`).then((responseForTenantHistory)=>{
          console.log(responseForTenantHistory.data);
          setTenancyDetails(responseForTenantHistory.data);

      })

      }

    } catch(error){
        console.log(error);
    }
  }
  const updatePrevTenancyIdInVacancyTracking = async(tenancyId) =>{
    try{
      await axios.patch(`${url}/vacancyTracking/updatePrevTenancyId/${tenancyId}`,{}).then((responseForUpdating)=>{
        console.log(responseForUpdating.data)
      })

    }
    catch(error){
      console.log(error);
    }
  }
  const updateNextTenancyIdInVacancyTracking = async(tenancyId) =>{
    try{
      await axios.patch(`${url}/vacancyTracking/updateNextTenancyId/${tenancyId}`,{}).then((responseForUpdating)=>{
        console.log(responseForUpdating.data)
      })

    }
    catch(error){
      console.log(error);
    }
  } 
  const deleteTenancyById = async(tenancyId,roomId) =>{
    try{
        var deleteSuccessFully=false;
        await axios.delete(`${url}/tenant_customer/${tenancyId}`).then ((responseForDeletingRelation)=>{
            deleteSuccessFully=true;
        })
        await axios.delete(`${url}/tenants/${tenancyId}`).then((responseForDeleteTenancy) => {
            console.log(responseForDeleteTenancy.data);
            updatePrevTenancyIdInVacancyTracking(tenancyId);
            updateNextTenancyIdInVacancyTracking(tenancyId);
            getAllTenantsHistory(roomNumber);
            displayNotificationMessage("success", " Deleted Guest Information for room : " + roomId + " ( tenancyId: " + tenancyId + " )");
        })
        
        


    }
    catch(error){
        console.log(error);
    }
  }
  const voidTenancyById = async(tenancyId,roomid) =>{
    try{
      const status="void";
      await axios.patch(`${url}/tenants/${tenancyId}`,{
        status,
        roomid,
        tenancyDescription

      }).then((responseForVoidTenancy)=>{
        console.log(responseForVoidTenancy);
        setShowVoidTenancy(false)

      })
    }
    catch(error){
      console.log(error);
    }
  }
 
    return (
        <>
        <Header/>
        <Row style={{marginLeft:"5%"}}>
          <Col><Form.Label><b>Select a Room:</b></Form.Label></Col>
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row style={{marginLeft:"5%",marginBottom:"1%"}}>
          <Col><Form.Select className="w-50"  size="sm"
                    value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value);getAllTenantsHistory(e.target.value)}}>
                  <option value=''  >All Rooms</option>
                  {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.id}</option>
                ))}
                </Form.Select></Col>
          <Col></Col>
          <Col></Col>
          <Col style={{fontSize:"1.2rem"}}><b>Number of Records: {tenancyDetails.length}</b></Col>
        </Row>
                
                
        <Table className="ms-5" autoHeight wordWrap="break-word"
        //data={activeTenancyDetails} 
        data={getData()}
        size="lg"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        >

      <Column width={100} fixed sortable>
        <HeaderCell><b>Room </b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
            <a onClick={(e)=>{
              // setValueTenancyId(row.id);
              navigate(`/tenants/add/${rowData.id}`, { state:{ tenancyId: rowData.id} })}}>
            <Link>
              {rowData.roomid}
            </Link>
            </a>
            
          )}
          </Cell>
      </Column>
 

      <Column width={180} sortable>
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column width={180} sortable>
        <HeaderCell>Names</HeaderCell>
        <Cell dataKey="names" />
      </Column>

      <Column width={180} sortable>
        <HeaderCell>phoneNumbers</HeaderCell>
        <Cell dataKey="phoneNumbers" />
      </Column>

      <Column width={100} sortable>
        <HeaderCell>Checkin</HeaderCell>
        <Cell dataKey="checkin" />
      </Column>

      <Column width={150} sortable>
        <HeaderCell>Expected Checkout</HeaderCell>
        <Cell dataKey="checkout" />
      </Column>

      <Column width={150} sortable>
        <HeaderCell>Actual Checkout Date</HeaderCell>
        <Cell dataKey="actualCheckoutDate" />
      </Column>
      <Column width={150} sortable>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>


      
      <Column width={70} sortable>
        <HeaderCell>Rent </HeaderCell>
        <Cell dataKey="rate" >
        {rowData => (
              <>
                <b> $</b>{rowData.rate}
              </>    
          )}
        </Cell>
      </Column>


      <Column width={180}>
        <HeaderCell>...</HeaderCell>
        <Cell>
          {rowData => (
            <>
            <Link >Edit</Link> <br></br>
          <Link onClick={(e)=>{
            setShowVoidTenancy(true);
            setTenancyId(rowData.id);
            setRoomNumber(rowData.roomid);
            setSelectedRowData(rowData);
          }}>Void</Link><br></br>
                <Link onMouseMove={()=>setIsPayment(false)} 
                onTouchMove={()=>setIsPayment(false)}
                      onClick={() =>{setIsPayment(true);setTenancyId(rowData.id)}}>Payment</Link>
          {/* <Link onMouseMove={(e)=>setShowMakePaymentModal(false)} 
          onClick={(e)=>{setTenancyId(rowData.id);setShowMakePaymentModal(true)}}>Payment</Link> <br></br> */}
          </>
          )}
        </Cell>
      </Column>
 

    </Table>
    {isPayment && <MakePayment value={propsForMakePayment}/>}

    <Modal show={showVoidTenancy} onHide={(e)=>setShowVoidTenancy(false)}
    // show={showCustomerDetails} onHide={handleCloseCustomerDetails}
    >
          <Modal.Body>
            <h4>Are you sure to delete </h4><br></br>
            <div style={{fontSize:"1.1rem"}}>
            <Row className="mb-2">
              <Col>Name(s)</Col>
              <Col> {selectedRowData.names}</Col>
             
            </Row>
            <Row className="mb-2">
              <Col>Phone Number(s)</Col>
              <Col> {selectedRowData.phoneNumbers}</Col>
              
            </Row>
            <Row className="mb-2">
              <Col>Checkin Date</Col>
              <Col> {formatDateText(selectedRowData.checkin)}</Col>
              
            </Row>
            <Row className="mb-2">
              <Col>Expected Checkout</Col>
              <Col> {formatDateText(selectedRowData.checkout)}</Col>
              
            </Row>
            <Row className="mb-2">
              <Col>Actual Checkout</Col>
              <Col> {selectedRowData.actualCheckoutDate ? formatDateText(selectedRowData.actualCheckoutDate) : null }</Col>
              
            </Row>
            <Row><b>Reason For Cancelling :</b></Row>
            <Row className="ms-2 me-2 mt-2">
              <Form.Control as="textarea" value={tenancyDescription} onChange={(e)=>setTenancyDescription(e.target.value)}/>
            </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={(e) => setShowVoidTenancy(false)}
            >
              No
            </Button>
            <Button variant="primary" 
            onClick={(e) => 
            voidTenancyById(tenancyId,roomNumber)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
  
        </>
        
     

    );
   
   }
