import React, { useState } from "react";
import axios from "axios";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import { DateRangePicker , AutoComplete, Table } from 'rsuite';

import {formatDate, parseResponseJSON} from "./CommonFunctions";
const { Column, HeaderCell, Cell } = Table;
// creating functional component ans getting props from app.js and destucturing them
const StepOne = () =>{
  const [searchStartDate,setSearchStartDate] = useState(formatDate(new Date()));
  const [searchEndDate,setSearchEndDate] = useState(formatDate(new Date(),1));
  const [vacantRooms,setVacantRooms] = useState([]);
  const url=process.env.REACT_APP_SERVER_URL;
  const   getAvailableRoomsBySearchDates= async (e,searchStartDate,searchEndDate) => {
    //  if(e) 
    //  e.preventDefault();
     try {
       if(searchStartDate==null || searchEndDate==null)
       {
         alert("Select Both searchStartDate and searchEndDate Dates");
         return;
       }
       
       await axios.post(`${url}/vRoomsByDate`,{
         searchStartDate,
         searchEndDate
       }
       ).then((responseForVacantRooms) => {
         console.log(responseForVacantRooms.data);
         setVacantRooms(responseForVacantRooms.data);
       })
     } catch ( error)
     {
       console.log (error);
     }
  }
  return (
    <>
    <DateRangePicker
            format="MMM-dd, yy"
            style={{width: 400 ,fontSize:"3rem",transition:"none"}}   
            size="lg"
            className="me-5"
            value={[new Date(searchStartDate), new Date(searchEndDate)]}
            defaultCalendarValue={[new Date(), new Date()]}
            onChange={(e)=>{setSearchStartDate(e[0]);setSearchEndDate(e[1])}} 
          />
    <Button onClick={(e)=>{
      getAvailableRoomsBySearchDates(e,searchStartDate,searchEndDate);
    }}>Search</Button><hr></hr>
    <label>Number of days : 7</label>
    <Table data={vacantRooms}>
    <Column  align="center" fixed sortable>
        <HeaderCell>Id</HeaderCell>
        <Cell dataKey="id"></Cell>
      </Column>

      <Column align="center" fixed sortable>
        <HeaderCell>Rate</HeaderCell>
        <Cell dataKey="Rate"></Cell>
      </Column>

      <Column align="center" fixed sortable>
        <HeaderCell>Amenities</HeaderCell>
        <Cell dataKey="Amenities"></Cell>
      </Column>

    </Table>
    </>

  );

}

export default StepOne;
