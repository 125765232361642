// src/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUserRole, isAuthenticated } from './Auth';

const ProtectedRoute = ({ requiredRole }) => {
    if (!isAuthenticated()) {
        // Redirect to login page if not authenticated
        return <Navigate to="/login" />;
    }
    //Check whether the token expired or not
    // If expired..return to login
    

    const userRole = getUserRole();
    
    if (requiredRole && !requiredRole.includes(userRole)) {
        // Redirect to unauthorized page if role doesn't match
        
        return <Navigate to="/unauthorized" />;
    }

    // Render the child components (nested routes)
    return <Outlet />;
};

export default ProtectedRoute;
