import React, { useState, useEffect , useContext } from "react";
import axios from "axios";

import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Row,Col,Table} from "react-bootstrap";
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";

export const AddCashDeposit=(props)=>{
    const [showCashDepositModal,setShowCashDepositModal]=useState(false);
    const [depositedBy,setDepositedBy]=useState("");
    const [depositDate,setDepositDate]=useState(formatDate(new Date()));
    const [amount,setAmount]=useState();
    const [bankName,setBankName]=useState("");
    const [remarks,setRemarks]=useState("")
    const [depositId,setDepositId]=useState();
    const {displayNotificationMessage}=useContext(CommonComponents);
    const url=process.env.REACT_APP_SERVER_URL;
    let totalAmountToDeposit=0;
    useEffect(() => {
        setShowCashDepositModal(true);
        if(props && props.paymentsToDeposit){
            totalAmountToDeposit=0;
            const array=props.paymentsToDeposit;
            for(let i=0;i<array.length;i++){
              if(array[i].cashAmount)
              totalAmountToDeposit+=array[i].cashAmount;
              if(array[i].otherAmount)
              totalAmountToDeposit+=array[i].otherAmount;
            }

            setAmount(totalAmountToDeposit);
          }
      }, []);
      const handleCloseModal=()=>{
        setShowCashDepositModal(false);
        if(props && props.handleCashDepositEntry)
        {
            props.handleCashDepositEntry(false);
        }
      }
      
 
      const saveCashDepositEntry= async()=>{
        try{
            await axios.post(`${url}/cashDeposits`,{
                depositDate,
                amount,
                depositedBy,
                bankName,
                remarks

            }).then((responseForSavingDepositEntry)=>{
                const tempDbResponse=parseResponseJSON(responseForSavingDepositEntry.data);
                if(tempDbResponse)
                {
                    setDepositId(tempDbResponse.data)
                    handleCloseModal();
                    displayNotificationMessage("success","Cash Deposit added");
                    if(props && props.paymentsToDeposit){
                    updatePaymentsDepositStatus(props.paymentsToDeposit,tempDbResponse.data)
                    }
                }

                 })

        }catch(error){
            console.log(error)
            //setResponseForSms(error.response.data)
            
        }


      }
      const updateIndividualPaymentDepositStatus= async(paymentsToDeposit,id,depositId,index)=>{
        const depositStatus="deposited";
        const cashAmount=paymentsToDeposit[index].cashAmount;
        const creditCardAmount=paymentsToDeposit[index].creditCardAmount;
        const otherAmount=paymentsToDeposit[index].otherAmount;
        await axios.patch(`${url}/payments/${id}`,{
            cashAmount,
            creditCardAmount,
            otherAmount,
            depositId,
            depositStatus
        })
      }
      const updatePaymentsDepositStatus= async(paymentsToDeposit,deposiId) => {
        try{
            for(let i=0;i<paymentsToDeposit.length;i++)
            {
                 await updateIndividualPaymentDepositStatus(paymentsToDeposit,paymentsToDeposit[i].id,deposiId,i);

            }

        }
        catch(error){
            console.log(error);

        }
      }
      const editCashDepositEntry=async()=>{
        try{
            await axios.patch(`${url}/cashDeposits/${depositId}`,{
                depositDate,
                amount,
                depositedBy,
                bankName,
                remarks

            }).then((responseForEditingDepositEntry)=>{
            console.log( "Deposit entry  updated (" + depositId +" ). Status - " + responseForEditingDepositEntry.status);
            })

        }catch(error){
            console.log(error)
            //setResponseForSms(error.response.data)

        }
      }
    return (
        <>
        {/* <Modal show={showCashDepositModal} onHide={handleCloseModal} >

            <Modal.Header closeButton>

                <Modal.Title>Cash Deposit Details</Modal.Title>
 
            </Modal.Header>
            

            <Modal.Body className="me-3"> */}
                {/* <p>Amount available:$ </p> */}
                {/* <Row>Deposit Amount= ${totalAmountToDeposit}</Row> */}
                <Row  className="mb-2">
                    <Col><b>Deposit Date: </b></Col>
                    <Col><b>Amount: </b></Col>
                </Row>
                <Row>

                
                <Col><Form.Control 
                    type="date" 
                    value={depositDate}
                    onChange={(e) => setDepositDate(e.target.value)} 
                    aria-describedby="basic-addon1"/>
              </Col>
              <Col>
              <Form.Control 
                    type="number" 
                    min="0"
                    value={amount ? amount : totalAmountToDeposit}
                    onChange={(e) => setAmount(e.target.value)} 
                    aria-describedby="basic-addon1"/>
              </Col>
                </Row>
                <br></br>
                <Row>
                <Col><b className="mb-2">Deposited By: </b></Col>
                <Col><b className="mb-2">Name of the Bank: </b></Col>
                </Row>

                <Row>
                
                <Col>
                  <Form.Control 
                    type="text" 
                    value={depositedBy}
                    onChange={(e) => setDepositedBy(e.target.value)} 
                    aria-describedby="basic-addon1"/>
                </Col>
                <Col>
                  <Form.Control 
                    type="text" 
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)} 
                    aria-describedby="basic-addon1"/>
                </Col>
                </Row>
                <br></br>
                <Row>

                <b className="mb-2">Remarks: </b>
                    <Col><Form.Control as="textarea" name="Remarks" value={remarks} 
        onChange={(e) => setRemarks(e.target.value)}/></Col>

                </Row>

            {/* </Modal.Body>
            <Modal.Footer> */}
                <Button variant="secondary"  onClick={handleCloseModal}>Cancel</Button> 
                <Button type="submit" onClick={(e)=>depositId ? editCashDepositEntry(e) :saveCashDepositEntry(e)}>Save</Button> 
            {/* </Modal.Footer>
        </Modal> */}
        
        </>

    );

}
export default AddCashDeposit;