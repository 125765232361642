import { format, formatDistance, formatRelative, subDays } from 'date-fns' 
import {useToaster} from "rsuite"
// const CommonFunctions = () => {  
 export const isDateOrDatetime = (input)  => {
  const date = new Date(input);
  return !isNaN(date.getTime());
}
export const getDataSorted = (sortColumn,sortType,importedData) => {
  if (sortColumn && sortType) {
    // alert(sortType)
    return importedData.sort((a, b) => {
      let x = a[sortColumn];
      let y = b[sortColumn];
      
    
      // console.log(typeof x)
      if(isDateOrDatetime(x) && isDateOrDatetime(y))
      {
        x=new Date(x);
        y=new Date(y);

      }
      else{
      if (typeof x === 'string') {
        x = x.charCodeAt();
        console.log(typeof x + ' x: ' +x)
      }
      if (typeof y === 'string') {
        y = y.charCodeAt();
        console.log(typeof y + ' y: ' +y)
      }
    }
      
      if (sortType === 'asc') {
        return x - y;
      } else {
        return y - x;
      }
    });
  }
  return importedData;
}
 export const  formatDate = ( dateString, numberofDays ) =>{
  // console.log("Common Function "+dateString)
  if(numberofDays)
  {
    dateString.setDate(dateString.getDate()+ numberofDays);

  }
    return format(new Date(dateString), 'yyyy-MM-dd') ;
  }
  export const parseResponseJSON = (DbResponse)=>{
    try{
        if(DbResponse){
            const DbResponseString=JSON.stringify(DbResponse);
            const DbResponseJson=JSON.parse(DbResponseString);
            return DbResponseJson;
        }
    }catch(error){
        console.log(error+" while parsing the string "+ DbResponse);
    }
  } 

  export const logMe=(functionName,message)=>{
    console.log("In "+functionName + ": "+message);
  }
  export const dateDifference=(startDate,endDate)=>{
    const date1 = new Date(startDate);
const date2 = new Date(endDate);
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

return diffDays;
  }

  export const allStates=()=>{
    const STATES = [ "Alabama", "Alaska", "Arizona", "Arkansas",
    "California", "Colorado", "Connecticut",
    "Delaware", "Florida", "Georgia", "Hawaii",
    "Idaho", "Illinois", "Indiana", "Iowa",
    "Kansas", "Kentucky", "Louisiana", "Maine",
    "Maryland", "Massachusetts", "Michigan",
    "Minnesota", "Mississippi", "Missouri",
    "Montana", "Nebraska", "Nevada", "New Hampshire",
    "New Jersey", "New Mexico", "New York",
    "North Carolina", "North Dakota","Ohio",
    "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont",
    "Virginia", "Washington", "West Virginia",
    "Wisconsin", "Wyoming"]
    return STATES;
   }
   export const dateFormatOptions = {
    weekday: 'short', // Short name of the day (e.g., "Thu")
    month: 'short',   // Short name of the month (e.g., "Jul")
    day: 'numeric',   // Numeric day (e.g., "4")
    year: 'numeric',  // Numeric year (e.g., "2002")
  };
// };

export const formatDateText = (inputDate) => {
  const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
const formattedDate = new Date(inputDate + 'T00:00:00').toLocaleDateString('en-US', options);
return formattedDate;
};
// export default CommonFunctions;
