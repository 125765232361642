import React, { useState } from "react";
import axios from "axios";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import { DateRangePicker , AutoComplete, Table } from 'rsuite';

import {formatDate, parseResponseJSON} from "./CommonFunctions";
const { Column, HeaderCell, Cell } = Table;
// creating functional component ans getting props from app.js and destucturing them
const StepOne = () =>{
  return (
    <>
    <h6>Selected Room: </h6>
    <h6>Selected Date Range: </h6>
    <h6>Checkin - </h6>
     <h6>   Checkout: </h6>
     <h6>Rent </h6>
     <h6>Deposit </h6>
     <h6>Pets </h6>
     <h6>Pet Deposit </h6>
     <h6>Pets Description</h6>
     <h6>Guests</h6>
     <Button>Print Forms</Button>
     <Button>Checkin</Button>

    </>

  );

}

export default StepOne;
