import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
 
const EditCustomer = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [card, setCard] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const navigate = useNavigate();
  const { id,id1 } = useParams();
  const url=process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    getCustomerById();
  }, []);
 
  const updateCustomer = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${url}/customers/${id}`, {
        fname,
        lname,
        card,
        email,
        gender,
        phone,
        address,
        state
      });
      navigate(`/tenants/${id1}/edit`);
    } catch (error) {
      console.log(error);
    }
  };
 
  const getCustomerById = async () => {
    const response = await axios.get(`${url}/customers/${id}`);
    setFname(response.data.fname);
    setLname(response.data.lname);
    setCard(response.data.card);
    setEmail(response.data.email);
    setGender(response.data.gender);
    setPhone(response.data.phone);
    setAddress(response.data.address);
    setState(response.data.state);
  };
 
  return (
    <div className="container">
      <form onSubmit={updateCustomer}>
    <div class="row">

        
		<div class="input-group input-group-icon">


    <input type="text" name="fname" 
        className="input"
        id="first-name"
        value={fname}
        onChange={(e) => setFname(e.target.value)}
        placeholder="First Name"/>
		  <div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		</div>

    <div class="input-group input-group-icon">
    
			<input type="text" name="lname" 
        className="input"
        id="last-name"
        value={lname}
        onChange={(e) => setLname(e.target.value)}
        placeholder="Last Name"/>
			<div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		  </div>
      

      <div class="input-group input-group-icon">
		  <input type="email" name="email" 
        className="input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email Address"/>
		  <div class="input-icon"><i class="fa fa-envelope"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></i></div>
		</div>
    <div class="input-group input-group-icon">
		  <input type="phone" name="phone"
      className="input"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      placeholder="Phone Number"/>
		  <div class="input-icon"><i class="fa fa-phone"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></i></div>
		</div>
    <div class="input-group input-group-icon">
			<input name="address"
                type="text"
                className="input"
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)} 
                placeholder="Street Address"/>
			<div class="input-icon"><i class="fa fa-building" ><FontAwesomeIcon icon={faBuilding}></FontAwesomeIcon></i></div>
		  </div>
		  <select type="text"
                className="input"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}>
          <option>Alabama</option> <option>Alaska</option> <option>Arizona</option> <option>Arkansas</option>
          <option>California</option> <option>Colorado</option> <option>Connecticut</option><option>Delaware</option>
          <option>Florida</option><option>Georgia</option><option>Hawaii</option><option>Idaho</option>
          <option>Illinois</option><option>Indiana</option><option>Iowa</option><option>Kansas</option>
          <option>Kentucky</option><option>Louisiana</option><option>Maine</option><option>Maryland</option>
          <option>Massachusetts</option><option>Michigan</option><option>Minnesota</option>
          <option>Mississippi</option><option>Missouri</option><option>Montana</option><option>Nebraska</option>
          <option>Nevada</option><option>New Hampshire</option><option>New Jersey</option>
          <option>New Mexico</option><option>New York</option><option>North Carolina</option>
          <option>North Dakota</option><option>Ohio</option><option>Oklahoma</option><option>Oregon</option>
          <option>Pennsylvania</option><option>Rhode Island</option><option>South Carolina</option>
          <option>South Dakota</option><option>Tennessee</option><option>Texas</option><option>Utah</option>
          <option>Vermont</option><option>Virginia</option><option>Washington</option>
          <option>West Virginia</option><option>Wisconsin</option><option>Wyoming</option>
          
        </select>

    </div>
    <div class="input-group input-group-icon">
            <button type="submit" className="button is-success">
              Save
            </button>
          </div> 
    </form>
    </div>
  );
};
 
export default EditCustomer;