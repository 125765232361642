import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../css/display.css"; 
import { Link } from "react-router-dom";

import Header from "./Header.js";
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
const CheckTenantFinal = () => {
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    // const [card, setCard] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("Male");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [arrival, setArrival] = useState("");
    let [checkout, setCheckout] = useState("");
    const [rate, setRate] = useState("");
    const [adults, setAdults] = useState("");
    const [children, setChildren] = useState("");
    const [deposit, setDeposit] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    
    const [bedtype, setBedtype] = useState("");
    const [size, setSize] = useState("");
    const [customerid,setCustomerid]=useState("");
    const [roomid,setRoomid]=useState("");
    const [customersDetails, setCustomersDetails]=useState([]);
    const url=process.env.REACT_APP_SERVER_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const available_status=1;
  var today = new Date();
let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
 
  useEffect(() => {
    getTenantById();
    getCustomersByTenantId();
    // alert(fname);
  }, []);
  const up_status="past"
 function printPage(){
const pp=document.getElementById("topnav"); 
document.getElementsByTagName('Header').style.display="none";
pp.style.display="none";
window.print();
document.getElementsByTagName('Header').style.display="block";
pp.style.display="block";
 }
 const getCustomersByTenantId = async() => {
  let response=await axios.get(`${url}/tenants/${id}/customers`)
  setCustomersDetails(response.data);
  // console.log(response.data);
 }
//   const updateTenant = async (e) => {
//     e.preventDefault();
//     try {
        
//       await axios.patch(`${url}/tenants/${id}`, {
//         // roomid,
//         customerid,
//         arrival,
//         checkout,
//         rate,
//         adults,
//         children,
//         deposit,
//         type,
//         status
//       });

//       await axios.patch(`${url}/customers/${customerid}`, {
//         fname,
//         lname,
//         // card,
//         email,
//         gender,
//         phone,
//         address,
//         state
//       });

//       // await axios.patch(`${url}/rooms/${roomid}`, {
//       //   bedtype,
//       //   size
//       // });


//       navigate("/tenants");
//     } catch (error) {
//       console.log(error);
//     }
//   };
 
  const getTenantById = async () => {
    let response = await axios.get(`${url}/tenants/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    setFname(pp.fname);
    setLname(pp.lname);
    setEmail(pp.email);
    setAddress(pp.address);
    setState(pp.state);
    setPhone(pp.phone);
    setArrival(pp.arrival);
    setCheckout(pp.checkout);
    setRate(pp.rate);
    setAdults(pp.adults);
    setChildren(pp.children);
    setDeposit(pp.deposit);
    setType(pp.type);
    setStatus("past");
    setCustomerid(pp.customerid); 
    setRoomid(pp.roomid)
    setBedtype(pp.bedtype)
    setSize(pp.size)
    // alert(pp.customerid)
    
  };
  const updateTenant = async (e) => {
    e.preventDefault();
    try {
        
      await axios.patch(`${url}/tenants/${id}`, {
        // roomid,
        // customerid,
        arrival,
        checkout,
        rate,
        adults,
        children,
        deposit,
        type,
        status
      });

      
      await axios.patch(`${url}/customers/${customerid}`,{
        lname,
        fname,
        // card,
        email,
        gender,
        phone,
        address,
        state,
        status
      })

      await axios.patch(`${url}/rooms/${roomid}`, {
        bedtype,
        size,
        available_status
        
      });


      navigate(`/tenants`);
    } catch (error) {
      console.log(error);
    }
  };
 
  return (
    <div>
      <Header/>
        <div class="topnav" id="topnav"> 
  <div class="topnav-right"><Link to={`/tenants/${id}/display`} >Checkin Form</Link>
    <Link to={`/tenants/${id}/display`} >Checkout Notice</Link>
    <a onClick={printPage}>Print</a>
    <a onClick={updateTenant}>CheckOut</a>
  </div>
</div>
    <div className="maindiv">
        {/* <ul>
        <li>Tidy Inn LLC</li>
        <li>1601 George Jenkins Blvd</li>
        <li>Lakeland Fl. 33815</li>
        <li>863-825-5007</li>
        </ul> */}
        
<div className="header">
    <div className="he1">
<a><strong>Tidy Inn LLC</strong></a><br></br></div>
<div className="he1"><a>1601 George Jenkins Blvd </a><br></br></div>
<div className="he1"><a>Lakeland Fl. 33815 </a><br></br></div>
<div className="he1"><a>863-825-5007 </a><br></br></div>
</div>
<div className="info">
{customersDetails.map((c, index) => (
    <div>
<a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a><br></br>
<a>Room#: <strong>{c.roomid}</strong></a></div>
))}
</div>


<p>I understand and agree that this will not be my primary residence.  I have a permanent address somewhere else and agree and understand that this will be a transient occupancy.</p>

<p>I agree to check out on <strong>{date}</strong> before 11:00 am EST.  I will remove all my stuff, leave the room in a clean condition, and return the keys to the manager at the front desk as well.</p>


<div className="info">
    <div className="sign">
    {customersDetails.map((c, index) => (
        <div>
<a>Guest Signature: <strong></strong></a><br></br>
<a>Guest Signature: <strong>{c.fname+" " +c.lname}</strong></a><br>
</br><a>Date: <strong>{date}</strong></a>
</div>
    ))}
</div>
</div>
<br></br>
{/* <div className="info">
    <div className="sign">
        <div>
<a>Guest Signature: <strong></strong></a></div>
<div><a>Guest Signature: <strong>{fname+" " +lname}</strong></a></div>
<div><a>Date: <strong>{date}</strong></a></div>
</div>
</div> */}


      </div>
      </div>
  );
};
 
export default CheckTenantFinal;


