import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { Message } from 'rsuite'
import { Table } from 'react-bootstrap'
import { formatDate } from '../CommonFunctions'
const PaymentsToDepositSummary = (props) => {
  useEffect(() => {
    if (props && props.paymentsToDeposit) {
      console.log(props.paymentsToDeposit)
    }
  }, [])
  let totalAmountToDeposit = 0
  if (props && props.paymentsToDeposit) {
    const array = props.paymentsToDeposit
    for (let i = 0; i < array.length; i++) {
      if (array[i].cashAmount) totalAmountToDeposit += array[i].cashAmount
      if (array[i].otherAmount) totalAmountToDeposit += array[i].otherAmount
    }
  }
  return (
    <>
      <div> </div>
      {props && props.paymentsToDeposit ? (
        <Table className="ms-5" style={{ width: '95%' }}>
          <thead>
            <tr>
              <th>Room Number</th>
              <th>Payment Date</th>
              <th>Receipt Number</th>
              <th>Paid By</th>
              <th>Cash Amount</th>
              <th>Other Amount</th>
            </tr>
          </thead>
          <tbody>
            {props.paymentsToDeposit.map((row, index) => (
              <tr key={row.id}>
                <td>{row.roomid}</td>
                <td>{formatDate(row.paymentDate.toString())}</td>
                <td>{row.receiptNumber}</td>
                <td>{row.paidBy}</td>
                <td>{row.cashAmount ? '$' + row.cashAmount : '-'}</td>
                <td>{row.otherAmount ? '$' + row.otherAmount : '-'}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={2}>
                Total Cash + Other Amount : ${totalAmountToDeposit}
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Message></Message>
      )}
    </>
  )
}
export default PaymentsToDepositSummary
