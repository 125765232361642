import { Timeline, Grid, Row, Col } from 'rsuite';



const TenancyTimeline = () => (
  <Grid fluid>
    <Row>
      <Col xs={8}>
      <Timeline align={'left'}>
    <Timeline.Item>
        <>2018-03-01 </>
        <br></br>
        <>Your order starts processing</>
    </Timeline.Item>
    <Timeline.Item>
        <>2018-03-01 </>
        <br></br>
        <>Your order starts processing</>
    </Timeline.Item>
    <Timeline.Item>
        <>2018-03-01 </>
        <br></br>
        <>Your order starts processing</>
    </Timeline.Item>
    <Timeline.Item>
        <>2018-03-01 </>
        <br></br>
        <>Your order starts processing</>
    </Timeline.Item>
    <Timeline.Item>
        <>2018-03-01 </>
        <br></br>
        <>Your order starts processing</>
    </Timeline.Item>
  </Timeline>
      </Col>
    </Row>
  </Grid>
);
export default TenancyTimeline;

