import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from 'react'
  import axios from 'axios'
  import {
    Table,
    Popover,
    Whisper,
    Checkbox,
    Dropdown,
    IconButton,
    Progress,
  } from 'rsuite';
  import Modal from 'react-bootstrap/Modal';
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button'
  import { Row, Col } from 'react-bootstrap'
  import {
    formatDate,
    parseResponseJSON,
    logMe,
    getDataSorted,
    formatDateText,
  } from '../CommonFunctions'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Header';
  const { Column, HeaderCell, Cell } = Table
  const EditDeposit = (props) => {
    const url = process.env.REACT_APP_SERVER_URL;
    const [depositedPayments,setDepositedPayments]=useState([]);
    const [showPayments,setShowPayments]=useState(false)
    const {depositId}=useParams();
    const navigate=useNavigate();
    useEffect(() => {
        if(depositId){
            getAllPaymentsForDepositId(depositId);
            setShowPayments(true)
        }
      }, []);
      const getAllPaymentsForDepositId = async(depositId)=>{
        try{
            await axios.get(`${url}/paymentsByDepositId/${depositId}`).then(
                (responseForPayments)=>{
                    console.log(responseForPayments.data);
                    setDepositedPayments(responseForPayments.data);


            })

        }catch(error){
            console.log(error);
        }
      }
      const handleChange = (id, key, value) => {
        const nextData = Object.assign([], depositedPayments);
        nextData.find(item => item.id === id)[key] = value;
        setDepositedPayments(nextData);
      };
      const handleEditState = id => {
        const nextData = Object.assign([], depositedPayments);
        const activeItem = nextData.find(item => item.id === id);
        activeItem.status = activeItem.status ? null : 'EDIT';
        setDepositedPayments(nextData);
      };
      const handleClosePayments =()=>{
        setShowPayments();
        navigate(`/deposits`);
      }
    return (
        <>
        <Header/>
        <Modal show={showPayments}  size="lg" onHide={handleClosePayments}
            style={{ zIndex: 2000 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Deposit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Table height={420} data={depositedPayments} rowHeight={70}>
                    <Column width={100}>
                        <HeaderCell>Payment Id</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>

                    <Column width={160}>
                        <HeaderCell>Payment Date</HeaderCell>
                        <Cell dataKey="paymentDate">
                        {rowData => (
                          <>
                           {formatDateText(formatDate(rowData.paymentDate))}
                          </>
                          
                        )}
                        </Cell>
                    </Column>

                    <Column width={120}>
                        <HeaderCell>Receipt Number</HeaderCell>
                        <Cell dataKey="receiptNumber" />
                    </Column>

                    <Column width={120}>
                        <HeaderCell>Paid By</HeaderCell>
                        <Cell dataKey="paidBy" />
                    </Column>

                    <Column width={120}>
                        <HeaderCell>Amount</HeaderCell>
                        <Cell dataKey="cashAmount" >
                        {rowData => (
                          <>
                           ${rowData.cashAmount + rowData.otherAmount}
                          </>
                          
                        )}
                        </Cell>
                    </Column>

                    <Column width={140}>
                        <HeaderCell>Deposit Status</HeaderCell>
                        <EditableCell dataKey="depositStatus" onChange={handleChange} />
                    </Column>

                    {/* <Column flexGrow={1}>
                        <HeaderCell>...</HeaderCell>
                        <ActionCell dataKey="id" onClick={handleEditState} />
                    </Column> */}
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                  
                </Modal.Footer>

        </Modal>
        
        </>
    );
  }
  const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
    const editing = rowData.status === 'EDIT';
    let status=rowData.depositStatus;
    return (
      <Cell {...props} className={editing ? 'table-content-editing' : ''}>
        {editing ? (
          // <input
          // className="rs-input"
          // defaultValue={rowData[dataKey]}
          // onChange={event => {
          //   onChange && onChange(rowData.id, dataKey, event.target.value);
          // }} />
          <select id="myDropdown" defaultValue={rowData[dataKey]} className="rs-input"
          onChange={event => {
            onChange && onChange(rowData.id, dataKey, event.target.value);}}>
            <option value="deposited">deposited</option>
            <option value="undeposited">undeposited</option>
          </select>
          // <Dropdown title={status} >
          //   <Dropdown.Item value="deposited">deposited</Dropdown.Item>
          //   <Dropdown.Item value="undeposited">undeposited</Dropdown.Item>
          // </Dropdown>
        ) : (
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        )}
      </Cell>
    );
  };
  
  const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          onClick={() => {
            onClick(rowData.id);
          }}
        >
          {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };
  export default EditDeposit;