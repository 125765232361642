import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./Header.js";
// import "../css/display.css"; //include new style sheet
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
const DisplayFinal = () => {
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    // const [card, setCard] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("Male");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [arrival, setArrival] = useState("");
    let [checkout, setCheckout] = useState("");
    const [rate, setRate] = useState("");
    const [adults, setAdults] = useState("");
    const [children, setChildren] = useState("");
    const [deposit, setDeposit] = useState("");
    const [type, setType] = useState("");
    const [status, setStatus] = useState("");
    const [currentDate,setCurrentDate]=useState("");
    const [customersDetails,setCustomersDetails]=useState([]);
    const url=process.env.REACT_APP_SERVER_URL;
    // const [bedtype, setBedtype] = useState("");
    // const [size, setSize] = useState("");
    const [customerid,setCustomerid]=useState("");
    // const [roomid,setRoomid]=useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  var today = new Date();
let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
 
  useEffect(() => {
    getTenantById();
    getCustomersByTenantId();
    // alert(fname);
  }, []);
  
 function printPage(){
const pp=document.getElementById("topnav");
// document.getElementById("nav-header").style.display="none";
pp.style.display="none";
window.print();
// document.getElementById("nav-header").style.display="block";
pp.style.display="block";
 }

 const getCustomersByTenantId = async() => {
  let response=await axios.get(`${url}/tenants/${id}/customers`)
  setCustomersDetails(response.data);
  // console.log(response.data);
 }
 
//   const updateTenant = async (e) => {
//     e.preventDefault();
//     try {
        
//       await axios.patch(`${url}/tenants/${id}`, {
//         // roomid,
//         customerid,
//         arrival,
//         checkout,
//         rate,
//         adults,
//         children,
//         deposit,
//         type,
//         status
//       });

//       await axios.patch(`${url}/customers/${customerid}`, {
//         fname,
//         lname,
//         // card,
//         email,
//         gender,
//         phone,
//         address,
//         state
//       });

//       // await axios.patch(`${url}/rooms/${roomid}`, {
//       //   bedtype,
//       //   size
//       // });


//       navigate("/tenants");
//     } catch (error) {
//       console.log(error);
//     }
//   };
 
  const getTenantById = async () => {
    let response = await axios.get(`${url}/tenants/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    setFname(pp.fname);
    setLname(pp.lname);
    setEmail(pp.email);
    setAddress(pp.address);
    setState(pp.state);
    setPhone(pp.phone);
    setArrival(pp.arrival);
    setCheckout(pp.checkout);
    setRate(pp.rate);
    setAdults(pp.adults);
    setChildren(pp.children);
    setDeposit(pp.deposit);
    setType(pp.type);
    setStatus(pp.status);
    setCustomerid(pp.customerid); 
    // alert(pp.customerid)
    
  };
 
  return (
    <div>
      <Header/>
        <div class="topnav" id="topnav"> 
  <div class="topnav-right">
    <a onClick={printPage}>Print</a>
    <Link
                    to={`http://localhost:3000/tenants/edit/${id}`}
                    // className="button1 is-small is-info mr-2"
                  >
                    Edit
                  </Link>
  </div>
</div>
    <div className="maindiv">
        {/* <ul>
        <li>Tidy Inn LLC</li>
        <li>1601 George Jenkins Blvd</li>
        <li>Lakeland Fl. 33815</li>
        <li>863-825-5007</li>
        </ul> */}
        
<div className="header">
    <div className="he1">
<a><strong>Tidy Inn LLC</strong></a><br></br></div>
<div className="he1"><a>1601 George Jenkins Blvd </a><br></br></div>
<div className="he1"><a>Lakeland Fl. 33815 </a><br></br></div>
<div className="he1"><a>863-825-5007 </a><br></br></div>
</div>
<div className="info">
      {customersDetails.map((c, index) => (
<div>
       <div> <a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a></div>
<div><a>Phone Number: <strong>{c.phone}</strong>  </a><a>Email Address: <strong>{c.email}</strong></a></div>
</div>

))}
</div>
<div><a>Arrival Date: <strong>{arrival}</strong>  </a><a>Room#: <strong>{id}</strong></a></div>
<div><a>Rate: <strong>${rate} ({type})</strong>  </a><a>Room Deposit: <strong>${deposit}</strong> </a></div>
<div><a>Number of Adults: <strong>{adults}</strong>  </a><a>Number of Children: <strong>{children}</strong></a></div>
<div><a>Check out date: <strong>{checkout}</strong></a></div>
</div>
<h4>Terms and Conditions</h4>
<ol>
    <li>This is an extended stay motel and stay is temporary. This is not your primary residence.  You are a <strong>transient occupant.</strong> </li>
    <li>You can not use the motel address as your personal or business address. </li>
    <li>Check-out time is at 11:00 A.M. </li>
    <li>If you would like to occupy the premises for longer, you must speak with someone at the front desk and come to a written agreement.  If no agreement is reached, you must vacate by the check-out time and date.</li>
    <li>Guests will be held responsible for any loss or damage done to a room during their stay. </li>
    <li>Motel Management is not responsible for your personal belongings or valuables</li>
    <li>We check toilets for clogging issues before your stay. If the toilet is clogged during your stay, a sum of $250 per plumber call will be charged. Guest will be responsible for any additional charges if applicable </li>
    <li>The room you are assigned MUST be left in the same condition in which you rented it.</li>
    <li>No tampering with or removal of smoke detectors. </li>
    <li>Speed Limit 5 MPH. Careful driving is expected</li>
</ol>

<p>By signing you acknowledge you have read all of the above terms and conditions</p>
<div className="info">
    <div className="sign">
<a>Guest Signature: <strong></strong></a>
<a>Date: <strong>{date}</strong></a>
</div>
</div>
<br></br><br></br>
<h3>TIDY INN HOUSE RULES</h3>

<br></br>
<ul>
    <li><strong>No Pets.</strong> If any pets are found, the tenant agrees to pay a fine of $500 and move out immediately. If damages are more than $500, the tenant agrees to pay the attorney and legal fees.</li>
    <li><strong>No smoking allowed in the rooms.</strong> You will be asked to leave immediately and legal action will follow if we find you smoking inside the roomQuiet hours are from 9pm - 6am</li>
    <li><strong>Cleanliness :</strong> We appreciate you keeping the rooms clean. We only allow clean tenants. You will be requested to leave if you keep the room dirty. Management reserves the right to decide.</li>
    <li><strong>No loud music</strong></li>
    <li><strong>No loud arguments.</strong> Our guests like our place quiet. You will be asked to leave if you argue loud</li>
    <li><strong>No additional guests / human trafficking / drugs.</strong> If we find traffic to the room, you will be asked to leave immediately. We work with police to stop human trafficking and drugs. We alert the police immediately and you will be reported as a potential drug dealer or human trafficker. You agree to leave if any such incidents happen</li>
    <li><strong>Zero tolerance for slamming the doors.</strong> You will be asked to leave immediately and legal action may follow if there are damages</li>
    <li><strong>Courtesy:</strong>  Please be courteous to the employees. We can discuss and resolve problems without raising the voice. </li>
    <li><strong>Inspections.</strong> Management will do inspections for smoking, cleanliness, presence of pets or any safety or operational concerns..</li>

</ul>

<p>I agree with the house rules. I agree to check out immediately if management decides that rules are broken. I will be responsible for any damages / court charges caused </p>


<div className="info">
    <div className="sign">
<a>Guest Signature: <strong></strong></a>
<a>Date: <strong>{date}</strong></a>
</div>
</div>
<br></br>
<div className="info">
    <div className="sign">
<a>Guest Signature: <strong></strong></a>
<a>Date: <strong>{date}</strong></a>
</div>
</div>


      </div>
  );
};
 
export default DisplayFinal;
