import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Paper, Box,Typography, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowBackIosNewIcon className={className} style={{...style, left: 10,position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            backgroundColor: 'black',
            borderRadius: '50%',
            color: 'white',
            width: 40,
            height: 40,
            '&:hover': {
              backgroundColor: 'darkgrey',
            }}} onClick={onClick}/>
        
      
    );
  };
  
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ArrowForwardIosIcon className={className} style={{...style, right: 10,position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            // zIndex: 1,
            backgroundColor: 'black',
            borderRadius: '50%',
            color: 'white',
            width: 40,
            height: 40,
            '&:hover': {
              backgroundColor: 'darkgrey',
            }}} onClick={onClick}/>
    );
  };
  const paperStyle = {
    backgroundColor: '#e0f7fa', // Light teal background color
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const ImageSample =({title})=>{
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect screen size
    return (
        // <Grid item xs={4}>
            <img src="/tidyInnSampleImage.jpg"  height="320ch"
            // width={ isMobile ? '370ch' : '400rem'}
            width="auto"
            style={{padding: 15, textAlign: 'center',borderRadius: '5%',
                
             }}/>
      //  </Grid>
    )
  }

function CarouselSample() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow/>,
    nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
    <div className="slider-container">
      <Slider {...settings}>
      
        <ImageSample title="Photo 1" sx={{marginLeft:"10%"}}/>
        <ImageSample title="Photo 2"/>
        <ImageSample title="Photo 3"/>
        <ImageSample title="Photo 4"/>
        <ImageSample title="Photo 5"/>
        <ImageSample title="Photo 6"/>
        <ImageSample title="Photo 7"/>
        <ImageSample title="Photo 8"/>
        


      </Slider>
    </div>
    </>
    
  );
}

export default CarouselSample;


