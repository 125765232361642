
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate,useLocation, useParams } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {Provider} from "./GuestContext";
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
// import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {formatDate, parseResponseJSON,logMe , dateDifference} from "./CommonFunctions";
import { ManageCustomerInformationComponent } from "./Customer";
import {SearchRoomsByDateComponent} from "./SearchRoomsByDate"
import TestComponent from "./TestComponent";
import {CheckinForm} from "./CheckinForm";
import {CheckoutForm} from "./CheckoutForm"
import MakePayment from "./MakePayment";
import {Row , Col} from 'react-bootstrap'
import Header from "./Header.js"
import { Table , Rate , RadioTile} from 'rsuite';
import SendSMS from "./SendSMS";

const { Column, HeaderCell, Cell } = Table;

const VacancyTrackingReport=()=>{
    const [vacancyActivityHistory,setVacancyActivityHistory] =useState([]);
    // const [vacancyTrackingId,setVacancyTrackingId] =useState("");
    const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const {vacancyTrackingId} =useParams();
  const [showVacancyActivityPopup,setShowVacancyActivityPopup]=useState(false);

  
  const url=process.env.REACT_APP_SERVER_URL;
  
  const navigate=useNavigate();
    useEffect(() => {
        setShowVacancyActivityPopup(true);
        getVacancyActivityByVacancyTrackingId(vacancyTrackingId);
        
      }, []);
      const handleCloseAddInqueryPopup=()=>{
        setShowVacancyActivityPopup(false);
        window.history.back();
      }


    const getVacancyActivityByVacancyTrackingId = async(vacancyTrackingId) => {
        try{
            await axios.get(`${url}/vacancyActivityByVTId/${vacancyTrackingId}`).then((responseForResponseActivityReport)=>{
                console.log(responseForResponseActivityReport.data);
                setVacancyActivityHistory(responseForResponseActivityReport.data);
            })

        }
        catch(error){
            console.log(error);
        }
    }
    const getData = () => {
        if (sortColumn && sortType) {
          return vacancyActivityHistory.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
          
            console.log(typeof x)
            if (typeof x === 'string') {
              x = x.charCodeAt();
              console.log(typeof x + ' x: ' +x)
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
              console.log(typeof y + ' y: ' +y)
            }
            // if(typeof x==="date")
            // {
            //     console.log("Date type : "+x)
            // }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
        }
        return vacancyActivityHistory;
      }


    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
    const formatDate = (inputDate) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = new Date(inputDate).toLocaleDateString('en-US', options);
  return formattedDate;
      };
      

    // FIXME: export the table to excel
    return (
      <>
    {/* <Header/> */}
    <Modal  id="activity-modal" show={showVacancyActivityPopup} onHide={handleCloseAddInqueryPopup}>
    <Modal.Header closeButton><Modal.Title></Modal.Title> <b>Room Number: {vacancyActivityHistory.length>0 ? vacancyActivityHistory[0].roomId : null}</b></Modal.Header>
        <Modal.Body className="w-175">
    
    {/* <h4 className="mt-3 ms-5" > Today's Guests</h4>
    <h5 className="mt-0 me-5 mb-0" style={{marginLeft:"50%"}}>Active Guests: {activeTenancyDetails.length } 
    &nbsp; &nbsp; Overstayed Guests : {countOverstayedGuests(activeTenancyDetails)}  
    &nbsp; &nbsp; Today Checkouts : {countTodayCheckoutGuests(activeTenancyDetails)}  
    &nbsp; &nbsp; Tomorrow Checkouts : {countTmroCheckoutGuests(activeTenancyDetails)}  </h5> */}
    <br></br>
   
    {/* <br></br> */}
    <Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    //   data={activeTenancyDetails}
    data={getData()}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}

    >
        {/* <Column width={100} sortable>
        <HeaderCell>Tracking Id</HeaderCell>
        <Cell dataKey="vacancyTrackingId" />
      </Column> */}

      {/* <Column width={80} sortable>
        <HeaderCell>Room</HeaderCell>
        <Cell dataKey="roomId" />
      </Column> */}
      <Column width={180}>
        <HeaderCell>Updated At</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.updatedAt ? formatDate(rowData.updatedAt) : null}
          </>
          )}
        </Cell>
      </Column>

      <Column width={200}>
        <HeaderCell>Status</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.currentStatus}
          </>
          )}
        </Cell>
      </Column>
      
      <Column width={140}>
        <HeaderCell>Responsible Person</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.responsiblePerson}
          </>
          )}
        </Cell>
      </Column>
      <Column width={240}>
        <HeaderCell>Activity Log</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.activityLog}
          </>
          )}
        </Cell>
      </Column>

      
      <Column width={280}>
        <HeaderCell>comments</HeaderCell>
        <Cell>
          {rowData => (
            <>
            {rowData.comments}
          </>
          )}
        </Cell>
      </Column>
      
      

      

      {/* <Column width={180}>
        <HeaderCell>...</HeaderCell>
        <Cell>
          {rowData => (
            <>
            { rowData.previousTenancyId ?<> <Link to={`/tenants/add/${rowData.previousTenancyId}`}>
                Previous Booking Info</Link> <br></br> </> :null}
           { rowData.nextTenancyId ? <><Link to={`/tenants/add/${rowData.nextTenancyId}`}>Next Booking Info</Link><br></br></>  :null}
           <Link to={`/tenants/add/${rowData.nextTenancyId}`}>Details</Link>
          
          </>
          )}
        </Cell>
      </Column> */}
      

    </Table>
    </Modal.Body>
    </Modal>
 
    
    </>
    );

}
export default VacancyTrackingReport;