
// material-ui
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MainCard from './MainCard';
// import {TrendingDownIcon , TrendingUpIcon } from '@mui/icons-material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { NumericFormat } from 'react-number-format';
// project import

const iconSX = { fontSize: '0.75rem', color: 'inherit', marginLeft: 0, marginRight: 0 };

export default function CardAnalysis({  title="checkins", count=45, 
    percentage=2, isLoss=false, extra=500,isMoney=false,subTitle,navigateURL="/" }) {
        const color = isLoss ? 'error':'success' 
  return (
    <MainCard contentSX={{ p: 0.25 }} navigateURL={navigateURL} >
      <Stack spacing={0.5}>
      <Grid container alignItems="center">
          <Grid item>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
          </Grid>
          </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" color="inherit">
            <NumericFormat value={count} thousandSeparator prefix={isMoney ? '$' : ''} displayType="text"/>
              
            </Typography>
          </Grid>&nbsp;&nbsp;
          {subTitle ? <Grid item>
            <Typography variant="h5" color="text.secondary">
            {subTitle}
        </Typography>
          </Grid> : null}
           {/* {percentage && (
            <Grid item>
              <Chip
                variant="h4"
                color={color}
                icon={isLoss ? <TrendingDownIcon style={iconSX} /> : 
                <TrendingUpIcon style={iconSX}  />}
                label={`${percentage}%`}
                sx={{ ml: 1.25, pl: 1 }}
                size="small"
              />
            </Grid>
          )}  */}
        </Grid>
      </Stack>
       <Box sx={{ pt: 1.5 }}>
       <Grid container alignItems="center">
          <Grid item>
          <Typography variant="b" color="text.secondary">
          You made an extra{' '}
          <Typography variant="caption" sx={{ color: `${color || 'primary'}.main` }}>
            {extra}
          </Typography>{' '}
          this year
        </Typography>
          </Grid>
          </Grid>
        
      </Box> 
    </MainCard>
  );
}