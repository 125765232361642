import React, { useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import validator from "validator";
import { Col } from "rsuite";
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import {ManageCustomerInformationComponent} from "./Customer.js";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = () => {
  const [addGuest,setAddGuest]=useState(false);
  const [numberOfAdults , setNumberOfAdults]      = useState (0);
  const [numberOfChildren , setNumberOfChildren]  = useState (0);
  
  return (
    <>
    <label>Selected Room: {10}</label><hr></hr>
    <label>Selected Date Range: {formatDate(new Date())} to {formatDate(new Date(),1)}</label><br></br>
    <Col sm>Adults:</Col>
      <Col sm>
        <Form.Control
          className="me-5"
          type="number"
          min="0" 
          value={numberOfAdults}
          onChange={(e) => setNumberOfAdults(e.target.value)}
          aria-describedby="basic-addon1"
        />

      </Col>
      <Col sm>Children:</Col>
      <Col sm>
        <Form.Control
          className="me-5 w-15"
          type="number"
          min="0" 
          value={numberOfChildren}
          onChange={(e) => setNumberOfChildren(e.target.value)} 
          aria-describedby="basic-addon1"
        />

      </Col>
 <br></br>
    <Button onClick={(e)=>setAddGuest(true)}>Add Guest</Button>
   {/* { addGuest && <ManageCustomerInformationComponent/>} */}
    </>

  );
}

export default StepTwo;
