import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
// import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {Provider} from "./GuestContext";
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Row,Col} from 'react-bootstrap'
import {formatDate, parseResponseJSON,logMe} from "./CommonFunctions";
import Header from "./Header.js"
import CommonComponents from "./CommonComponents.js";
import { Rate } from "rsuite";
// const {id}=useParams();
const GuestReviews=()=>{
    const [payOnTime,setPayOnTime]=useState();
    const [overallRating, setOverallRating]=useState();
    const [cleanliness,setCleanliness]=useState();
    const [traffic,setTraffic]=useState();
    const [noisy,setNoisy]=useState();
    const [managerComments,setManagerComments]=useState("");
    const [reviewId,setReviewId] = useState("");
    const [isAddReview,setIsAddReview] = useState(true);
    const [guestReviewsDetails,setGuestReviewsDetails] = useState([]);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const {id}=useParams();
    const navigate=useNavigate();
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      getAllReviewsByLTTI();
    
    }, []);
    const getAllReviewsByLTTI = async()=>{
      try{
        await axios.get(`${url}/tenants/getLTTIByTenancyId/${id}`).then(async(responseForLTTI)=>{
          console.log(responseForLTTI.data);
          const tempLTTI=responseForLTTI.data[0].longTermTenancyId;
          await axios.get(`${url}/getReviewsByLTTI/${tempLTTI}`).then((responseForAllReviews)=>{
            console.log(responseForAllReviews.data);
            setGuestReviewsDetails(responseForAllReviews.data);
          })
        })
        

      }
      catch(error){
        console.log(error);
      }
    }
    const handleCloseAddReview =()=>{
      setIsAddReview(false);
      navigate(`/tenants/add/${id}`)
    }
    return (
        <>
        <Header />
       {/* <Modal show={isAddReview} onHide={handleCloseAddReview} dialogClassName="modal-width">

      <Modal.Header closeButton>

      <Modal.Title></Modal.Title>
      
      </Modal.Header>

      <Modal.Body> */}
        <div>
        <Table bordered>
          <thead>
            <th>ReviewId</th>
            <th>Overall Rating</th>
            <th>Pay on time</th>
            <th>Cleanliness</th>
            <th>Noisy</th>
            <th>Traffic</th>
            <th>Manager Comments</th>

            </thead>
        <tbody>
        {guestReviewsDetails.map((row, index) => (
            <>
            <td>{row.id}</td>
            <td><Rate readOnly size="xs" value={row.overallRating}/></td>
            <td><Rate readOnly size="xs" value={row.payOnTime}/></td>
            <td><Rate readOnly size="xs" value={row.cleanliness}/></td>
            <td><Rate readOnly size="xs" value={row.noisy}/></td>
            <td><Rate readOnly size="xs" value={row.traffic}/></td>
            {/* <td>{row.overallRating}</td> */}
            {/* <td>{row.payOnTime}</td>
            <td>{row.cleanliness}</td>
            <td>{row.noisy}</td>
            <td>{row.traffic}</td> */}
            <td>{row.managerComments}</td>
            </>
          ))}
          </tbody>
          </Table>
  
        </div>
        {/* </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
        
        
        </Modal> */}
        </>
    );

}
export default GuestReviews;