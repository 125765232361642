import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import "../css/checkList.css"
const RoomReadyChecklist= () =>{

  return (
    <>
     <div id="checklist">
     <input id="01" type="checkbox" name="r" value="1"/>
  <label for="01">Task 1</label>
  <input id="02" type="checkbox" name="r" value="2" />
  <label for="02">Task 2</label>
  <input id="03" type="checkbox" name="r" value="3" />
  <label for="03">Task3</label>
  
    </div>
    </>

  );
}

export default RoomReadyChecklist;