import React, {Component} from 'react';
import { useState } from 'react';
import {DayPilotMonth, DayPilotScheduler} from "@daypilot/daypilot-lite-react";
import Header from './Header';
import { Form } from 'react-bootstrap';

const MonthlyCalender = () => {
    const [searchDate,setSearchDate] = useState('2023-01-01');
    const events= [
        {
            id: 1,
            text: "Event 1",
            start: "2023-09-07T10:30:00",
            end: "2023-09-10T13:00:00"
        },
        {
            id: 2,
            text: "Event 2",
            start: "2023-09-08T09:30:00",
            end: "2023-09-08T11:30:00",
            barColor: "#6aa84f"
        },
    ]
    return (
        <>
        <Header/>
        <Form.Control type="date" value={searchDate} 
            onChange={(e)=>setSearchDate(e.target.value)}
        ></Form.Control>
        <DayPilotMonth startDate={searchDate} events={events}
        />
        {/* <DayPilotScheduler/> */}
        </>
    );
  }

export default MonthlyCalender;