
import { useEffect, useState } from 'react';
import TitleComponent from './TitleComponent';
import { BarChart,XAxis,YAxis, Bar, ResponsiveContainer,Tooltip } from 'recharts';
import { parseISO } from 'date-fns';


// project import
const options = { month: 'short', day: 'numeric' };


export default function BarChartComponent({data,searchStartDate}) {
  const [displayType,setDisplayType] = useState("daily");
  const date=parseISO(searchStartDate)
  function getWeeklyData(dailyData) {
    const weeks = [];
    let weekSum = 0;
    let weekStartDate = null;
    let weekEndDate = null;
    
    dailyData.forEach((entry, index) => {
        if (index % 7 === 0) {
            if (weekStartDate) {
                weeks.push({
                  date: `${weekStartDate.toLocaleDateString('en-US',options)}-${weekEndDate.toLocaleDateString('en-US',options)}`,
                    amount: weekSum
                });
            }
            weekStartDate = new Date(entry.date);
            weekSum = 0;
        }
        weekEndDate = new Date(entry.date);
        weekSum += entry.amount;
    });

    // Add the last week
    if (weekStartDate) {
        weeks.push({
            date: `${weekStartDate.toLocaleDateString('en-US',options)}-${weekEndDate.toLocaleDateString('en-US',options)}`,
            amount: weekSum
        });
    }

    return weeks;
}
  const calculateTotalAmount = (data) =>{
    let total=0;
    for(let itr in data){
      const currentRow=data[itr];
      total+=currentRow.amount;
    }
    return total;
  }
  return (
    <>
    <TitleComponent title={`${date.toLocaleDateString('en-US',{month:"long"})} Revenue Statistics`} count={calculateTotalAmount(data)} 
    isMoney={true} displayType={displayType} setDisplayType={setDisplayType}/>
    <div style={{ width: '90%', height: 280 }}>
    <ResponsiveContainer>
    <BarChart  data={displayType === "weekly" ? getWeeklyData(data) : data} 
    barCategoryGap="25%" margin={{ top: 10, right: 30, left: 30, bottom: 5 }} >
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="amount" fill="#1E90FF" />
    </BarChart>
    </ResponsiveContainer>
    </div>
    
    </>
  );
}