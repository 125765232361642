import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom'
import AddInquery from './AddInquery'
import { IconButton } from 'rsuite'
import EditIcon from '@rsuite/icons/Edit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const Header = () => {
  const titleStyle = {
    color: 'white', // Change this to your desired title color
  }
  const [inqueriesActivity, setInqueriesActivity] = useState(false)
  const [currentCash, setCurrentCash] = useState(0)
  const url = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    getCurrentCash()
  }, [])

  const getCurrentCash = async () => {
    try {
      await axios.get(`${url}/currentCash`).then((response) => {
        setCurrentCash(response.data[0]['totalSum'])
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="black"
        style={{ color: 'white', padding: '0' }}
      >
        <Container
          expand="sm"
          style={{
            color: 'white',
            backgroundColor: 'black',
            fontSize: '16px',
            height: '10%',
          }}
        >
          <Navbar.Brand href="#home">
            <Link style={{ color: 'white',fontWeight:'bolder',marginRight:"10px" }}>
            TIDY Inn
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ backgroundColor: 'white' }}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ color: 'white' }}
          >
            <Nav className="me-auto">
              <Nav.Link href="/tenants/add" style={{ color: 'white' }}>
                Checkin New Guest
              </Nav.Link>
              &nbsp;&nbsp;&nbsp;
              <Nav.Link href="/tenants" style={{ color: 'white' }}>
                Manage Existing Guest
              </Nav.Link>
              &nbsp;&nbsp;&nbsp;
              <NavDropdown
                title={'Room Management'}
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item href="/rooms">Rooms</NavDropdown.Item>
                <NavDropdown.Item href="/repairs">Repairs</NavDropdown.Item>
                <NavDropdown.Item href="/amenities">Amenities</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#pricing" style={{ color: 'white' }}></Nav.Link>
              <NavDropdown title={'Reports'} id="collapsible-nav-dropdown">
                <NavDropdown.Item href="/roomAvailability">
                  Availability
                </NavDropdown.Item>
                <NavDropdown.Item href="/activeTenants">
                  Missing Payments
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/dailyReport">
                  Daily Report
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/searchCustomers">
                  Search Tenants
                </NavDropdown.Item>
                <NavDropdown.Item href="/payments">
                  Payments History
                </NavDropdown.Item>
                <NavDropdown.Item href="/freeDaysReport">
                  Occupancy Report
                </NavDropdown.Item>
                <NavDropdown.Item href="/tenantHistory">
                  Tenancy History
                </NavDropdown.Item>
                <NavDropdown.Item href="/prevTenants">
                  Previous Guests
                </NavDropdown.Item>
                <NavDropdown.Item href="/tenants">
                  Current Guests
                </NavDropdown.Item>
                <NavDropdown.Item href="/reports">
                  Checkout Report
                </NavDropdown.Item>
                <NavDropdown.Item href="/deposits">
                  Deposits Report
                </NavDropdown.Item>
                <NavDropdown.Item href="/inventory">
                  Inventory Report
                </NavDropdown.Item>
                <NavDropdown.Item href="/customerInquiryReport">
                  Feedback Report
                </NavDropdown.Item>

                {/* <NavDropdown.Divider />
              <NavDropdown.Item href="/repairs">Repairs</NavDropdown.Item>
              <NavDropdown.Item href="/amenities">Amenities</NavDropdown.Item> */}
                {/* <NavDropdown.Divider /> */}
              </NavDropdown>
            </Nav>
            <Nav>
            <Nav.Link href="/dashboard" style={{ color: 'white' }}>
                Dashboard
              </Nav.Link>
              {/* <Nav.Link href="/sms" style={{ color: 'white' }}>
                SMS
              </Nav.Link> */}
              <Nav.Link
                href="/inqueries"
                style={{ color: 'white' }}
                // onClick={(e)=>setInqueriesActivity(true)}
              >
                Inqueries
              </Nav.Link>
              <Nav.Link href="/cashManagement" style={{ color: 'white' }}>
                Cash Management
              </Nav.Link>
              <Nav.Link
                href="/vacancyTrackingReport"
                style={{ color: 'white' }}
              >
                Vacancy Tracking
              </Nav.Link>

              <Nav.Link href="/currentCash" style={{ color: 'white' }}>
                <FontAwesomeIcon
                  onClick={{}}
                  style={{ cursor: 'pointer' }}
                  icon={faMoneyCheckDollar}
                ></FontAwesomeIcon>{' '}
                ${currentCash}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {inqueriesActivity && <AddInquery />}
    </>
  )
}

export default Header
