import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';


const data = [
  { date: '2024-07-01', lostRent: 400, vacantRooms: [1,2,3,4] },
  { date: '2024-07-02', lostRent: 300, vacantRooms: [1,2,3,4] },
  { date: '2024-07-03', lostRent: 700, vacantRooms: [1,2,3,4] },
  { date: '2024-07-04', lostRent: 278, vacantRooms: [1,2,3,4] },
  { date: '2024-07-05', lostRent: 189, vacantRooms: [1,2,3,4] },
  { date: '2024-07-06', lostRent: 239, vacantRooms: [1,2,3,4] },
  { date: '2024-07-07', lostRent: 349, vacantRooms: [1,2,3,4] },
  { date: '2024-07-08', lostRent: 400, vacantRooms: [1,2,3,4] },
  { date: '2024-07-09', lostRent: 300, vacantRooms: [1,2,3,4] },
  { date: '2024-07-10', lostRent: 700, vacantRooms: [1,2,3,4] },
  { date: '2024-07-11', lostRent: 278, vacantRooms: [1,2,3,4] },
  { date: '2024-07-12', lostRent: 189, vacantRooms: [1,2,3,4] },
  { date: '2024-07-13', lostRent: 239, vacantRooms: [1,2,3,4] },
  { date: '2024-07-14', lostRent: 349, vacantRooms: [1,2,3,4] },
  { date: '2024-07-15', lostRent: 400, vacantRooms: [1,2,3,4] },
  { date: '2024-07-16', lostRent: 300, vacantRooms: [1,2,3,4] },
  { date: '2024-07-17', lostRent: 700, vacantRooms: [1,2,3,4] },
  { date: '2024-07-18', lostRent: 278, vacantRooms: [1,2,3,4] },
  { date: '2024-07-19', lostRent: 189, vacantRooms: [1,2,3,4] },
  { date: '2024-07-20', lostRent: 239, vacantRooms: [1,2,3,4] },
  { date: '2024-07-21', lostRent: 349, vacantRooms: [1,2,3,4] },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper style={{  border: '1px solid #ccc' }}>
        <b>{`Date: ${label}`}</b><br></br>
        <b>{`Lost Rent: $${payload[0].value}`}</b><br></br>
        <>{`Vacant Rooms: ${payload[0].payload.vacantRooms.length}`}</>
        <p>Additional details...</p>
      </Paper>
    );
  }

  return null;
};

const LineChartComponent = ({occupancyData}) => {
  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper style={{padding:"5%"}}>
          <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={false} />
                <YAxis tick={false} />
                <Tooltip />
                <Bar dataKey="lostRent" fill="#82ca9d" />
              </BarChart>
          </ResponsiveContainer>
            sdfgfcgf
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper>
            {/* <Typography variant="h6">Bookings</Typography>
            <Typography variant="h4">5,339</Typography> */}
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={false} />
                <YAxis tick={false} />
                <Tooltip />
                <Bar dataKey="lostRent" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
            {/* <Typography variant="body2">Previous period: -16%</Typography>
            <Typography variant="body2">Previous year: 128%</Typography> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '20px' }}>
            {/* <Typography variant="h6">Conversion Rate</Typography>
            <Typography variant="h4">98.05%</Typography> */}
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={false} />
                <YAxis tick={false} />
                <Tooltip />
                <Bar dataKey="lostRent" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            {/* <Typography variant="body2">Previous period: 12%</Typography>
            <Typography variant="body2">Previous year: 9%</Typography> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ padding: '20px' }}>
            {/* <Typography variant="h6">Booking Revenue</Typography>
            <Typography variant="h4">$9,352.31</Typography> */}
            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={false} />
                <YAxis tick={false} />
                <Tooltip />
                <Bar dataKey="lostRent" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
            {/* <Typography variant="body2">Previous period: 319%</Typography>
            <Typography variant="body2">Previous year: 84%</Typography> */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LineChartComponent;
