import { Steps, Panel, Placeholder, ButtonGroup, Button, DatePicker,Table } from 'rsuite';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import PencilSquareIcon from '@rsuite/icons/legacy/PencilSquare';
import BookIcon from '@rsuite/icons/legacy/Book';
const { Column, HeaderCell, Cell } = Table;


const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        <Button
          appearance="link"
          onClick={() => {
            onClick(rowData.id);
          }}
        >
          {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      </Cell>
    );
  };


const CreatePastTenancyCards = () => {
  const [step, setStep] = React.useState(0);
  const [data, setData] = React.useState([]);
  const onChange = nextStep => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  const handleEditState = id => {
    const nextData = Object.assign([], data);
    const activeItem = nextData.find(item => item.id === id);
    activeItem.status = activeItem.status ? null : 'EDIT';
    setData(nextData);
  };
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find(item => item.id === id)[key] = value;
    setData(nextData);
  };
  return (
    <div>
        {/* Resize */}
        <Modal show={true}>
            <Modal.Body>
            <Steps current={step}>
        <Steps.Item title="Select Date" icon={<PencilSquareIcon style={{ fontSize: 20 }} />} />
        <Steps.Item title="Tenancy Cards" icon={<BookIcon style={{ fontSize: 20 }} />} />
      </Steps>
      <hr />
      <Panel header={`Step: ${step + 1}`}>
        { step==0 ?
            <>
            <Form.Label> <b> Select Initial Checkin Date:  </b> </Form.Label>
                <Form.Control type="date"></Form.Control>
            </>
        : step==1 ?
            <>
                <Table autoHeight wordWrap="break-word">
                <Column width={100} sortable>
                    <HeaderCell>Checkin</HeaderCell>
                    <Cell dataKey="checkout" />
                </Column>

                <Column width={100} sortable>
                    <HeaderCell>Checkout</HeaderCell>
                    <Cell dataKey="checkout" />
                </Column>

                <Column width={100} sortable>
                    <HeaderCell>Rent</HeaderCell>
                    <Cell dataKey="checkout" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>...</HeaderCell>
                    <ActionCell dataKey="id" onClick={handleEditState} />
                </Column>

              
                    
                </Table>
            </>
        : null}
      </Panel>
      <hr />
      <ButtonGroup>
        <Button onClick={onPrevious} disabled={step === 0}>
          Previous
        </Button>
        <Button onClick={onNext} disabled={step === 3}>
          Next
        </Button>
      </ButtonGroup>

            </Modal.Body>
        </Modal>
    
    </div>
  );
};
export default CreatePastTenancyCards;