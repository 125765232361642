import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import Header from "./Header.js";
import "../App.css"
import { Link, redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {formatDate, parseResponseJSON,dateDifference, getDataSorted, formatDateText} from "./CommonFunctions";
import InputGroup from 'react-bootstrap/InputGroup';
import {Row,Col} from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import CommonComponents from "./CommonComponents.js";
import { Rate , Divider, Table} from "rsuite";
import AddInquery from "./AddInquery.js";
const { Column, HeaderCell, Cell } = Table;

export const Inqueries=()=>{
    const [name,setName] =useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [contactedDate,setContactedDate] =useState("");
    const [callRegarding,setCallRegarding] = useState("");
    const [callStatus,setCallStatus] = useState("");
    const [lastMessageDate,setLastMessageDate]= useState("");
    const [lastMessageType,setLastmessageType] = useState("");
    const [messageHistory,setMessageHistory] = useState("");
    let [inqueryStatus,setInqueryStatus]=useState("");
    const [Inquerys, setInquerys] = useState([]);
    const [isAddInquery,setIsAddInquery]=useState(false);
    const [InqueryId,setInqueryId]=useState("");
    const [responseForInquery,setResponseForInquery]=useState("");
    const [showDeleteInqueryPopup, setShowDeleteInqueryPopup]=useState(false);
    const [selectedPhoneNumbers,setSelectedPhoneNumbers]=useState([]);
    const [managerRatingOnInquery, setManagerRatingOnInquery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");
    const [loading, setLoading] = useState(false);
    // const [dupStateValue,setDupStateValue]=useState("false");
    const {displayNotificationMessage}=useContext(CommonComponents);
    // const {roomId} = useParams();
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      getInquerys();
    //   getAllRooms();
    }, []);
    
//    const getAllRooms=async()=>{
//     try{
//       await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
//         console.log(responseForAllRooms.data);
//         setAllRooms(responseForAllRooms.data);
//       })

//     }
//     catch(error){
//       console.log(error);
//     }
//    }
   const getInquerysByRoomId = async(roomId)=>{
    try{
      await axios.get(`${url}/Inquerys/getInquerysByRoomId/${roomId}`).then((responeForGettingInquerysByroomId)=>{
        console.log(responeForGettingInquerysByroomId.data);
        setInquerys(responeForGettingInquerysByroomId.data)
      })

    }
    catch(error){
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
   }
    const getInquerys = async () => {
      try{
      const responseForGettingInquerys = await axios.get(`${url}/potentialGuests`);
      setInquerys(responseForGettingInquerys.data);
      console.log(responseForGettingInquerys.data)
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    };

    // Display pop up
    const handleShowAddInquery=(InqueryId)=>{
      
      
      // This is when you edit a ticket
      if(InqueryId){
        setInqueryId(InqueryId);
        getInqueryById(InqueryId); // get details of ticket

      }
      else{
        // Create a ticket, before that clean the slate
        setName("");
        setPhoneNumber("");
        setContactedDate("");
        setCallRegarding("");
        setCallStatus("");
        setLastMessageDate("");
        setLastmessageType("");
        setMessageHistory("");
      }
      setIsAddInquery(true);
    }
    const handleCloseAddInquery=()=>{
      setIsAddInquery(false);
      setInqueryId("")
      setResponseForInquery("");
      setManagerRatingOnInquery(0)
    }

    const handleCloseDeleteInqueryPopup=()=>{
      setInqueryId("")
      setShowDeleteInqueryPopup(false);
      setResponseForInquery("")
    }
    const handleShowDeleteInqueryPopup=(InqueryId)=>{
      setInqueryId(InqueryId)
      setShowDeleteInqueryPopup(true)
    }

    const deleteInquery=async(e)=>{
      if(e) e.preventDefault()
      try {

        await axios.delete(`${url}/potentialGuests/${InqueryId}`).then((responseForeDeletingInquery)=>{
          // setResponseForInquery("Deleted")
          displayNotificationMessage("warning","Deleted Inquery Ticket Succesfuly");
          handleCloseDeleteInqueryPopup()

        });
        getInquerys();
      } catch (error) {
        console.log(error);
        displayNotificationMessage("error",error.response.data);
        
      }
    }


    const getInqueryById=async(InqueryId)=>{
      try{
      let responseForInqueryById = await axios.get(`${url}/potentialGuests/${InqueryId}`);
      const tempResponseForInqueryId=parseResponseJSON(responseForInqueryById.data);
      console.log(tempResponseForInqueryId[0].managerRatingOnInquery) ;
    //   setRoomNumber(tempResponseForInqueryId[0].roomid);
    //   setDescription(tempResponseForInqueryId[0].description);
    //   setActive(tempResponseForInqueryId[0].active);
    //   setAssignedto(tempResponseForInqueryId[0].assignedto);
    //   setScheduledDate(tempResponseForInqueryId[0].scheduledDate);
    //   setCompletedDate(tempResponseForInqueryId[0].completedDate);
    //   setInqueryStatus(tempResponseForInqueryId[0].status);
    //   setManagerRatingOnInquery(tempResponseForInqueryId[0].managerRatingOnInquery)
    }
    catch(error)
    {
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
  }
  const searchInqueryByStatus=async(targetValue)=>{
    try{
      if(targetValue=="all")
      getInquerys();
      else{
        await axios.post(`${url}/potentialGuests/search`,{
          targetValue
        }).then((responseForFilteringInquery)=>{
          setInquerys(responseForFilteringInquery.data);
          

        });
        
      }
      


    }catch(error){
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
  }
  const handleSortColumn = (sortColumn, sortType) => {
    // alert("dsgfg")
    setLoading(true);
    // setIsAddInquery(true)
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };


    return (
      <div>
        <Header/>
        {/* filter */}
        {/* <Form.Select className="mb-2 mt-2 ms-5 w-25" 
              style={{float:"left"}}
              onChange={(e)=>searchInqueryByStatus(e.target.value)}>
                <option value="all">All Inquerys</option>
                <option value="Incomplete">Incomplete</option>
                <option value="Completed">Completed</option>
             
                <option value="Need management action">Require Management Response</option>
                
                
        </Form.Select> */}
        

        <Form.Select className="mb-2 mt-2 ms-5 w-25" 
              style={{float:"left"}}
              onChange={(e)=>searchInqueryByStatus(e.target.value)}>
                <option value="all">All</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="last 7 days">Last 7 days</option>
                <option value="last 30 days">Last 30 days</option>
                <option value="not called back">Not called back</option>
                <option value="follow up today">follow up today</option>
                <option value="follow up tomorrow">follow up tomorrow</option>
                <option value="follow up this week">follow up this week</option>
                
        </Form.Select>
        <Form.Label className="mt-3 ms-5" style={{fontSize:"20px"}}>Count: <b>{Inquerys ? Inquerys.length : null}</b></Form.Label>
        
        
        <Button className="mt-2 mb-2 me-5" 
        // onMouseMove={(e)=>handleCloseAddInquery()} 
        // onTouchMove={(e)=>handleCloseAddInquery()}
        onClick={()=>handleShowAddInquery(null)} style={{float:"right"}}>Add A Inquery</Button>
        <br></br>

        {isAddInquery  && <AddInquery 
        value={{InqueryId: InqueryId}} getInquerys={getInquerys}
        handleCloseAddInquery={handleCloseAddInquery}
        />}
        {/* {isAddInquery &&  <AddInquery/>} */}



        {/* <Table className="ms-5" style={{width:"95%"}}>
          <thead>
            <tr>
              <th></th>
              <th>Call Id</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Contacted Date</th>
              <th>Inquery Status</th>
              <th>Callback Date</th>
              <th>Call Regarding</th>
              <th>Follow up date</th>
              <th>Last Message Date</th>
              <th>Last Message Type</th>
              <th>Message History</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Inquerys.map((row, index) => (
              <tr key={row.id}>
                <td><Form.Check className="ms-2 mt-2" onChange={(e)=>{
            if(e.target.checked)
            selectedPhoneNumbers.push(row.phoneNumber);
            else{
              const index = selectedPhoneNumbers.indexOf(row.phoneNumber);
              if (index > -1) { // only splice array when item is found
                selectedPhoneNumbers.splice(index, 1); // 2nd parameter means remove one item only
              }
            } }}>
          </Form.Check></td>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.phoneNumber}</td>
                <td>{row.contactedDate}</td>
                <td>{row.callStatus}</td>
                <td>{row.callBackDate}</td>
                <td>{row.message}</td>
                <td>{row.followUpDate}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Link
                  onMouseMove={(e)=>handleCloseAddInquery()}
                    onClick={()=>handleShowAddInquery(row.id)}
                  >
                    Edit
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Link
                    onClick={()=>handleShowDeleteInqueryPopup(row.id)}
                  >
                    Delete
                  </Link>
                  <br></br>
                
                  
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        <br></br>

        <Table className="ms-5"
          autoHeight
          wordWrap="break-word"
          data={getDataSorted(sortColumn,sortType,Inquerys)}
          size="lg"
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
          loading={loading}
          // onClick={()=>setInqueryId(100)}
          // onMouseMove={()=>setInqueryId("")}
        >

      <Column width={40} fixed>
        <HeaderCell><b style={{color:"black"}}></b></HeaderCell>
        <Cell>
          {rowData => (
            <Form.Check className="ms-2" onChange={(e)=>{
              if(e.target.checked)
              selectedPhoneNumbers.push(rowData.phoneNumber);
              else{
                const index = selectedPhoneNumbers.indexOf(rowData.phoneNumber);
                if (index > -1) { // only splice array when item is found
                  selectedPhoneNumbers.splice(index, 1); // 2nd parameter means remove one item only
                }
              } }}>
            </Form.Check>
            
          )}
          </Cell>
      </Column>
          <Column width={80} fixed sortable>
            <HeaderCell><b style={{color:"black"}}>Call Id</b></HeaderCell>
            <Cell dataKey="id">
              {rowData => (
                <Link 
                // onMouseMove={(e)=>handleCloseAddInquery()}
                // onTouchMove={(e)=>handleCloseAddInquery()}
                onClick={()=>handleShowAddInquery(rowData.id)}>
                  {rowData.id}
                </Link>
                
              )}
              </Cell>
          </Column>
          <Column width={140}>
            <HeaderCell><b style={{color:"black"}}>Name</b></HeaderCell>
            <Cell dataKey="name"/>
          </Column>

          <Column width={140} sortable>
            <HeaderCell><b style={{color:"black"}}>Phone Numbers</b></HeaderCell>
            <Cell dataKey="phoneNumber">
              </Cell>
          </Column>

          <Column width={150} sortable>
            <HeaderCell><b style={{color:"black"}}>Contacted Date</b></HeaderCell>
            <Cell dataKey="contactedDate">
            {rowData => (
                <>
                  {rowData.contactedDate ? formatDateText(rowData.contactedDate) : null}
                </>
                
              )}

              </Cell>
          </Column>

          <Column width={140} sortable>
            <HeaderCell><b style={{color:"black"}}>Inquery Status</b></HeaderCell>
            <Cell dataKey="callStatus">
              </Cell>
          </Column>

          <Column width={160} sortable>
            <HeaderCell><b style={{color:"black"}}>Callback Date</b></HeaderCell>
            <Cell dataKey="callBackDate">
            {rowData => (
                <>
                  {rowData.callBackDate ? formatDateText(rowData.callBackDate) : null}
                </>
                
              )}
              </Cell>
          </Column>

          <Column width={240} sortable>
            <HeaderCell><b style={{color:"black"}}>Call Regarding</b></HeaderCell>
            <Cell dataKey="message">
              </Cell>
          </Column>

          <Column width={140} sortable>
            <HeaderCell><b style={{color:"black"}}>Follow up date</b></HeaderCell>
            <Cell dataKey="followUpDate">
            {rowData => (
                <>
                  {rowData.followUpDate ? formatDateText(rowData.followUpDate) : null}
                </>
                
              )}
              
              </Cell>
          </Column>

          <Column width={120}>
            <HeaderCell><b style={{color:"black"}}>Actions</b></HeaderCell>
            <Cell dataKey="followUpDate">
            {row => (
                <>
                  <Link
                  // onMouseMove={(e)=>handleCloseAddInquery()}
                  // onTouchMove={(e)=>handleCloseAddInquery()}
                    onClick={()=>handleShowAddInquery(row.id)}
                  >
                    Edit
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Link
                    onClick={()=>handleShowDeleteInqueryPopup(row.id)}
                  >
                    Delete
                  </Link>
                </>
                
              )}
              
              </Cell>
          </Column>

          

      


        </Table>

        <Modal show={showDeleteInqueryPopup} onHide={handleCloseDeleteInqueryPopup}>
          <Modal.Header style={{fontSize:"large"}} closeButton>
          {responseForInquery ? responseForInquery :"Warning: Are you sure to delete this Inquery?"}</Modal.Header>
          <Modal.Footer>
            <Button onClick={handleCloseDeleteInqueryPopup}>No</Button>
            <Button onClick={(e)=>deleteInquery(e)}>Yes</Button>

          </Modal.Footer>
        </Modal>



      </div>
    );


}

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9"
};