import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import Header from "./Header.js";
import "../App.css"
import { Link, redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {formatDate, parseResponseJSON,dateDifference, getDataSorted, formatDateText} from "./CommonFunctions";
import InputGroup from 'react-bootstrap/InputGroup';
import {Row,Col} from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import CommonComponents from "./CommonComponents.js";
import { Rate , Divider, Badge, Dropdown,Table} from "rsuite";
import { AddRepair } from "./AddRepair.js";
import PageIcon from '@rsuite/icons/Page';
import NewPost from "./AddImage.js"
const { Column, HeaderCell, Cell } = Table;
export const Repairs=()=>{
    const [description,setDescription]=useState("");
    const [assignedto,setAssignedto]=useState("");
    let [repairStatus,setRepairStatus]=useState("Yet to be assigned");
  const [scheduledDate,setScheduledDate]=useState("");
    let [active,setActive]=useState("");
    const [completedDate,setCompletedDate]=useState(null)
    const [roomNumber,setRoomNumber]=useState("");
    const [repairs, setRepairs] = useState([]);
    const [isAddRepair,setIsAddRepair]=useState(false);
    const [isImageUpload,setIsImageUpload] = useState(false);
    const [repairId,setRepairId]=useState("");
    const [responseForRepair,setResponseForRepair]=useState("");
    const [showDeleteRepairPopup, setShowDeleteRepairPopup]=useState(false);
    const [repairStatusFilter, setRepairStatusFilter] =useState("Incomplete")

    const [managerRatingOnRepair, setManagerRatingOnRepair] = useState("");
    const [managerComments,setManagerComments]=useState("");//update in UI and database
    const [allRooms,setAllRooms]=useState([]);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");
    const [loading, setLoading] = useState(false);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const {roomId} = useParams();
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      if(roomId){
        getRepairsByRoomId(roomId);
      }
      else{
      // getRepairs();
      searchRepairByStatus("Incomplete");
      
      }
      getAllRooms();
    }, []);
    // useEffect(() => {
    //   if(roomId){
    //     getRepairsByRoomId(roomId);
    //   }
    //   else{
    //   // getRepairs();
    //   searchRepairByStatus("Incomplete")
    //   }
    //   getAllRooms();
    // }, [roomId]);
   const getAllRooms=async()=>{
    try{
      await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
        console.log(responseForAllRooms.data);
        setAllRooms(responseForAllRooms.data);
      })

    }
    catch(error){
      console.log(error);
    }
   }
   const getRepairsByRoomId = async(roomId)=>{
    try{
      await axios.get(`${url}/repairs/getRepairsByRoomId/${roomId}`).then((responeForGettingRepairsByroomId)=>{
        console.log(responeForGettingRepairsByroomId.data);
        setRepairs(responeForGettingRepairsByroomId.data)
      })

    }
    catch(error){
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
   }
    const getRepairs = async () => {
      try{
      await axios.get(`${url}/repairs`).then((responseForGettingRepairs)=>{
        setRepairs(responseForGettingRepairs.data);
        console.log(responseForGettingRepairs.data)
      });
     
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    };

    // Display pop up
    const handleShowAddRepair=(repairId)=>{
      
      
      // This is when you edit a ticket
      if(repairId){
        setRepairId(repairId);
        getRepairById(repairId); // get details of ticket

      }
      else{
        // Create a ticket, before that clean the slate
        setRoomNumber("");
        setDescription("");
        setActive("");
        setAssignedto("");
        setScheduledDate(null);
        setCompletedDate(null);
        setRepairId("");
        setRepairStatus("");
        setResponseForRepair("");
        setManagerRatingOnRepair(0);
      }
      setIsAddRepair(true);
    }
    const handleAddImage = (repairId) =>{
      setIsImageUpload(true);
      if(repairId){
        setRepairId(repairId)
      }
    }

    const handleCloseAddImage = () =>{
      setIsImageUpload(false);
      // if(repairId){
      //   setRepairId(repairId)
      // }
    }  


    const handleCloseAddRepair=()=>{
      setIsAddRepair(false);
      setRepairId("")
      setResponseForRepair("");
      setManagerRatingOnRepair(0)
    }

    const handleCloseDeleteRepairPopup=()=>{
      setRepairId("")
      setShowDeleteRepairPopup(false);
      setResponseForRepair("")
    }
    const handleShowDeleteRepairPopup=(repairId)=>{
      setRepairId(repairId)
      setShowDeleteRepairPopup(true)
    }

    const deleteRepair=async(e)=>{
      if(e) e.preventDefault()
      try {

        await axios.delete(`${url}/repairs/${repairId}`).then((responseForeDeletingRepair)=>{
          // setResponseForRepair("Deleted")
          displayNotificationMessage("warning","Deleted Repair Ticket Succesfuly");
          handleCloseDeleteRepairPopup()

        });
        getRepairs();
      } catch (error) {
        console.log(error);
        displayNotificationMessage("error",error.response.data);
        
      }
    }
    const editRepairDetails=async(e,repairId)=>{
      if(e) e.preventDefault();
      try{
        var status=repairStatus;
        var roomid=roomNumber;
        // var managerRatingonRepair= managerRatingonRepair==0 ? null : managerRatingonRepair;
        setRepairId(repairId)
        await axios.patch(`${url}/repairs/${repairId}`,{
          roomid,
        description,
        status,
        scheduledDate,
        completedDate,
        active,
        assignedto,
        managerRatingOnRepair,
        managerComments
        }).then((responseForEditRepair)=>{
          console.log( "Repair updated (" + repairId +" ). Status - " + responseForEditRepair.status);
          getRepairs();
          setResponseForRepair("Updated "+ repairId);
          displayNotificationMessage("success","Updated - Ticket Number: " + repairId);
          handleCloseAddRepair();
        })


      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 

      }
    }
    const saveRepairDetails=async(e)=>{
      if(e) e.preventDefault();
      try{
        var status=repairStatus;
        var roomid=roomNumber;
        var completedDate=completedDate ? completedDate : null
        var scheduledDate=scheduledDate ? scheduledDate : null
        var managerRatingOnRepair= managerRatingOnRepair==0 ? null : managerRatingOnRepair
        await axios.post(`${url}/repairs` ,{
        roomid,
        description,
        status,
        scheduledDate,
        completedDate,
        active,
        assignedto,
        managerRatingOnRepair,
        managerComments
      }).then((responseForSavingNewRepair)=>{
        const tempDbResponse=parseResponseJSON(responseForSavingNewRepair.data);
        if(tempDbResponse)
        {
          setRepairId(tempDbResponse.ticketId)
          // getRepairById(tempDbResponse.ticketId)
          setResponseForRepair("Ticket " + tempDbResponse.ticketId + " created ");
          getRepairs();
          displayNotificationMessage("success","Created a new Repair Ticket - Ticket Number: " + tempDbResponse.ticketId);
          handleCloseAddRepair();
        }

      })
      
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }
    const getRepairById=async(repairId)=>{
      try{
      let responseForRepairById = await axios.get(`${url}/repairs/${repairId}`);
      const tempResponseForRepairId=parseResponseJSON(responseForRepairById.data);
      console.log(tempResponseForRepairId[0].managerRatingOnRepair) ;
      setRoomNumber(tempResponseForRepairId[0].roomid);
      setDescription(tempResponseForRepairId[0].description);
      setActive(tempResponseForRepairId[0].active);
      setAssignedto(tempResponseForRepairId[0].assignedto);
      setScheduledDate(tempResponseForRepairId[0].scheduledDate);
      setCompletedDate(tempResponseForRepairId[0].completedDate);
      setRepairStatus(tempResponseForRepairId[0].status);
      setManagerRatingOnRepair(tempResponseForRepairId[0].managerRatingOnRepair)
    }
    catch(error)
    {
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
  }
  const searchRepairByStatus=async(targetValue)=>{
    try{
      if(targetValue=="all")
      getRepairs();
      else{
        await axios.post(`${url}/repairs/searchRepairByStatus`,{
          targetValue
        }).then((responseForSearchingRepairByStatus)=>{
          console.log(responseForSearchingRepairByStatus.data)
          setRepairs(responseForSearchingRepairByStatus.data)
        })
      }
      


    }catch(error){
      console.log(error);
      displayNotificationMessage("error",error.response.data);
    }
  }

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };


    return (
      <div>
        <Header/>
        <Dropdown title={"Filter by Status"} style={{marginLeft:"5%",marginBottom:"1rem",marginTop:"1rem"}} onSelect={(e)=>{setRepairStatusFilter();searchRepairByStatus(e)}}>
            <Dropdown.Item eventKey={"all"}>All</Dropdown.Item>
            <Dropdown.Item eventKey={"Incomplete"} >Incomplete</Dropdown.Item>
            <Dropdown.Item eventKey={"Completed"}>Completed</Dropdown.Item>
            <Dropdown.Item eventKey={"Need management action"}>Need management action</Dropdown.Item>
          </Dropdown>
        {/* <Form.Select className="mb-2 mt-2 ms-5 w-25" 
              style={{float:"left"}}
              onChange={(e)=>searchRepairByStatus(e.target.value)}>
                <option value="all">All Repairs</option>
                <option value="Incomplete">Incomplete</option>
                <option value="Completed">Completed</option>
             
                <option value="Need management action">Require Management Response</option>
                
                
        </Form.Select> */}
        

        {/* <Form.Select className="mb-2 mt-2 ms-5 w-25" 
              style={{float:"left"}}
              onChange={(e)=>searchRepairByStatus(e.target.value)}>
                <option value="all">Any Time</option>
                <option value="Created last 7 days">Created Last 7 days</option>
                <option value="Created last 30 days">Created Last 30 days</option>
                <option value="open more than 3 days">Open More than 3 days</option>
                <option value="open more than 7 days">Open More than 7 days</option>
                <option value="open more than 30 days">Open More than 30 days</option>
                
        </Form.Select> */}

        <Dropdown title="Filter by Room Number" style={{marginLeft:"5%",maxHeight:"200px !important"}}>
        {allRooms.map((row, index) => (
            <Dropdown.Item eventKey={row.id}>
              {/* <Link to={`/repairs/${row.id}`}> */}
              {row.id} 
              {/* </Link> */}
              </Dropdown.Item>
        ))}
          </Dropdown>
        <Form.Label className="mt-3 ms-5" style={{fontSize:"16px"}}>Count: <b>{repairs ? repairs.length : null}</b></Form.Label>
        
        
        <Button className="mt-2 mb-2 me-5" onMouseMove={(e)=>handleCloseAddRepair()} onClick={()=>handleShowAddRepair(null)} style={{float:"right"}}>Add A Repair</Button>

        {isAddRepair  && <AddRepair value={{repairId: repairId}} getRepairs={getRepairs} handleCloseAddRepair={handleCloseAddRepair}/>}
        {isImageUpload && <NewPost value={{repairId:repairId}}/>}
        {/* {isAddRepair &&  <AddRepair/>} */}



        {/* <Table className="ms-5" style={{width:"95%"}}>
          <thead>
            <tr>
              <th>Ticket No.</th>
              <th>RoomId</th>
              <th>Description</th>
              <th>Scheduled At</th>
              <th>Assigned To</th>
              <th>Status</th>
              <th>Created At</th>
              <th>No. of Days Open</th>
              <th>Completed At</th>
              <th>Rating</th>
              <th>Manager Comments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {repairs.map((row, index) => (
              <tr key={row.id}>
                <td><Link
                  onMouseMove={(e)=>handleCloseAddRepair()}
                    onClick={()=>handleShowAddRepair(row.id)}
                  >
                    {row.id}
                  </Link></td>
                <td>{row.roomid}</td>
                <td>{row.description}</td>
                <td>{row.scheduledDate}</td>
                <td>{row.assignedto}</td>
                <td>{row.status}</td>
                <td>{new Date(row.createdAt).toString().substring(0,10)}</td>
                <td>{dateDifference(row.createdAt,row.completedDate ? row.completedDate : new Date())}</td>
                <td>{row.completedDate}</td>
                <td><Rate size="xs" value={row.managerRatingOnRepair} readOnly></Rate></td>
                <td>{row.managerComments}</td>
                <td>
                  <Link
                  onMouseMove={(e)=>handleCloseAddRepair()}
                    onClick={()=>handleShowAddRepair(row.id)}
                  >
                    Edit
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Link
                    onClick={()=>handleShowDeleteRepairPopup(row.id)}
                  >
                    Delete
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Badge content={row.imageCount} size="1em" >  
                  <PageIcon style={{color:"blue",backgroundColor:"#f2f2f5", marginRight: 4, fontSize: '1.3em'}} 
                  onMouseMove={(e)=>setIsImageUpload(false)}
                  onClick={()=>handleAddImage(row.id)}
                  />
                  </Badge>
                  
                  <br></br>
                
                </td>
              </tr>
            ))}
          </tbody>
        </Table> */}

      <Table virtualized
      className="ms-4"
      autoHeight
      wordWrap="break-word"
      data={getDataSorted(sortColumn,sortType,repairs)}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}
      // cellBordered
      >

      <Column width={100} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Ticket No.</b></HeaderCell>
        <Cell dataKey="id">
          {rowData => (
            <Link
            onMouseMove={(e)=>handleCloseAddRepair()}
            onTouchMove={(e)=>handleCloseAddRepair()}
              onClick={()=>handleShowAddRepair(rowData.id)}
            >
              {rowData.id}
            </Link>
            
          )}
          </Cell>
      </Column>
      <Column width={80} sortable>
        <HeaderCell><b>Room</b> </HeaderCell>
        <Cell dataKey="roomid">
        {rowData => (
          <>
            {rowData.alias}
          </>
            
          )}
        
        </Cell>
      </Column>

      

      

      <Column width={150}>
        <HeaderCell><b>Description </b></HeaderCell>
        <Cell dataKey="description" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Scheduled At </b></HeaderCell>
        <Cell dataKey="scheduledDate">
        {rowData => (
            <>
            {rowData.scheduledDate ? formatDateText(rowData.scheduledDate) : ""}
            </>
            
          )}
        
        </Cell>
      </Column>
      <Column width={120} sortable>
        <HeaderCell><b>Assigned To </b></HeaderCell>
        <Cell dataKey="assignedto">
        </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell><b>Status </b></HeaderCell>
        <Cell dataKey="status" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Created At </b></HeaderCell>
        <Cell dataKey="createdAt" >
        {rowData => (
            <>
            {new Date(rowData.createdAt).toString().substring(0,10)}
            </>
            
          )}
        </Cell>
      </Column>

      <Column width={110}>
        <HeaderCell><b>Days Open </b></HeaderCell>
        <Cell dataKey="dateDifference">
        {rowData => (
            <>{
              dateDifference(rowData.createdAt,rowData.completedDate ? rowData.completedDate : new Date()) }</>
            
          )}

        </Cell>
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Completed At </b></HeaderCell>
        <Cell dataKey="completedDate">
        {rowData => (
            <>{rowData.completedDate ? formatDateText(rowData.completedDate) : ""}</>
            
          )}
        </Cell>
      </Column>

      <Column width={120}>
        <HeaderCell><b>Rating</b></HeaderCell>
        <Cell dataKey="createdAt" >
        {rowData => (
            <Rate size="xs" style={{width:"100px"}} value={rowData.managerRatingOnRepair} readOnly></Rate>
            
          )}
        </Cell>
      </Column>

      <Column width={150}>
        <HeaderCell><b>Manager Comments </b></HeaderCell>
        <Cell dataKey="managerComments" />
      </Column>

      <Column width={80} sortable>
        <HeaderCell><b>Actions</b></HeaderCell>
        <Cell>
        {row => (
            <>
            <Link
                  onMouseMove={(e)=>handleCloseAddRepair()}
                  onTouchMove={(e)=>handleCloseAddRepair()}
                    onClick={()=>handleShowAddRepair(row.id)}
                  >
                    Edit
                  </Link>
                  <br></br>
                  {/* <Divider style={{backgroundColor:"grey",margin:"0.5rem 0rem"}}/> */}
                  <Link
                    onClick={()=>handleShowDeleteRepairPopup(row.id)}
                  >
                    Delete
                  </Link>
                  <br></br>
                  {/* <Divider  style={{backgroundColor:"grey",margin:"0.5rem 0rem"}}/> */}
                  <Badge content={row.imageCount ? row.imageCount : 0} size="0.5rem" >  
                  <Link 
                  onMouseMove={(e)=>setIsImageUpload(false)}
                  onTouchMove={(e)=>setIsImageUpload(false)}
                  onClick={()=>handleAddImage(row.id)}
                  >Images </Link>
                  </Badge>
                  
                  <br></br>

            </>
            
          )}

        </Cell>
      </Column>
      

    </Table>
        










        <Modal show={showDeleteRepairPopup} onHide={handleCloseDeleteRepairPopup}>
          <Modal.Header style={{fontSize:"large"}} closeButton>
          {responseForRepair ? responseForRepair :"Warning: Are you sure to delete this Repair?"}</Modal.Header>
          <Modal.Footer>
            <Button onClick={handleCloseDeleteRepairPopup}>No</Button>
            <Button onClick={(e)=>deleteRepair(e)}>Yes</Button>

          </Modal.Footer>
        </Modal>
        



      </div>
    );


}

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9"
  
};