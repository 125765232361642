import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { formatDate } from '../../CommonFunctions'
import CommonComponents from '../../CommonComponents.js'

const InventoryModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [restockData, setRestockData] = useState({
    productId: props.data.selectedRow.productId,
    count: 0,
    restockDate: formatDate(new Date()),
    description: '',
    cost: 0,
  })
  const [useData, setUseData] = useState({
    productId: props.data.selectedRow.productId,
    count: 0,
    usedDate: formatDate(new Date()),
    description: '',
  })
  const url = process.env.REACT_APP_SERVER_URL

  const restockInventory = async () => {
    try {
      await axios
        .post(`${url}/restockInventory`, restockData)
        .then((response) => {
          displayNotificationMessage('success', 'Updated Inventory Stock')
          closeModal()
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data.message)
    }
  }

  const usingInventory = async () => {
    try {
      await axios.post(`${url}/useInventory`, useData).then((response) => {
        displayNotificationMessage('success', 'Updated Inventory Stock')
        closeModal()
      })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data.message)
    }
  }
  const closeModal = () => {
    props.data.setModalInventory(false)
  }
  return (
    <Modal open={props.data.modalInventory} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>
            {props.data.isUse == true ? 'Consume' : 'Restock'} Inventory :{' '}
            {props.data.selectedRow.name}
          </h4>
        </Modal.Title>
      </Modal.Header>
      {props.data.isUse == true ? (
        <>
          <Modal.Body style={{ overflow: 'none' }}>
            <Row>
              <Col>
                <Form.Label>Current Stock</Form.Label>
              </Col>
              <Col>
                <Form.Label>Location</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>{props.data.selectedRow.count}</Form.Label>
              </Col>
              <Col>
                <Form.Label>
                  {props.data.selectedRow.storageLocation}
                </Form.Label>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Consume Count</Form.Label>
              </Col>
              <Col>
                <Form.Label>Date</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Control
                  type="number"
                  value={useData.count}
                  onChange={(e) =>
                    setUseData({
                      ...useData,
                      count: Number(e.target.value),
                    })
                  }
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  type="date"
                  value={useData.usedDate}
                  onChange={(e) =>
                    setUseData({ ...useData, usedDate: e.target.value })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Description</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={useData.description}
                  onChange={(e) =>
                    setUseData({ ...useData, description: e.target.value })
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => usingInventory()} appearance="primary">
              Save
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body style={{ overflow: 'none' }}>
            <Row>
              <Col>
                <Form.Label>Current Stock</Form.Label>
              </Col>
              <Col>
                <Form.Label>Location</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>{props.data.selectedRow.count}</Form.Label>
              </Col>
              <Col>
                <Form.Label>
                  {props.data.selectedRow.storageLocation}
                </Form.Label>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Supplier Links</Form.Label>
              </Col>
              <Col>
                <Form.Label>Request Count</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>{props.data.selectedRow.supplierLinks}</Form.Label>
              </Col>
              <Col>
                <Form.Label>{props.data.selectedRow.request}</Form.Label>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>New Addition</Form.Label>
              </Col>
              <Col>
                <Form.Label>Date</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Control
                  type="number"
                  value={restockData.count}
                  onChange={(e) =>
                    setRestockData({
                      ...restockData,
                      count: Number(e.target.value),
                    })
                  }
                />
              </Col>
              <Col xs={6}>
                <Form.Control
                  type="date"
                  value={restockData.restockDate}
                  onChange={(e) =>
                    setRestockData({
                      ...restockData,
                      restockDate: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Description</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={restockData.description}
                  onChange={(e) =>
                    setRestockData({
                      ...restockData,
                      description: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Label>Cost</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Control
                  type="number"
                  value={restockData.cost}
                  onChange={(e) =>
                    setRestockData({
                      ...restockData,
                      cost: Number(e.target.value),
                    })
                  }
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => restockInventory()} appearance="primary">
              Save
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}
export default InventoryModal
