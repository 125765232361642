import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from 'react'
  import axios from 'axios'
  import {
    Table,
    Popover,
    Whisper,
    Checkbox,
    Dropdown,
    IconButton,
    Progress,
    DatePicker,
    Rate
  } from 'rsuite';
  import { Link } from 'react-router-dom';
  import {Tabs,Tab} from "react-bootstrap"
  import Modal from 'react-bootstrap/Modal';
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button'
  import { Row, Col } from 'react-bootstrap'
  import {
    formatDate,
    parseResponseJSON,
    logMe,
    getDataSorted,
    formatDateText,
    dateDifference
  } from './CommonFunctions'
import { useParams } from 'react-router-dom';
import Header from './Header';
  const { Column, HeaderCell, Cell } = Table;

  const DepositReport = (props) => {
    const url = process.env.REACT_APP_SERVER_URL;
    const [depositDetails,setDepositDetails]=useState([])
    useEffect(() => {
      getAllDeposits();
        
      }, []);
      const getAllDeposits = async() => {
        try{
            await axios.get(`${url}/cashDeposits`).then((responseForDeposits)=>{
                console.log(responseForDeposits.data);
                setDepositDetails(responseForDeposits.data);
            })

        }catch(error){
            console.log(error);
        }
      }

    return (
        <>
        <Header/>
        <div class="container">
        <Table autoHeight data={depositDetails} id="table">

      <Column width={100} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Deposit Id</b></HeaderCell>
        <Cell dataKey="id">
          {rowData => (
            <Link to={`/deposits/${rowData.id}`}
            >
              {rowData.id}
            </Link>
            
          )}
          </Cell>
      </Column>
      <Column width={180} sortable>
        <HeaderCell><b>Deposit Date</b> </HeaderCell>
        <Cell dataKey="depositDate">
        {rowData => (
          <>
            {formatDateText(rowData.depositDate)}
          </>
            
          )}
        
        </Cell>
      </Column>

      

      

      <Column width={150}>
        <HeaderCell><b>Deposited By </b></HeaderCell>
        <Cell dataKey="depositedBy" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Amount</b></HeaderCell>
        <Cell dataKey="amount">
        {rowData => (
            <>
            {'$' + rowData.amount}
            </>
            
          )}
        
        </Cell>
      </Column>
      <Column width={120} sortable>
        <HeaderCell><b>Bank Name </b></HeaderCell>
        <Cell dataKey="bankName">
        </Cell>
      </Column>

      <Column width={240} sortable>
        <HeaderCell><b>Remarks </b></HeaderCell>
        <Cell dataKey="remarks" />
      </Column>

     


     
      

        </Table>
        </div>
        </>

    );
    
}
export default DepositReport;