import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import CommonComponents from '../../CommonComponents.js'

const DeleteVoidModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const url = process.env.REACT_APP_SERVER_URL
  console.log(props.data)
  const voidInventory = async () => {
    if (props.data.isDelete) {
      try {
        await axios
          .patch(`${url}/deleteInventory/${props.data.selectedRow.productId}`)
          .then((response) => {
            closeModal()
          })
      } catch (error) {
        displayNotificationMessage('error', error.response.data)
        console.log(error)
      }
    } else {
      try {
        await axios
          .patch(`${url}/voidInventory/${props.data.selectedRow.productId}`)
          .then((response) => {
            closeModal()
          })
      } catch (error) {
        displayNotificationMessage('error', error.response.data)
        console.log(error)
      }
    }
  }
  const closeModal = () => {
    props.data.setVoidInventory(false)
  }

  return (
    <Modal open={props.data.voidInventory} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>
            {props.data.isDelete ? 'Delete' : 'Discontinue'} Inventory :{' '}
            {props.data.selectedRow.name}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <>
        <Modal.Body style={{ overflow: 'none' }}>
          <Row>
            <Col>
              <Form.Label>
                Are you sure you want to{' '}
                {props.data.isDelete ? 'delete' : 'discontinue'}{' '}
                {props.data.selectedRow.name}?
              </Form.Label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => voidInventory()} appearance="primary">
            Yes
          </Button>
          <Button onClick={() => closeModal()} appearance="primary">
            No
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  )
}
export default DeleteVoidModal
