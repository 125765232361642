import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import $, { fn } from 'jquery'
import { Link, redirect } from 'react-router-dom'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCreditCard,
  faEnvelope,
  faPhone,
  faBuilding,
  faFlag,
  faDollar,
} from '@fortawesome/free-solid-svg-icons'
import { useToaster } from "rsuite";
import { Provider } from './GuestContext'
import SearchDateContext from './GuestContext'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import Table from 'react-bootstrap/Table'
import { useFormInput } from './useFormInput.js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  dateDifference,
} from './CommonFunctions'
import { ManageCustomerInformationComponent } from './Customer'
import { SearchRoomsByDateComponent } from './SearchRoomsByDate'
import TestComponent from './TestComponent'
import { CheckinForm } from './CheckinForm'
import { CheckoutForm } from './CheckoutForm'
import MakePayment from './MakePayment'
import CommonComponents from './CommonComponents.js'
import {
  Rate,
  Message,
  Divider,
  Tag,
  TagGroup,
  Dropdown,
  IconButton,
} from 'rsuite'
import EditIcon from '@rsuite/icons/Edit'
import PageIcon from '@rsuite/icons/Page'
import { ThreeDayNoticeForm } from './ThreeDayNoticeForm.js'
import CustomerReviewModal from './CustomerReviewModal'
import TenantPaymentSummary from './TenantPaymentSummary.js'
import TenancyDetailsByLTTI from './TenancyDetailsByLTTI.js'

export const ManageTenancyInformationComponent = (props) => {
  
  const [roomNumber, setRoomNumber] = useState()
  const [rent, setRent] = useState()
  const [rentalType, setRentalType] = useState('weekly')
  const [numberOfAdults, setNumberOfAdults] = useState(0)
  const [numberOfChildren, setNumberOfChildren] = useState(0)
  const [deposit, setDeposit] = useState(0)
  const [hasPets, setHasPets] = useState(0)
  const [tenancyDescription, setTenancyDescription] = useState('')
  const [actualCheckoutDate, setactualCheckoutDate] = useState()
  const [balanceAmount, setBalanceAmount] = useState(null)
  const [isComponentVisible, setComponentVisibility] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const [customerAction, setCustomerAction] = useState('')
  const [customersDetails, setCustomersDetails] = useState([])
  const [tenancyDetails, setTenancyDetails] = useState({})
  const [responseForTenancy, setResponseForTenancy] = useState('')
  const [isCheckout, setIsCheckout] = useState(false)
  const [isCheckoutAndRenew, setIsCheckoutAndRenew] = useState(false)
  const [isCheckoutAndRenewSuccessful, setIsCheckoutAndRenewSuccessful] =
    useState(false)
  const [isChangeRoom, setIsChangeRoom] = useState(false)
  const [isCheckinFormVisible, setIsCheckinFormVisible] = useState(false)
  const [isThreeDayNoticeFormVisible, setIsThreeDayNoticeFormVisible] =
    useState(false)
  const [isCheckoutFormVisible, setIsCheckoutFormVisible] = useState(false)
  const [isMakePaymentComponentVisible, setIsMakePaymentComponentVisible] =
    useState(false)
  const [
    isPaymentSummaryComponentVisible,
    setIsPaymentSummaryComponentVisible,
  ] = useState(false);
  const [showTenancyDetailsByLTTIModal,setShowTenancyDetailsByLTTIModal]= useState(false)
  const [status, setStatus] = useState('')
  const [numberOfDays, setNumberOfDays] = useState('')
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [isOldTenancy, setIsOldTenancy] = useState(false)
  const [multipleTenancyIds, setMultipleTenancyIds] = useState([])
  const [editFields, setEditFields] = useState(false)
  const [paidAmount, setPaidAmount] = useState(null)
  const [showReview, setShowReview] = useState(false)
  const [currentTenancyPaymentDetails, setCurrentTenancyPaymentDetails] =
    useState([]) //only
  const [latestRoomStatus, setLatestRoomStatus] = useState('')
  const [lastActualCheckoutDate, setLastActualCheckoutDate] = useState()
  const [vacancyTrackingId, setVacancyTrackingId] = useState('')
  var tempDate
  const [vacantRooms, setVacantRooms] = useState([])
  const navigate = useNavigate()
  const url = process.env.REACT_APP_SERVER_URL
  //shared elements in the context
  const {
    searchDate,
    vacantRoomNumber,
    rentedRoomNumber,
    searchStartDate,
    searchEndDate,
    tenancyId,
    setValueSearchEndDate,
    setValueSearchStartDate,
    setValueTenancyId,
    setValueSearchDate,
    setValueRentedRoomNumber,
    setValueVacantRoomNumber,
  } = useContext(SearchDateContext)
  const [checkin, setCheckin] = useState(searchStartDate)
  const [checkout, setCheckout] = useState(formatDate(searchEndDate))
  const [firstArrivalDate, setFirstArrivalDate] = useState(searchStartDate)
  const [guestQuality, setGuestQuality] = useState(0)
  const [longTermTenancyId, setLongTermTenancyId] = useState(null)
  let today = formatDate(new Date())
  const location = useLocation()
  // const data = location.state;

  const { id } = useParams()
  const queryParams = new URLSearchParams(location.search)

  //props that are sent to child component ManageCustomerInformationComponent
  const compoundVisibilityCallback = {
    isComponentVisible,
    customerId,
    customerAction,
  }

  const propsForCheckinForm = {
    isCheckinFormVisible,
    tenancyDetails,
    customersDetails,
  }

  const propsForCheckoutForm = {
    isCheckoutFormVisible,
    tenancyDetails,
    customersDetails,
  }
  // tenancyId itself is d=sufficient --Component Independence
  // --So send only tenancy Id and retrieve the details itself in the payment Component
  const propsForMakePayment = {
    tenancyDetails,
    customersDetails,
    tenancyId,
  }
  const propsForPaymentSummary = {
    longTermTenancyId,
  }

  //that excecutes whenever there is a change in the props of parents
  //--simply to say: whenever there is a change in tenancyId
  useEffect(() => {
    if (!id) {
      console.log(
        'vacantRoomNumber : ' +
          vacantRoomNumber +
          'rentedRoomNumber : ' +
          rentedRoomNumber
      )
      if (vacantRoomNumber == '') {
        getTenancyIdAndCustomerDetailsOfRentedRoom()
        if (tenancyId) {
          getBalanceDueByTenancyId(tenancyId)
          getPaymentByTenancyId(tenancyId)
        }
      } else if (rentedRoomNumber == '') {
        getTenancyIdAndCustomerDetailsOfVacantRoom()
        setBalanceAmount(null)
      }

      setIsCheckinFormVisible(false)
      setIsCheckoutFormVisible(false)
      setResponseForTenancy('')
    }
  }, [props])

  useEffect(() => {
    //   if(location.state){
    //     console.log("location.state " + location.state.tenancyId)
    //   setValueTenancyId(location.state.tenancyId)
    // getTenancyDetailsByTenancyId(location.state.tenancyId);

    //   }
    if (queryParams.size != 0) {
      console.log('Have params in  the URL : ' + queryParams)
      console.log('Checkin: ' + queryParams.get('checkin'))
      console.log('checkout: ' + queryParams.get('checkout'))
      setCheckin(queryParams.get('checkin'))
      setCheckout(queryParams.get('checkout'))
      setValueVacantRoomNumber(queryParams.get('roomNumber'))
      setRoomNumber(queryParams.get('roomNumber'))
      setRent(getRentByRoomNumber(queryParams.get('roomNumber')))
      if (queryParams.get('roomNumber')) {
        getVacancyTrackingIdByRoomNumber(queryParams.get('roomNumber'))
      }
      // getRoomReadinessStatus(queryParams.get('roomNumber'));
    }

    if (id) {
      setValueTenancyId(id)

      getTenancyDetailsByTenancyId(id)

      getCustomersByTenancyId(id)
      getBalanceDueByTenancyId(id)
      getPaymentByTenancyId(id)

      console.log(
        ' in useEffect of params id : ' +
          id +
          'vacantRoomNumber ' +
          vacantRoomNumber
      )
    }

    setStateValuesByTenancyDetails()
    setResponseForTenancy('')
    setIsCheckoutAndRenewSuccessful(false)
  }, [id])
  useEffect(() => {
    console.log('in UseEffect change of tenancyId ' + tenancyId)
  }, [tenancyId])

  const getVacantRoomsByDate = async (e, searchStartDate, searchEndDate) => {
    //  if(e)
    //  e.preventDefault();
    try {
      if (searchStartDate == null || searchEndDate == null) {
        alert('Select Both searchStartDate and searchEndDate Dates')
        return
      }

      await axios
        .post(`${url}/vRoomsByDate`, {
          searchStartDate,
          searchEndDate,
        })
        .then((responseForVacantRooms) => {
          console.log(responseForVacantRooms.data)
          setVacantRooms(responseForVacantRooms.data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getTenancyIdAndCustomerDetailsOfVacantRoom = async (e) => {
    if (e) e.preventDefault()
    try {
      if (vacantRoomNumber) {
        const responseForTenancyOfVacantRoom = await axios.get(
          `${url}/getTenancyDraftDetails/${vacantRoomNumber}`
        )
        console.log(responseForTenancyOfVacantRoom.data)
        setMultipleTenancyIds(responseForTenancyOfVacantRoom.data)
        const tempInfo = parseResponseJSON(
          responseForTenancyOfVacantRoom.data[0]
        )
        if (tempInfo) {
          const tempId = tempInfo.id
          getCustomersByTenancyId(tempId)
          setValueTenancyId(tempId)
          getTenancyDetailsByTenancyId(tempId)
        } else {
          setValueTenancyId('')
          setCustomersDetails([])
          setTenancyDetails({})
          setStateValuesByTenancyDetails()
          setRoomNumber(vacantRoomNumber)
        }
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const getTenancyIdAndCustomerDetailsOfRentedRoom = async (e) => {
    if (e) e.preventDefault()
    try {
      if (rentedRoomNumber) {
        await axios
          .post(`${url}/cdetails/${rentedRoomNumber}`, {
            searchStartDate,
            searchEndDate,
          })
          .then((responseForTenancyOfRentedRoom) => {
            console.log(
              'Getting ALl tenants information in the seleted room Range'
            )
            console.log(responseForTenancyOfRentedRoom.data)
            setMultipleTenancyIds(responseForTenancyOfRentedRoom.data)
            setValueTenancyId('')
            //   const tempInfo=parseResponseJSON(responseForTenancyOfRentedRoom.data[0]);
            // if(tempInfo){
            //   logMe("getTenancyIdAndCustomerDetailsOfRentedRoom: tempInfo " ,tempInfo )
            //   const tempId=tempInfo.id;
            //   getCustomersByTenancyId(tempId);
            //   setValueTenancyId(tempId);
            //   getTenancyDetailsByTenancyId(tempId)

            // }
            // else{
            //   setValueTenancyId("");
            //   setCustomersDetails([])
            //   setTenancyDetails({})
            //   setStateValuesByTenancyDetails();
            // }
          })
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const getVacancyTrackingIdByRoomNumber = async (roomId) => {
    try {
      await axios
        .get(`${url}/vacancyTracking/getVacancyTrackingIdByroomId/${roomId}`)
        .then((responseForVacancyTrackingId) => {
          console.log(responseForVacancyTrackingId.data[0])
          // setVacancyTrackingId(responseForVacancyTrackingId.data[0].latestVacancyTrackingId);
          if (responseForVacancyTrackingId.data.length > 0) {
            setVacancyTrackingId(
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId
            )
            getRoomReadinessStatus(
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId
            )
            getlastActualCheckoutDate(
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId
            )
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getRoomReadinessStatus = async (vacancyTrackingId) => {
    try {
      await axios
        .get(
          `${url}/vacancyActivity/getLatestStatusByVacancyTrackingId/${vacancyTrackingId}`
        )
        .then((responseForCurrentRoomStatus) => {
          console.log(responseForCurrentRoomStatus.data)
          // alert(responseForCurrentRoomStatus.data[0].currentStatus)
          if (responseForCurrentRoomStatus.data.length > 0)
            setLatestRoomStatus(
              responseForCurrentRoomStatus.data[0].currentStatus
            )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const getlastActualCheckoutDate = async (vacancyTrackingId) => {
    try {
      await axios
        .get(`${url}/vacancyTracking/actualCheckoutDate/${vacancyTrackingId}`)
        .then((responseForCurrentRoomStatus) => {
          console.log(responseForCurrentRoomStatus.data)
          // alert(responseForCurrentRoomStatus.data[0].currentStatus)
          if (responseForCurrentRoomStatus.data.length > 0)
            setLastActualCheckoutDate(
              responseForCurrentRoomStatus.data[0].actualCheckoutDate
            )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const setLongTermTenancy = (firstArrivalDate, longTermTenancyId) => {
    try {
      if (firstArrivalDate) {
        setFirstArrivalDate(firstArrivalDate)
      } else {
        setFirstArrivalDate(checkin)
      }
      if (longTermTenancyId) {
        setLongTermTenancyId(longTermTenancyId)
      } else {
        //FIXME: create a new LongTermTenancyId
        nextLongTermTenancyId()
        logMe('setLongTermTenancy', 'Creating a new Long Term Tenancy')
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const nextLongTermTenancyId = async () => {
    try {
      var tempLTTI = null
      await axios
        .get(`${url}/nextLongTermTenancyId`)
        .then((responseForNextLTTI) => {
          console.log(responseForNextLTTI.data[0])
          const tempDbResponse = responseForNextLTTI.data[0]

          if (tempDbResponse) {
            setLongTermTenancyId(tempDbResponse.nextLongTermTenancyId)
            // alert(tempDbResponse.nextLongTermTenancyId)
            tempLTTI = tempDbResponse.nextLongTermTenancyId
          } else {
            displayNotificationMessage(
              'error',
              'Could not set long term tenancy Id'
            )
          }
        })
      return tempLTTI
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const getPrevTenancyFirstArrivalDateAndLongTermTenancyId = async () => {
    try {
      //alert("getPrevTenancyFirstArrivalDateAndLongTermTenancyId  - Room Number: " + roomNumber + "vacantRoomNumber - " + vacantRoomNumber)
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const getLongTermTenancyIdAndDate = async () => {
    if (isOldTenancy) {
      //  const response= await
      getPrevTenancyFirstArrivalDateAndLongTermTenancyId()
      // console.log(response)
      return firstArrivalDate
    } else {
      // FIXME : CHeck for no LTTI and create a new one
      setLongTermTenancy(checkin, null)
      return checkin
    }
  }
  const setIsOldTenancyChange = async (isOldTenancy) => {
    try {
      setIsOldTenancy(isOldTenancy)
      if (isOldTenancy) {
        var tid = tenancyId ? tenancyId : 0
        await axios
          .get(`${url}/prevArrivalDate/${roomNumber}/${tid}`)
          .then((responseForPrevTenancy) => {
            console.log('getPrevTenancyFirstArrivalDateAndLongTermTenancyId ')
            console.log(responseForPrevTenancy.data[0])
            const tempDbResponse = responseForPrevTenancy.data[0]
            if (tempDbResponse) {
              setLongTermTenancy(
                tempDbResponse.firstArrivalDate,
                tempDbResponse.longTermTenancyId
              )
              // return responseForPrevTenancy.data[0];
            } else {
              getTenancyDetailsByTenancyId(tenancyId)
            }
            // else{
            //   displayNotificationMessage("error","Could not retrieve the prev tenancy record");
            // }
          })
      } else {
        setLongTermTenancy(checkin, null)
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  // const createVacancyActivity = async(nextTenancyId,currentStatus,activityLog,roomId)
  //Saving the tenancyDetails--create a new record in DB
  const saveTenancyDetails = async (e, status) => {
    if (e) e.preventDefault()
    try {
      if (!vacantRoomNumber) {
        alert('Should Select the room Number')
        return
      }
      var rate = rent
      var tempId = 0
      // var status=null;
      const tempJsonResponse = await getLongTermTenancyIdAndDate()
      var adults = numberOfAdults
      var children = numberOfChildren
      var roomid = vacantRoomNumber
      if (!rent) {
        displayNotificationMessage('error', 'Please Enter the rent')
        return
      }
      if (longTermTenancyId == null) {
        await nextLongTermTenancyId().then(async (response) => {
          var longTermTenancyId = response
          await axios
            .post(`${url}/tenants`, {
              roomid,
              checkin,
              firstArrivalDate,
              checkout,
              rate,
              adults,
              children,
              deposit,
              rentalType,
              status,
              hasPets,
              tenancyDescription,
              guestQuality,
              longTermTenancyId,
            })
            .then(async (responseForSaveTenancy) => {
              const tempDbResponse = parseResponseJSON(
                responseForSaveTenancy.data
              )
              console.log(tempDbResponse)
              if (tempDbResponse) {
                setValueTenancyId(tempDbResponse.data) // Setting Global context tentnacyID

                // FIXMENEED IT OR NOT
                // getTenancyDetailsByTenancyId(tempDbResponse.data)

                if (status == 'active') {
                  setValueRentedRoomNumber(vacantRoomNumber)
                  navigate(`/tenants/add/${tempDbResponse.data}`)
                  // await createVacancyActivity(tempDbResponse.data,"checked in","Guest Checkedin",roomNumber);
                  // displayNotificationMessage("success","Checked-In  Room Number - " + roomid);
                } else {
                  displayNotificationMessage(
                    'success',
                    'Saved Draft Tenancy Details for Room Number - ' + roomid
                  )
                }

                tempId = tempDbResponse.data
              }
            })
        })
      } else {
        await axios
          .post(`${url}/tenants`, {
            roomid,
            checkin,
            firstArrivalDate,
            checkout,
            rate,
            adults,
            children,
            deposit,
            rentalType,
            status,
            hasPets,
            tenancyDescription,
            guestQuality,
            longTermTenancyId,
          })
          .then((responseForSaveTenancy) => {
            const tempDbResponse = parseResponseJSON(
              responseForSaveTenancy.data
            )
            console.log(tempDbResponse)
            if (tempDbResponse) {
              setValueTenancyId(tempDbResponse.data) // Setting Global context tentnacyID

              // FIXMENEED IT OR NOT
              // getTenancyDetailsByTenancyId(tempDbResponse.data)

              if (status == 'active') {
                setValueRentedRoomNumber(vacantRoomNumber)
                navigate(`/tenants/add/${tempDbResponse.data}`)
                // displayNotificationMessage("success","Checked-In  Room Number - " + roomid);
              } else {
                displayNotificationMessage(
                  'success',
                  'Saved Draft Tenancy Details for Room Number - ' + roomid
                )
              }

              tempId = tempDbResponse.data
            }
          })
      }
      return tempId
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //Updating the tenancyDetails of given tenancyId
  const editTenacyDetails = async (e, status) => {
    if (e) e.preventDefault()
    try {
      // if(!roomNumber){
      //   return;
      // }
      var rate = rent
      // var status="active";
      // var firstArrivalDate=getLongTermTenancyIdAndDate();

      // var arrival=checkin;
      var adults = numberOfAdults
      var children = numberOfChildren

      await axios
        .patch(`${url}/tenants/${tenancyId}`, {
          checkin,
          firstArrivalDate,
          checkout,
          rate,
          adults,
          children,
          deposit,
          rentalType,
          status,
          hasPets,
          tenancyDescription,
          guestQuality,
          longTermTenancyId,
        })
        .then((responseForEditTenancy) => {
          console.log(
            'Tenancy updated (' +
              tenancyId +
              ' ). Status - ' +
              responseForEditTenancy.status
          )
          if (status == 'active') {
            setValueRentedRoomNumber(roomNumber)
            navigate(`/tenants/add/${tenancyId}`)

            // const tempTenancyId=tenancyId;
            // setValueTenancyId(undefined);
            // setValueTenancyId(tempTenancyId)
            // setResponseForTenancy("CheckedIn")
          }
          displayNotificationMessage(
            'success',
            'Updated Tenancy Details for Room Number - ' + roomNumber
          )
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //deleting the tenancy Details by tenancyId
  const deleteTenacyDetails = async (e) => {
    e.preventDefault()
    try {
      deleteTenancyCustomerRelation(e)
      await axios
        .delete(`${url}/tenants/${tenancyId}`)
        .then((responseForDeleteTenancy) => {
          console.log(
            'Tenancy Deleted for ID (' +
              tenancyId +
              ' ). Status - ' +
              responseForDeleteTenancy.status
          )
          setResponseForTenancy('deleted the tenancy Details')
        })
      setCustomersDetails([])
      setValueTenancyId('')
      setTenancyDetails({})
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //get the details of customers associated with the tenancyId
  const getCustomersByTenancyId = (tId) => {
    // if(e) e.preventDefault();
    try {
      if (tenancyId || tId) {
        var tempTenancyId = tId ? tId : tenancyId
        axios
          .get(`${url}/tenants/${tempTenancyId}/customers`)
          .then((responseForGettingCustomersByTenancyId) => {
            console.log(responseForGettingCustomersByTenancyId.data)
            setCustomersDetails(responseForGettingCustomersByTenancyId.data)
          })
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //get the tenancy details by tenancyId
  const getTenancyDetailsByTenancyId = (tenancyId) => {
    try {
      axios
        .get(`${url}/tenants/${tenancyId}`)
        .then((responseTenancyDetails) => {
          console.log(responseTenancyDetails.data[0])
          setTenancyDetails(responseTenancyDetails.data[0])
          setStateValuesByTenancyDetails(responseTenancyDetails.data[0])
          if (location.state) {
            setValueSearchDate(responseTenancyDetails.data[0].checkin)
            setValueRentedRoomNumber(responseTenancyDetails.data[0].roomid)
          }
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  //insert into tenant_customer relation
  const saveTenancyCustomerRelation = async (e, customerId, tId) => {
    if (e) e.preventDefault()
    try {
      //If No tenancy Id then Create one
      var tenantId = tId ? tId : tenancyId
      logMe('saveTenancyCustomerRelation', 'tenancy Id: ' + tenancyId)
      var customerid = customerId
      console.log(
        'Adding a customer to Draft CustomerId:' +
          customerId +
          ' TenancyId: ' +
          tenancyId
      )
      // alert("in saveTenancyCustomerRelation customerid: " + customerid);//2 exceuted 2 times --customerid second time getting null--that null is passign to database
      await axios.post(`${url}/tenants/addRelation`, {
        tenantId,
        customerid,
      })
      getCustomersByTenancyId()
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  // FIXME : Alert customer is any error happened. Handle exception
  //delete from tenant_customer relation
  const deleteTenancyCustomerRelation = async (e, customerId) => {
    if (e) e.preventDefault()
    try {
      //removing the relation when we delete customer by both customerId and tenancyId
      if (customerId) {
        await axios
          .delete(`${url}/tenants/${tenancyId}/customers/${customerId}`)
          .then((responseForDeleteTenancyCustomerRelation) => {
            console.log(
              'Tenancy-Customer Relation Deleted (' +
                tenancyId +
                ' ) (' +
                customerId +
                '). Status - ' +
                responseForDeleteTenancyCustomerRelation.status
            )
          })
        getCustomersByTenancyId()
      }
      //removing all records-relations associated with this tenancyId
      else {
        await axios
          .delete(`${url}/tenants/${tenancyId}/deleteRelation`)
          .then((responseForDeleteTenancyCustomerRelation) => {
            console.log(
              'Tenancy-Customer Relation Deleted (' +
                tenancyId +
                ' ). Status - ' +
                responseForDeleteTenancyCustomerRelation.status
            )
          })
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const checkoutTenancy = async (e, actualCheckoutDate) => {
    if (e) e.preventDefault()
    try {
      // var actualCheckoutDate=new Date();
      var status = 'checked-out'
      await axios
        .patch(`${url}/tenants/${tenancyId}`, {
          actualCheckoutDate,
          status,
        })
        .then(async (responseForCheckout) => {
          setResponseForTenancy('Checked out')
          setValueVacantRoomNumber(roomNumber)
          setValueSearchStartDate(new Date())
          setValueSearchEndDate(new Date())
          getTenancyDetailsByTenancyId(tenancyId)
          setIsCheckout(false)
          //creating vacancyTrackingId
          const previousTenancyId = tenancyId
          const checkoutDate = checkout
          const roomId = roomNumber
          const nextTenancyId = null
          // FIX_VT ..update if found active customers
          const responseForVacancyTrackingId= await axios
          .get(`${url}/vacancyTracking/getVacancyTrackingIdByroomId/${roomId}`) ;
          if(responseForVacancyTrackingId.data.length===0){ //not found any
          await axios
            .post(`${url}/vacancyTracking`, {
              checkoutDate,
              actualCheckoutDate,
              previousTenancyId,
              roomId,
              nextTenancyId,
            })
            .then(async (responseForVacancyTrackingId) => {
              console.log(responseForVacancyTrackingId.data)
              const tempDbResponse = parseResponseJSON(
                responseForVacancyTrackingId.data
              )
              console.log(tempDbResponse)
              let vacancyTrackingId
              if (tempDbResponse) {
                vacancyTrackingId = tempDbResponse.data
              }
              if (vacancyTrackingId) {
                const currentStatus = 'needs cleaning'
                const activityLog = 'Guest checked-out and room needs cleaning'
                const roomId = roomNumber
                const responsiblePerson = 'system generated'
                await axios
                  .post(`${url}/vacancyActivity`, {
                    vacancyTrackingId,
                    roomId,
                    currentStatus,
                    activityLog,
                    responsiblePerson,
                  })
                  .then((responseForVacancyActivity) => {
                    console.log(responseForVacancyActivity.data)
                  })
              }
            })
          }
          else{
            console.log(responseForVacancyTrackingId.data[0])
          const vacancyTrackingId = responseForVacancyTrackingId.data[0].latestVacancyTrackingId;
            await axios
              .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
                actualCheckoutDate
              })

          }
          
        })
      
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const checkoutAndRenewTenancy = async (e, tempCheckout) => {
    if (e) e.preventDefault()
    try {
      //check Function whether there is tenancy is next selected period

      var actualCheckoutDate = tempCheckout

      var status = 'checked-out'

      // var firstArrivalDate=tempCheckout;

      console.log('Checkin ' + checkin + '\t  Checkout: ' + checkout)

      var checkin = tempCheckout
      var checkout = formatDate(new Date(tempCheckout), 8)
      // alert(checkin + 8)

      console.log(numberOfDays)
      if (numberOfDays == 'weekly') {
        checkout = formatDate(new Date(tempCheckout), 8)
        // console.log(formatDate(new Date(tempCheckout),9));
      } else if (numberOfDays == 'daily') {
        checkout = formatDate(new Date(tempCheckout), 2)
        // console.log(formatDate(new Date(tempCheckout),3));
      }
      else if (numberOfDays == 'monthly') {
        checkout = formatDate(new Date(tempCheckout), 31)
        // console.log(formatDate(new Date(tempCheckout),3));
      }
      const rentalType=numberOfDays;
      //checking
      await axios
        .post(`${url}/tenants/checkRenewAvailability`, {
          roomNumber,
          checkin,
          checkout,
          rentalType
        })
        .then(async(responseForRenewAvail) => {
          if(responseForRenewAvail.data && responseForRenewAvail.data.length>0){
            displayNotificationMessage(
              'warning',
              `Already tenancy Exists`,
              `/tenants/add/${responseForRenewAvail.data[0].id}`
            )
            

          }
          else{
            await axios.patch(`${url}/tenants/${tenancyId}`, {
              actualCheckoutDate,
              status,
            })
            console.log('After Checkout Tenancy')
            var rate = rent
            status = 'active'
            var adults = numberOfAdults
            var children = numberOfChildren
            var roomid = roomNumber
            var tempTenancyId
            await axios
              .post(`${url}/tenants`, {
                roomid,
                checkin,
                firstArrivalDate,
                checkout,
                rate,
                adults,
                children,
                deposit,
                rentalType,
                status,
                hasPets,
                tenancyDescription,
                longTermTenancyId,
              })
              .then((responseForSaveTenancy) => {
                const tempDbResponse = parseResponseJSON(responseForSaveTenancy.data)
                console.log(tempDbResponse)
                if (tempDbResponse) {
                  // setValueTenancyId(tempDbResponse.data);
                  tempTenancyId = tempDbResponse.data
                  if (tempTenancyId) {
                    for (var i = 0; i < customersDetails.length; i++) {
                      console.log(customersDetails[i].customerId)
                      saveTenancyCustomerRelation(
                        e,
                        customersDetails[i].customerId,
                        tempTenancyId
                      )
                    }
      
                    // setValueTenancyId(tempTenancyId);
                    // getTenancyDetailsByTenancyId(tempTenancyId);
                    displayNotificationMessage(
                      'success',
                      'Checked out , Renewed and Checked In'
                    )
                    setIsCheckoutAndRenew(false)
                    setIsCheckoutAndRenewSuccessful(true)
                    // setValueSearchStartDate(checkin);
                    // setValueSearchEndDate(checkout);
                    setIsOldTenancy(true)
                    navigate(`/tenants/add/${tempTenancyId}`)
                  }
                }
              })
          }
        })
      
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const getRentByRoomNumber = async (roomNumber) => {
    try {
      await axios
        .get(`${url}/getRent/${roomNumber}`)
        .then((responseForRent) => {
          console.log(responseForRent.data[0].weeklyRate)
          return responseForRent.data[0].weeklyRate
        })
      return 400
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const getBalanceDueByTenancyId = async (tenancyId) => {
    // if(e) e.preventDefault();
    try {
      await axios
        .get(`${url}/paymentDue/${tenancyId}`)
        .then((responseForBalanceDue) => {
          if (responseForBalanceDue.data.length) {
            setPaidAmount(responseForBalanceDue.data[0].paidAmount)
            setBalanceAmount(
              responseForBalanceDue.data[0].paidAmount -
                responseForBalanceDue.data[0].totalRent
            )
            console.log(responseForBalanceDue.data[0].totalRent)
          }
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  //no ltti: Only getting payments for this tenancy Period
  const getPaymentByTenancyId = async (tenancyId) => {
    // if(e) e.preventDefault();
    try {
      await axios
        .get(`${url}/paymentHistory/${tenancyId}`)
        .then((responseForPayments) => {
          if (responseForPayments.data.length) {
            setCurrentTenancyPaymentDetails(responseForPayments.data)
            // setPaidAmount(responseForBalanceDue.data[0].paidAmount);
            // setBalanceAmount(responseForBalanceDue.data[0].paidAmount-responseForBalanceDue.data[0].totalRent);
            // console.log(responseForBalanceDue.data[0].totalRent);
            console.log(responseForPayments.data)
          }
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const changeRooms = async (e) => {
    if (e) e.preventDefault()
    try {
      var roomid = roomNumber
      console.log(roomNumber)
      await axios
        .patch(`${url}/tenants/${tenancyId}`, {
          roomid,
        })
        .then((responseForChangingRooms) => {
          console.log(responseForChangingRooms)
          setValueRentedRoomNumber(roomNumber)
          setIsChangeRoom(false)
        })
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  const checkinTenancy = async (e, tenancyId) => {
    if (e) e.preventDefault()
    try {
      let nextTenancyId = tenancyId
      // let dummyValue=false;
      if (tenancyId) {
        editTenacyDetails(e, 'active')
        // dummyValue=true;
      } else {
        await saveTenancyDetails(e, 'active').then((response) => {
          console.log(response)
          nextTenancyId = response
        })
        // dummyValue=true;
      }
      if (nextTenancyId) {
        const roomId = roomNumber

        await axios
          .get(`${url}/vacancyTracking/getVacancyTrackingIdByroomId/${roomId}`) 
          .then(async (responseForVacancyTrackingId) => {
            console.log(responseForVacancyTrackingId.data[0])
            const vacancyTrackingId =
              responseForVacancyTrackingId.data[0].latestVacancyTrackingId;
              // FIX_VT.....update status along
              const status="done";
            await axios
              .patch(`${url}/vacancyTracking/${vacancyTrackingId}`, {
                nextTenancyId,
                checkin,
                status
              })
              .then((response) => {
                // displayNotificationMessage("success","Inspected date saved");
                console.log(response.data)
              })

            const currentStatus = 'checked-in'
            const activityLog = 'Guest checkedin'
            await axios
              .post(`${url}/vacancyActivity`, {
                vacancyTrackingId,
                roomId,
                currentStatus,
                activityLog,
              })
              .then((responseForVacancyActivity) => {
                console.log(responseForVacancyActivity.data)
              })
          })
      }

      displayNotificationMessage(
        'success',
        'Checked-In room Number - ' + roomNumber
      )
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const saveTenancy = async (e, tenancyId) => {
    if (e) e.preventDefault()
    try {
      if (tenancyId) {
        editTenacyDetails(e, status)
      } else {
        saveTenancyDetails(e, 'draft')
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const handleClick = (customerId, action) => {
    setCustomerId(customerId)
    setCustomerAction(action)
    setComponentVisibility(true)
    setIsCheckinFormVisible(false)
  }
  const handleClose = () => {
    setCustomerId(null)
    setCustomerAction('')
    setComponentVisibility(false)
  }

  const handleCheckinFormVisible = () => {
    setIsCheckinFormVisible(true)
    setIsCheckoutAndRenewSuccessful(false)
  }
  const handleCloseCheckinForm = () => {
    setIsCheckinFormVisible(false)
  }
  const handleCheckoutFormVisible = () => {
    setIsCheckoutFormVisible(true)
  }
  const handleCloseCheckoutForm = () => {
    setIsCheckoutFormVisible(false)
  }
  const TenancyInputValue = () => {
    // FIXME: Split it into new Component
    return (
      <>
        <Table striped>
          {/* <Row className="mt-4">
            <Col sm style={{float:"right"}} className="mt-2">Checkin Date:</Col>
            <Col sm style={{float:"left"}}>
              <Form.Control
                type="date"
                value={checkin} 
                style={{backgroundColor:"#edfae1"}}
                onChange={(e)=>
                  {
                    setCheckin(e.target.value);

                    // FIXME : When the date is picked, based on the rental type, date should change
                    if(rentalType=="weekly"){
                      setCheckout(formatDate(new Date(e.target.value),8))
                      
                    }
                    else if(rentalType=="daily"){
                      setCheckout(formatDate(new Date(e.target.value),2))
                    }
                  }
                }
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm className="mt-2 ms-2">Rental Type:</Col>
            <Col sm style={{float:"left"}}>
              <Form.Select className="me-2" size="sm" style={{height:"38px"}}
                value= { rentalType }  onChange={(e)=>{
                  // setValueVacantRoomNumber("");
                  setRentalType(e.target.value);
                  if(e.target.value=="weekly"){
                    setCheckout(formatDate(new Date(checkin),8))
                    
                  }
                  else if(e.target.value=="daily"){
                    setCheckout(formatDate(new Date(checkin),2))
                  }

                
                }}
              >
              <option value="weekly"> Weekly</option>
              <option value="daily"> Daily</option>
              </Form.Select>
            </Col>
            <Col sm>Checkout Date:</Col>
            <Col sm>
              <Form.Control
                type="date"
                value={checkout}  
                style={{backgroundColor:"#edfae1"}}
                onChange={(e)=>setCheckout(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>Pets</Col>
            <Col sm>
              <Form.Control
                type="number"
                min="0"
                value={hasPets}
                onChange={(e)=>setHasPets(e.target.value)} 
                aria-describedby="basic-addon1"
                
              />
            </Col>
          </Row> */}
          <Row>
            <Col>Rent: </Col>
            <Col>Deposit: </Col>
            <Col>Adults: </Col>
            <Col>Children: </Col>
            <Col>Pets: </Col>
            <Col></Col>
            <Col>Notes</Col>
          </Row>
          <Row>
            <Col sm>
              <Form.Control
                type="number"
                min="0"
                value={rent}
                onChange={(e) => setRent(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                type="number"
                min="0"
                value={deposit}
                onChange={(e) => setDeposit(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                className="me-5"
                type="number"
                min="0"
                value={numberOfAdults}
                onChange={(e) => setNumberOfAdults(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                className="me-5 w-15"
                type="number"
                min="0"
                value={numberOfChildren}
                onChange={(e) => setNumberOfChildren(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col sm>
              <Form.Control
                type="number"
                min="0"
                value={hasPets}
                onChange={(e) => setHasPets(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
            <Col></Col>
            <Col>
              <textarea
                value={tenancyDescription}
                onChange={(e) => setTenancyDescription(e.target.value)}
              ></textarea>
            </Col>
          </Row>
          {/* <Row className="mt-4 mb-4">
            <Col sm>Rent:</Col>
            <Col sm>
              <Form.Control
                type="number"
                min="0"
                value={rent}
                onChange={(e)=>setRent(e.target.value)} 
                aria-describedby="basic-addon1"
                
              />
            </Col>
            <Col sm>Deposit</Col>
            <Col sm>
              <Form.Control
                type="number"
                min="0" 
                value={deposit}
                onChange={(e) => {setDeposit(e.target.value);
                } }
                aria-describedby="basic-addon1"
              />
            </Col>

            <Col sm>Adults:</Col>
            <Col sm>
              <Form.Control
                className="me-5"
                type="number"
                min="0" 
                value={numberOfAdults}
                onChange={(e) => setNumberOfAdults(e.target.value)}
                aria-describedby="basic-addon1"
              />

            </Col>
            <Col sm>Children:</Col>
            <Col sm>
              <Form.Control
                className="me-5 w-15"
                type="number"
                min="0" 
                value={numberOfChildren}
                onChange={(e) => setNumberOfChildren(e.target.value)} 
                aria-describedby="basic-addon1"
              />

            </Col>

          </Row> */}
        </Table>

        {/* <DatePicker showIcon selected={checkin} onChange={(e) => setCheckin(e.target.value)} /> */}
      </>
    )
  }

  const setStateValuesByTenancyDetails = (tenancyDetails) => {
    if (tenancyDetails) {
      setRoomNumber(tenancyDetails.roomid)
      setCheckin(tenancyDetails.checkin)
      setFirstArrivalDate(tenancyDetails.firstArrivalDate)
      setCheckout(tenancyDetails.checkout)
      setRent(tenancyDetails.rate)
      setDeposit(tenancyDetails.deposit)
      setNumberOfAdults(tenancyDetails.adults)
      setNumberOfChildren(tenancyDetails.children)
      setRentalType(tenancyDetails.rentalType)
      setactualCheckoutDate(tenancyDetails.actualCheckoutDate)
      setHasPets(tenancyDetails.hasPets)
      setTenancyDescription(tenancyDetails.tenancyDescription)
      setStatus(tenancyDetails.status)
      setGuestQuality(tenancyDetails.guestQuality)
      setLongTermTenancyId(tenancyDetails.longTermTenancyId)
    } else {
      setRoomNumber('')
      setCheckin(searchDate)
      setFirstArrivalDate(searchDate)
      setCheckout(formatDate(new Date(searchDate), 7))
      setRent(400)
      setDeposit(0)
      setNumberOfAdults(0)
      setNumberOfChildren(0)
      setRentalType('weekly')
      setHasPets(0)
      setTenancyDescription('')
      setGuestQuality(0)
      setLongTermTenancyId(null)
    }
  }

  const handleFormDropDown = (targetForm) => {
    if (targetForm === 'checkin') {
      setIsCheckinFormVisible(true)
      setIsCheckoutAndRenewSuccessful(false)
    } else if (targetForm === 'threeDayNotice') {
      setIsThreeDayNoticeFormVisible(true)
    }
  }

  const editIconStyles = {
    color: 'blue',
    backgroundColor: '#f2f2f5',
    marginRight: 4,
    fontSize: '2em',
  }
  //Rendering
  return (
    <>
      <Table style={{ backgroundColor: '#f2f2f5' }}>
        <Row style={{ paddingTop: '1%', marginBottom: '0.6%' }}>
          <Col className="ms-4" sm>
            Room{' '}
          </Col>
          {status.length === 0 || status === 'draft' ? (
            <Col sm>
              Room Status
              <Link
                to={`/vacancyActivity/${roomNumber}`}
                style={{ backgroundColor: 'rgb(242, 242, 245)' }}
              >
                <EditIcon
                  style={{
                    color: 'blue',
                    backgroundColor: '#f2f2f5',
                    marginRight: 4,
                    fontSize: '1.2em',
                  }}
                  // onClick={(e)=>navigate('/vacancyActivity/${roomNumber}')}
                />
              </Link>
              <Link
                to={`/VacancyActivityReport/${vacancyTrackingId}`}
                style={{ backgroundColor: 'rgb(242, 242, 245)' }}
              >
                <PageIcon
                  style={{
                    color: 'blue',
                    backgroundColor: '#f2f2f5',
                    marginRight: 4,
                    fontSize: '1.2em',
                  }}
                  // onClick={(e)=>navigate('/vacancyActivity/${roomNumber}')}
                />
              </Link>
            </Col>
          ) : null}
          <Col sm>Booking ID </Col>
          <Col sm>Checkin Date</Col>
          <Col sm>Checkout Date </Col>
          <Col sm></Col>
          {id ? (
            <>
              <Col sm>Payment</Col>
              <Col sm>Payment to date</Col>
              <Col sm>Balance </Col>
              <Col sm>Forms</Col>
            </>
          ) : null}
        </Row>
        {}
        <Row
          style={{
            paddingBottom: '1%',
            fontWeight: 'bolder',
            fontSize: '1rem',
          }}
        >
          <Col className="ms-4" sm>
            {roomNumber} ({status.length === 0 ? 'Vacant' : status})
          </Col>
          {status.length === 0 || status === 'draft' ? (
            <Col sm>
              {latestRoomStatus} <br></br>{' '}
              {dateDifference(new Date(), lastActualCheckoutDate)} days of
              vacancy{' '}
            </Col>
          ) : null}
          <Col sm>{tenancyId}</Col>
          {editFields == false && (
            <Col sm>
              {checkin}{' '}
              <EditIcon
                style={editIconStyles}
                onClick={(e) => setEditFields(true)}
              />
            </Col>
          )}
          {editFields == true && (
            <Col sm>
              <Form.Control
                type="date"
                value={checkin}
                style={{ backgroundColor: '#edfae1' }}
                onChange={(e) => {
                  setCheckin(e.target.value)

                  // FIXME : When the date is picked, based on the rental type, date should change
                  if (rentalType == 'weekly') {
                    setCheckout(formatDate(new Date(e.target.value), 7))
                  } else if (rentalType == 'daily') {
                    setCheckout(formatDate(new Date(e.target.value), 1))
                  }
                }}
                aria-describedby="basic-addon1"
              />
            </Col>
          )}
          {editFields == false && (
            <Col sm>
              {actualCheckoutDate ? actualCheckoutDate : checkout}
              <EditIcon
                style={editIconStyles}
                onClick={(e) => setEditFields(true)}
              />{' '}
            </Col>
          )}
          {editFields == true && (
            <Col sm>
              <Form.Control
                type="date"
                value={checkout}
                min={checkin}
                style={{ backgroundColor: '#edfae1' }}
                onChange={(e) => setCheckout(e.target.value)}
                aria-describedby="basic-addon1"
              />
            </Col>
          )}

          <Col sm>
            {dateDifference(
              checkin,
              actualCheckoutDate ? actualCheckoutDate : checkout
            )}{' '}
            days
          </Col>
          {id ? (
            <>
              <Col>
                {currentTenancyPaymentDetails.map((row, index) => (
                  <>
                    {index == 0 ? null : ','}$
                    {row.cashAmount + row.creditCardAmount + row.otherAmount}
                  </>
                ))}
              </Col>

              <Col sm>${paidAmount}</Col>
              {/* Fix me:  balanceAmount > 0 ?  : balanceAmount<0 ? " Excess": "exact"*/}
              <Col sm>
                $
                {balanceAmount < 0 ? (
                  <span style={{ color: 'red' }}>{balanceAmount}</span>
                ) : (
                  balanceAmount
                )}
              </Col>
              <Col sm style={{ padding: '0', margin: '0' }}>
                {/* <PageIcon style={editIconStyles} onClick={handleCheckinFormVisible} /> */}
                <Dropdown
                  style={{ padding: '0' }}
                  renderToggle={renderIconButton}
                  // title="Forms"
                  onSelect={(e) => handleFormDropDown(e)}
                >
                  <Dropdown.Item eventKey={'checkin'}>
                    Checkin form
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={'threeDayNotice'}>
                    Three day notice form
                  </Dropdown.Item>
                </Dropdown>
              </Col>
            </>
          ) : null}
        </Row>
      </Table>
      <div class="grid-container">
        <div>
          <>
            <Row
              style={{
                backgroundColor: 'aliceblue',
                color: 'black',
                paddingTop: '1%',
                fontWeight: 'bolder',
                fontSize: '1.1rem',
                paddingBottom: '1%',
                paddingLeft: '5%',
                marginLeft: '5%',
                marginRight: '5%',
              }}
            >
              Guest Details{' '}
              <Col
                style={{
                  marginLeft: '2%',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                <Link
                  onMouseMove={handleClose}
                  onTouchMove={handleClose}
                  onClick={() => handleClick(null, 'create')}
                >
                  {' '}
                  Add{' '}
                </Link>
              </Col>
            </Row>

            <div class="container" id="container" style={{ width: '90%' }}>
              {customersDetails.length === 0 ? null : (
                <Table className="table is-striped is-fullwidth">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customersDetails.map((c, index) => (
                      <tr>
                        <td>{c.fname + ' ' + c.lname}</td>
                        <td>{c.email}</td>
                        <td>{c.phone}</td>
                        <td>{c.address + ' - ' + c.state}</td>

                        <td>
                          <Link
                            onMouseMove={handleClose}
                            onTouchMove={handleClose}
                            onClick={() => handleClick(c.customerId, 'update')}
                          >
                            Edit
                          </Link>

                          <Divider
                            vertical
                            style={{ backgroundColor: 'grey' }}
                          />

                          <Link
                            onMouseMove={handleClose}
                            onTouchMove={handleClose}
                            onClick={() => handleClick(c.customerId, 'delete')}
                          >
                            Delete
                          </Link>

                          <Divider
                            vertical
                            style={{ backgroundColor: 'grey' }}
                          />

                          {c.reviewCount == '0' ? (
                            <span>No Reviews</span>
                          ) : (
                            <a
                              style={{
                                cursor: 'pointer',
                                background: 'none',
                                color: 'blue',
                              }}
                              onClick={(e) => {
                                setCustomerId(c.customerId)
                                setShowReview(true)
                              }}
                            >
                              {'[' + c.reviewCount + '] '} Reviews
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {showReview && (
                <CustomerReviewModal
                  data={{ showReview, setShowReview, customerId }}
                ></CustomerReviewModal>
              )}
            </div>
          </>
        </div>

        <div style={{ height: '180%', display: 'block' }}>
          <>
            {vacantRoomNumber ? (
              <>
                <Button
                  className="primary-btn"
                  onClick={(e) => checkinTenancy(e, tenancyId)}
                >
                  Checkin
                </Button>
                <br></br>

                {tenancyId ? (
                  <>
                    <Button
                      className="primary-btn"
                      onClick={(e) => deleteTenacyDetails(e)}
                    >
                      Clear Draft
                    </Button>
                    <br />
                  </>
                ) : null}
              </>
            ) : (
              <>
                <>
                  <Button
                    className="primary-btn"
                    onClick={(e) => {
                      setIsCheckout(true)
                    }}
                  >
                    Checkout Now
                  </Button>
                  <br />

                  <Button
                    className="primary-btn"
                    onClick={(e) => {
                      setIsCheckoutAndRenew(true)
                      setactualCheckoutDate(checkout)
                    }}
                  >
                    {' '}
                    Renew
                  </Button>
                  <br />

                  <Button
                    className="primary-btn"
                    onClick={(e) => {
                      navigate(`/tenants/${id}/guestReviews`)
                    }}
                  >
                    Detailed Review
                  </Button>
                  <br />

                  {/* <td><Button className="primary-btn" onMouseUp={handleCloseCheckinForm} onClick={handleCheckinFormVisible}> Forms </Button></td> */}
                </>
              </>
            )}
            {vacantRoomNumber || rentedRoomNumber || roomNumber ? (
              <>
                <Button
                  className="primary-btn"
                  onClick={(e) => {
                    saveTenancy(e, tenancyId)
                    // if (id) window.location.reload()
                  }}
                >
                  Save
                </Button>
                <br />

                <Button
                  className="primary-btn"
                  onClick={(e) => {
                    getVacantRoomsByDate(null, checkin, checkout)
                    setIsChangeRoom(true)
                  }}
                >
                  Change Rooms
                </Button>
                <br />

                <Button
                  className="primary-btn"
                  onMouseUp={() => setIsMakePaymentComponentVisible(false)}
                  onTouchMove={() => setIsMakePaymentComponentVisible(false)}
                  onClick={() => setIsMakePaymentComponentVisible(true)}
                >
                  Take Payment
                </Button>
                <br />
              </>
            ) : null}
          </>
          <li>
            <Link to={`/tenants/${id}/allReviews`}>All Reviews</Link>
            <br></br>
          </li>
          <li>
            <Link onClick={(e)=>{
                setShowTenancyDetailsByLTTIModal(true);
            }}
            // to={`/tenantPaymentSummary/${id}/`}
            >Payment Summary</Link>
          </li>
          {showTenancyDetailsByLTTIModal && <TenancyDetailsByLTTI
        longTermTenancyId={longTermTenancyId}
        showTenancyDetailsByLTTIModal={showTenancyDetailsByLTTIModal}
        setShowTenancyDetailsByLTTIModal={setShowTenancyDetailsByLTTIModal}
        />}
        </div>
        <div>
          <>
            <Row
              style={{
                backgroundColor: 'aliceblue',
                color: 'black',
                paddingTop: '1%',
                fontWeight: 'bolder',
                fontSize: '1.1rem',
                paddingBottom: '1%',
                paddingLeft: '5%',
                marginLeft: '5%',
                marginRight: '5%',
              }}
            >
              <Col style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                <Form.Check
                  label="Guest renewed"
                  checked={isOldTenancy}
                  onChange={(e) => setIsOldTenancyChange(!isOldTenancy)}
                ></Form.Check>
              </Col>
              <Col>
                {roomNumber ? (
                  <Rate
                    value={guestQuality}
                    defaultValue={0}
                    size="xs"
                    onChange={(value) => setGuestQuality(value)}
                  />
                ) : null}
              </Col>

              <Col style={{ fontWeight: '1px', fontSize: '1rem' }}>
                First Arrival Date :
                {editFields == false && (
                  <>
                    {firstArrivalDate}
                    <EditIcon
                      style={editIconStyles}
                      size="sm"
                      onClick={(e) => setEditFields(true)}
                    />
                  </>
                )}
                {editFields == true && (
                  <Form.Control
                    type="date"
                    value={firstArrivalDate}
                    style={{ backgroundColor: '#edfae1' }}
                    onChange={(e) => setFirstArrivalDate(e.target.value)}
                    aria-describedby="basic-addon1"
                  />
                )}
              </Col>
            </Row>
            <Row></Row>
            <div
              class="container"
              id="container"
              style={{
                width: '90%',
                paddingTop: '1%',
                paddingBottom: '1%',
                marginLeft: '5%',
                marginRight: '5%',
              }}
            >
              <Table className="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Rent</th>
                    <th>Deposit</th>
                    <th>Adults</th>
                    <th>Children</th>
                    <th>Pets</th>
                    <th>Description / Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Col sm>
                        <Form.Control
                          type="number"
                          min="0"
                          value={rent}
                          onChange={(e) => setRent(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </Col>
                    </td>
                    <td>
                      <Col sm>
                        <Form.Control
                          type="number"
                          min="0"
                          value={deposit}
                          onChange={(e) => setDeposit(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </Col>
                    </td>
                    <td>
                      <Col sm>
                        <Form.Control
                          className="me-5"
                          type="number"
                          min="0"
                          value={numberOfAdults}
                          onChange={(e) => setNumberOfAdults(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </Col>
                    </td>
                    <td>
                      <Col sm>
                        <Form.Control
                          className="me-5 w-15"
                          type="number"
                          min="0"
                          value={numberOfChildren}
                          onChange={(e) => setNumberOfChildren(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </Col>
                    </td>
                    <td>
                      <Col sm>
                        <Form.Control
                          type="number"
                          min="0"
                          value={hasPets}
                          onChange={(e) => setHasPets(e.target.value)}
                          aria-describedby="basic-addon1"
                        />
                      </Col>
                    </td>
                    <td width="40%">
                      <textarea
                        maxLength={250}
                        value={tenancyDescription}
                        onChange={(e) => setTenancyDescription(e.target.value)}
                      ></textarea>{' '}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        </div>
      </div>

      {/* <Table className="table is-striped is-fullwidth">
        <thead>
          <tr>
         <th>Tenancy Id</th>
         <th>Checkin</th>
         <th>Checkout</th>
         <th>Names</th>
         <th>Status</th>
         </tr>
         </thead>
         <tbody>
         { multipleTenancyIds.map((row, index) => (
          <tr>
          <td><a onClick={(e)=>{
              // setValueTenancyId(row.id);
              // navigate(`/tenants/add/${row.id}`, { state:{ tenancyId: row.id} })
              setValueTenancyId(row.id);
              getCustomersByTenancyId(row.id);
              getTenancyDetailsByTenancyId(row.id);
            } }>
            <Link>
              {row.id}
            </Link>
            </a></td>
          <td>{row.checkin}</td>
          <td>{row.checkout}</td>
          <td></td>
          <td>{row.status}</td>
          </tr>
          ))}
         </tbody>

        </Table> */}
      {/* <h5>Tenancy details {"(Id: "+ tenancyId+" ) " + responseForTenancy}</h5> 
      {rentedRoomNumber ? 
        actualCheckoutDate  ? <h5 style={{color:"green"}}>Checked-out Successfully</h5> 
                            : checkin> today ? <h5 style={{color:"red"}}>Yet to Checkin</h5> 
                            : checkout< today ? <h5 style={{color:"red"}}>Overstay</h5>  :null
      : null}
      {vacantRoomNumber && tenancyId ? <h5 style={{color:"red"}}>Saved: Reservation On Hold</h5> : null}
       */}
      {/* isCheckoutAndRenewSuccessful */}
      {isCheckoutAndRenewSuccessful && (
        <Message className="fs-5" showIcon type="success" closable>
          New Lease is created with new checkin and checkout dates. Please sign
          the checkin forms and collect the payment.
        </Message>
      )}
      <div id="tenancy-management">
        {/* contains all the tenancy Details inputs */}
        {/* <TagGroup>
    <Tag size="lg" style={{backgroundColor:"lightgrey",color:"black",paddingLeft:0,paddingRight:100}}><b>(vacant)</b> </Tag>
    </TagGroup> */}

        {/* FIXME seperate Function */}

        {/* #007bff */}
        {/* <label className="ms-5 mb-2 fs-5 mt-1" >

            { tenancyId ? 
              vacantRoomNumber ?
               <b> Draft</b> 

              : actualCheckoutDate ? 
                  actualCheckoutDate<today ?

                    <b style={{color:"grey"}}> Guests Checked-out on {actualCheckoutDate}</b> 

                  : <b style={{color:"green"}}> Guests scheduled to check-out on {actualCheckoutDate}</b>

                : checkout<today ? 

                    <b style={{color:"red"}}>Over-Stayed</b> 

                  :<b style={{color:"green"}}>Active Guests</b>

            : <b>Vacant</b>}</label> */}
        {/* <b>{ responseForTenancy ? " ( " + responseForTenancy + " )" : null}</b> */}

        <Row></Row>

        {/* <Form.Select>
            <option>Same as Last tenant</option>
            <option>New Tenant</option>
          </Form.Select> */}

        {/* {TenancyInputValue()} */}

        {/* Displaying the customer Details in UI */}

        {/* <Row>
      <Col xs={6} md={4}><Button className="primary-btn" variant="primary" onMouseMove={handleClose} 
                          onClick={()=>handleClick(null,"create")}>Add Guest</Button>
        </Col>
          {vacantRoomNumber ? 
          <>
          <Col xs={3} md={2}>
            <Button className="primary-btn" onClick={(e)=>checkinTenancy(e,tenancyId)}>Checkin</Button>
          </Col>
          {tenancyId ? <Col xs={6} md={4}><Button className="primary-btn" onClick={(e)=>deleteTenacyDetails(e)}>Clear Draft</Button></Col>  : null}
          </> :
          <>
          <Col xs={6} md={4}>
            <Button className="primary-btn" onClick={(e)=>{setIsCheckout(true)}}>Checkout Now</Button>
          </Col>
          <Col xs={6} md={4}>
            <Button className="primary-btn" onClick={(e)=>{setIsCheckoutAndRenew(true);
                                  setactualCheckoutDate(checkout);}}
            > Renew</Button>
          </Col>
          <Col xs={6} md={4}>
            <Button className="primary-btn" onMouseUp={handleCloseCheckinForm} onClick={handleCheckinFormVisible}> Forms </Button>
          </Col>

          </>}
          {vacantRoomNumber || rentedRoomNumber  || roomNumber? 
            <>
             <Col xs={6} md={4}><Button className="primary-btn" onClick={(e)=>saveTenancy(e,tenancyId)}>Save</Button></Col>
            <Col xs={6} md={4}><Button className="primary-btn" onClick={(e)=>setIsChangeRoom(true)}>Change Rooms</Button></Col>
            <Col xs={6} md={4}><Button className="primary-btn" onMouseUp={()=>setIsMakePaymentComponentVisible(false)} onClick={()=>setIsMakePaymentComponentVisible(true)}>Make Payment</Button></Col>
            </> : null } 

        </Row> */}

        {isMakePaymentComponentVisible && (
          <MakePayment
            value={propsForMakePayment}
            getBalanceDueByTenancyId={getBalanceDueByTenancyId}
          />
        )}

        {/* {isPaymentSummaryComponentVisible && <TenantPaymentSummary value={propsForPaymentSummary} 
      setIsPaymentSummaryComponentVisible={setIsPaymentSummaryComponentVisible} /> } */}

        {isComponentVisible && (
          <ManageCustomerInformationComponent
            value={compoundVisibilityCallback}
            getCustomersByTenancyId={getCustomersByTenancyId}
            saveTenancyCustomerRelation={saveTenancyCustomerRelation}
            deleteTenancyCustomerRelation={deleteTenancyCustomerRelation}
            saveTenancyDetails={saveTenancyDetails}
          />
        )}

        {isThreeDayNoticeFormVisible && (
          <ThreeDayNoticeForm
            value={propsForCheckinForm}
            setIsThreeDayNoticeFormVisible={setIsThreeDayNoticeFormVisible}
          />
        )}

        {/* Buttons to provide rented room functionalities */}

        {/* Checkout Model to display on checkout */}
        {(tempDate = actualCheckoutDate ? null : null)}
        <Modal show={isCheckout}>
          <Modal.Body>
            <h4> Have the Tenant moved out? </h4>

            <Form.Control
              type="date"
              value={actualCheckoutDate ? actualCheckoutDate : checkout}
              min={checkin}
              style={{ backgroundColor: '#edfae1' }}
              onChange={(e) => setactualCheckoutDate(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCheckout(false)
                if (!responseForTenancy) setactualCheckoutDate(tempDate)
              }}
            >
              No and Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              onClick={(e) => {
                checkoutTenancy(
                  e,
                  actualCheckoutDate ? actualCheckoutDate : checkout
                )
              }}
            >
              Yes and Check Out
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Checkout And Renew Modal */}
        <Modal show={isCheckoutAndRenew}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Label>Number of Days Extension: </Form.Label>
              </Col>
              <Col>
                <Form.Label>Rent: </Form.Label>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Select
                  className="h-100"
                  value={numberOfDays}
                  onChange={(e) => {
                    setNumberOfDays(e.target.value)
                  }}
                  aria-describedby="basic-addon1"
                >
                  <option value="weekly">Weekly</option>
                  <option value="daily">Daily</option>
                  <option value="monthly">Monthly</option>
                </Form.Select>
              </Col>

              <Col>
                <Form.Control
                  type="number"
                  min="0"
                  value={rent}
                  onChange={(e) => setRent(e.target.value)}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <Message showIcon type="info" closable>
              Tenancy is renewed for other week starting from {checkout} to{' '} 
              {numberOfDays == 'daily'
                ? formatDate(new Date(checkout), 1)
                : numberOfDays == 'monthly' ? formatDate(new Date(checkout), 31)
                : formatDate(new Date(checkout), 7)}
            </Message>

            <br></br>

            {/* <h5>Tenancy Details</h5>
        {TenancyInputValue()} */}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsCheckoutAndRenew(false)
                getTenancyDetailsByTenancyId(tenancyId)
              }}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={(e) => {
                checkoutAndRenewTenancy(e, checkout)
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Model to siplay on changeRooms */}
        <Modal show={isChangeRoom}>
          <Modal.Body>
            <h4>We are in ChangeRoom</h4>

            <Form.Select
              className="w-75"
              size="sm"
              value={roomNumber}
              onChange={(e) => {
                setRoomNumber(e.target.value)
              }}
            >
              <option value=""></option>
              {vacantRooms.map((row, index) => (
                <option value={row.id}>{row.id}</option>
              ))}
            </Form.Select>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsChangeRoom(false)}>
              No
            </Button>

            <Button variant="primary" onClick={(e) => changeRooms(e)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {isCheckinFormVisible && (
          <CheckinForm
            value={propsForCheckinForm}
            setIsCheckinFormVisible={setIsCheckinFormVisible}
          />
        )}
        {isCheckoutFormVisible && (
          <CheckoutForm
            value={propsForCheckoutForm}
            setIsCheckoutFormVisible={setIsCheckoutFormVisible}
          />
        )}
      </div>
    </>
  )
}

const renderIconButton = (props, ref) => {
  return <IconButton {...props} ref={ref} icon={<PageIcon />} color="grey" />
}
