import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import '../../App.css'
import { formatDate, parseResponseJSON } from './../CommonFunctions'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import CommonComponents from './../CommonComponents.js'
const PaymentViewModal = (props) => {
  const [paymentData, setPaymentData] = useState({
    roomid: null,
    receiptNumber: null,
    cashAmount: null,
    paymentDate: null,
    otherAmount: null,
    creditCardAmount: null,
    paidBy: null,
    paidByOthers: null,
  })
  const [customersDetails, setCustomersDetails] = useState([])
  const { displayNotificationMessage } = useContext(CommonComponents)
  const url = process.env.REACT_APP_SERVER_URL

  const handleClosePaymentModal = () => {
    props.data.setShowPayment(false)
    // setTenancyId('')
    // setPaymentId('')
    // setTenancyDetails({})
  }

  useEffect(() => {
    getPaymentDetailsByPaymentId(props.data.selectedRow.pid)
    getCustomerDetailsByTenantId(props.data.selectedRow.tid)
  }, [])

  const getCustomerDetailsByTenantId = async (id) => {
    try {
      if (id) {
        await axios.get(`${url}/tenants/${id}/customers`).then((response) => {
          setPaymentData({
            ...paymentData,
            roomid: response.data[0]['roomid'],
          })
          setCustomersDetails(response.data)
        })
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }

  const getPaymentDetailsByPaymentId = async (paymentId) => {
    // if(e) e.preventDefault();
    try {
      if (paymentId) {
        await axios.get(`${url}/payments/${paymentId}`).then((response) => {
          setPaymentData({
            ...paymentData,
            creditCardAmount: response.data[0]['creditCardAmount'],
            cashAmount: response.data[0]['cashAmount'],
            otherAmount: response.data[0]['otherAmount'],
            receiptNumber: response.data[0]['receiptNumber'],
            paidBy: response.data[0]['paidBy'],
            paidByOthers: response.data[0]['paidBy'],
            paymentDate: formatDate(response.data[0]['paymentDate']),
          })
          // setStateValues(responseForGettingPaymentDetailsByPaymentId.data[0])
        })
      }
    } catch (error) {
      console.log(error)
      displayNotificationMessage('error', error.response.data)
    }
  }
  return (
    <>
      <Modal show={props.data.showPayment} onHide={handleClosePaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Payment Details: {props.data.selectedRow.tid}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <b>Room Number: </b>
            </Col>
            <Col>
              <b>Tenancy Period: </b>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>{paymentData.roomid}</Col>
            <Col>
              {props.data.selectedRow.checkin}
              {' - '}
              {props.data.selectedRow.checkout}
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <b>Reciept Number</b>
            </Col>
            <Col>
              <b>Actual Payment Date</b>
            </Col>
          </Row>
          <Form validated={true}>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  disabled
                  value={paymentData.receiptNumber}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col>
                <Form.Control
                  type="date"
                  disabled
                  value={paymentData.paymentDate}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <b>Cash Amount:</b>
              </Col>
              <Col>
                <b>Card Amount: </b>
              </Col>
              <Col>
                <b>Other Amount: </b>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Control
                  type="number"
                  min="0"
                  disabled
                  value={paymentData.cashAmount}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min="0"
                  disabled
                  value={paymentData.creditCardAmount}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  min="0"
                  disabled
                  value={paymentData.otherAmount}
                  aria-describedby="basic-addon1"
                />
              </Col>
            </Row>
            <br></br>
            <b>Paid By: </b>
            <Form.Select className="h-75 mt-2" disabled>
              <option></option>
              {customersDetails &&
                customersDetails.map((row, index) => (
                  <option value={row.fname + ' ' + row.lname}>
                    {row.fname + ' ' + row.lname}
                  </option>
                ))}
              ;
            </Form.Select>
            <br></br>
            <b>If paid by others- Name:</b>
            <Form.Control disabled value={paymentData.paidByOthers} required />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePaymentModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default PaymentViewModal
