import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { formatDate } from '../CommonFunctions'
import CommonComponents from '../CommonComponents.js'

const ConfigureModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [configureData, setConfigureData] = useState({
    lastDate: formatDate(new Date()),
    additionalAmt: null,
    cashboxId: null,
  })
  const url = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    getCashBox()
  }, [])

  const getCashBox = async () => {
    try {
      await axios.get(`${url}/cashBox`).then((response) => {
        const data = response.data[0]
        console.log(configureData)
        setConfigureData({
          lastDate: data.paymentDate,
          additionalAmt: data.totalAmount,
          cashboxId: data.cashboxId,
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  const resetCashBox = async () => {
    try {
      await axios
        .patch(`${url}/updateCashbox/${configureData.cashboxId}`, configureData)
        .then((response) => {
          props.data.setUpdateCashbox(true)
          closeModal()
        })
    } catch (error) {
      displayNotificationMessage('error', error.response.data)
      console.log(error)
    }
  }

  const createCashBox = async () => {
    try {
      await axios
        .post(`${url}/createCashbox`, configureData)
        .then((response) => {
          props.data.setUpdateCashbox(true)
          closeModal()
        })
    } catch (error) {
      displayNotificationMessage('error', error.response.data)
      console.log(error)
    }
  }

  const closeModal = () => {
    props.data.setShowConfigure(false)
  }
  return (
    <Modal open={props.data.showConfigure} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>
            {null == configureData.cashboxId ? (
              <>Create CashBox</>
            ) : (
              <>Edit Settings</>
            )}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'none' }}>
        <Row>
          <Col>
            <Form.Label>Date</Form.Label>
          </Col>
          <Col>
            <Form.Label>Additional Cash Box</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="date"
              value={configureData.lastDate}
              onChange={(e) =>
                setConfigureData({
                  ...configureData,
                  lastDate: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              type="number"
              value={configureData.additionalAmt}
              onChange={(e) =>
                setConfigureData({
                  ...configureData,
                  additionalAmt: e.target.value,
                })
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {configureData.cashboxId == null ? (
          <>
            <Button onClick={() => createCashBox()} appearance="primary">
              Create CashBox
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => resetCashBox()} appearance="primary">
              Reset CashBox
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}
export default ConfigureModal
