import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import Header from "./Header1.js";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css"
import { faHand } from "@fortawesome/free-solid-svg-icons";
import {ModalBody, Table,Modal,Form,Button,Tabs,Tab} from "react-bootstrap"
import {formatDate, parseResponseJSON, dateDifference} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";

import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
const AddInquery = (props) => {
  
    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [phoneNumber,setPhoneNumber]=useState(null);
    const [contactedDate,setContactedDate]=useState(formatDate(new Date()));
    const [message,setMessage]=useState("");
    const [callStatus,setCallStatus]=useState("Yet to Call");
    const [callBackDate,setCallBackDate]=useState(formatDate(new Date()));
    const [followUpDate,setFollowUpDate]=useState(formatDate(new Date()));
    const [remarks,setRemarks]=useState(null);
    const [showAddInqueryPopup,setShowAddInqueryPopup]=useState(false);
    const [responseForSms,setResponseForSms]=useState("");
    const [InqueryId,setInqueryId] = useState("");
    const {displayNotificationMessage}=useContext(CommonComponents);
    const url=process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate();
    const texty='{ "Name" : "James Matthews" , "Phone" : 8632020735 , "Email": "matthews1958@msn.com" , "Message":"Rates for monthly stay 1 adult"}'
    useEffect(() => {
       console.log("in AddInquery")
       setShowAddInqueryPopup(true);
       if(props.value && props.value.InqueryId){
        setInqueryId(props.value.InqueryId);
        getInqueryById(props.value.InqueryId);
        
      }
      
       },[]);

       const getInqueryById=async(InqueryId)=>{
        try{
        let responseForInqueryById = await axios.get(`${url}/potentialGuests/${InqueryId}`);
        const tempResponseForInqueryId=parseResponseJSON(responseForInqueryById.data);
        setName(tempResponseForInqueryId[0].name);
        setEmail(tempResponseForInqueryId[0].email);
        setPhoneNumber(tempResponseForInqueryId[0].phoneNumber);
        setContactedDate(tempResponseForInqueryId[0].contactedDate);
        setMessage(tempResponseForInqueryId[0].message);
        setCallStatus(tempResponseForInqueryId[0].callStatus);
        setCallBackDate(tempResponseForInqueryId[0].callBackDate);
      }
      catch(error)
      {
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }
    const handleCloseAddInqueryPopup=()=>{
        setShowAddInqueryPopup(false);
        if(props.value){
          props.getInquerys();
        }
        if(props && props.setShowAddInqueryPopup)
        {
          props.setShowAddInqueryPopup();
        }
        if(props &&  props.handleCloseAddInquery){
          props.handleCloseAddInquery()
        }
      }
      const handleShowAddInqueryPopup=()=>{
        setShowAddInqueryPopup(true)
      }
      const savePotentialGuestsInfo=async(e)=>{
        if(e) e.preventDefault();
        try{
          await axios.post(`${url}/potentialGuests`,{
            name,
            email,
            phoneNumber,
            message,
            contactedDate,
            message,
            callStatus,
            callBackDate,
            followUpDate,
            remarks
          }).then((responseForSavingPotentialGuests)=>{
            console.log(responseForSavingPotentialGuests.data)
            // getPotentialGuestsInfo();
            navigate('/inqueries');
            handleCloseAddInqueryPopup();
          })
      
        }catch(error){
          console.log(error)
          setResponseForSms(error.response.data)
        }
      }
      const editPotentialGuestsInfo=async(e)=>{
        if(e) e.preventDefault();
        try{
          await axios.patch(`${url}/potentialGuests/${InqueryId}`,{
            name,
            email,
            phoneNumber,
            message,
            contactedDate,
            message,
            callStatus,
            callBackDate,
            followUpDate,
            remarks
          }).then((responseForSavingPotentialGuests)=>{
            console.log(responseForSavingPotentialGuests.data)
            // getPotentialGuestsInfo();
            navigate('/inqueries');
            handleCloseAddInqueryPopup();
          })
      
        }catch(error){
          console.log(error)
          setResponseForSms(error.response.data)
        }
      }
      function convertToJSON (e) {
        // const tempJson = JSON.parse(texty);
        var tempString=e.target.value;
        console.log(tempString)
        if(tempString.length==0)
        {
          setName("");
          setPhoneNumber("")
          setEmail("")
          setMessage("")
          return;
        }
        var newLine=0;
        if(tempString.includes("Name"))
        {
          
          const nameIndex=tempString.indexOf("Name");
          newLine=tempString.indexOf("\n",nameIndex+1);
          const name=tempString.substring(nameIndex+5,newLine);

          setName(name)
        }
        else{
          setName("")
        }
        if(tempString.includes("Phone"))
        {
          
          const phoneIndex=tempString.indexOf("Phone");
          newLine=tempString.indexOf("\n",phoneIndex+1);
          const phone=tempString.substring(phoneIndex+7,newLine);
          alert(phone)
       
          setPhoneNumber(phone)
        }
        else{
          setPhoneNumber("")
        }

        if(tempString.includes("Email"))
        {
          
          const mailIndex=tempString.indexOf("Email");
          newLine=tempString.indexOf("\n",mailIndex+2);
          const mail=tempString.substring(mailIndex+6,newLine);
      
          setEmail(mail)
        }
        else{
          setEmail("")

        }
        
        if(tempString.includes("Message"))
        {
          const messageIndex=tempString.indexOf('"');
          newLine=tempString.indexOf('"',messageIndex+3);
          
          const message=tempString.substring(messageIndex+1,newLine);
         
          setMessage(message)
        }
        else{
          setMessage("")
        }
        
      }
    return (
        <Modal show={showAddInqueryPopup} onHide={handleCloseAddInqueryPopup} style={{width:"100%"}}>
        <Modal.Header closeButton><Modal.Title>Add a Inquery: <i style={{color:"red",fontSize:"medium"}}>{responseForSms}</i></Modal.Title></Modal.Header>
        <Form >
        <Modal.Body>
          <Form.Control as="textarea" rows={5} onChange={(e)=>convertToJSON(e)}/><br></br>

          <Row>
            <Col><Form.Label>Name </Form.Label></Col>
            <Col><Form.Label>Phone Number</Form.Label></Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                value={name}  
                onChange={(e)=>setName(e.target.value)}
                aria-describedby="basic-addon1" 
              />
            </Col>
            
            <Col>
              <Form.Control
                type="text"
                value={phoneNumber}  
                onChange={(e)=>setPhoneNumber(e.target.value)}
                aria-describedby="basic-addon1"
                required 
              />

            </Col>
          </Row>
        <br></br>
        <Row>
          <Col><Form.Label>Email</Form.Label></Col>
          <Col><Form.Label>Message</Form.Label></Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="email"
              value={email}  
              onChange={(e)=>setEmail(e.target.value)}
              aria-describedby="basic-addon1" 
            />
          </Col>
          <Col>
            <Form.Control className="w-100"  
              as="textarea" 
              rows={2} 
              value={message}
              onChange={(e) => setMessage(e.target.value)}/>
          </Col>
        </Row> 
        <br></br>
        <Row>
          <Col><Form.Label>Contacted Date</Form.Label></Col>
          <Col><Form.Label>Call Back Status</Form.Label></Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="date"
              value={contactedDate}  
              onChange={(e)=>setContactedDate(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </Col>
          <Col>
            <Form.Select onChange={(e) => setCallStatus(e.target.value)} className="h-100">
              <option value="Yet to Call">Yet to Call</option>
              <option value="Called Back">Called Back</option>
            </Form.Select>
          </Col>
        </Row>
      

      
      <br></br>
      <Row>
      <Col><Form.Label>Call Back Date</Form.Label></Col>
          <Col><Form.Label>Follow up Date</Form.Label></Col>
          
        </Row>
        <Row>
          <Col>
          <Form.Control
          type="date"
          value={callBackDate}  
          onChange={(e)=>setCallBackDate(e.target.value)}
          aria-describedby="basic-addon1"
        />
        </Col>
          <Col>
          <Form.Control
          type="date"
          value={followUpDate}  
          onChange={(e)=>setFollowUpDate(e.target.value)}
          aria-describedby="basic-addon1"
        />
          </Col>
        </Row>
        
        

        {/* (415)&nbsp;555&#8209;0132 */}
        
        
 
      
        <Form.Label>Comments</Form.Label><br></br>
        <Form.Control as="textarea" name="Comments" value={remarks} 
        onChange={(e) => setRemarks(e.target.value)}/>
      </Modal.Body>
      
      <Modal.Footer>
        <Button variant="secondary"  onClick={handleCloseAddInqueryPopup}>Cancel</Button> 
        <Button type="submit" onClick={(e)=>InqueryId ? editPotentialGuestsInfo(e) :savePotentialGuestsInfo(e)}>Save</Button> 
      </Modal.Footer>
      </Form>

      
      </Modal>

    );
}

export default AddInquery;