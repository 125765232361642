import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import Header from "./Header.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
 
const EditAmenity = () => {
    const [description,setDescription]=useState("");
    const [type,setType]=useState("");
    const [addeddate,setAddeddate]=useState("");
    const [roomid,setRoomid]=useState("");
    // const [roomid,setRoomid]=useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const url=process.env.REACT_APP_SERVER_URL;
//   status="Yet to be assigned";
//   active="Active";
  useEffect(() => {
    getAmenityById();
    // alert(fname);
  }, []);
 
  const updateAmenity = async (e) => {
    e.preventDefault();
    try {
        
      await axios.patch(`${url}/amenities/${id}`, {
        // roomid,
        // customerid,
        roomid,
        description,
        type,
        addeddate
      });

      navigate(`/amenities`);
    } catch (error) {
      console.log(error);
    }
  };
 
  const getAmenityById = async () => {
    let response = await axios.get(`${url}/amenities/${id}`);
    let semisplit= JSON.stringify(response.data)//.split(':');
    // alert(JSON.stringify(response.data));
    let pp=JSON.parse(semisplit)[0];
    // alert(pp.id);
    // setFname(pp.fname);
    console.log(pp);
    setRoomid(pp.roomid);
    setDescription(pp.description);
    setType(pp.type);
    setAddeddate(pp.addeddate);
    // alert(pp.customerid)
    
  };
 
  return (
    <div>
      <Header/>
    <div className="container">
        <form onSubmit={updateAmenity}>
        <div class="row">
		<div class="input-group input-group-icon">
    <textarea
        placeholder="Amenity Description"
        // style={styles.textarea}
        value={description}
        name="description" 
        onChange={(e)=>setDescription(e.target.value)}
      />
		  {/* <div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div> */}
		</div>
          </div>
         
          <div class="row">
		<div class="col">
      <select value={roomid}
                name="Room"
                onChange={(e) => setRoomid(e.target.value)}>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
      </div>
      </div>
          

    <div class="row">
		<div class="input-group input-group-icon">
    <input type="text" name="assignedto" 
        className="input"
        id="first-name"
        value={type}
        onChange={(e) => setType(e.target.value)}
        placeholder="Type of Amenity"/>
		  <div class="input-icon"><i class="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i></div>
		</div>
    </div>

    <div class="row">
		<div class="col-half">
		  <h4>Added At</h4>
		  <div class="input-group">
			<div>

			{/* <!-- class="col-half" --> */}
			  <input type="date" value={addeddate}
                onChange={(e) => setAddeddate(e.target.value)} 
                placeholder="Scheduled Date"/>
			</div>
		  </div>
		</div> 

		
	  </div>


          <div className="field">
            <button type="submit" className="button is-success">
              Save
            </button>
          </div>
        
        </form>
      </div>
    </div>
  );
};
 
export default EditAmenity;