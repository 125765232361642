
import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate,useLocation, useParams } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
// import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {formatDate, parseResponseJSON,logMe , dateDifference,isDateOrDatetime,getDataSorted,formatDateText} from "./CommonFunctions";

import { Table , Rate , RadioTile,IconButton,Row,Divider,Col,Dropdown,Message} from 'rsuite';
import {ModalBody,Form,Tabs,Tab} from "react-bootstrap"
import Header from "./Header.js";
import { faDownLong } from "@fortawesome/free-solid-svg-icons";

const { Column, HeaderCell, Cell } = Table;
const rowKey = "longTermTenancyId";

const TenantPaymentSummary=(props)=>{
    const [paymentDetails,setPaymentDetails]=useState([]);
    const [paymentSummary,setPaymentSummary]=useState([]);
    const [sortColumn, setSortColumn] = useState("stayDuration");
  const [sortType, setSortType] = useState("asc");
  const [loading, setLoading] = useState(false);
  const[longTermTenancyId,setLongTermTenancyId]=useState();
  const url=process.env.REACT_APP_SERVER_URL;
  const {tenancyId}=useParams();
  let rem=0;
  
  const navigate=useNavigate();
    useEffect(() => {
        if(tenancyId)
        {
            getLongTermTenancyId(tenancyId)
        }
       
        
      }, []);
      const getLongTermTenancyId = async(tenancyId) => {
        try{
            await axios.get(`${url}/tenants/getLTTIbyTenancyId/${tenancyId}`).then((responseForPaymentDetails)=>{
            console.log(responseForPaymentDetails.data.length);
              if(responseForPaymentDetails.data.length>0)
              {
                const longTermTenancyId=responseForPaymentDetails.data[0].longTermTenancyId;
                setLongTermTenancyId(longTermTenancyId)
                //  getPaymentSummaryByLLTI(longTermTenancyId);
                 getAllPaymentsByLTTI(longTermTenancyId);

              }
            //   setPaymentDetails(responseForPaymentDetails.data);
            })
    
          }catch(error){
                console.log(error);
            }

      }
      const getPaymentSummaryByLLTI = async (longTermTenancyId) => {
        try{
          await axios.get(`${url}/payments/getPaymentSummaryByLLTI/${longTermTenancyId}`).then((responseForPaymentDetails)=>{
            console.log(responseForPaymentDetails.data);
            setPaymentSummary(responseForPaymentDetails.data);
          })
  
        }catch(error){
              console.log(error);
          }
      }
      const getPaymentsByTenancyId = async(tenancyId) =>{
        try{
            await axios.get(`${url}/paymentHistory/${tenancyId}`).then((responseForTransactions)=>{
                console.log(responseForTransactions.data);
                setPaymentDetails(responseForTransactions.data)
            })

        }catch(error){
              console.log(error);
          }
      }
      const getAllPaymentsByLTTI = async(longTermTenancyId) =>{
        try{
          if(longTermTenancyId){
            await axios.get(`${url}/payments/getAllPaymentsByLLTI/${longTermTenancyId}`).then((responseForTransactions)=>{
                console.log(responseForTransactions.data);
                setPaymentDetails(responseForTransactions.data)
            })
            
          }

        }catch(error){
              console.log(error);
          }
      }
      
      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
      const dropdownStyles = {
        backgroundColor: '#f0f0f0', // Replace with your desired background color
        marginLeft:"5%",
        maxHeight:"200px !important",
        color:"blue"
      };

      return(
        <>
        <Header/>
        <div class="container" id="container" style={{width:"90%"}}>
        <Tabs defaultActiveKey={1}  className="mb-3 ms-5" variant="underline"
        style={{backgroundColor:"aliceblue",color:"black",paddingTop:"1%",fontWeight:"bolder",fontSize:"1.1rem",
        paddingBottom:"1%",
        paddingLeft:"5%",
        marginLeft:"5%",marginRight:"5%"}}
        //  onSelect={(e)=>setSelectedPhoneNumbers([])}
         >
        <Tab eventKey={1} title="Payment Summary">
        <div class="container" id="container" style={{width:"100%"}}>
        {/* {paymentSummary.length === 0 ? null : */}
      {paymentSummary &&
      <Table 
      autoHeight
      // height={1000}
      //   data={activeTenancyDetails}
      wordWrap="break-word"
      bordered={true}
        data={getDataSorted(sortColumn,sortType,paymentSummary)}
        size="lg"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}>
          <Column width={120} sortable>
            <HeaderCell>Booking Id </HeaderCell>
            <Cell dataKey="id" ></Cell>
          </Column>
          <Column width={250} sortable>
            <HeaderCell>Tenancy Period </HeaderCell>
            <Cell dataKey="checkin" >
            {rowData => (
              <>
                {formatDateText(rowData.checkin) + " - " + formatDateText(rowData.checkout)}
              </>
                
            )}
            </Cell>
          </Column>
          <Column width={100} sortable>
            <HeaderCell>Rent </HeaderCell>
            <Cell dataKey="rate" >
            {rowData => (
              <>
                ${rowData.rate}
              </>
                
            )}
            </Cell>
          </Column>
          <Column width={120} sortable>
            <HeaderCell>Cash</HeaderCell>
            <Cell dataKey="totalCashAmt" >
            {rowData => (
              <>
                ${rowData.totalCashAmt}
              </>
                
            )}
            </Cell>
          </Column>
          <Column width={120} sortable>
            <HeaderCell>Credit Card </HeaderCell>
            <Cell dataKey="totalCredit" >
            {rowData => (
              <>
                ${rowData.totalCredit}
              </>
                
            )}

            </Cell>
          </Column>
          <Column width={150} sortable>
            <HeaderCell>Other Amount </HeaderCell>
            <Cell dataKey="totalOtherAmt" >
            {rowData => (
              <>
                ${rowData.totalOtherAmt}
              </>
                
            )}
            </Cell>
          </Column>
          <Column width={120} sortable>
            <HeaderCell>Balance </HeaderCell>
            <Cell >
            {rowData => (
              <>
              $
              {rem=rowData.rate - rowData.totalCashAmt - rowData.totalCredit - rowData.totalOtherAmt}
              {rem>0 ? <b style={{color:"red"}}>
                {/* (Owes) */}
                </b> : rem==0 ? <b></b> :
              <b style={{color:"green"}}>(Excess)</b>}
                
              </>
                
            )}
            </Cell>
          </Column>
          <Column width={150}>
            <HeaderCell>Payment Status </HeaderCell>
            <Cell >
            {rowData => (
              <>
              {rowData.totalCashAmt + rowData.totalCredit + rowData.totalOtherAmt ==0
              ? <b style={{color:"red"}}>No Payment Received</b> : 
              rowData.totalCashAmt + rowData.totalCredit + rowData.totalOtherAmt==rowData.rate
              ? <b style={{color:"black"}}>Full Payment Received</b> :
              rowData.totalCashAmt + rowData.totalCredit + rowData.totalOtherAmt<rowData.rate
              ? <b style={{color:"black"}}>Partial Payment</b> : null}
                
              </>
                
            )}
            </Cell>
          </Column>
          {/* <Column width={120} sortable>
            <HeaderCell>Running Balance </HeaderCell>
            <Cell >
            {rowData => (
              <>
              $
              {rem=rowData.rate - rowData.totalCashAmt - rowData.totalCredit - rowData.totalOtherAmt}
              {rem>=0 ? <b style={{color:"red"}}>(Owes)</b> : <b style={{color:"green"}}>(Excess)</b>}
                
              </>
                
            )}
            </Cell>
          </Column> */}


      </Table>
        }

      </div>

            
        </Tab>
        <Tab eventKey={2} title="Payment Details" className="ms-5">
            <>
            {/* <Col style={{marginLeft:"2%",fontWeight:"bold",fontSize:"1rem"}}>
            <Link> Add </Link></Col> */}
           
            <Dropdown
            onSelect={(e)=>{e==-1 ? getAllPaymentsByLTTI(longTermTenancyId):getPaymentsByTenancyId(e)}}
            title="Filter by Tenancy Period" style={dropdownStyles}>
              <Dropdown.Item eventKey={-1}>All Payments</Dropdown.Item>
            {paymentSummary.map((row, index) => (
                <Dropdown.Item eventKey={row.id}>
                {formatDateText(row.checkin) + " - " + formatDateText(row.checkout)} 
                </Dropdown.Item>
            ))}
            </Dropdown>
            {/* <Col className="ms-5">Room Number: </Col>
            <Col> </Col>

            <Col className="ms-5">Selected Tenancy Period: </Col>
            <Col> </Col> */}
            
        <div class="container" id="container" style={{width:"100%"}}>
        {paymentDetails.length === 0 ? <Message showIcon type="error" closable>
         No transactions in the selected Date Range
       </Message> :

            <Table
             autoHeight
            // height={1000}
            //   data={activeTenancyDetails}
            wordWrap="break-word"
                bordered={true}
                data={getDataSorted(sortColumn,sortType,paymentDetails)}
                size="lg"
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}>

                {/* <Column width={150} sortable>
                <HeaderCell>Booking Id </HeaderCell>
                <Cell dataKey="id" ></Cell>
                </Column> */}
                <Column width={250} sortable>
                <HeaderCell>Tenancy Period </HeaderCell>
                <Cell dataKey="checkin" >
                {rowData => (
                <>
                    {formatDateText(rowData.checkin) + " - " + formatDateText(rowData.checkout)}
                </>
                    
                )}
                </Cell>
                </Column>

                <Column width={140} sortable>
                <HeaderCell>Receipt Number </HeaderCell>
                <Cell dataKey="receiptNumber" >
                {rowData => (
                <>
                    {rowData.paymentDate ? <b>{rowData.receiptNumber}</b> :
                    <b style={{color:"red"}}>No Payment</b>
                    }
                </>
                    
                )}
                </Cell>
                </Column>


                <Column width={130} sortable>
                <HeaderCell>Cash Amount </HeaderCell>
                <Cell dataKey="cashAmount" >
                {rowData => (
                  <>
                    {rowData.cashAmount ? "$"+rowData.cashAmount : "-"}
                  </>
                    
                )}
                </Cell>
                </Column>


                <Column width={130} sortable>
                <HeaderCell>Credit Amount</HeaderCell>
                <Cell dataKey="creditCardAmount" >
                {rowData => (
                  <>
                  {rowData.creditCardAmount ? "$"+rowData.creditCardAmount : "-"}
                  </>
                    
                )}
                </Cell>
                </Column>

                <Column width={130} sortable>
                <HeaderCell>Other Amount </HeaderCell>
                <Cell dataKey="otherAmount" >
                {rowData => (
                  <>
                    {rowData.otherAmount ? "$"+rowData.otherAmount : "-"}
                  </>
                    
                )}
                </Cell>
                </Column>
                <Column width={130} sortable>
                <HeaderCell>Paid By </HeaderCell>
                <Cell dataKey="paidBy" ></Cell>
                </Column>

                <Column width={150} sortable>
                <HeaderCell>Paid on </HeaderCell>
                <Cell dataKey="paymentDate" >
                {rowData => (
                  <>
                    {rowData.paymentDate}
                  </>
                    
                )}
                  
                </Cell>
                </Column>

                
            </Table>
        
        }
        </div>
        </>
            
        </Tab>
        </Tabs>
          <>
          
        

          
          



          </>
        </div>
        </>

      );
}
export default TenantPaymentSummary;