import React, { useState, useEffect , useContext } from "react";
import axios from "axios";

import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {Row,Col,Table} from "react-bootstrap";
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";

export const AddAmenity=(props)=>{
    const [description,setDescription]=useState("");
    const [amenityType,setAmenityType]=useState("");
    const [addeddate,setAddeddate]=useState("");
    const [roomNumber,setRoomNumber]=useState("");
    const [amenities, setAmenities] = useState([]);
    const [isAddAmenity,setIsAddAmenity]=useState(false);
    const [amenityId,setAmenityId]=useState("");
    const [responseForAmenity,setResponseForAmenity]=useState("");
    const [showDeleteAmenityPopup, setShowDeleteAmenityPopup]=useState(false);
    const [allRooms,setAllRooms]=useState([]);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      console.log(process.env.REACT_APP_SERVER_URL)
      setIsAddAmenity(true)
      getAllRooms();
      if(props.value && props.value.amenityId){
        setAmenityId(props.value.amenityId);
        getAmenityById(props.value.amenityId);
      }
      else if(props.value && props.value.roomNumber)
      {
        console.log("props.value.roomNumber " + props.value.roomNumber)
        setRoomNumber(props.value.roomNumber)
      }
    }, []);
 
    const getAllRooms=async()=>{
      try{
        await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
          console.log(responseForAllRooms.data);
          setAllRooms(responseForAllRooms.data);
        })
  
      }
      catch(error){
        console.log(error);
      }
     }

 
    const handleCloseAddAmenity=()=>{
      setAmenityId("")
      setIsAddAmenity(false);
      setResponseForAmenity("")
      if(props && props.handleCloseAddAmenity)
      {
        props.handleCloseAddAmenity();
      }

    }


    const editAmenityDetails=async(e,amenityId)=>{
      if(e) e.preventDefault();
      try{
        var roomid=roomNumber;
        var type=amenityType;
        await axios.patch(`${url}/amenities/${amenityId}`, {
        roomid,
        description,
        type,
        addeddate
      }).then((responseForEditAmenity)=>{
        console.log( "Amenity updated (" + amenityId +" ). Status - " + responseForEditAmenity.status);
        if(props.value){
          if(props.value.amenityId)
          {
            props.getAmenities();
          }
        }
        displayNotificationMessage("success","Updated Amenity Details");
          handleCloseAddAmenity();

      })
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }
    const saveAmenityDetails=async(e)=>{
      if(e) e.preventDefault();
      try{
        var roomid=roomNumber;
        var type=amenityType;
        await axios.post(`${url}/amenities`, {
        roomid,
        description,
        type,
        addeddate
      }).then((responseForSaveAmenity)=>{
        setResponseForAmenity("Saved");
        const tempDbResponse=parseResponseJSON(responseForSaveAmenity.data);
        if(tempDbResponse)
        {
          setAmenityId(tempDbResponse.data)
        if(props.value && !props.value.roomNumber){
            props.getAmenities();
        }
        handleCloseAddAmenity();
        displayNotificationMessage("success","New Amenity Details added");
      }

      })
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 
      }
      
    }
    const getAmenityById=async(amenityId)=>{
      try{
        if(amenityId)
        {
          await axios.get(`${url}/amenities/${amenityId}`).then((responseForAmenityId)=>{
            const tempResponseForAmenityId=parseResponseJSON(responseForAmenityId.data);
            console.log(tempResponseForAmenityId[0])  
            setRoomNumber(tempResponseForAmenityId[0].roomid);
            setDescription(tempResponseForAmenityId[0].description);
            setAddeddate(tempResponseForAmenityId[0].addeddate);
            setAmenityType(tempResponseForAmenityId[0].type)
            // setAmenityId(amenityId);
          }); 

        }
        else{
          setRoomNumber("");
          setDescription("");
          setAddeddate(formatDate(new Date()));
          setAmenityType("")

        }
      
        
      }
      
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 
      }                      
    }

    return (
      <div>

        <Modal show={isAddAmenity} onHide={handleCloseAddAmenity}>

        <Modal.Header closeButton>
        
          <Modal.Title>Amenity Details   {responseForAmenity ? <h5 style={{color:"blue"}}>{responseForAmenity}</h5> :  <h5></h5>}</Modal.Title>
         
        </Modal.Header>
        
        <Modal.Body className="me-3">
          <Form.Group >
            <Row>
              <Col className="mt-2 ms-4"><Form.Label style={{float:"right"}}><b>Select a Room Number :</b></Form.Label></Col>
              <Col><Form.Select className="w-75"  size="sm"
                  value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value)}}>
                <option value='' disabled selected></option>
                {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.alias}</option>
                ))}
              </Form.Select></Col> 


            </Row>
              
          </Form.Group>
          <Form.Label className="ms-5 mt-3"><b>Amenity Description :</b></Form.Label>
          <Form.Control className="me-3 ms-5 mt-1 w-75"  as="textarea" rows={2} 
              value={description} onChange={(e) => setDescription(e.target.value)}/> 
   
          <br></br>
          <Row>
            <Col><Form.Label className="ms-5 mt-1 h-4" style={{float:"left"}}><b>Amenity Type :</b></Form.Label></Col>
            <Col className="w-25 me-5 pe-5">
            <Form.Control
              type="text"
              value={amenityType} 
              onChange={(e)=>{setAmenityType(e.target.value)}}
              aria-describedby="basic-addon1"
              style={{float:"left"}}
            />
            </Col>
          </Row><br></br>
          <Row>
            <Col><Form.Label className="ms-5 mt-1 h-4" style={{float:"left"}}><b>Added At :</b></Form.Label></Col>
            <Col className="w-25 me-5 pe-5">
            <Form.Control
              type="date"
              value={addeddate} 
              onChange={(e)=>{setAddeddate(e.target.value)}}
              aria-describedby="basic-addon1"
              style={{float:"left"}}
            />
            </Col>
          </Row>
          
      

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e)=>handleCloseAddAmenity(e)}>
            Close
          </Button>
          { amenityId ? <Button variant="primary" onClick={(e)=>editAmenityDetails(e,amenityId)}>
          Update Changes
        </Button> : 
       <Button variant="primary" onClick={(e)=>saveAmenityDetails(e)}>
          Save Changes
        </Button>}
          
        </Modal.Footer>
      </Modal> 








      </div>
    );


}