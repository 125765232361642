import React, { useState, useEffect, useRef, useContext } from 'react'
import { Modal, Button, Table, IconButton } from 'rsuite'
import axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { formatDate } from '../../CommonFunctions'
import CommonComponents from '../../CommonComponents.js'

const CreateInventoryModal = (props) => {
  const { displayNotificationMessage } = useContext(CommonComponents)
  const [inventoryData, setInventoryData] = useState({
    name: '',
    type: 1,
    count: 0,
    description: '',
    supplierLinks: '',
    storageLocation: '',
    cost: 0,
  })
  const url = process.env.REACT_APP_SERVER_URL

  const addInventory = async () => {
    try {
      await axios
        .post(`${url}/addInventory`, inventoryData)
        .then((response) => {
          closeModal()
        })
    } catch (error) {
      displayNotificationMessage('error', error.response.data)
      console.log(error)
    }
  }

  const closeModal = () => {
    props.data.setCreatelInventory(false)
  }
  return (
    <Modal open={props.data.createlInventory} onClose={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Create Product</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'none' }}>
        <Row>
          <Col>
            <Form.Label>Product Name</Form.Label>
          </Col>
          <Col>
            <Form.Label>Product Count</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="string"
              value={inventoryData.name}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  name: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={6}>
            <Form.Control
              type="number"
              value={inventoryData.count}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  count: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Unit Type</Form.Label>
          </Col>
          <Col>
            <Form.Label>Storage Location</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Select
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  type: Number(e.target.value),
                })
              }
              className="h-100"
            >
              <option value="0">Lbs</option>
              <option value="1">Number</option>
              <option value="2">Gallon</option>
            </Form.Select>
          </Col>
          <Col xs={6}>
            <Form.Control
              type="string"
              value={inventoryData.storageLocation}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  storageLocation: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Description</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Control
              as="textarea"
              rows={2}
              value={inventoryData.description}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  description: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Cost</Form.Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Control
              type="number"
              value={inventoryData.cost}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  cost: e.target.value,
                })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Form.Label>Supplier Links</Form.Label>
          </Col>
          <Col xs={12}>
            <Form.Control
              as="textarea"
              rows={2}
              value={inventoryData.supplierLinks}
              onChange={(e) =>
                setInventoryData({
                  ...inventoryData,
                  supplierLinks: e.target.value,
                })
              }
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => addInventory()} appearance="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default CreateInventoryModal
