import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from 'react'
  import axios from 'axios'
  import {
    Table,
    Popover,
    Whisper,
    Checkbox,
    Dropdown,
    IconButton,
    Progress,
    DatePicker,
    Rate
  } from 'rsuite';
  import { Link } from 'react-router-dom';
  import {Tabs,Tab} from "react-bootstrap"
  import Modal from 'react-bootstrap/Modal';
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button'
  import { Row, Col } from 'react-bootstrap'
  import {
    formatDate,
    parseResponseJSON,
    logMe,
    getDataSorted,
    formatDateText,
    dateDifference
  } from './CommonFunctions'
import { useParams } from 'react-router-dom';
import Header from './Header';
  const { Column, HeaderCell, Cell } = Table;

  const DailyReport = (props) => {
    const url = process.env.REACT_APP_SERVER_URL;
    const [selectedDate,setSelectedDate]=useState(formatDate(new Date()));
    const [payments,setPayments]=useState([]);
    const [repairs,setRepairs]=useState([]);
    const [checkins,setCheckins]=useState([]);
    const [checkouts,setCheckouts]=useState([]);
    const [eventKeyForTab,setEventKeyForTab]=useState(1);
    useEffect(() => {
      getPaymentsOnSelectedDate(selectedDate);
        
      }, []);

      const getPaymentsOnSelectedDate = async(selectedDate) =>{
        try{
          await axios.post(`${url}/payments/selectedDay`,{
            selectedDate
          }).then((responseForPayments)=>{
            console.log(responseForPayments.data);
            setPayments(responseForPayments.data);
          })

        }catch(error){
          console.log(error);
        }
      }
      const getRepairsOnSelectedDate = async(selectedDate) =>{
        try{
          await axios.post(`${url}/repairs/selectedDay`,{
            selectedDate
          }).then((responseForRepairs)=>{
            console.log(responseForRepairs.data);
            setRepairs(responseForRepairs.data);
          })

        }catch(error){
          console.log(error);
        }
      }
      const getCheckinsOnSelectedDate = async(selectedDate) =>{
        try{
          await axios.post(`${url}/checkins/selectedDay`,{
            selectedDate
          }).then((responseForCheckins)=>{
            console.log(responseForCheckins.data);
            setCheckins(responseForCheckins.data);
          })
          

        }catch(error){
          console.log(error);
        }
      }
      const getCheckoutsOnSelectedDate = async(selectedDate) =>{
        try{
          await axios.post(`${url}/checkouts/selectedDay`,{
            selectedDate
          }).then((responseForCheckouts)=>{
            console.log(responseForCheckouts.data);
            setCheckouts(responseForCheckouts.data); 
          })

        }catch(error){
          console.log(error);
        }
      }
      const getDetailsForEvent=(eventKey,sDate)=>{
        const selectedDate=formatDate(sDate)
        if(eventKey==1)
        {
          getPaymentsOnSelectedDate(selectedDate);

        }
        else if(eventKey==2)
        {
          getRepairsOnSelectedDate(selectedDate);

        }
        else if(eventKey==3)
        {
          getCheckinsOnSelectedDate(selectedDate);

        }
        else if(eventKey==4)
        {
          getCheckoutsOnSelectedDate(selectedDate);

        }
      }
      
      return (
        <>
        <Header/>
        <div class="container">
            <Row>
        <DatePicker size="lg" format="MMM dd, yyyy" 
        onChange={(e)=>{setSelectedDate(e);
        getDetailsForEvent(eventKeyForTab,e)}}/>
        </Row>
        <Tabs defaultActiveKey={1}  className="mb-3 ms-5" variant="underline"
        style={{color:"black",paddingTop:"1%",fontWeight:"bolder",fontSize:"1.1rem",
        paddingBottom:"1%",
        paddingLeft:"5%",
        marginLeft:"5%",marginRight:"5%",display:"flex",justifyContent:"space-around"}}
         onSelect={(e)=>{setEventKeyForTab(e);getDetailsForEvent(e,selectedDate)}}
        >
            <Tab eventKey="1" title="Payments">
              <PaymentsComponent payments={payments}/>
            </Tab>
            <Tab eventKey="2" title="Repairs">
              <RepairsComponent repairs={repairs}/>
            </Tab>
            <Tab eventKey="3" title="Checkins">
              <CheckinsComponent checkins={checkins}/>
            </Tab>
            <Tab eventKey="4" title="Checkouts">
            <CheckinsComponent checkins={checkouts}/>
            </Tab>
        </Tabs>
         </div>
        </>
      );

    }
    const PaymentsComponent=(props) =>{
      return (
        <div>
          <Table autoHeight data={props.payments} id="table">
          <Column width={120} align="center" fixed>
        <HeaderCell>Room Number</HeaderCell>
        <Cell dataKey="roomid" />
      </Column>
      <Column width={200} align="center" fixed>
        <HeaderCell>Payment Date</HeaderCell>
        <Cell dataKey="paymentDate">
        {(rowData) => <>{formatDate(rowData.paymentDate.toString())}</>}
        </Cell>
      </Column>

      <Column width={200} align="center" fixed>
        <HeaderCell>Receipt Number</HeaderCell>
        <Cell dataKey="receiptNumber" />
      </Column>

      <Column width={100} align="center" fixed>
        <HeaderCell>Paid By</HeaderCell>
        <Cell dataKey="paidBy" />
      </Column>

      <Column width={120} align="center" fixed>
        <HeaderCell>Cash Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.cashAmount ? '$'+rowData.cashAmount : "-"}</>}
        </Cell>
      </Column>
      <Column width={120} align="center" fixed>
        <HeaderCell>Credit Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.creditCardAmount ? '$'+rowData.creditCardAmount : "-"}</>}
          
          </Cell>
      </Column>

      <Column width={200} align="center" fixed>
        <HeaderCell>Other Amount</HeaderCell>
        <Cell>
          {(rowData) => <>{rowData.otherAmount ? '$'+rowData.otherAmount : "-"}</>}
          
        </Cell>
      </Column>
    </Table>
        </div>
      );
        
       
    
    };

    const RepairsComponent=(props) =>{
      return(
        <div>
          <Table autoHeight data={props.repairs} id="table"
      >

      <Column width={100} fixed sortable>
        <HeaderCell><b style={{color:"black"}}>Ticket No.</b></HeaderCell>
        <Cell dataKey="id">
          {rowData => (
            <Link
            >
              {rowData.id}
            </Link>
            
          )}
          </Cell>
      </Column>
      <Column width={80} sortable>
        <HeaderCell><b>Room</b> </HeaderCell>
        <Cell dataKey="roomid">
        {rowData => (
          <>
            {rowData.roomid}
          </>
            
          )}
        
        </Cell>
      </Column>

      

      

      <Column width={150}>
        <HeaderCell><b>Description </b></HeaderCell>
        <Cell dataKey="description" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Scheduled At </b></HeaderCell>
        <Cell dataKey="scheduledDate">
        {rowData => (
            <>
            {rowData.scheduledDate ? formatDateText(rowData.scheduledDate) : ""}
            </>
            
          )}
        
        </Cell>
      </Column>
      <Column width={120} sortable>
        <HeaderCell><b>Assigned To </b></HeaderCell>
        <Cell dataKey="assignedto">
        </Cell>
      </Column>

      <Column width={100} sortable>
        <HeaderCell><b>Status </b></HeaderCell>
        <Cell dataKey="status" />
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Created At </b></HeaderCell>
        <Cell dataKey="createdAt" >
        {rowData => (
            <>
            {new Date(rowData.createdAt).toString().substring(0,10)}
            </>
            
          )}
        </Cell>
      </Column>

      <Column width={110}>
        <HeaderCell><b>Days Open </b></HeaderCell>
        <Cell dataKey="dateDifference">
        {rowData => (
            <>{
              dateDifference(rowData.createdAt,rowData.completedDate ? rowData.completedDate : new Date()) }</>
            
          )}

        </Cell>
      </Column>

      <Column width={120} sortable>
        <HeaderCell><b>Completed At </b></HeaderCell>
        <Cell dataKey="completedDate">
        {rowData => (
            <>{rowData.completedDate ? formatDateText(rowData.completedDate) : ""}</>
            
          )}
        </Cell>
      </Column>

      <Column width={120}>
        <HeaderCell><b>Rating</b></HeaderCell>
        <Cell dataKey="createdAt" >
        {rowData => (
            <Rate size="xs" style={{width:"100px"}} value={rowData.managerRatingOnRepair} readOnly></Rate>
            
          )}
        </Cell>
      </Column>

      <Column width={150}>
        <HeaderCell><b>Manager Comments </b></HeaderCell>
        <Cell dataKey="managerComments" />
      </Column>

     
      

    </Table>
        </div>
      );

    };

const CheckinsComponent=(props)=>{
  return (
    <div>
      <Table autoHeight data={props.checkins} id="table">
        <Column width={100} fixed sortable>
        <HeaderCell><b>Room </b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
            <a 
            // onClick={(e)=>{
            //   navigate(`/tenants/add/${rowData.id}`, { state:{ tenancyId: rowData.id} })}}
              >
            <Link>
              {rowData.roomid}
            </Link>
            </a>
            
          )}
          </Cell>
      </Column>
      {/* <Column width={100} sortable>
        <HeaderCell>Vacancy </HeaderCell>
        <Cell dataKey="Vacancy" />
      </Column> */}
      {/* <Column width={150} sortable>
        <HeaderCell>Guest Quality </HeaderCell>
        <Cell dataKey="guestQuality" >
        {rowData => (
            <Rate readOnly size="xs" style={{width:"100px"}} value={rowData.guestQuality}/>
            
          )}
        
        </Cell>
      </Column> */}

      <Column width={180} sortable>
        <HeaderCell>Names</HeaderCell>
        <Cell dataKey="names" />
      </Column>

      <Column width={140} sortable>
        <HeaderCell>phoneNumbers</HeaderCell>
        <Cell dataKey="phoneNumbers" />
      </Column>
      <Column width={140} sortable>
        <HeaderCell>Initial Arrival Date</HeaderCell>
        <Cell dataKey="firstArrivalDate">
        {rowData => (
          <>
            {formatDateText(rowData.firstArrivalDate)}<br></br>
            {/* { " ( " + dateDifference(rowData.firstArrivalDate,new Date())+ " days since )"} */}
          </>
            
          )}
        </Cell>
      </Column>

      <Column width={220} sortable>
        <HeaderCell>Checkin</HeaderCell>
        <Cell dataKey="checkin">
        {rowData => (
          <>
            {rowData.firstArrivalDate==rowData.checkin ? formatDateText(rowData.checkin) : formatDateText(rowData.checkin) + " (Renewed)"}
          </>
            
          )}
        </Cell>
      </Column>

      <Column width={150} sortable>
        <HeaderCell>Expected Checkout</HeaderCell>
        <Cell dataKey="checkout">
        {rowData => (
          <>
            {formatDateText(rowData.checkout)}
          </>
            
          )}
        </Cell>
      </Column>
      <Column width={150} sortable>
        <HeaderCell>Actual Checkout</HeaderCell>
        <Cell dataKey="actualCheckoutDate">
        {rowData => (
          <>
            {formatDateText(rowData.actualCheckoutDate)}
          </>
            
          )}
        </Cell>
      </Column>
      
      <Column width={70} sortable>
        <HeaderCell>Rent </HeaderCell>
        <Cell dataKey="rate" >
        {rowData => (
              <>
                <b> $</b>{rowData.rate}
              </>    
          )}
        </Cell>
      </Column>
      

    </Table>
    </div>
  );
}
export default DailyReport;