import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { NumericFormat } from 'react-number-format';
import { dateDifference, formatDateText, formatDate} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    Menu,
    Paper
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
  } from '@mui/x-data-grid';
import TenancyDetailsByLTTI from "./TenancyDetailsByLTTI";
import Header from "./Header";
import { Table , Rate , RadioTile} from 'rsuite';
import {  Col, Row,Form } from "react-bootstrap";
import PaymentsByTenancyId from "./PaymentsByTenancyId";

const { Column, HeaderCell, Cell } = Table;

const PaymentHistory = ({}) => {
  const [checkin, setCheckin]                     = useState (formatDate(new Date(),-7)) ;
    const [checkout, setCheckout]                   = useState (formatDate(new Date())) ;
    const [reportSummary,setReportSummary]=useState([]);
    const [showTenancyDetailsByLTTIModal,
      setShowTenancyDetailsByLTTIModal]= useState(false);
    const [showPaymentDetailsByTenancyIdModal,
      setShowPaymentDetailsByTenancyIdModal]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow,setSelectedRow] = useState({})
    const navigate=useNavigate();
    const url=process.env.REACT_APP_SERVER_URL;
  useEffect(() => {
    
  }, []);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns=[
    { field: `roomid`, headerName: 'Room Number', width: 100, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;
      
        return <Link onClick={(e)=>{
          // setTenancyJson(params.row);
          // setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
          // setShowTaxCalculationModal(true)
        }}>
          {currentRow.roomid}
        </Link>
      
      
    } },
    { field: 'checkin', headerName: 'Tenancy Period', width: 300, align: 'left' ,
        renderCell:(params)=>{
          const currentRow=params.row;
          const first=currentRow.checkin;
          const last=params.row.actualCheckoutDate ?
          params.row.actualCheckoutDate : 
          params.row.checkout;
          
            return <Link onClick={(e)=>{
              // setTenancyJson(params.row);
              // setUnitJson({id:params.row.id,name:params.row.name,streetAddress:params.row.streetAddress});
              // setShowTaxCalculationModal(true)
              setSelectedRow(params.row)
              setShowTenancyDetailsByLTTIModal(true);
            }}>
              {formatDateText(first) + ' to ' + formatDateText(last)}
            </Link>
           
          
    } },
    
    { field: 'receiptNumber', headerName: 'Receipt Number', width: 150, align: 'left' },
    { field: 'paidBy', headerName: 'Paid By', width: 200, align: 'left' },
    { field: 'actualPaymentDate', headerName: 'Payment Date', width: 200, align: 'left' ,
        renderCell:(params)=>{
          const currentRow=params.row;
          
            return formatDateText(currentRow.actualPaymentDate) 
           
          
    } },

    // { field: 'overStay', headerName: 'OverStayed', width: 100, align: 'left' ,
    // renderCell:(params)=>{
    //   const overStay=params.row.overStay;

    //   if(overStay===0)  
    //     return <>Checkout Today</> 
        
    //   else if(overStay<0)  
    //     return <>{"Overstayed" + (-1*overStay) + " days" }</>
    //   else
    //     return <>{overStay + " day(s)"}</>
    //  } },
    
    { field: 'rate', headerName: 'Rent', width: 100, align: 'left' ,
    renderCell:(params)=>{
      const currentRow=params.row;
      return <NumericFormat value={currentRow.rate} thousandSeparator prefix={'$'} displayType="text"/>;

      
     } },
     { field: 'cashAmount', headerName: 'Cash Amount', width: 150, align: 'left' ,
        renderCell:(params)=>{
          const currentRow=params.row;

          if(currentRow.cashAmount)
          return <NumericFormat value={currentRow.cashAmount} thousandSeparator prefix={'$'} displayType="text"/>
          return "-"
    } },
    { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 150, align: 'left' ,
        renderCell:(params)=>{
          const currentRow=params.row;
    
          if(currentRow.creditCardAmount)
          return <NumericFormat value={currentRow.creditCardAmount} thousandSeparator prefix={'$'} displayType="text"/>
          return "-";
    } },
    { field: 'otherAmount', headerName: 'Other Amount', width: 150, align: 'left' ,
        renderCell:(params)=>{
          const currentRow=params.row;
    
          if(currentRow.otherAmount)
          return <NumericFormat value={currentRow.otherAmount} thousandSeparator prefix={'$'} displayType="text"/>
          return "-"
    } },
    { field: 'amountPaid', headerName: 'Total', width: 150, align: 'left' ,
    renderCell:(params)=>{
        const currentRow=params.row;
      return <NumericFormat value={currentRow.amountPaid} thousandSeparator prefix={'$'} displayType="text"/>
     } },
  ];
  
  const getAllActiveTenantsInfo= async(e,searchStartDate,searchEndDate)=>{
    try{
        const dateColumnFilter="actualPaymentDate";
        const response = await axios.post(`${url}/payments/allPaymentsinDateRange`,{
            searchStartDate,
            searchEndDate,
            dateColumnFilter
        })
      if(response){
        console.log(response.data)
        setReportSummary(response.data);
      }

    }
    catch(error){
      console.log(error)

    }
  }
  const preProcessedRows = (rows)=>{
    const resultArray=rows.map((row) => {
    var temp=0;
        if(row.cashAmount!==null){
          temp+=row.cashAmount;
        } 
        if(row.creditCardAmount!==null){
          temp+=row.creditCardAmount;
        } 
        if(row.otherAmount!==null){
          temp+=row.otherAmount;
        }
    return { ...row, roomid:parseInt(row.roomid),amountPaid: temp };
  });
  return resultArray;
}
 
  


  
   
    
    return (
        <>
        <Header/>
        
        <br></br>
        <Paper style={{margin:"0% 10%", padding:"1.2% 0%"}} >
          <Box style={{margin:"0% 10%", display:"flex" , justifyContent:"space-around"}}>
            <TextField id="outlined-basic" label="Start Date" variant="outlined" 
            type="date" value={checkin} onChange={(e)=>{
                  setCheckin(e.target.value);
              }}
            />
            <TextField id="outlined-basic" label="End Date" variant="outlined" 
            type="date" value={checkout} onChange={(e)=>{
              setCheckout(e.target.value);
              }}
            />
            <b className="mt-3"
            > Selected for {dateDifference(checkin,checkout) + " days"}</b>
            <Button variant="contained" onClick={(e)=>getAllActiveTenantsInfo(e,checkin,checkout)}>Search</Button>
          </Box>
        </Paper>
        
        <br></br>
        
          <Box
        sx={{
          height: 450,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={preProcessedRows(reportSummary)} columns={columns}
        getRowId={(row) => row.paymentId}
        />
        
      </Box>
      {showTenancyDetailsByLTTIModal && <TenancyDetailsByLTTI
      tenancyDetailsProps={selectedRow}
        showTenancyDetailsByLTTIModal={showTenancyDetailsByLTTIModal}
        setShowTenancyDetailsByLTTIModal={setShowTenancyDetailsByLTTIModal}
        />}
      { showPaymentDetailsByTenancyIdModal && <PaymentsByTenancyId
        paymentDetailsProps={selectedRow}
        showPaymentDetailsByTenancyIdModal={showPaymentDetailsByTenancyIdModal}
        setShowPaymentDetailsByTenancyIdModal={setShowPaymentDetailsByTenancyIdModal}

      />}
      
        </>
    );

}
export default PaymentHistory;