import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import $ from 'jquery';
import Header from "./Header.js";
import { Link, redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/esm/Button.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import {Row,Col,Table} from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import {formatDate, parseResponseJSON,getDataSorted,formatDateText} from "./CommonFunctions";
import CommonComponents from "./CommonComponents.js";
import { Divider,Badge } from "rsuite";
import { AddAmenity } from "./AddAmenity.js";
import { Table , Rate , RadioTile} from 'rsuite';
import PageIcon from '@rsuite/icons/Page';
import NewPost from "./AddImage.js";
// import "../css/reports.css";
const { Column, HeaderCell, Cell } = Table;
export const Amenities=()=>{
    const [description,setDescription]=useState("");
    const [amenityType,setAmenityType]=useState("");
    const [addeddate,setAddeddate]=useState("");
    const [roomNumber,setRoomNumber]=useState("");
    const [amenities, setAmenities] = useState([]);
    const [isAddAmenity,setIsAddAmenity]=useState(false);
    const [amenityId,setAmenityId]=useState("");
    const [isImageUpload,setIsImageUpload] = useState(false);
    const [responseForAmenity,setResponseForAmenity]=useState("");
    const [showDeleteAmenityPopup, setShowDeleteAmenityPopup]=useState(false);
    const [allRooms,setAllRooms]=useState([]);
    const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [loading, setLoading] = useState(false);
    const {displayNotificationMessage}=useContext(CommonComponents);
    const {roomId} = useParams();
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      if(roomId){
        getAmenitiesByRoomId(roomId);

      }
      else{
      getAmenities();
      }
      getAllRooms();
    }, []);
    useEffect(() => {
      if(roomId){
        getAmenitiesByRoomId(roomId);
      }
      else{
      getAmenities();
      }
      getAllRooms();
    }, [roomId]);
    const getAllRooms=async()=>{
      try{
        await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
          console.log(responseForAllRooms.data);
          setAllRooms(responseForAllRooms.data);
        })
  
      }
      catch(error){
        console.log(error);
      }
     }


     const getAmenitiesByRoomId = async(roomId)=>{
      try{
        await axios.get(`${url}/amenities/getAmenitiesByRoomId/${roomId}`).then((responeForGettingAmenitiesByroomId)=>{
          console.log(responeForGettingAmenitiesByroomId.data);
          setAmenities(responeForGettingAmenitiesByroomId.data)
        })
  
      }
      catch(error){
        console.log(error);
          displayNotificationMessage("error",error.response.data);
      }
     }



    const getAmenities = async () => {
      try{
      await axios.get(`${url}/amenities`).then((responseForGettingAmenities)=>{
        setAmenities(responseForGettingAmenities.data);

      })
     
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    };
    const handleSortColumn = (sortColumn, sortType) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
      }, 500);
    };
    const handleShowAddAmenity=(amenityId)=>{
      setIsAddAmenity(true);
      if(amenityId){
        setAmenityId(amenityId);
        getAmenityById(amenityId);
      }
      else{
        setRoomNumber("");
        setDescription("");
        setAddeddate("");
        setAmenityType("")
        setAmenityId("");
        setResponseForAmenity("");
      }
    }
    const handleAddImage = (amenityId) =>{
      setIsImageUpload(true);
      if(amenityId){
        setAmenityId(amenityId)
      }
    }
    const handleCloseAddAmenity=()=>{
      setAmenityId("")
      setIsAddAmenity(false);
      setResponseForAmenity("")
    }

    const handleCloseDeleteAmenityPopup=()=>{
      setAmenityId("")
      setShowDeleteAmenityPopup(false);
      setResponseForAmenity("")
    }
    const handleShowDeleteAmenityPopup=(amenityId)=>{
      setAmenityId(amenityId)
      setShowDeleteAmenityPopup(true)
      
    }
    const deleteAmenity=async()=>{
      try{
        await axios.delete(`${url}/amenities/${amenityId}`).then((responseForDeletingAmenity)=>{
          displayNotificationMessage("warning","Deleted Amenity Succesfuly");
          handleCloseDeleteAmenityPopup();
        });
        getAmenities();

      }catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }
    const editAmenityDetails=async(e,amenityId)=>{
      if(e) e.preventDefault();
      try{
        var roomid=roomNumber;
        var type=amenityType;
        await axios.patch(`${url}/amenities/${amenityId}`, {
        roomid,
        description,
        type,
        addeddate
      }).then((responseForEditAmenity)=>{
        console.log( "Amenity updated (" + amenityId +" ). Status - " + responseForEditAmenity.status);
          getAmenities();
          displayNotificationMessage("success","Updated Amenity Details");
          handleCloseAddAmenity();

      })
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data);
      }
    }
    const saveAmenityDetails=async(e)=>{
      if(e) e.preventDefault();
      try{
        var roomid=roomNumber;
        var type=amenityType;
        await axios.post(`${url}/amenities`, {
        roomid,
        description,
        type,
        addeddate
      }).then((responseForSaveAmenity)=>{
        setResponseForAmenity("Saved");
        const tempDbResponse=parseResponseJSON(responseForSaveAmenity.data);
        if(tempDbResponse)
        {
          setAmenityId(tempDbResponse.data)
        getAmenities();
        handleCloseAddAmenity();
        }

        

      })
      }
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 
      }
      
    }
    const getAmenityById=async(amenityId)=>{
      try{
        await axios.get(`${url}/amenities/${amenityId}`).then((responseForAmenityId)=>{
          const tempResponseForAmenityId=parseResponseJSON(responseForAmenityId.data);
          console.log(tempResponseForAmenityId[0])  
          setRoomNumber(tempResponseForAmenityId[0].roomid);
          setDescription(tempResponseForAmenityId[0].description);
          setAddeddate(tempResponseForAmenityId[0].addeddate);
          setAmenityType(tempResponseForAmenityId[0].type)
          setAmenityId(amenityId);
        });  
        
      }
      
      catch(error){
        console.log(error);
        displayNotificationMessage("error",error.response.data); 
      }                      
    }

    return (
      <div> 
        <Header/>
        <Button className="mt-2 mb-2 me-5" 
        // onMouseMove={(e)=>handleCloseAddAmenity()}
        // onTouchMove={(e)=>handleCloseAddAmenity()}
        onClick={()=>handleShowAddAmenity(null)} style={{marginLeft:"80%"}}>Add an Amenity</Button>

      {isAddAmenity && <AddAmenity value={{amenityId:amenityId}} getAmenities={getAmenities} handleCloseAddAmenity={handleCloseAddAmenity}/>}
      {isImageUpload && <NewPost value={{amenityId:amenityId}}/>}



      <Table
    className="ms-5"
      autoHeight
      wordWrap="break-word"
    //   data={activeTenancyDetails}
    data={getDataSorted(sortColumn,sortType,amenities)}
    // data={amenities}
      size="lg"
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}

    >

     

      <Column width={130} fixed sortable>
        <HeaderCell><b>Room</b></HeaderCell>
        <Cell dataKey="roomid">
          {rowData => (
           <Link to='/rooms'>{rowData.alias}</Link>
          )}
          </Cell>
      </Column>
  


      <Column width={300}>
        <HeaderCell>Description</HeaderCell>
        <Cell dataKey="description" />
      </Column>

      <Column width={200} sortable>
        <HeaderCell>Added At</HeaderCell>
        <Cell dataKey="addeddate">
        {rowData => (
            <>
            {rowData.addeddate ? formatDateText(rowData.addeddate) : ""}
            </>
            
          )}
        </Cell>
      </Column>
            
      <Column width={200} sortable>
        <HeaderCell>Amenity Type</HeaderCell>
        <Cell dataKey="type" />
      </Column>

      {/* <Column width={60} sortable>
        <HeaderCell>Total Amount Paid</HeaderCell>
        <Cell dataKey="paidAmount" />
      </Column> */}
      <Column width={300}>
        <HeaderCell>Actions</HeaderCell>
        <Cell style={{ padding: '6px' }} dataKey="stayDuration">
          {rowData => (
              <>
               <Link
                  onMouseMove={(e)=>handleCloseAddAmenity()}
                  onTouchMove={(e)=>handleCloseAddAmenity()}
                    onClick={()=>handleShowAddAmenity(rowData.id)}
                    // className="button1 is-small is-info mr-2"
                  >
                    Edit
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Link
                    onClick={()=>handleShowDeleteAmenityPopup(rowData.id)}
                    // className="button1 is-small is-info mr-2"
                  >
                   Delete
                  </Link>
                  <Divider vertical style={{backgroundColor:"grey"}}/>
                  <Badge content={rowData.imageCount} size="1em" >
                  <PageIcon style={{color:"blue",backgroundColor:"#f2f2f5", marginRight: 4, fontSize: '1.3rem'}} 
                  onMouseMove={(e)=>setIsImageUpload(false)}
                  onTouchMove={(e)=>setIsImageUpload(false)}
                  onClick={()=>handleAddImage(rowData.id)}
                  />
                  </Badge>
              </>    
          )}
        </Cell>
      </Column>
    </Table>


        <Modal show={showDeleteAmenityPopup} onHide={handleCloseDeleteAmenityPopup}>
          <Modal.Header style={{fontSize:"large"}} closeButton>
            {responseForAmenity ? responseForAmenity :"Warning: Are you sure to delete this Amenity?"}
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={handleCloseDeleteAmenityPopup}>No</Button>
            <Button onClick={deleteAmenity}>Yes</Button>

          </Modal.Footer>
        </Modal>



      </div>
    );


}