

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import $ from 'jquery';
import Header from "./Header.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faSearch,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import {Form,Row,Col,Button,Modal} from "react-bootstrap"
import MakePayment from "./MakePayment";
import { Message } from "rsuite";

const PreviousGuests = () => {
    const [searchStartDate,setSearchStartDate]=useState(formatDate(new Date(),-31));
    const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date(),1));
    const [roomNumber,setRoomNumber]=useState("")
    // const [selectedTenancyDetails,setSelectedTenancyDetails]=useState({});
  
    const [tenancyDetails,setTenancyDetails]=useState([]);
    // const [rroomIds,setRRoomIds]=useState([])
    // const [roomid,setRoomid]=useState(null)
    // const [tenantIds,setTenantIds]=useState([]);    
    const [paymentDetails,setPaymentDetails]=useState({})
    const [tenancyId,setTenancyId]=useState(null);
    const [paymentId,setPaymentId]=useState(null);
    const [allRooms,setAllRooms]=useState([]);
    const [isRoomNumberInSearch, setIsRoomNumberInSearch]=useState(false)
    const url=process.env.REACT_APP_SERVER_URL;
    var balance=0;
    var previd=0;
    var totalRunningBalance=0;
    let paymentid=null;
    var totalRent=0;
    var totalAmountPaid=0;
      

    useEffect(() => {
        // displayRooms();
        // getPaymentHistoryByTenantId();
        // getCustomerDetailsByTenantId(); 
        getAllRooms();
        
      }, []);
      const getAllRooms=async()=>{
        try{
          await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
            console.log(responseForAllRooms.data);
            setAllRooms(responseForAllRooms.data);
          })
    
        }
        catch(error){
          console.log(error);
        }
       }
       
       const getPreviousTenantsByRange = async() =>{
        try{
            await axios.post(`${url}/prevTenants`,{
                searchStartDate,
                searchEndDate,
                roomNumber

            }).then((responseForprevTenants)=>{
                console.log(responseForprevTenants.data);
                setTenancyDetails(responseForprevTenants.data);
              })


        }catch(error){
          console.log(error);
        }
       }
  




    return(
        <div>
            <Header/>
            <div className="container">
            {/* <h4 className="ms-5 mt-3">Search By Start and End Date: </h4> */}
            
            <Row className="ms-5 ps-5 mt-3 mb-0">
              <Col className="ms-5 ps-5" style={{float:"right"}}>
              <Form.Label><b>Select a Room:</b></Form.Label>
                <Form.Select className="w-75 h-50"  size="sm"
                    value= { roomNumber }  onChange={(e)=>{setRoomNumber(e.target.value)}}>
                  <option value=''  ></option>
                  {allRooms.map((row,index)=>(
                  <option value={row.id}>{row.id}</option>
                ))}
                </Form.Select>
              </Col>
              <Col>
              <Form.Label><b>Start Date:</b></Form.Label>
              <Form.Control
                className="w-75"
                type="date"
                value ={searchStartDate} 
                onChange={(e)=>{
                  setSearchStartDate(e.target.value);
                }}
                aria-describedby="basic-addon1"
              />
              </Col>
              <Col>
                <Form.Label><b>End Date:</b></Form.Label>
                <Form.Control
                  className="me-5 w-75"
                  type="date"
                  value ={searchEndDate} 
                  onChange={(e)=>{
                    setSearchEndDate(e.target.value);
                  }}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col className="w-25 h-25 mt-4 me-5">
              <Button variant="secondary" onClick={(e)=>getPreviousTenantsByRange(e)}><b>Search</b></Button>
              </Col>
    
            </Row>

            
            <br></br>
            </div>
            <div className="container">
            

              {tenancyDetails.length ? 


<>
<table className="table is-striped is-fullwidth" id="payment-history">
          <thead>
            <tr>
              <th>Room Number</th>
              <th>Guests</th>
              <th>First Checkin Date</th>
              <th>Checkin</th>
              <th>Checkout</th>
              <th>Actual Checkout Date</th>
              <th>Room Rate</th>
            </tr>
          </thead>
          <tbody>
            {tenancyDetails.map((row, index) => (
              <tr>
                <td><Link to={`/tenants/add/${row.id}`}>{row.roomid}</Link></td>
                <td>{row.names}</td>
                <td>{row.firstArrivalDate}</td>
                <td>{row.checkin}</td>
                <td>{row.checkout}</td>
                <td>{row.actualCheckoutDate}</td>
                <td>{row.rate}</td>
              </tr>
            ))}


          <tr>
           
          </tr>
          
          </tbody>
        </table>



        </>
         : <Message showIcon type="error" closable>
         No transactions in the selected Date Range
       </Message>} 


            
               <br></br>

           
            </div>
        </div>
    )
};
export default PreviousGuests;



