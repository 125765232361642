import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { formatDateText, parseResponseJSON} from "./CommonFunctions";
import { Box } from "@mui/material";
import { DataGrid,GridPagination } from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";

const PaymentsByTenancyId = ({paymentDetailsProps,tenancyId,showPaymentDetailsByTenancyIdModal,setShowPaymentDetailsByTenancyIdModal}) => {
   
    
    const url=process.env.REACT_APP_SERVER_URL;
    const [paymentDetails,setPaymentDetails]=useState([]);
    const [totalRent,setTotalRent]=useState(0);
    var [totalAmountPaid,setTotalAmountPaid]=useState(0);
    var runningBalance=0;
    
      const handleClosePaymentDetailsByTenancyIdPopup=()=>{
        setShowPaymentDetailsByTenancyIdModal(false)
      }
      useEffect(() => {
        if(paymentDetailsProps){
          console.log(paymentDetailsProps)
            setPaymentDetails(preProcess(paymentDetailsProps.payments));
            setTotalAmountPaid(paymentDetailsProps.amountPaid);
        }
        else{
          if(tenancyId){
          }
        }
        
        
      }, []);
      const preProcess = (data)=>{
        var resultArray=[]
        for(var itr in data){
          resultArray=resultArray.concat(data[itr]);
        }
        console.log(resultArray);
        return resultArray;
      }

      
      const columns=[
        // { field: 'actualPaymentDate', headerName: 'Payment Date', width: 100, align: 'left'},
        { field: 'actualPaymentDate', headerName: 'Payment Date', width: 200, align: 'left' ,
          renderCell:(params)=>{
              return formatDateText(params.row.actualPaymentDate)
          } },
        { field: 'cashAmount', headerName: 'Cash Amount', width: 150, align: 'left' ,
            renderCell:(params)=>{
                return <NumericFormat value={params.row.cashAmount} 
                  thousandSeparator prefix={'$'} displayType="text"/>
        } },
        { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 150, align: 'left' ,
            renderCell:(params)=>{
                return <NumericFormat value={params.row.creditCardAmount} 
                  thousandSeparator prefix={'$'} displayType="text"/>
        } },
        { field: 'otherAmount', headerName: 'Other Amount', width: 150, align: 'left' ,
            renderCell:(params)=>{
                return <NumericFormat value={params.row.otherAmount} 
                  thousandSeparator prefix={'$'} displayType="text"/>
        } },
        { field: 'amountPaid', headerName: 'Amount Paid', width: 150, align: 'left' ,
          renderCell:(params)=>{
            const cr=params.row;
            var amountPaid=0;
            if(cr.cashAmount!==null){
              amountPaid+=cr.cashAmount;
            } 
            if(cr.creditCardAmount!==null){
              amountPaid+=cr.creditCardAmount;
            } 
            if(cr.otherAmount!==null){
              amountPaid+=cr.otherAmount;

            }
            
            return <NumericFormat value={amountPaid} 
              thousandSeparator prefix={'$'} displayType="text"/>
          } },
          
      ];
      function CustomPagination(props) {
        return <>
            <b>Total Amount
            <NumericFormat value={totalAmountPaid} thousandSeparator prefix={'$'} displayType="text"/></b>
            <GridPagination  {...props} /> </>;
      }

   
    
    return (
        <>
      <Dialog
        open={showPaymentDetailsByTenancyIdModal}
        onClose={handleClosePaymentDetailsByTenancyIdPopup}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <Box
        sx={{
          height: 340,
          width: '97%',
          marginLeft:'1.5%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={paymentDetails} columns={columns}
        getRowId={(row) => row.paymentId}
        slots={{
          pagination: CustomPagination,
        }}
        />
        
      </Box>
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDetailsByTenancyIdPopup}>Cancel</Button>
          {/* <Button type="submit" onClick={(e)=>{
            
          }}>Save</Button> */}
        </DialogActions>
      </Dialog>
                
            
        </>
    );

}
export default PaymentsByTenancyId;