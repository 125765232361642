import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../css/headerStyle.css";
import {ModalBody, Table,Modal,Form,Button,Tabs,Tab,Nav, Navbar , Container , NavDropdown} from "react-bootstrap";
import SendSMS from "./SendSMS"
import AddInquery from "./AddInquery";
// import { useAuth0 } from "@auth0/auth0-react";
const Header = () => {
  // const { loginWithRedirect,logout } = useAuth0();
  // const { user, isAuthenticated, isLoading } = useAuth0();
  // const { id1,id2 } = useParams();
    // const [activeItem, setActiveItem] = useState('home');
    const [inqueriesActivity,setInqueriesActivity]=useState(false);
    const navigate=useNavigate();
  
 
  return (
    <>
      <header class="header" style={{width:"100%"}}>
  <input class="menu-btn" type="checkbox" id="menu-btn" style={{color:"white", fontSize:"16px"}} />
  <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
  <ul class="menu">
    {/* Default room availability report */}
  <li><Link style={{color:"white", fontSize:"20px"}} id="logo"><b>  TidyInn  </b></Link></li>
  <li><Form.Select placeholder="Reports" onChange={(e)=>{
    if(e.target.value === "existing guests")
    navigate('/tenants');
  else
  navigate("/SampleWizard");

  }}>Manage Guests
    <option value="new guests">Checkin New Guests</option>
      <option value="existing guests"><Link to={`/tenants`} >Manage Existing Guests</Link></option>
      </Form.Select></li>
    {/* <li><Link style={{color:"white", fontSize:"16px"}} to={`/tenants/add`} ><b>Checkin New Guests</b></Link></li> 
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/tenants/add`} ><b>Manage Existing Guests</b></Link></li>  */}
    {/* <li><Link style={{color:"white", fontSize:"16px"}} to={`/tenants/add`} ><b>Room Availability</b></Link></li> */}
    {/* <li><Link style={{color:"white", fontSize:"16px"}} to={`/tenants`} >Active Tenants</Link></li> */}
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/rooms`} >Rooms</Link></li>
    {/* <li> <Link style={{color:"white", fontSize:"16px"}} to={`/reports`} > Reports</Link> </li> */}
    {/* fix the styling */}
    <li><Form.Select placeholder="Reports">Reports
    <option>Room Availability</option>
      <option>Current Guests</option>
      <option>Active Tenants</option>
      <option>Tenant History</option>
      <option>Payment Report</option>
      <option>Repairs</option>
      </Form.Select></li>
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/repairs`} >Repairs</Link></li>
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/amenities`} >Amenities</Link></li>
    {/* <li><Link style={{color:"white", fontSize:"16px"}} to={`/payments`} >Payments</Link></li> */}
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/sms`} >SMS</Link></li>
    {/* <li><Link style={{color:"white", fontSize:"16px"}} to={`/`} >Reviews</Link></li> */}
    <li><Link style={{color:"white", fontSize:"16px"}} to={`/images`} >Image upload</Link></li>
   
    {/* <li><a><Link to={`/guestReviews`} >Guest Reviews</Link></a></li>
  <li><a><Link to={`/`} >Inventory</Link></a></li>
  <li><a><Link to={`/`} >Improvements</Link></a></li> */}
    <li><Link style={{color:"white", fontSize:"16px"}} 
        onMouseMove={()=>setInqueriesActivity(false)}
        onTouchMove={()=>setInqueriesActivity(false)}
         onClick={()=>setInqueriesActivity(true)}>
      Inqueries</Link></li> 
      {inqueriesActivity && <AddInquery />}
    
  </ul>
</header>
      {/* <nav>
<div class="menu-icon">
  <span class="fas fa-bars"></span>
</div>

<div class="logo">TIDY Inn LLC</div>

<div class="nav-items">


<li><a><Link to={`/tenants/add`} >Manage Guests</Link></a></li>  
<li><a><Link to={`/tenants`} >Active Tenants</Link></a></li>
  <li><a><Link to={`/rooms`} >Rooms</Link></a></li>
  <li><a> <Link to={`/reports`} > Reports</Link></a> </li>
  <li><a><Link to={`/repairs`} >Repairs</Link></a></li>
  <li><a><Link to={`/amenities`} >Amenities</Link></a></li>
  <li><a><Link to={`/payments`} >Payments</Link></a></li>
  <li><a><Link to={`/sms`} >SMS</Link></a></li> */}
  {/* <li><a><Link to={`/sms`} >Cash Management</Link></a></li> */}
  {/* <li><a><Link to={`/guestReviews`} >Guest Reviews</Link></a></li>
  <li><a><Link to={`/`} >Inventory</Link></a></li>
  <li><a><Link to={`/`} >Improvements</Link></a></li> */}
  {/* <li><a><Link onMouseMove={()=>setInqueriesActivity(false)} onClick={()=>setInqueriesActivity(true)}>Inqueries</Link></a></li> */}
  {/* {isAuthenticated===false && <li><a><Link onClick={() => loginWithRedirect()}>Log In</Link></a></li>}
  {isAuthenticated===true && <li><a><Link onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log out</Link></a></li>} */}
{/* </div>
{inqueriesActivity && <AddInquery />} */}


{/* </nav> */}
    </>
  );
};
 
export default Header;

