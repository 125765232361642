
import React, { useState } from "react";
import axios from "axios";
import { Form, Card, Button,Row,Col,Table } from "react-bootstrap";
import validator from "validator";
import { DateRangePicker , AutoComplete, Input } from 'rsuite';

import {formatDate, parseResponseJSON} from "./CommonFunctions";
// creating functional component ans getting props from app.js and destucturing them
const StepFour = () =>{
    const [hasPets,setHasPets]                      = useState(0);

  const url=process.env.REACT_APP_SERVER_URL;
  return (
    <Table striped>
    <Row className="mt-4 mb-4">
    <Col sm>Number of pets</Col>
    <Col sm>
        <Form.Control
        type="number"
        min="0"
        value={hasPets}
        onChange={(e)=>setHasPets(e.target.value)} 
        aria-describedby="basic-addon1"
        />
    </Col>
    <Col sm>Pet Deposit</Col>
    <Col sm>
        <Form.Control
        type="number"
        min="0"
        value={hasPets}
        onChange={(e)=>setHasPets(e.target.value)} 
        aria-describedby="basic-addon1"
        />
    </Col>
    </Row>
    <Row>
    <Col sm>Description</Col>
    <Col sm>
        <Form.Control
        type="text"
        min="0"
        value={hasPets}
        onChange={(e)=>setHasPets(e.target.value)} 
        aria-describedby="basic-addon1"
        />
    </Col>

    </Row>
    </Table>
  );
}

export default StepFour;
