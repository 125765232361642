import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Stack,
    Grid,
    Rating,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Drawer

} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { experimentalStyled as styled } from '@mui/material/styles';
import Header from "../Header";
import { NumericFormat,PatternFormat } from "react-number-format";
import MainCard from "../AdminDashboard/MainCard";
import CarouselSample from "./CarouselSample";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const CardDemo = ({navigationUrl,title="",value=5,name="Saranya Chowdary Pathuri",
    description=`"Overall, my experience at Tidy Inn Motel exceeded my expectations. 
    It's clear that the management and staff truly care about their guests, 
    and it shows in every aspect of the service. " `
}) => {
    return (
        <MainCard>
    <Stack spacing={0.5}>
      <Grid container alignItems="center">
          <Grid item>
        <Typography variant="h6" color="text.secondary">
            {/* Rating */}
            <Rating name="read-only" value={value} readOnly />
        </Typography>
        <Typography variant="b" color="text.secondary">
            {/* Rating */}
            <b>{name}</b>
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {/* Rating */}
           {description}
        </Typography>
          </Grid>
      </Grid>
    </Stack>
    </MainCard>
      
    );
  };
const CardGroup = () =>{
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={12} sm={6} md={3}>
      <Item><CardDemo name="virgina smith" 
      description={`I really enjoyed staying here. The staff was so friendly. 
        The room I got was nice and comfy, really made me feel like I was at home. 
        The floor was absolute beautiful. I do believe, it was my favorite part.`}
       /></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
    <Item><CardDemo name="Londa Thomas" 
      description={`Tidy Inn is a great place to stay. I just relocated to Florida and needed a safe place while 
        looking for an apartment since I work in Florida. The rooms are clean, 
        the owner keeps the place updated all the time. The owner is very down to earth and trying to help others. 
        He is always making upgrades to the rooms. The price is reasonable, friendly property manager. 
        There is no traffic none really at all. Quite, cozy, and allows you time to get back on track with no drama. 
        I’ve been very happy with Tidy Inn`}
       /></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}> 
      <Item><CardDemo name="September Breeze" 
      description={`I been staying here since october when i took my children and left my husband, 
        I have not had any problems living here. The manager is the sweetest woman you will ever meet. 
        The owner is on top of his property making it back to the joy it was when it was first built. 
        They take pride in making sure your every need is satisfied. 
        If you had not had the chance to come be a part of tidy inn even for one night 
        you should try on your next hotel stay.`}
      /></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Item><CardDemo name="TEALA Work" 
      description={`The people are really caring and understanding. 
        The manager was absolutely awesome, and really made me feel at home. 
        I have come to truly love this place. If nothing else, 
        I know I'll definitely be coming back here, and 
        I would suggest that everyone come and experience the homely hospitality that Tidy Inn provides 
        to all of its customers.`}
      /></Item>
    </Grid>
</Grid>
    )
}
const FeaturesGrid =() =>{
    return (
        <>
         <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography>Part 1</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography>Part 2</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper sx={{ padding: 2, textAlign: 'center' }}>
          <Typography>Part 3</Typography>
        </Paper>
      </Grid>
    </Grid>
        </>
    )
}
const featureListStyling={
    padding: 2, textAlign: 'center', backgroundColor: "#99e6ff"

}
const navItems = [];
const junk=`Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
const MainPage=(props)=>{
    const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
    
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      
    }, []);
    const drawer = (
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
          <List>
            {navItems.map((item) => (
              <ListItem button key={item}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>
      );
   
    return (
      <>
      <AppBar color="primary">
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 2,  fontSize: { xs: '1.5rem', sm: '2rem' }, // Smaller font size on mobile
          paddingLeft: { xs: '2%', sm: '5%' }}}
        >
          <b style={{ color: 'white' }}>Tidy Inn</b>
        </Typography>
        <Box sx={{ display: 'block', }}>
          <Button sx={{ color: '#fff' }}>
            CONTACT US AT
            </Button>
             <u><b style={{ 
                color: 'white',fontSize: { xs: '1rem', sm: '2.5rem' } }}>
                    +1(863) 825-5007</b></u>
          
        </Box>
      </Toolbar>
    </AppBar>
      <br></br><br></br><br></br>
      
      <Box sx={{width: '100%',padding:"0% 7%",backgroundColor:"rgb(250, 250, 251)" }}>
      <img src="/tidyInnSampleImage.jpg"  height="auto"
            // width={ isMobile ? '370ch' : '400rem'}
            width="100%"
            style={{padding: 10, textAlign: 'center',
                
             }}/>
             <br></br><br></br>
      {/* <Grid container alignItems="center" sx={{padding:"0.5% 0%"}}>
          <Grid item>
        <Typography variant="h6" color="text.primary">
          Dashboard
        </Typography>
          </Grid>
          </Grid> */}
      <CardGroup/>
        <br></br>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={featureListStyling}>
                <Typography>{junk}</Typography>
                </Paper>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
        
        </Grid>
      </Grid>
      <br></br>
      <CarouselSample/>
      
      </Box>
      
      
      <br></br>
      

      
      </>
    );


}
export default MainPage;