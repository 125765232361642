
// import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {Grid,Stack,Box} from '@mui/material'
import MainCard from './MainCard';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardAnalysis from "./CardAnalysis"

// header style
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
function CardGroup({totalMonthlyAmount,currentVacancy,repairs,inventoryCount}) 
{
  console.log("Inventory :" + inventoryCount)

  return (
    
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Grid item xs={12} sm={6} md={3}>
      <Item><CardAnalysis title="Vacancy" isLoss={false} count={currentVacancy} isMoney={false}
      subTitle="rooms" navigateURL="/tenants/add"/></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Item><CardAnalysis title="Inventory Requests" isLoss={true} isMoney={false} count={inventoryCount}
      navigateURL="/inventory"/></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}> 
      <Item><CardAnalysis title="Active Repairs" isLoss={false} count={repairs} isMoney={false} 
       navigateURL="/repairs"/></Item>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Item><CardAnalysis title="This Month Revenue" isLoss={true} count={totalMonthlyAmount} isMoney={true}
      navigateURL="/paymentsHistory"/></Item>
    </Grid>
</Grid>
  );
}

export default CardGroup;

