// src/utils/auth.js
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

export const getUserRole = () => {
    const token = Cookies.get('token');
    if (!token) return null;

    const decoded = jwtDecode(token);
    return decoded.role;
};
export const getUserMail = () => {
    const token = Cookies.get('token');
    if (!token) return null;

    const decoded = jwtDecode(token);
    // console.log(decoded.email)
    return decoded.email;
};

export const isAuthenticated = () => {
    const token = Cookies.get('token');
   if(token){
    const decoded = jwtDecode(token);
    if(!(decoded.name))
    {
      return false;
      
    }
   }
    
    // console.log(token)
    return !!token;
};
export const isTokenExpired = () => {
    const token = Cookies.get('token');
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };
