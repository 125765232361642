import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "./Header.js";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css"
import { faHand } from "@fortawesome/free-solid-svg-icons";
import {ModalBody, Table,Modal,Form,Button,Tabs,Tab} from "react-bootstrap"
import {formatDate, parseResponseJSON} from "./CommonFunctions";
import AddInquery from "./AddInquery.js";

import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const SendSMS = (props) => {
  const [showMessagePopup,setShowMessagePopup]=useState(false);
  const [phoneNumber,setPhoneNumber]=useState(null);
  const [contactedDate,setContactedDate]=useState(formatDate(new Date()));
  const [remarks,setRemarks]=useState(null);
  const [selectedPhoneNumbers,setSelectedPhoneNumbers]=useState([]);
  const [potentialGuestsList,setPotentialGuestsList]=useState([]);
  const [currentGuestsList,setCurrentGuestsList]=useState([]);
  const [prevGuestsList,setPrevGuestsList]=useState([]);
  const [responseForSms,setResponseForSms]=useState("");
  const [showAddInqueryPopup,setShowAddInqueryPopup]=useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState({});
  const [list, setList] = useState([]);
  const url=process.env.REACT_APP_SERVER_URL;
  // const [filterValueForCurrentGuests,setFilterValueForCurrentGuests]=useState("all")
  const [description,setDescription]=useState("Thank You for reaching out to Tidy Inn. We have rooms available for 400 a week including taxes.\n"+
  " All rooms come with comfortable queen bed, a fridge, microwave and free WIFI.\n" +
  " Please call us at 863 825 5007. You can see the room before renting.\n"+
  "Visit us at tidyinnoflakeland.com");
  const navigate=useNavigate();

  useEffect(() => {
    getPotentialGuestsInfo();
    getAllCurrentGuests();
    filterPreviousGuests(7);
   },[]);

   useEffect(() => {
// setShowAddInqueryPopup(true)
// handleShowMessagePopup();
  }, [props]);

  const handleCloseMessagePopup=()=>{
    setShowMessagePopup(false);
    setResponseForSms("")
  }
  const handleShowMessagePopup=()=>{
    setShowMessagePopup(true);
  }
  const handleCloseAddInqueryPopup=()=>{
    setShowAddInqueryPopup(false)
  }
  const handleShowAddInqueryPopup=()=>{
    setShowAddInqueryPopup(true)
  }
  const handleSelectAll=(e)=>{
    if(e)
    setIsCheckAll(!isCheckAll);
    else
    setIsCheckAll(false)
    setIsCheck(currentGuestsList.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      console.log([])
    }
    else{
      console.log(currentGuestsList.map(li => li.id))
    }

  }
  const handleClick=(e)=>{
    // alert(e.target.id);
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
      console.log(isCheck.filter(item => item !== id))
    }
    else{
      console.log([...isCheck, id]);
    }
  }


 const sendSms = async(e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/sms`, {
        selectedPhoneNumbers,
        description
        
    }).then((responseForSendingSms)=>{
      setResponseForSms("Successfully sent")

    })
  }catch (error) {
      console.log(error);
      console.log(error.response.data)
      setResponseForSms(error.response.data);
  }
};
const getPotentialGuestsInfo=async(e)=>{
  if(e) e.preventDefault();
  try{
    await axios.get(`${url}/potentialGuests`).then((responseForGettingPotentialGuestsInfo)=>{
      console.log(responseForGettingPotentialGuestsInfo.data)
      setPotentialGuestsList(responseForGettingPotentialGuestsInfo.data)
    })
  }catch(error){
    console.log(error)
  }
}

const getTodayCheckins=async(e)=>{
  if(e) e.preventDefault();
  try{
    const responseForTodayCheckins=await axios.get(`${url}/todayCheckins`);
    console.log(responseForTodayCheckins.data)
    setCurrentGuestsList(responseForTodayCheckins.data)

  }catch(error){
    console.log(error)
  }
}
const getTmroCheckouts=async(e)=>{
  if(e) e.preventDefault();
  try{
    const responseForTmroCheckouts=await axios.get(`${url}/nreports`);
    
    const tempList=[];
    for(var i=0;i<responseForTmroCheckouts.data.length;i++)
    {
      var names=responseForTmroCheckouts.data[i].names.split(',');
      var phoneNumbers=responseForTmroCheckouts.data[i].phoneNumbers.split(',');
      for(var j=0;j<names.length;j++){
        // console.log(names[j])
        var tempJson={'name':names[j],'phone':phoneNumbers[j]}
        // console.log(tempJson)
        tempList.push(tempJson);
      }
    }
    console.log(tempList)
    setCurrentGuestsList(tempList)


  }catch(error){
    console.log(error)
  }

}
const getAllCurrentGuests=async(e)=>{
  if(e) e.preventDefault();
  try{
    const responseForAllCurrentGuests=await axios.get(`${url}/currentGuests`);
    console.log(responseForAllCurrentGuests.data)
    setCurrentGuestsList(responseForAllCurrentGuests.data)
    
  }catch(error){
    console.log(error)
  }
}
  const filterCurrentGuests=(targetValue)=>{

    if(targetValue=="all-current-guests")
    {
      getAllCurrentGuests();
    }
    else if(targetValue=="Today-checkins"){
      getTodayCheckins();
    }
    else if(targetValue=="Tomorrow-checkouts"){
      getTmroCheckouts();
    }
    setCurrentGuestsList([])
    handleSelectAll(null);
    setIsCheck([]);
  }
  const filterPreviousGuests=async(targetValue)=>{
    try{
      await axios.post(`${url}/prevGuests`,{
        targetValue
      }).then((responseForPrevGuests)=>{
        console.log(responseForPrevGuests.data.length)
        setPrevGuestsList(responseForPrevGuests.data)
      })
    }catch(error){
      console.log(error);
    }
  }
  const filterInqueries=async(targetValue)=>{
    try{
      await axios.post(`${url}/potentialGuests/inqueries`,{
        targetValue
      }).then((responseForInqueries)=>{
        console.log(responseForInqueries.data.length)
        setPotentialGuestsList(responseForInqueries.data)
      })

    }catch(error){
      console.log(error)
    }
  }
  const tabsContent=(guestsList)=>{
    return (
      <>

        <Table className="w-75">
          <thead>
            <tr>
              <th>
                <Form.Check className="w-25 ms-2 mt-2" 
                  checked={isCheckAll} onChange={(e)=>handleSelectAll(e,true)}>
                </Form.Check>
              </th>
              <th>Name</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          {guestsList.map((row, index) => 
            <tr>
              <Form.Check  id={row.id} checked={isCheck[row.id]}  
                onChange={(e)=>{
                  if(e.target.checked)
                    selectedPhoneNumbers.push(row.phone);
                  else{
                    const index = selectedPhoneNumbers.indexOf(row.phone);
                  if (index > -1) { // only splice array when item is found
                    selectedPhoneNumbers.splice(index, 1); // 2nd parameter means remove one item only
                  }
                }
                handleClick(e)}}>
              </Form.Check>
              <td>{row.fname+" "+row.lname}</td>
              <td>{row.phone}</td>
            </tr> 
          )}
        </Table>
      </>

    );
  }
  return (
    <div>
      <Header/>

      <br></br>
      <div style={{float:"right"}}>
      <Button className="ms-5" onClick={(e)=>handleShowMessagePopup()}>Send Message</Button>
      <Button className="ms-5" 
      // onTouchMove={(e)=>setShowAddInqueryPopup(false)}
      //  onMouseMove={(e)=>setShowAddInqueryPopup(false)} 
       onClick={(e)=>setShowAddInqueryPopup(true)}>Add Inquiry</Button>
      </div>
      <Tabs defaultActiveKey={3}  className="mb-3 ms-5" variant="underline" onSelect={(e)=>setSelectedPhoneNumbers([])}>
        <Tab eventKey={1} title="Current Guests" className="ms-5">
            <Form.Select className="mb-3 ms-5 w-25" 
              style={{float:"left"}}
              onChange={(e)=>filterCurrentGuests(e.target.value)}>

                <option value="all-current-guests">All</option>
                <option value="Today-checkins">Today Checked-In Guests</option>
                <option value="Tomorrow-checkouts">Expected Tomorrow Checkouts</option>
                
            </Form.Select>
            <label style={{float:"right",marginRight:"50%"}}><b>Count : {currentGuestsList.length}</b></label>
            <Table className="w-75">
          <thead>
            <tr>
              <th>Select
                {/* <Form.Check
                  checked={isCheckAll} onClick={handleSelectAll}>
                </Form.Check> */}
              </th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Room Number</th>
              <th>First Arrival Date</th>
              <th>last Checkin</th>
              <th>Expected Checkout</th>
            </tr>
          </thead>
          {currentGuestsList.map((row, index) => 
            <tr>
              <Form.Check className = "w-0 ms-5 me-0" id={row.id} checked={isCheck[row.id]}  
                onChange={(e)=>{
                  if(e.target.checked)
                    selectedPhoneNumbers.push(row.phone);
                  else{
                    const index = selectedPhoneNumbers.indexOf(row.phone);
                    if (index > -1) { // only splice array when item is found
                      selectedPhoneNumbers.splice(index, 1); // 2nd parameter means remove one item only
                    }
                  } 
                }}>
              </Form.Check>
              <td>{row.fname+" "+row.lname}</td>
              <td>{row.phone}</td>
              <td>{row.roomid}</td>
              <td>{row.firstArrivalDate}</td>
              <td>{row.checkin}</td>
              <td>{row.checkout}</td>
            </tr> 
          )}
        </Table>
        </Tab>

  <     Tab eventKey={2} title="Previous Guests" className="ms-5">
          <Form.Select className="mb-3 ms-5 w-25"
            onChange={(e)=>filterPreviousGuests(e.target.value)}>

              <option value="7">Last Week</option> 
              <option value="30">Last Month</option>
              <option value="0">All</option>
                
          </Form.Select>
          <Table className="w-75">
            <thead><tr>
                <th></th>
                <th>Name</th>
              <th>Phone Number</th>
              <th>Room Number</th>
              <th>First Arrival Date</th>
              <th>last Checkin</th>
              <th>Expected Checkout</th>
              </tr></thead>
            {prevGuestsList.map((row, index) => 
              <tr>
                <Form.Check className="ms-3 mt-2" ></Form.Check>
              <td>{row.fname+" "+row.lname}</td>
              <td>{row.phone}</td>
              <td>{row.roomid}</td>
              <td>{row.firstArrivalDate}</td>
              <td>{row.checkin}</td>
              <td>{row.checkout}</td>
            </tr> 
        )}
            </Table>

      </Tab>
  <Tab eventKey={3} title="Inqueries" size="lg" className="ms-5">
      <Form.Select style={{float:"left"}} className="mb-3 ms-5 w-25" onChange={(e)=>filterInqueries(e.target.value)}>
            
      {/* default */}
      <option value="0">All</option>
        <option value="7">Last Week</option> 
        <option value="30">Last Month</option>
       
      </Form.Select>
      <br></br>
      <label style={{float:"right",marginRight:"50%"}}><b>Count : {potentialGuestsList.length}</b></label>
      <Table className="w-75">
        <thead>
        <tr>
          <th></th>
          <th>Name</th>
        <th>Phone Number</th>
        <th>Contacted Date</th>
        <th>Inquery Status</th>
        <th>Call Back Date</th>
        <th>Call Regarding</th>
        <th>Latest Messaged Date</th>
        <th>Latest Message Type</th>
        <th>Message History</th>
        </tr>
        </thead><>
        {potentialGuestsList.map((row, index) => (
          <tr >
          
          <td><Form.Check className="ms-2 mt-2" onChange={(e)=>{
            if(e.target.checked)
            selectedPhoneNumbers.push(row.phoneNumber);
            else{
              const index = selectedPhoneNumbers.indexOf(row.phoneNumber);
              if (index > -1) { // only splice array when item is found
                selectedPhoneNumbers.splice(index, 1); // 2nd parameter means remove one item only
              }
            } }}>
          </Form.Check></td>
          <td>{row.name}</td>
          <td>{row.phoneNumber}</td>
          <td>{row.contactedDate}</td>
          <td>{row.callStatus}</td>
          <td>{row.callBackDate}</td>
          <td>{row.message}</td>
          <td></td>
          <td></td>
          <td></td>
          
          
        </tr>
        ))}
        </>
        
      </Table>
    </Tab>
    <Tab eventKey={4} title="Settings" className="ms-5" size="lg">

    </Tab>
  </Tabs>
      <Modal show={showMessagePopup} onHide={handleCloseMessagePopup}>
        <Modal.Header closeButton>
          {responseForSms ? responseForSms: selectedPhoneNumbers.length +" Numbers Selected"}
        </Modal.Header>
        <ModalBody>
        
        <div style={{display:"inline"}}><Form.Label>Message Type</Form.Label>
        
          <Form.Select >
            <option>Welcome</option>
            <option>Wifi Remainder</option>
            <option>Offer</option>
            <option>Checkout Remainder</option>
            <option>Quality of Checkin</option>
            <option>Quality of Stay</option>
            <option>Policy Remainder</option>
            <option>Feedback</option>
          </Form.Select></div>
          <br></br>
          <Form.Control as="textarea" size="lg" value={description} onChange={(e)=>setDescription(e.target.value)}></Form.Control>
         
        
        <Modal.Footer>
        <Button onClick={handleCloseMessagePopup}>Cancel</Button>
          <Button type="submit" onClick={sendSms}>Send Text</Button>
        </Modal.Footer>

    </ModalBody>

      </Modal>

       {showAddInqueryPopup && <AddInquery setShowAddInqueryPopup={setShowAddInqueryPopup}/>}
        
      </div>


  );
};
 
export default SendSMS;

