import React, {useState,useEffect,useContext,forwardRef,useImperativeHandle,useRef} from 'react'
  import axios from 'axios'
  import {
    Table,
    Popover,
    Whisper,
    Checkbox,
    Dropdown,
    IconButton,
    Progress,
  } from 'rsuite';
  
import { eachDayOfInterval, format, parseISO  } from 'date-fns';
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button'
  import { Row, Col } from 'react-bootstrap'
  import {
    formatDate,
    parseResponseJSON,
    logMe,
    getDataSorted,
    formatDateText,
  } from './CommonFunctions';
  import Header from "./Header.js"
const { Column, HeaderCell, Cell } = Table;
let completeOccupancy=0;
const FreeDaysDeposit = (props) => {
    const [startDate,setStartDate]=useState(formatDate(new Date(),-7));
    const [endDate,setEndDate]=useState(formatDate(new Date()));
    const url = process.env.REACT_APP_SERVER_URL;
    const [freeDaysData,setFreeDaysData]=useState([]);
    const [rooms,setRooms]=useState([]);
    
    useEffect(() => {
        getFreeRoomsByDate();
        // testForPostRequest();
      
    }, []);
    
    const getFreeRoomsByDate= async() => {
      try{
        await axios.post(`${url}/vacancyCheckByDateRange`,{
          startDate,
          endDate
      }).then( async(responseForRooms) =>{
          console.log(responseForRooms.data);
          const response1=await axios.get(`${url}/rooms`);
          if(response1){
            const temp=response1.data;
            const rooms=temp.filter(roomId => !roomId.rentable);
            setFreeDaysData(preProcess(responseForRooms.data,startDate,endDate,rooms));
          }
          
        })
      }catch(error)
      {
          console.log(error);
      }
    }
    const preProcess = (data,startDate,endDate,rooms)=>{
      let myDict={};
      console.log("start Date : " + startDate + " end date : " + endDate);
      const interval = {
        // start: new Date(startDate).toUTCString(),
        // end: new Date(endDate).toUTCString()
        start: parseISO(startDate),
        end: parseISO(endDate)
      };
      const dates = eachDayOfInterval(interval).map(date => format(date, 'yyyy-MM-dd'));

      // Create a dictionary to hold the result
      const result = {};
      dates.forEach(date => {
        result[date] = [];
      });      

  // Iterate through each room and its tenancies
  Object.entries(data).forEach(([roomId, tenancies]) => {
    tenancies.forEach(tenancy => {
      const checkInDate = format(parseISO(tenancy.checkin), 'yyyy-MM-dd');
      let checkoutDate=null;
      if(tenancy.actualCheckoutDate===null){

      }
      else{
        checkoutDate = format(parseISO(tenancy.actualCheckoutDate), 'yyyy-MM-dd');
      }

      dates.forEach(date => {
        if(checkoutDate===null){
          if (date >= checkInDate) {
            result[date].push(parseInt(roomId));
          }
        }
        else{
          if (date >= checkInDate && date < checkoutDate) {
            result[date].push(parseInt(roomId));
          }
        }
        
      });
    });
  });
  const resultArray=[];
  for(let key in result){
    const occupiedRooms=result[key];
    console.log(occupiedRooms)
    const vacantRooms= rooms.filter(row => !occupiedRooms.includes(row.id));
    let lostRent=0;
    vacantRooms.map((row)=>{
      lostRent +=row.dailyRate;
    })
    resultArray.push({
      date:key,
      occupiedRooms:occupiedRooms,
      vacantRooms:vacantRooms,
      lostRent:lostRent
    })

  }
  console.log(resultArray)

  return resultArray;

    }

    return (
        <>
        <Header/>
        <div class="container">
        <Row className="ms-5 mt-2">
        <Col>
              <Form.Label><b>Start Date:</b></Form.Label>
              <Form.Control
                className="w-75"
                type="date"
                value ={startDate} 
                onChange={(e)=>{
                  setStartDate(e.target.value);
                }}
                aria-describedby="basic-addon1"
              />
              </Col>
              <Col>
                <Form.Label><b>End Date:</b></Form.Label>
                <Form.Control
                  className="me-5 w-75"
                  type="date"
                  max={new Date()}
                  value ={endDate} 
                  onChange={(e)=>{
                    setEndDate(e.target.value);
                  }}
                  aria-describedby="basic-addon1"
                />
              </Col>
              <Col className='mt-4'>
              <Button onClick={(e)=>getFreeRoomsByDate()}>
                Search</Button>
              </Col>
        </Row>
        <br></br>
        <Table data={freeDaysData} height={480} className="ms-5" headerHeight={60}>
            
            <Column width={220} sortable align="left">
          <HeaderCell><b>Date</b></HeaderCell>
          <Cell dataKey="generated_date">
          {rowData => (
              <>{formatDateText(rowData.date)}
              </>    
          )}
          </Cell>
        </Column>
        
        <Column width={290} sortable align="left">
          <HeaderCell><b>Rooms that are vacant</b></HeaderCell>
          <Cell dataKey="roomsArray">
          {rowData => (
            <div>
            {rowData.vacantRooms.map(room => room.id).join(', ')}
        </div>
                 
          )}

          </Cell>
        </Column>
        <Column width={180}>
          <HeaderCell><b>No.of Rooms</b>
          <br></br><b style={{color:"blue"}}>No. of days motel is full: {completeOccupancy}</b>
          </HeaderCell>
          <Cell dataKey="roomsArray" >
          {rowData => (
              <>
                { rowData.vacantRooms.length==0 ? <b style={{color:"green"}}> Fully rented </b>: rowData.vacantRooms.length }
              </>    
          )}
          </Cell>
        </Column>
        <Column width={180}>
          <HeaderCell><b>Predicted Lost Rent</b>
          </HeaderCell>
          <Cell dataKey="roomsArray" >
          {rowData => (
              <>
                { "$"+rowData.lostRent }
              </>    
          )}
          </Cell>
        </Column>

        </Table>
        </div>
        </>

    );
}
export default FreeDaysDeposit;