import React, { useState, useEffect, useContext } from 'react'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import CommonComponents from './CommonComponents.js'
import { Rate } from 'rsuite'
// const {id}=useParams();
const AddReview = (data) => {
  const [payOnTime, setPayOnTime] = useState()
  const [overallRating, setOverallRating] = useState()
  const [cleanliness, setCleanliness] = useState()
  const [traffic, setTraffic] = useState()
  const [noisy, setNoisy] = useState()
  const [managerComments, setManagerComments] = useState('')
  const [reviewId, setReviewId] = useState('')
  const [isAddReview, setIsAddReview] = useState(true)
  const { displayNotificationMessage } = useContext(CommonComponents)
  const { id } = useParams()
  const navigate = useNavigate()
  const url = process.env.REACT_APP_SERVER_URL

  const getReviewsId = async () => {
    try {
      await axios
        .get(`${url}/getCustomerReviewById/${data.data.isEditReview}`)
        .then((responseForReviews) => {
          setCleanliness(responseForReviews.data.cleanliness)
          setOverallRating(responseForReviews.data.overallRating)
          setTraffic(responseForReviews.data.traffic)
          setNoisy(responseForReviews.data.noisy)
          setManagerComments(responseForReviews.data.managerComments)
          setPayOnTime(responseForReviews.data.payOnTime)
        })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (data.data) {
      // setReviewId(data.data.isEditReview)
      getReviewsId()
      setReviewId(data.data.isEditReview)
    }
  }, [])
  const createCustomerReview = async (e) => {
    if (e) e.preventDefault()
    try {
      const tenancyId = id
      await axios
        .post(`${url}/tenants/${id}/customerReviews`, {
          tenancyId,
          overallRating,
          managerComments,
          payOnTime,
          cleanliness,
          traffic,
          noisy,
        })
        .then((responseForCreatingGuestReview) => {
          console.log(responseForCreatingGuestReview.data)

          navigate(`/tenants/add/${id}`)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const editCustomerReview = async (e, reviewId) => {
    if (e) e.preventDefault()
    try {
      await axios
        .patch(`${url}/updateCustomerReview/${reviewId}`, {
          overallRating,
          managerComments,
          payOnTime,
          cleanliness,
          traffic,
          noisy,
        })
        .then((responseForUpdatingGuestReview) => {
          handleCloseAddReview()
          displayNotificationMessage('success', 'Guest Review Updated!')
        })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCustomerReview = async (e, reviewId) => {
    if (e) e.preventDefault()
    try {
      await axios
        .delete(`${url}/tenants/${id}/customerReviews/${reviewId}`)
        .then((responseForDeletingReview) => {
          console.log(responseForDeletingReview)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseAddReview = () => {
    setIsAddReview(false)
    if (!data.data) navigate(`/tenants/add/${id}`)
    data.data.setIsEditReview(null)
  }

  return (
    <>
      <Modal
        show={isAddReview}
        onHide={handleCloseAddReview}
        style={{ zIndex: 2000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {reviewId ? 'Update Review' : 'Create Review'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="me-3">
          <div>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Overall Rating :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 pe-4 mt-1">
                <Rate
                  className="mt-1"
                  value={overallRating}
                  defaultValue={0}
                  size="xs"
                  onChange={(value) => setOverallRating(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Payment On Time :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 pe-4 mt-1">
                <Rate
                  className="mt-1"
                  value={payOnTime}
                  defaultValue={0}
                  size="xs"
                  onChange={(value) => setPayOnTime(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Cleanliness :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 pe-4 mt-1">
                <Rate
                  className="mt-1"
                  value={cleanliness}
                  defaultValue={0}
                  size="xs"
                  onChange={(value) => setCleanliness(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Lack of traffic :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 pe-4 mt-1">
                <Rate
                  className="mt-1"
                  value={traffic}
                  defaultValue={0}
                  size="xs"
                  onChange={(value) => setTraffic(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Quietness :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 pe-4 mt-1">
                <Rate
                  className="mt-1"
                  value={noisy}
                  defaultValue={0}
                  size="xs"
                  onChange={(value) => setNoisy(value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="ms-5 mt-2" style={{ float: 'right' }}>
                  <b>Manager Comments :</b>
                </Form.Label>
              </Col>
              <Col className="w-25 me-5 mt-1">
                <Form.Control
                  as="textarea"
                  className="mt-1 me-5"
                  value={managerComments}
                  onChange={(e) => setManagerComments(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {reviewId ? (
            <Button
              style={{ float: 'right' }}
              onClick={(e) => editCustomerReview(e, reviewId)}
            >
              Update
            </Button>
          ) : (
            <Button
              style={{ float: 'right' }}
              onClick={(e) => createCustomerReview(e)}
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default AddReview
