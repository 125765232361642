import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { formatDateText, parseResponseJSON} from "./CommonFunctions";
import { Box } from "@mui/material";
import { DataGrid,GridPagination } from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";

const TenancyDetailsByLTTI = ({tenancyDetailsProps,longTermTenancyId,
  showTenancyDetailsByLTTIModal,setShowTenancyDetailsByLTTIModal}) => {
   
    
    const url=process.env.REACT_APP_SERVER_URL;
    const [tenancyDetails,setTenancyDetails]=useState([]);
    const [totalRent,setTotalRent]=useState(0);
    const [totalAmountPaid,setTotalAmountPaid]=useState(0);
    var runningBalance=0;
    
      const handleCloseTenancyDetailsByLTTIPopup=()=>{
        setShowTenancyDetailsByLTTIModal(false)
      }
      useEffect(() => {
        if(tenancyDetailsProps){
          // console.log(preProcessData(tenancyDetailsProps))
          setTenancyDetails(preProcessData(tenancyDetailsProps))
        }
        else{
          if(longTermTenancyId){
            getTenancyInfoByLTTI(longTermTenancyId);
          }
        }
        
        
        
      }, []);
      const getTenancyInfoByLTTI = async(longTermTenancyId) =>{
        try{
          await axios.get(`${url}/payments/getAllPaymentsByLLTI/${longTermTenancyId}`).then((responseForPaymentDetails)=>{
            console.log(responseForPaymentDetails.data);
             setTenancyDetails(preProcessData(responseForPaymentDetails.data));
          })
    
        }catch(error){
              console.log(error);
          }
      }
      

      const preProcessData =(tenancyDetailsProps)=>{
        const payments=tenancyDetailsProps.payments;
        const tenancyDetails=tenancyDetailsProps.tenancyDetails;  
        var tenancyIdToPaymentsDict={};
        for(var itr in payments){
          var current=payments[itr];
          if(current.length==0){
              continue;
          }
          else{
            const tenancyId=current[0].tenancyId;
            tenancyIdToPaymentsDict[tenancyId]=current;

          }
        }
        var returnArray=[];
        var totalRent=0,totalAmountPaid=0;
        for(var itr in tenancyDetails){
          var tempPayments=[];
          var amountPaid=0;
          const currentTenancyDetails=tenancyDetails[itr];
          if(currentTenancyDetails.id in tenancyIdToPaymentsDict){

            tempPayments=tenancyIdToPaymentsDict[currentTenancyDetails.id];

            
            for(var itr in tempPayments){
              const current=tempPayments[itr];
              var temp=0;
              if(current.cashAmount!==null){
                temp+=current.cashAmount;
              } 
              if(current.creditCardAmount!==null){
                temp+=current.creditCardAmount;
              } 
              if(current.otherAmount!==null){
                temp+=current.otherAmount;

              }
              amountPaid+=temp;

            }
          }
          totalRent+=currentTenancyDetails.rate;
          totalAmountPaid+=amountPaid;
          returnArray.push({
            roomid:currentTenancyDetails.roomid,
            checkin:currentTenancyDetails.checkin,
            checkout:currentTenancyDetails.checkout,
            tenancyId:currentTenancyDetails.id,
            tenancyDetails:currentTenancyDetails,
            payments:tempPayments,
            amountPaid:amountPaid,
            runningBalance:totalRent-totalAmountPaid
          })

        }
        setTotalRent(totalRent)
        setTotalAmountPaid(totalAmountPaid);

        return returnArray;
      }
      const columns=[
        { field: 'roomid', headerName: 'Room Number', width: 100, align: 'left'},
        { field: 'tenancyId', headerName: 'Booking Id', width: 100, align: 'left'},
        { field: 'checkin', headerName: 'Tenancy Period', width: 300, align: 'left' ,
          renderCell:(params)=>{
              return formatDateText(params.row.checkin) + ' to ' + formatDateText(params.row.checkout)
          } },
        { field: 'rent', headerName: 'Rent', width: 150, align: 'left' ,
            renderCell:(params)=>{
              runningBalance+=params.row.tenancyDetails.rate;
                return <NumericFormat value={params.row.tenancyDetails.rate} 
                  thousandSeparator prefix={'$'} displayType="text"/>
            } },
        { field: 'amountPaid', headerName: 'Amount Paid', width: 150, align: 'left' ,
          renderCell:(params)=>{
            runningBalance-=params.row.amountPaid;
            return <NumericFormat value={params.row.amountPaid} 
              thousandSeparator prefix={'$'} displayType="text"/>
          } },
          { field: 'runningBalance', headerName: 'Running Balance', width: 150, align: 'left' ,
            renderCell:(params)=>{
              return <NumericFormat value={params.row.runningBalance} 
                thousandSeparator prefix={'$'} displayType="text"/>
            } },
      ];
      function CustomPagination(props) {
        return <>
        <b>Expected Rent:
            <NumericFormat value={totalRent} thousandSeparator prefix={'$'} displayType="text"/></b>
            <b>Received Amount
            <NumericFormat value={totalAmountPaid} thousandSeparator prefix={'$'} displayType="text"/></b>
            <GridPagination  {...props} /> </>;
      }

   
    
    return (
        <>
      <Dialog
        open={showTenancyDetailsByLTTIModal}
        onClose={handleCloseTenancyDetailsByLTTIPopup}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle> 
          {/* <IconButton aria-label="close" onClick={handleCloseTenancyDetailsByLTTIPopup}>
            <CancelIcon />
        </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <Box
        sx={{
          height: 400,
          width: '97%',
          marginLeft:'1.5%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={tenancyDetails} columns={columns}
        getRowId={(row) => row.tenancyId}
        slots={{
          pagination: CustomPagination,
        }}
        />
        
      </Box>
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTenancyDetailsByLTTIPopup}>Cancel</Button>
          {/* <Button type="submit" onClick={(e)=>{
            
          }}>Save</Button> */}
        </DialogActions>
      </Dialog>
                
            
        </>
    );

}
export default TenancyDetailsByLTTI;